import { Component, OnChanges, OnDestroy, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { timer, Observable, Subscription } from 'rxjs';
import $ from 'src/app/shared/jquery';
import { switchMap, tap } from 'rxjs/operators';
import { TournamentService } from 'src/app/services/tournament.service';
import { environment } from 'src/environments/environment';
import * as _ from 'underscore';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LeagueTableInterface } from '@interfaces/league-table.interface';

@Component({
    selector: 'app-league-table',
    templateUrl: './league-table.component.html',
})
export class LeagueTableComponent implements OnChanges, OnDestroy {
    @Input()
    public subTournamentId: number;

    @Input()
    public seasonId: number | any = null;

    @Input()
    public participantHome: number;

    @Input()
    public isLive: boolean = false;


    /**
     * Show only one table not all, must be selected
     */
    @Input()
    public onlyOne: boolean = false;

    @Input()
    public participantAway: number;

    @Input()
    public sportName: string;

    @Input()
    public sportId: number;

    @Output()
    public onLoad:EventEmitter<Record<string, any>> = new EventEmitter();

    public table: Subscription;
    public tab: LeagueTableInterface | null = null;

    private first: boolean = true;

    public selected: number | string = -1;

    // eslint-disable-next-line no-useless-constructor
    public constructor(
        private tournamnet: TournamentService,
        private deviceService: DeviceDetectorService,
        private ref: ChangeDetectorRef,
    ) {}


    public ngOnChanges(): void {
        if (_.isUndefined(this.seasonId)) {
            this.seasonId = '';
        }
        if (this.table) {
            this.table.unsubscribe();
        }
        this.table = timer(0, environment.leagueTableReloadTime || 15000)
            .pipe(
                switchMap((): Observable<any> => this.tournamnet.getLeagueTable(this.seasonId, this.subTournamentId)),
                tap((): void => {
                    setTimeout((): void => {
                        $('[data-toggle="tooltip"]').tooltip();
                    }, 1000);
                }),
            )
            .subscribe((tab: LeagueTableInterface) => {
                if (this.onlyOne && tab.tables.length > 1 && this.first) {
                    // @ts-ignore
                    const index = tab.tables.findIndex((tab) => {
                        const subIndex = tab.data.total.find(
                            (p: Record<string, any>) => p.participant_id === this.participantHome);
                        return !!subIndex;
                    });
                    this.selected = index || 0;
                }

                const isLive = tab.tables.some((tab => tab.isLive));

                if (isLive) {
                    this.onLoad.emit({ isLive });
                }

                this.first = false;
                this.tab = tab;
            });
    }

    public ngOnDestroy(): void {
        this.table.unsubscribe();
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public trackByIndex(index: any): any {
        return index;
    }

    public get isMobile(): boolean {
        return this.deviceService.isMobile();
    }
}
