import { WindowRefService } from '@/shared/window-ref';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-isport',
    templateUrl: './isport.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ISportComponent implements OnInit {
    @Input()
    public video: string;

    @Input()
    public fullScreen: boolean = false;

    public currentStyles: Record<string, string> = {};

    constructor(private win: WindowRefService) {}

    ngOnInit(): void {
        this.setCurrentStyles();
    }

    public get url(): string {
        return `https://tv.isport.blesk.cz/embed/${this.video}`;
    }

    private setCurrentStyles(): void {
        if (this.fullScreen) {
            this.currentStyles = {
                height: 'calc(55vw)',
            };
        }
    }
}
