<!-- BATTING-->
<div class="match-info__table small pb-2 text-center mt-3">
    <div
        class="match-info__table__row app-lineup-row player-stats-row"
        *ngFor="let wicket of wickets; trackBy: trackByFn"
    >
        <div class="match-info__table__cell match-info__table__cell--number text-left">
            {{ wicket.display_overs }}
        </div>
        <div
            class="match-info__table__cell match-info__table__cell--name font-weight-bold player-stats-row__player-name text-left cricket-innings-player-name"
        >
            {{ wicket.dismissal_params?.player?.name ?? '-' }}
        </div>
        <div class="match-info__table__cell match-info__table__cell--name text-left player-stats-row__player-name">
            <ng-container *ngIf="!wicket.dismissal_params?.dismissal_details"> - </ng-container>
            <ng-container *ngIf="wicket.dismissal_params?.dismissal_details">
                c: {{ wicket.dismissal_params?.dismissal_details.fielder_name }} b:
                {{ wicket.dismissal_params?.dismissal_details.bowler_name }}</ng-container
            >
        </div>

        <div class="match-info__table__cell match-info__table__cell--number text-center">
            {{ wicket.display_score }}
        </div>
    </div>
</div>
