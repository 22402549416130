/* eslint-disable @typescript-eslint/naming-convention */
import { BaseComponent } from '@components/base.component';
import { Component, Input } from '@angular/core';
import { Player, HockeyPosition, PlayerStatistics } from '@interfaces/match-data.interface';
import { isUndefined } from 'lodash-es';

@Component({
    selector: 'app-lineups-ice-hockey-field',
    templateUrl: './field.component.html',
})
export class LineupHockeyFieldComponent extends BaseComponent {
    @Input()
        formationH: {[key: string]: Player[] };

    @Input()
        formationA: {[key: string]: Player[] };

    @Input()
    public statistics: PlayerStatistics | null = null;

    public fVal: '1' | '2' | '3' | '4' = '1';

    constructor() {
        super();
    }

    public formationClass(side: 'home' | 'away', formation?: string | number): Record<string, boolean> {
        const classStyle = {};

        if (side === 'home') {
            if (formation === HockeyPosition.RIGHTBACK) {
                Object.assign(classStyle, { 'ice-hockey-lineup-rightBackHome': true });
            } else if (formation === HockeyPosition.LEFTBACK) {
                Object.assign(classStyle, { 'ice-hockey-lineup-leftBackHome': true });
            } else if (formation === HockeyPosition.LEFTWINK) {
                Object.assign(classStyle, { 'ice-hockey-lineup-leftWinkHome': true });
            } else if (formation === HockeyPosition.RIGHTWINK) {
                Object.assign(classStyle, { 'ice-hockey-lineup-rightWinkHome': true });
            } else if (formation === HockeyPosition.CENTER) {
                Object.assign(classStyle, { 'ice-hockey-lineup-centerHome': true });
            }
        }

        if (side === 'away') {
            if (formation === HockeyPosition.RIGHTBACK) {
                Object.assign(classStyle, { 'ice-hockey-lineup-rightBackAway': true });
            } else if (formation === HockeyPosition.LEFTBACK) {
                Object.assign(classStyle, { 'ice-hockey-lineup-leftBackAway': true });
            } else if (formation === HockeyPosition.LEFTWINK) {
                Object.assign(classStyle, { 'ice-hockey-lineup-leftWinkAway': true });
            } else if (formation === HockeyPosition.RIGHTWINK) {
                Object.assign(classStyle, { 'ice-hockey-lineup-rightWinkAway': true });
            } else if (formation === HockeyPosition.CENTER) {
                Object.assign(classStyle, { 'ice-hockey-lineup-centerAway': true });
            }
        }

        return classStyle;
    }

    public playerGoal(side: 'home' | 'away', id: number): number | null {
        if (!this.statistics || !this.statistics[side] || !this.statistics[side][id]) {
            return null;
        }

        const numPoints = this.statistics?.[side]![id]!.statistics.Points ?? 0;
        const numAssists = this.playerAssits(side, id) ?? 0;
        const total = +numPoints - +numAssists;
        return total > 0 ? total : null;
    }

    public playerAssits(side: 'home' | 'away', id: number): number | null {
        if (!this.statistics || !this.statistics[side] || !this.statistics[side][id]) {
            return null;
        }
        const numAssists = this.statistics?.[side]![id]!.statistics.Assists ?? null;
        return numAssists ? +numAssists : null;
    }

    get isSet(): boolean {
        return '1' in this.formationH || '1' in this.formationA;
    }

    get mergeFormation(): {[key: string]: Player[] } {
        return { ...this.formationH, ...this.formationA };
    }

    get homeGoalie(): Player | undefined {
        return this.formationH?.['null']!.find(p => p.lineupPosition === HockeyPosition.GOALIE);
    }

    get awayGoalie(): Player | undefined {
        return this.formationA?.['null']!.find(p => p.lineupPosition === HockeyPosition.GOALIE);
    }

    public filterFormation(): (data:{key: any, value: any}[]) => any[] {
        return (data: {key: any, value: any}[]): any[] => {
            if (isUndefined(data) || !data) {
                return [];
            }

            return data.filter((val): boolean => val.key !== 'null');
        };
    }

    public setFormation(formation: '1' | '2' | '3' | '4'): void {
        this.fVal = formation;
    }
}
