import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy } from '@angular/core';


import { SportConfigToken } from '@config/sport.config';

import { TranslateService } from '@ngx-translate/core';

import { OptaWidgetBase } from '../base.service';

declare const Opta: any;

@Component({
    selector: 'app-opta-action-areas',
    templateUrl: './action-areas.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptaWidgetActionAreasComponent extends OptaWidgetBase implements AfterViewInit, OnDestroy {
    @Input()
        matchId: string | number | null | undefined;

    @Input()
        competitionId: string | number | null | undefined;

    @Input()
        seasonId: string | number | null | undefined;

    @Input()
        template = 'normal';

    #headlineTrans = 'web.opta_action_areas';

    private transHeadline = '';


    public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>,
        public translate: TranslateService) {
        super();
    }

    public ngAfterViewInit(): void {
        this.transHeadline = this.translate.instant(this.#headlineTrans);
        setTimeout(() => {
            const element = document.querySelector('#opta-action-areas') as HTMLElement;

            if (element) {
                element.innerHTML = this.getHtml();
                const optaWidgetTags = document.querySelector('opta-widget[load="false"]');

                if (optaWidgetTags) {
                    optaWidgetTags.removeAttribute('load');
                    Opta.start();
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (Opta.widgets.ref_widget_action_areas) {
            Opta.widgets.ref_widget_action_areas.destroy(true);
        }
    }

    getHtml(): string {
        return `<opta-widget widget_id="ref_widget_action_areas" widget="action_areas" 
        competition="${this.competitionId}" season="${this.seasonId}" match="${this.matchId}" 
        template="${this.template}" live="true" load="false" animation="true" 
    show_match_header="false" show_crests="false"  show_splits_mins_5="false" show_splits_mins_15="true" 
    show_splits_mins_45="true" show_categories="both"
    navigation="tabs_more" default_nav="1"
    show_pitch_crests="false" show_competition_name="true" competition_naming="full" show_date="true" 
    date_format="dddd D MMMM YYYY" narrow_date_format="DD/MM/YY" team_naming="full" 
    show_timecontrols="true" title="${this.transHeadline}" show_title="true" show_logo="false" 
    show_live="true" breakpoints="400, 700" sport="football"></opta-widget>`;
    }
}
