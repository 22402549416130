<div mat-dialog-content id="app-cuptree-content">
    <style>
        .example-handle {
            position: absolute;
            top: 10px;
            right: 10px;
            color: #ccc;
            cursor: move;
            width: 24px;
            height: 24px;
            z-index: 9999;
        }
    </style>

    <div
        *ngIf="displayActions"
        _ngcontent-flj-c24=""
        class="example-handle"
        style="touch-action: none; -webkit-user-drag: none; -webkit-tap-highlight-color: transparent; user-select: none"
    >
        <svg _ngcontent-flj-c24="" fill="currentColor" viewBox="0 0 24 24" width="24px">
            <path
                _ngcontent-flj-c24=""
                d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
            ></path>
            <path _ngcontent-flj-c24="" d="M0 0h24v24H0z" fill="none"></path>
        </svg>
    </div>

    <div *ngIf="cuptree | async as tree; else elseCupTree" [style.transform]="transform" id="cup-tree-parent">
        <div [innerHtml]="tree.data" style="cursor: move"></div>
        <div
            *ngIf="tree.data.length === 0"
            class="tab-pane mb-4"
            id="tab3-content"
            role="tabpanel"
            aria-labelledby="tab3"
        >
            <div class="text-center pt-7">
                <svg class="icon icon--64 mb-4"><use xlink:href="#icon-cup" /></svg>
                <h4 class="font-weight-bold">{{ 'web.no_cuptree' | translate }}</h4>
                <p class="mb-6">{{ 'web.error_cuptree' | translate }}</p>
            </div>
        </div>
    </div>

    <ng-template #elseCupTree><app-page-loader></app-page-loader></ng-template>
</div>
<div mat-dialog-actions style="top: 0px; right: 100px" *ngIf="displayActions">
    <button class="btn btn-outline-secondary btn-sm" (click)="onNoClick()">{{ 'web.close' | translate }}</button>
</div>
