/**
 *  Abstract class
 *  @author Livescore <it@score-stats.com>
 *  @copyright 2024 24live
 */

import { BannerInterface } from 'src/app/interfaces/banner.interface';


export abstract class OptaWidgetBase {
    public bannerPositionId = null;

    public banner: BannerInterface | null = null;


    public constructor() {

    }

    abstract getHtml(): string;
}
