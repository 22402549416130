/**
 *  Service for tournaments
 *  @author Livescore <score-stats.com>
 *  @copyright 2019 livescore
 */

import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, share, take, tap } from 'rxjs/operators';

import { CommonService } from '../shared/common.service';
import { LangService } from '../services/lang.service';

import { TournamentInterface } from '../interfaces/tournament.interface';
import { CuptreeInterface } from '../interfaces/cup-tree.interface';
import { LeagueTableInterface } from '../interfaces/league-table.interface';
import URL_CONFIG from '../config/url.config';
import CONFIG from '../config/app.config';
import { PlayerStatsInterface } from '../interfaces/player-stats.interface';

import { UtilsService } from './utils.service';

@Injectable({
    providedIn: 'root',
})
export class TournamentService {
    public loaded: EventEmitter<number | null> = new EventEmitter();

    public constructor(
        private http: HttpClient,
        private common: CommonService,
        private trans: TranslateService,
        private lang: LangService,
    ) {}

    /**
     * Get League table
     * @return [description]
     */
    public getLeagueTable(
        seasonId: any,
        subtournamnetId?: any,
    ): Observable<LeagueTableInterface> {
        let iso = this.lang.getLangSnapshot();

        if (iso == null) {
            iso = CONFIG.defaultLang;
        }

        if (subtournamnetId === null) {
            subtournamnetId = '';
        }

        const url = UtilsService.replace(
            'seasonId',
            seasonId as string,
            URL_CONFIG.api.getLeagueTable,
        );
        const params = new HttpParams()
            .set('lang', iso)
            .set('subtournamentId', subtournamnetId)
            .set('seasonId', seasonId);
        const options = params ? { params } : {};
        return this.http.get<LeagueTableInterface>(url, options).pipe(
            catchError(this.common.errorCallback2()), // then handle the error
            tap(this.common.networkOnline()),
        );
    }

    /**
     * Get Tournament Player stats
     * @return [description]
     */
    public getPlayerStats(
        seasonId: any,
        subtournamentId?: any,
    ): Observable<PlayerStatsInterface[]> {
        let iso = this.lang.getLangSnapshot();

        if (iso == null) {
            iso = CONFIG.defaultLang;
        }

        if (subtournamentId === null) {
            subtournamentId = '';
        }

        const url = UtilsService.replace(
            'seasonId',
            seasonId as string,
            URL_CONFIG.api.getPlayerStats,
        );
        const params = new HttpParams()
            .set('lang', iso)
            .set('subtournamentId', subtournamentId)
            .set('seasonId', seasonId);
        const options = params ? { params } : {};
        return this.http.get<PlayerStatsInterface[]>(url, options).pipe(
            catchError(this.common.errorCallback), // then handle the error
        );
    }

    /**
     * Get Cup tree
     * @return [description]
     */
    public getCupTree(
        seasonId: any,
        subtournamnetId?: any,
    ): Observable<CuptreeInterface> {
        const iso = this.lang.getLangSnapshot();
        const url = UtilsService.replace(
            'seasonId',
            seasonId,
            URL_CONFIG.api.getCupTree,
        );

        if (subtournamnetId === null || subtournamnetId === 0 || subtournamnetId === '0') {
            subtournamnetId = '';
        }

        const seasonConv: unknown = +seasonId;
        const params = new HttpParams()
            .set('lang', iso as string)
            .set('subtournamentId', subtournamnetId)
            .set('seasonId', seasonConv as string);
        const options = params ? { params } : {};
        return this.http.get<CuptreeInterface>(url, options).pipe(
            share(),
            take(1),
            catchError(this.common.errorCallback2()), // then handle the error
            tap(this.common.networkOnline()),
        );
    }

    /**
     * Get torunament detail info
     * @param {number} tournamentId
     * @param {number} seasonId
     * @param {number} subId -  sub tournament id
     * @return {Promise<Observable<TournamentInterface>>}
     */
    public async getTournament(
        tournamentId: any,
        seasonId: any,
        subId: any,
        type: string = 'all',
        isFirst: boolean = true,
        limit: any = 100,
    ): Promise<Observable<TournamentInterface>> {
        // eslint-disable-next-line no-restricted-globals
        if (!tournamentId || isNaN(tournamentId)) {
            return Promise.resolve(throwError('Not valid tournament ID'));
        }

        const url = UtilsService.replace(
            'tournamentId',
            tournamentId as string,
            URL_CONFIG.api.getTournament,
        );

        if (+subId === 0) {
            subId = '';
        }
        return this.lang.getLang().then(
            (iso): Observable<TournamentInterface> => {
                const params = new HttpParams()
                    .set('lang', iso)
                    .set('section', type)
                    .set('seasonId', seasonId === null ? '' : seasonId)
                    .set('short', String(isFirst ? 0 : 1))
                    .set('limit', limit)
                    .set('sid', subId);
                const options = params ? { params } : {};
                return this.http.get<TournamentInterface>(url, options).pipe(
                    catchError(this.common.errorCallback2()), // then handle the error
                    tap(this.common.networkOnline()),
                );
            },
            (): Promise<any> => Promise.reject(new Error()),
        );
    }

    /**
     * Emit data load
     * @fires TournamentService#loaded
     * @param  {number} tournamentId
     */
    public emitLoad(tournamentId: number | null): void {
        this.loaded.emit(tournamentId);
    }
}
