import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-placeholder-table',
    templateUrl: './placeholder-table.component.html',
    styleUrls: ['./placeholder-table.component.scss'],
})
export class PlaceholderTableComponent implements OnInit {
    public constructor() {}

    public ngOnInit(): void {}
}
