import { BaseComponent } from '@components/base.component';
import { Component, Input, OnInit } from '@angular/core';
import { CodeState } from '@interfaces/match-data.interface';


@Component({
    selector: 'app-timeline-cricket',
    templateUrl: './cricket-timeline.component.html',
})
export class CricketTimelineComponent extends BaseComponent implements OnInit {
    @Input() data: {home: string;away: string; home2: string| null;away2: string | null;
        groupedInnings:Record<string, [string, Record<string, any>[]][]>};

    @Input() sportName: string;
    @Input() sportId: number;
    @Input() codeState?: CodeState | null;
    @Input() isLive: boolean;

    private inning = 1;

    public activeCommentary: boolean = false;

    constructor() {
        super();
    }


    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngOnInit(): void {
        // console.log(this.data);
    }

    public setInning(inning: number) : void {
        this.inning = inning;
    }

    get actualInning(): [string, Record<string, any>[]][] | [] {
        if (this.data.groupedInnings) {
            return this.data.groupedInnings[this.inning] ?? [];
        }

        return [];
    }
}
