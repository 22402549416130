import { Component, OnInit, OnChanges, AfterViewChecked, Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import $ from '@shared/jquery';
import { Form } from '@interfaces/form-response.interface';
import { H2H, MatchSimple, Periods } from '@interfaces/match-data.interface';
import { Router } from '@angular/router';

import SPORT_CONFIG from '@/config/sport.config';

@Component({
    selector: 'app-h2h',
    templateUrl: './h2h.component.html',
})
export class H2hComponent implements OnInit, OnChanges, AfterViewChecked {
    @Input()
    public home: string;

    @Input()
    public away: string;

    @Input()
    public homeShort: string;

    @Input()
    public awayShort: string;

    @Input('home_logo')
    public homeLogo: string | undefined;

    @Input('away_logo')
    public awayLogo: string | undefined;

    @Input()
    public data: H2H;

    @Input()
    public tracker: any;

    @Input()
    public sportName: string;

    @Input()
    public sportId: number;

    public sportConfig: any;

    public langLoaded = false;

    public balance: {totalHome: [number, number], totalAway: [number, number], h2h: [number, number]} =
    { totalHome: [0, 0], totalAway: [0, 0], h2h: [0, 0] };

    public constructor(public trans: TranslateService, private router: Router) {
        this.sportConfig = SPORT_CONFIG;
    }

    public ngOnInit(): void {
        $('#h2hTab li:first-child a').tab('show');

        this.trans.get('web.last_matches_win_short').subscribe((): void => {
            this.langLoaded = true;
        });
        this.balance = { totalHome: [0, 0], totalAway: [0, 0], h2h: [0, 0] };
        this.countBalance('totalHome', 'home_team', this.home);
        this.countBalance('totalAway', 'away_team', this.away);
        this.countBalance('h2h', 'h2h', null);
    }

    public ngOnChanges(): void {
        this.balance = { totalHome: [0, 0], totalAway: [0, 0], h2h: [0, 0] };
        this.countBalance('totalHome', 'home_team', this.home);
        this.countBalance('totalAway', 'away_team', this.away);
        this.countBalance('h2h', 'h2h', null);
    }

    /**
     * Check if home team is winner
     * @return {boolean}
     */
    public isWinnerHome(match: MatchSimple): boolean {
        return +match.score.away_team < +match.score.home_team || match.score.home_extra_info[0] === Periods.AP;
    }

    public ngAfterViewChecked(): void {
        $('[data-toggle="tooltip"]').tooltip();
    }

    public trackByFn(index: any): any {
        return index;
    }

    /**
     * Check if away team is winner
     * @return {boolean}
     */
    public isWinnerAway(match: MatchSimple): boolean {
        return +match.score.away_team > +match.score.home_team || match.score.away_extra_info[0] === Periods.AP;
    }

    private countBalance(type: 'totalHome' | 'totalAway' | 'h2h', total: 'home_team' | 'away_team' | 'h2h',
        team: string | null): void {
        this.data.total[total].forEach(((val) => {
            const isHome = team === val.home_team || (!team && this.home === val.home_team) ||
            (!team && this.homeShort === val.home_team);

            if (type === 'h2h') {
                if (this.isWinnerHome(val) && isHome) {
                    this.balance[type][0] += 1;
                } else if (this.isWinnerAway(val) && !isHome) {
                    this.balance[type][0] += 1;
                } else if ((this.isWinnerHome(val) && !isHome) || (this.isWinnerAway(val) && isHome)) {
                    this.balance[type][1] += 1;
                }
            } else if (val.badge === 'W') {
                this.balance[type][0] += 1;
            } else if (val.badge === 'L') {
                this.balance[type][1] += 1;
            }
        }));
    }

    /**
     * Get status of match
     * @param  match
     * @return  {string}
     */
    public form(form: string): string {
        if (Form.WIN === form) {
            return this.trans.instant('web.last_matches_win_short');
        }
        if (Form.LOSE === form) {
            return this.trans.instant('web.last_matches_lose_short');
        }
        if (Form.DRAW === form) {
            return this.trans.instant('web.last_matches_draw_short');
        }
        if (Form.OTWIN === form) {
            return this.trans.instant('web.last_matches_ot_win_short');
        }
        if (Form.OTLOSE === form) {
            return this.trans.instant('web.last_matches_ot_lose_short');
        }

        return '';
    }

    public goToMatch(matchId: number): void {
        this.router.navigate(['/page', 'sport', 'match', `${this.sportName}-${this.sportId}`, matchId]);
    }
}
