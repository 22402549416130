import { MatchCommentary } from '@interfaces/match-data.interface';
import { Component, Input } from '@angular/core';
import { setup } from '@animations/showme.animation';
import { BaseComponent } from '@components/base.component';

@Component({
    selector: 'app-commentaries',
    templateUrl: './commentaries.component.html',
    animations: [setup(), setup('showme2', '1s', '1.5s')],
})
export class CommentariesComponent extends BaseComponent {
    @Input()
    data?: MatchCommentary[]
}
