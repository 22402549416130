import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy } from '@angular/core';


import { SportConfigToken } from '@config/sport.config';

import { TranslateService } from '@ngx-translate/core';

import { OptaWidgetBase } from '../base.service';

declare const Opta: any;

@Component({
    selector: 'app-opta-average-positions',
    templateUrl: './average-positions.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptaWidgetAveragePositionsComponent extends OptaWidgetBase implements AfterViewInit, OnDestroy {
    @Input()
        matchId: string | number | null | undefined;

    @Input()
        competitionId: string | number | null | undefined;

    @Input()
        seasonId: string | number | null | undefined;

    @Input()
        template = 'normal';

    #headlineTrans = `web.opta_average_positions_${this.template}`;
    private transHeadline = '';


    public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>,
        public translate: TranslateService) {
        super();
    }

    public ngAfterViewInit(): void {
        this.transHeadline = this.translate.instant(this.#headlineTrans);
        this.#headlineTrans = `web.opta_average_positions_${this.template}`;
        setTimeout(() => {
            const element = document.querySelector(`#opta-average-positions_${this.template}`) as HTMLElement;

            if (element) {
                element.innerHTML = this.getHtml();
                const optaWidgetTags = document.querySelector('opta-widget[load="false"]');

                if (optaWidgetTags) {
                    optaWidgetTags.removeAttribute('load');
                    Opta.start();
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (Opta.widgets.ref_widget_average_positions && this.template === 'normal') {
            Opta.widgets.ref_widget_average_positions.destroy(true);
        } else if (Opta.widgets.ref_widget_average_positions2) {
            Opta.widgets.ref_widget_average_positions2.destroy(true);
        }
    }

    getHtml(): string {
        if (this.template === 'normal') {
            return `
              <opta-widget widget="average_positions"  widget_id="ref_widget_average_positions" 
              competition="${this.competitionId}" season="${this.seasonId}" match="${this.matchId}" load="false" 
              template="normal"
               live="true" orientation="horizontal" side="both" show_match_header="false" 
               show_halftime_score="false" show_crests="false" show_pitch_crests="false" show_graphics="all" 
               default_nav="1" show_images="false" show_subs="true" show_competition_name="true" 
               show_date="true" date_format="dddd D MMMM YYYY" narrow_date_format="DD/MM/YY" 
               show_referee="false" show_venue="false" show_attendance="false" show_team_sheets="false" 
               subs_selected="false" competition_naming="full" team_naming="full" player_naming="full" 
               show_logo="false" show_title="true" title="${this.transHeadline}" 
               breakpoints="400, 700" sport="football">
               </opta-widget>
    
             
              `;
        }

        return `
              <opta-widget widget="average_positions"  widget_id="ref_widget_average_positions2" 
              competition="${this.competitionId}" season="${this.seasonId}" match="${this.matchId}" load="false" 
              widget="average_positions"  template="passing" live="true" orientation="horizontal" side="both" 
              show_match_header="false" 
              show_halftime_score="false" show_crests="false" 
              show_pitch_crests="false" default_nav="1" show_subs="true" 
              show_competition_name="true" show_date="true" date_format="dddd D MMMM YYYY" 
              narrow_date_format="DD/MM/YY" show_referee="false" 
              show_venue="false" show_attendance="false" show_team_sheets="false" subs_selected="false" 
              show_threshold="false" competition_naming="full" team_naming="full" player_naming="full" 
              show_logo="false" title="${this.transHeadline}" 
              show_title="true" breakpoints="400, 700" sport="football">
               </opta-widget>
    
             
              `;
    }
}
