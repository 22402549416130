<ng-container *ngIf="isSet">
    <ul class="hockey-rink-formation">
        <li
            *ngFor="let f of mergeFormation | keyvalue | uni : filterFormation(); trackBy: trackByIndex"
            (click)="setFormation(f.key)"
            [class.hockey-rink-formation__item--active]="f.key === fVal"
            class="hockey-rink-formation__item app-cursor"
        >
            {{ 'web.formation' | translate }} {{ f.key }}
        </li>
    </ul>
    <div class="hockey-rink">
        <img src="/img/sport/ice-hockey-rink.png" class="hockey-rink__back" alt="" />
        <!-- HOME -->
        <div *ngIf="homeGoalie" class="lineup-player lineup-player-home ice-hockey-lineup-goalieHome">
            <span>{{ homeGoalie.number }}</span>
            <span class="lineup-player-name">{{ homeGoalie.name_short }}</span>
            <div class="hockey-rink__goal d-flex" title="{{ 'web.num_goals' | translate }}">
                <ng-container *ngIf="playerGoal('home', homeGoalie.id)">
                    <svg class="icon mr-2">
                        <use href="#icon-ice-hockey" />
                    </svg>
                    <div
                        *ngIf="playerGoal('home', homeGoalie.id)! > 1"
                        class="hockey-rink__assists-text hockey-rink__assists-text--extended"
                    >
                        {{ playerGoal('home', homeGoalie.id) }}x
                    </div>
                </ng-container>

                <div
                    class="hockey-rink__assists-text"
                    *ngIf="playerAssits('home', homeGoalie.id)"
                    title="{{ 'web.assists' | translate }}"
                >
                    {{ playerAssits('home', homeGoalie.id)! > 1 ? playerAssits('home', homeGoalie.id) + 'x' : '' }}A
                </div>
            </div>
        </div>

        <div
            *ngFor="let f of formationH[fVal]; trackBy: trackByIndex"
            [ngClass]="formationClass('home', f.lineupPosition)"
            class="lineup-player lineup-player-home"
        >
            <span>{{ f.number }}</span>
            <span class="lineup-player-name">{{ f.name_short }}</span>
            <div class="hockey-rink__goal d-flex" title="{{ 'web.num_goals' | translate }}">
                <ng-container *ngIf="playerGoal('home', f.id)">
                    <svg class="icon mr-2">
                        <use href="#icon-ice-hockey" />
                    </svg>
                    <div
                        *ngIf="playerGoal('home', f.id)! > 1"
                        class="hockey-rink__assists-text hockey-rink__assists-text--extended"
                    >
                        {{ playerGoal('home', f.id) }}x
                    </div>
                </ng-container>

                <div
                    class="hockey-rink__assists-text"
                    *ngIf="playerAssits('home', f.id)"
                    title="{{ 'web.assists' | translate }}"
                >
                    {{ playerAssits('home', f.id)! > 1 ? playerAssits('home', f.id) + 'x' : '' }}A
                </div>
            </div>
        </div>
        <!-- AWAY -->
        <div *ngIf="awayGoalie" class="lineup-player ice-hockey-lineup-goalieAway">
            <span>{{ awayGoalie.number }}</span>
            <span class="lineup-player-name">{{ awayGoalie.name_short }}</span>
            <div class="hockey-rink__goal d-flex" title="{{ 'web.num_goals' | translate }}">
                <ng-container *ngIf="playerGoal('away', awayGoalie.id)">
                    <svg class="icon mr-2">
                        <use href="#icon-ice-hockey" />
                    </svg>
                    <div
                        *ngIf="playerGoal('away', awayGoalie.id)! > 1"
                        class="hockey-rink__assists-text hockey-rink__assists-text--extended"
                    >
                        {{ playerGoal('away', awayGoalie.id) }}x
                    </div>
                </ng-container>

                <div
                    class="hockey-rink__assists-text"
                    *ngIf="playerAssits('away', awayGoalie.id)"
                    title="{{ 'web.assists' | translate }}"
                >
                    {{ playerAssits('away', awayGoalie.id)! > 1 ? playerAssits('away', awayGoalie.id) + 'x' : '' }}A
                </div>
            </div>
        </div>
        <div
            *ngFor="let f of formationA[fVal]; trackBy: trackByIndex"
            [ngClass]="formationClass('away', f.lineupPosition)"
            class="lineup-player"
        >
            <span>{{ f.number }}</span>
            <span class="lineup-player-name">{{ f.name_short }}</span>
            <div class="hockey-rink__goal d-flex" title="{{ 'web.num_goals' | translate }}">
                <ng-container *ngIf="playerGoal('away', f.id)">
                    <svg class="icon mr-2">
                        <use href="#icon-ice-hockey" />
                    </svg>
                    <div
                        *ngIf="playerGoal('away', f.id)! > 1"
                        class="hockey-rink__assists-text hockey-rink__assists-text--extended"
                    >
                        {{ playerGoal('away', f.id) }}x
                    </div>
                </ng-container>

                <div
                    class="hockey-rink__assists-text"
                    *ngIf="playerAssits('away', f.id)"
                    title="{{ 'web.assists' | translate }}"
                >
                    {{ playerAssits('away', f.id)! > 1 ? playerAssits('away', f.id) + 'x' : '' }}A
                </div>
            </div>
        </div>
    </div>
</ng-container>
