import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { ShareService } from '@ngx-share/core';

import { LangService } from '@/services/lang.service';

const MOBIL_NUM = 4;

@Component({
    selector: 'app-shared-buttons',
    templateUrl: './shared-buttons.component.html',
    styleUrls: ['./shared-buttons.component.scss'],
})
export class SharedButtonsComponent implements OnInit, OnChanges {
    @Input()
    private max: number = -1;

    @Input()
    public type: string = '';

    @Input()
    public sportUrl: string = '';

    @Input()
    public matchId: number;

    @Input()
    public overloadUrl?: string | undefined;

    /**
     * Can not show button for web PWA add to screen app
     *  if it is not running from browser buttons not work
     */
    public show: boolean = true;

    public include: string[] = [
        'facebook',
        'twitter',
        'telegram',
        'whatsapp',
        'linkedin',
        'pinterest',
        'messenger',
        'copy',
        'reddit',
        'tumblr',
        'mix',
        'vk',
        'line',
        'sms',
        'email',
        'print',
    ];


    /**
     * Number of buttons per view
     */
    public num: number = 6;

    public url: string;

    public constructor(
        public deviceService: DeviceDetectorService,
        private router: Router,
        public share: ShareService,
        private lang: LangService,
    ) {}

    public ngOnInit(): void {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            this.show = false;
        }
        if (this.overloadUrl) {
            this.url = `${window.location.origin}${this.overloadUrl}`;
        }
        if (!this.deviceService.isMobile()) {
            let index = this.include.findIndex(
                (val): boolean => val === 'messenger',
            );
            if (index !== -1) {
                this.include.splice(index, 1);
            }
            index = this.include.findIndex(
                (val): boolean => val === 'pinterest',
            );
            if (index !== -1) {
                this.include.splice(index, 1);
            }
        }

        if (this.deviceService.isMobile()) {
            this.num = MOBIL_NUM;
            if (this.max !== -1 && this.max < MOBIL_NUM) {
                this.num = this.max;
            }
        } else if (this.max !== -1) {
            this.num = this.max;
        }

        if (this.type === 'match-detail') {
            if (this.overloadUrl) {
                this.url = `${window.location.origin}${this.overloadUrl}`;
            } else {
                this.url = `${window.location.origin}/page/sport/match/${this.sportUrl}/${this.matchId}`;
            }
            return;
        }
        this.url = this.router.url;


        const isQueryString = window.location.href.includes('?');
        this.url = `${window.location.href}${isQueryString ? '&' : '?'}lang=${this.lang.getLangSnapshot()}`;
    }

    public ngOnChanges(_changes: SimpleChanges): void {
        if (this.overloadUrl) {
            this.url = `${window.location.origin}${this.overloadUrl}`;
        }
    }
}
