import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy } from '@angular/core';


import { SportConfigToken } from '@config/sport.config';

import { TranslateService } from '@ngx-translate/core';

import { OptaWidgetBase } from '../base.service';

declare const Opta: any;

@Component({
    selector: 'app-opta-goal-replay',
    templateUrl: './goal-replay.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptaWidgetGoalReplayComponent extends OptaWidgetBase implements AfterViewInit, OnDestroy {
    @Input()
    matchId: string | number | null | undefined;

    @Input()
    competitionId: string | number | null | undefined;

    @Input()
    seasonId: string | number | null | undefined;

      #headlineTrans = 'web.opta_goal_replay';
      private transHeadline = '';


      public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>,
          public translate: TranslateService) {
          super();
      }

      public ngAfterViewInit(): void {
          this.transHeadline = this.translate.instant(this.#headlineTrans);
          setTimeout(() => {
              const element = document.querySelector('#opta-goal-replay') as HTMLElement;

              if (element) {
                  element.innerHTML = this.getHtml();
                  const optaWidgetTags = document.querySelector('opta-widget[load="false"]');

                  if (optaWidgetTags) {
                      optaWidgetTags.removeAttribute('load');
                      Opta.start();
                  }
              }
          });
      }

      ngOnDestroy(): void {
          if (Opta.widgets.ref_widget_goal_replay) {
              Opta.widgets.ref_widget_goal_replay.destroy(true);
          }
      }

      getHtml(): string {
          return `
            <opta-widget widget="goal_replay"   widget_id="ref_widget_goal_replay"
            competition="${this.competitionId}" season="${this.seasonId}" match="${this.matchId}" 
            load="false"  template="normal"
            live="true" animation="true" orientation="horizontal" panning="narrow" plot_events="5" 
            show_direction_of_play="false" show_match_header="false" show_popup_events="all" show_score="true" 
            show_halftime_score="false" show_crests="false" show_images="true" show_team_formation="true" 
            show_competition_name="true" show_date="true" show_events="true" show_events_bar="true" 
            date_format="dddd D MMMM YYYY" narrow_date_format="DD/MM/YY" 
            show_tooltips="true" competition_naming="full" team_naming="full" player_naming="full" 
            show_live="true" show_logo="false" title="${this.transHeadline}" show_title="true" 
            breakpoints="450, 700" sport="football"></opta-widget>
  
          `;
      }
}
