import { ChangeDetectorRef, Component } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';

import { AdsBase } from '../ads.base';


@Component({
    selector: 'app-ads[match-detail]',
    templateUrl: './match-detail.component.html',
    styleUrls: ['./match-detail.component.scss'],
})
export class AdsMatchDetailComponent extends AdsBase {
    public constructor(public deviceService: DeviceDetectorService, protected ref: ChangeDetectorRef) {
        super(deviceService, ref);
    }
}
