<div class="tab-content match-summary live-timeline">
    <div
        id="tab-match-innings-content"
        role="tabpanel"
        aria-labelledby="tab-match-innings"
        class="tab-pane show tab-match-detail-tabs-sub active"
    >
        <ul role="tablist" aria-label="Match sub tabs innings" class="nav nav-pills match-detail-subnav bg-light px-2">
            <li role="tab" class="nav-item" aria-selected="true">
                <a
                    data-cy="match-detail-sub-actions"
                    data-toggle="tab"
                    (click)="setInning(1)"
                    href="#tab-match-summary-25-content"
                    class="nav-link active"
                    >{{ 'web.' + data.home | translate }}</a
                >
            </li>
            <li role="tab" class="nav-item ng-star-inserted" aria-selected="false">
                <a
                    data-cy="match-detail-sub-actions"
                    data-toggle="tab"
                    (click)="setInning(2)"
                    href="#tab-match-summary-25-content"
                    class="nav-link"
                    >{{ 'web.' + data.away | translate }}</a
                >
            </li>
            <li *ngIf="data.home2" role="tab" class="nav-item" aria-selected="true">
                <a
                    data-cy="match-detail-sub-actions"
                    data-toggle="tab"
                    (click)="setInning(3)"
                    href="#tab-match-summary-25-content"
                    class="nav-link"
                    >{{ 'web.' + data.home2 | translate }}</a
                >
            </li>
            <li
                *ngIf="data.away2"
                role="tab"
                aria-controls="tab-match-summary-25-content"
                id="tab-match-summary-25"
                class="nav-item"
                aria-selected="true"
            >
                <a
                    data-cy="match-detail-sub-actions"
                    data-toggle="tab"
                    (click)="setInning(4)"
                    href="#tab-match-summary-25-content"
                    class="nav-link"
                    >{{ 'web.' + data.away2 | translate }}</a
                >
            </li>
        </ul>
        <div [class.active]="stickyVal18.checked" class="custom-control custom-switch my-2 ml-2">
            <input
                #stickyVal18
                (change)="activeCommentary = stickyVal18.checked"
                [checked]="false"
                class="custom-control-input"
                id="switch18"
                type="checkbox"
            />
            <label class="custom-control-label text-less-emph2 app-word-nowrap" data-cy="switch18" for="switch18">
                &nbsp;{{ 'web.cricket_detail' | translate }}</label
            >
        </div>
    </div>

    <div class="cricket-timeline">
        <div
            class="cricket-timeline__item d-flex justify-content-between align-items-center w-100"
            *ngFor="let over of actualInning; trackBy: trackByIndex"
        >
            <div class="--col-1 p-1">{{ 'web.cricket_over' | translate }} {{ over[0] }}</div>
            <div class="--col-2">
                <div class="--names">
                    {{ over[1][over[1].length - 1]?.bowler.join(' ') }} {{ 'web.cricket_to' | translate }}
                    {{ over[1][over[1].length - 1]?.batter.join(' & ') }}
                </div>
                <div class="--balls d-flex">
                    <div
                        class="cricket-ball"
                        [class.--six]="ball.type === 'six'"
                        [class.--wicket]="ball.type === 'wicket'"
                        [class.--four]="ball.batting_params?.runs_scored && ball.batting_params?.runs_scored === 4"
                        *ngFor="let ball of over[1]; trackBy: trackByIndex"
                    >
                        <ng-container
                            *ngIf="ball.batting_params?.runs_scored && ball.batting_params?.runs_scored !== 0"
                            >{{ ball.batting_params?.runs_scored }}</ng-container
                        >
                        <ng-container
                            *ngIf="
                                !ball.batting_params?.runs_scored &&
                                ball.batting_params?.runs_scored === 0 &&
                                ball.type !== 'wicket'
                            "
                            >&#x2022;</ng-container
                        >
                        <ng-container *ngIf="ball.type === 'wicket'">W</ng-container>
                        <ng-container
                            *ngIf="
                                ball.bowling_params?.extra_runs_type &&
                                ball.bowling_params.extra_runs_type === 'leg_bye'
                            "
                            >LB</ng-container
                        >
                        <ng-container
                            *ngIf="
                                ball.bowling_params?.extra_runs_type && ball.bowling_params.extra_runs_type === 'wide'
                            "
                            >WD</ng-container
                        >
                        <ng-container
                            *ngIf="
                                ball.bowling_params?.extra_runs_type &&
                                ball.bowling_params.extra_runs_type === 'no_ball'
                            "
                            >NB</ng-container
                        >
                        <ng-container
                            *ngIf="
                                ball.bowling_params?.extra_runs_type && ball.bowling_params.extra_runs_type === 'bye'
                            "
                            >B</ng-container
                        >
                    </div>
                    <!-- <div class="cricket-ball">&#x2022;</div>
                    <div class="cricket-ball --wicket">W</div>
                    <div class="cricket-ball --six">6</div>
                    <div class="cricket-ball --four">4</div> -->
                </div>

                <div
                    class="--commentaries mt-1 w-100"
                    [class.--active]="activeCommentary"
                    *ngIf="
                        over[1][over[1].length - 1]?.commentariesAll &&
                        over[1][over[1].length - 1]?.commentariesAll.length > 0
                    "
                >
                    <ul>
                        <li
                            *ngFor="
                                let commentary of over[1][over[1].length - 1]?.commentariesAll;
                                trackBy: trackByIndex
                            "
                        >
                            {{ commentary.text }}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="--col-3 p-1">
                <strong
                    >{{ over[1][over[1].length - 1]?.totalRuns }} {{ 'web.cricket_title_runs2' | translate }}</strong
                >
                <br />
                {{ over[1][over[1].length - 1]?.display_score }}
            </div>
        </div>
        <!-- <div class="cricket-timeline__item d-flex justify-content-between align-items-center w-100">
            <div class="--col-1">Over 12</div>
            <div class="--col-2">
                <div class="--names">Overton J. to Patel A. & Bishnoi R.</div>
                <div class="--balls d-flex justify-content-center">
                    <div class="cricket-ball">&#x2022;</div>
                    <div class="cricket-ball --wicket">W</div>
                    <div class="cricket-ball --six">6</div>
                    <div class="cricket-ball --four">4</div>
                </div>
            </div>
            <div class="--col-3">
                <strong>7 runs</strong> <br />
                247/7
            </div>
        </div> -->
    </div>
</div>
