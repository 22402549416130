import { BaseComponent } from '@components/base.component';
import { Component, Input } from '@angular/core';

import { PeriodStatistics } from '@interfaces/match-data.interface';


@Component({
    selector: 'app-statistics-period',
    templateUrl: './period-statistics.component.html',

})
export class PeriodStatsComponent extends BaseComponent {
    @Input() stats?: PeriodStatistics[];
    @Input() sportName: string;
    @Input() home?: string = '';
    @Input() away?: string = '';

    public activeRow = 0;

    public data: any = [];

    constructor() {
        super();
    }


    get heads(): string[] {
        if (!this.stats || this.stats.length === 0) {
            return [];
        }

        return Object.keys(this.stats[0]!);
    }

    get isActive(): boolean {
        return this.isEsport(this.sportName);
    }
}
