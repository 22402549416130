import { Component } from '@angular/core';

@Component({
    selector: 'app-placeholder-detail',
    templateUrl: './placeholder-detail.component.html',
    styleUrls: ['./placeholder-detail.component.scss'],
})
export class PlaceholderDetailComponent {
    public constructor() {}
}
