<!-- <div class="lmt-container-{{type}}-{{id}}">

</div> -->

<div #tracker class="app-tracker-container" [class.d-none]="notDisplay">
    <div
        *ngIf="type == 'field'"
        class="app-custom-widget"
        id="sr-widget-lmt-{{ id }}"
        data-sr-show-odds="false"
        data-sr-ads-frequency="false"
        data-sr-tabs-position="top"
        [attr.data-sr-match-id]="id"
        [attr.data-sr-widget]="typeBr"
        attr.data-sr-theme="sportradar"
    ></div>
    <div
        *ngIf="type == 'commentary'"
        class="app-custom-widget app-custom-widget-commentary"
        data-sr-ads-frequency="false"
        data-sr-disable-widget-header="true"
        data-sr-show-odds="false"
        data-sr-tabs-position="top"
        id="sr-widget-commentary-{{ id }}"
        [attr.data-sr-widget]="typeBr"
        [attr.data-sr-match-id]="id"
        attr.data-sr-theme="sportradar"
    ></div>
    <div
        *ngIf="type == 'basketBoxScore'"
        class="app-custom-widget"
        id="sr-widget-box-score-{{ id }}"
        [attr.data-sr-widget]="typeBr"
        [attr.data-sr-match-id]="id"
    ></div>
    <div
        *ngIf="type == 'simpleField'"
        class="app-custom-widget"
        id="sr-widget-simple-lmt-{{ id }}"
        data-sr-show-odds="false"
        [attr.data-sr-widget]="typeBr"
        [attr.data-sr-match-id]="id"
    ></div>
    <div
        *ngIf="type == 'stats'"
        class="app-custom-widget"
        id="sr-widget-statistics-{{ id }}"
        data-sr-disable-pitch="true"
        data-sr-momentum="disable"
        data-sr-tabs-position="top"
        data-sr-scoreboard="disable"
        data-sr-disable-widget-header="true"
        data-sr-show-odds="false"
        data-sr-ads-frequency="false"
        [attr.data-sr-widget]="typeBr"
        [attr.data-sr-match-id]="id"
    ></div>
    <div
        *ngIf="type == 'statsTennis'"
        class="app-custom-widget"
        id="sr-widget-statisticsTennis-{{ id }}"
        data-sr-disable-pitch="true"
        data-sr-momentum="disable"
        data-sr-tabs-position="top"
        data-sr-scoreboard="disable"
        data-sr-disable-widget-header="true"
        data-sr-show-odds="false"
        data-sr-ads-frequency="false"
        [attr.data-sr-widget]="typeBr"
        [attr.data-sr-match-id]="id"
    ></div>
    <div
        *ngIf="type == 'stats-h2h'"
        class="app-custom-widget"
        id="sr-widget-statistics-h2h-{{ id }}"
        data-sr-disable-pitch="true"
        data-sr-momentum="disable"
        data-sr-tabs-position="top"
        data-sr-scoreboard="disable"
        data-sr-disable-widget-header="true"
        data-sr-disable-season-stats="true"
        data-sr-show-odds="false"
        data-sr-ads-frequency="false"
        [attr.data-sr-widget]="typeBr"
        [attr.data-sr-match-id]="id"
    ></div>

    <div
        *ngIf="type == 'timeline'"
        class="app-custom-widget"
        id="sr-widget-timeline-{{ id }}"
        data-sr-widget="match.verticalTimeline"
        [attr.data-sr-match-id]="id"
        data-sr-sort-by-periods="true"
        data-sr-disable-widget-header="false"
    ></div>
</div>
<app-page-loader [class.d-none]="!isEmpty" class="app-tracker-loader"></app-page-loader>
