<div class="tab-pane show tab-match-detail-tabs-sub active nav-wrapper">
    <div style="position: relative; overflow: hidden">
        <swiper
            class="swiper-container betting-navi bg-light"
            #swiper
            [config]="swiperConfig"
            [navigation]="true"
            (reachEnd)="end()"
            (fromEdge)="toEdge()"
            (reachBeginning)="arrowActive = true"
            (slideChange)="onChange()"
        >
            <ng-template
                swiperSlide
                *ngFor="let bet of config; trackBy: trackByIndex; index as i"
                data-cy="betting-item"
            >
                <a
                    id="tab-match-summary-1"
                    data-cy="match-detail-sub-actions"
                    data-toggle="tab"
                    (click)="active(i)"
                    role="tab"
                    aria-controls="tab-match-summary-1-content"
                    aria-selected="true"
                    class="nav-link app-cursor"
                    [class.active]="activeIndex === i"
                    >{{ 'web.betting_' + bet[0] | lowercase | translate }}</a
                >
            </ng-template>
        </swiper>

        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
    </div>

    <div class="tab-pane show active">
        <iframe
            (load)="onIfameLoad()"
            class="w-100"
            data-cy="betting-iframe"
            [style.height.px]="iheight"
            frameborder="0"
            scrolling="no"
            id="betting-iframe"
        ></iframe>
    </div>
</div>
