<ng-container *ngIf="loaded; else elseBlock">
    <ng-container *ngIf="loadedError; else loadBlock">
        <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
            <div class="text-center pt-7">
                <svg class="icon icon--64 mb-4"><use xlink:href="#icon-empty" /></svg>
                <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                <p class="mb-6">{{ 'web.error_data_load' | translate }}</p>
            </div>
        </div>
    </ng-container>
    <ng-template #loadBlock>
        <app-ads-container position="match_detail_top" [platforms]="['all']">
            <app-ads match-detail #ads></app-ads>
        </app-ads-container>
        <ng-container *ngIf="followView; else classicView">
            <h4 class="font-weight-bold text-uppercase mb-3">{{ 'web.foolow_event' | translate }}</h4>
            <div class="row no-gutters mb-4 bg-white align-items-center">
                <div class="col-5">
                    <div class="text-center p-3 h-100">
                        <div class="img-team img-team--lg img-team--home">
                            <img
                                (error)="imageError($event)"
                                [attr.src]="cdn + participants.home_team.image | responsive : 'participant' : 'medium'"
                                alt="{{ participants.home_team.name }}"
                                height="50"
                                class="img-fluid"
                            />
                        </div>
                        <h6 class="mb-0">
                            <a
                                [routerLink]="[
                                    '/page/sport/participant',
                                    data?.sport_code_name + '-' + data?.sport_id,
                                    participants.home_team.id
                                ]"
                                class="text-reset"
                                >{{ participants.home_team.name }}</a
                            >
                        </h6>
                    </div>
                </div>
                <div class="col-2 text-center">
                    <div class="h1 text-light2">vs.</div>
                </div>
                <div class="col-5">
                    <div class="text-center p-3 h-100">
                        <div class="img-team img-team--lg img-team--away">
                            <img
                                (error)="imageError($event)"
                                [attr.src]="cdn + participants.away_team.image | responsive : 'participant' : 'medium'"
                                alt="{{ participants.away_team.name }}"
                                height="50"
                                class="img-fluid"
                            />
                        </div>
                        <h6 class="mb-0">
                            <a
                                [routerLink]="[
                                    '/page/sport/participant',
                                    data?.sport_code_name + '-' + data?.sport_id,
                                    participants.away_team.id
                                ]"
                                class="text-reset"
                                >{{ participants.away_team.name }}</a
                            >
                        </h6>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #classicView>
            <h6 *ngIf="data" class="match-info-head mb-0 bg-light p-2 d-flex" data-cy="match-info-head">
                <div class="img-circle mr-2 rounded-circle">
                    <img
                        [src]="cdn + data?.image"
                        height="16"
                        alt="{{ data?.category_name }}: {{ data?.tournament_name }}"
                    />
                </div>
                <div>
                    <span class="font-weight-bold text-uppercase">
                        <a
                            [routerLink]="[
                                '/page/sport/event',
                                data?.sport_code_name + '-' + data?.sport_id,
                                data?.tournament_id
                            ]"
                            class="text-reset"
                            >{{ data?.category_name }}: {{ data?.tournament_name }}</a
                        ></span
                    >
                    <span *ngIf="data?.round_name != null"> - {{ data?.round_name }}</span>
                    <span *ngIf="data?.round_name == null && data?.round != null">
                        - {{ data?.round }}. {{ 'web.round' | translate | transinit }}</span
                    >
                </div>
            </h6>

            <ul
                class="nav nav-tabs nav-justified text-uppercase font-weight-bold nav-tabs--match"
                id="tabs-match"
                role="tablist"
            >
                <li
                    class="nav-item"
                    *ngIf="sportConfig.hasTracker(data?.sport_code_name, data?.tournament_id) || hasStream"
                    role="tab"
                    aria-controls="tab-match-info-content"
                    [attr.aria-selected]="tab === 'info'"
                    [attr.tabindex]="tab === 'info' ? 0 : -1"
                    id="tab-match-info"
                >
                    <a
                        class="nav-link icon-wrapper"
                        [class.active]="isActive('info')"
                        (click)="active('info')"
                        data-toggle="tab"
                        href="#tab-match-info-content"
                    >
                        <svg class="icon icon--16 mr-2"><use xlink:href="#icon-info" /></svg>
                        {{ 'web.info' | translate | transinit }}
                    </a>
                </li>
                <li
                    class="nav-item app-tracker-link-parent"
                    *ngIf="
                        sportConfig.hasTracker(data?.sport_code_name, data?.tournament_id) &&
                        data?.tracker &&
                        client.use('tracker')
                    "
                    role="tab"
                    aria-controls="tab-match-tracker-content"
                    [attr.aria-selected]="tab === 'tracker'"
                    [attr.tabindex]="tab === 'tracker' ? 0 : -1"
                    id="tab-match-tracker"
                    title="{{ data?.id }} | {{ participants.home_team?.name }} - {{ participants.away_team?.name }}"
                >
                    <a
                        class="nav-link icon-wrapper"
                        [class.active]="isActive('tracker')"
                        (click)="active('tracker'); setsTrackerInfo()"
                        data-toggle="tab"
                        href="#tab-match-tracker-content"
                        data-title="{{ data?.id }} | {{ participants.home_team?.name }} - {{
                            participants.away_team?.name
                        }}"
                    >
                        <svg class="icon icon--16 mr-2"><use xlink:href="#icon-tracker" /></svg>
                        {{ 'web.tracker' | translate | transinit }}
                    </a>
                </li>
                <li
                    class="nav-item"
                    *ngIf="hasStream && client.use('video')"
                    role="tab"
                    aria-controls="tab-match-stream-content"
                    [attr.aria-selected]="tab === 'stream'"
                    id="tab-match-stream"
                    title="{{ data?.id }} | {{ participants.home_team?.name }} - {{ participants.away_team?.name }}"
                >
                    <a
                        class="nav-link icon-wrapper"
                        (click)="active('stream')"
                        [class.active]="isActive('stream')"
                        data-toggle="tab"
                        data-title="{{ data?.id }} | {{ participants.home_team?.name }} - {{
                            participants.away_team?.name
                        }}"
                        href="#tab-match-stream-content"
                    >
                        <svg class="icon icon--16 mr-2"><use href="#icon-stream" /></svg>
                        <ng-container *ngIf="!isLiveStrem; else liveStream">
                            {{ 'web.stream' | translate }}</ng-container
                        >
                        <ng-template #liveStream> {{ 'web.live_stream' | translate }}</ng-template>
                    </a>
                </li>
            </ul>
            <div id="app-detail-sub" style="width: 100%; display: none"></div>
            <div class="tab-content  app-match-detail-content tab-content-{{ tab }}" data-cy="match-detail-content">
                <ng-container *ngIf="hasH2H">
                    <a
                        [routerLink]="[
                            '/compare',
                            sportName + '-' + data?.sport_id,
                            participants.home_team?.id,
                            participants.away_team?.id
                        ]"
                        class="btn btn-light app-h2h-button app-cursor venue-link compare-arrows"
                        matTooltip="{{ 'web.participant_comapre_head' | translate }}"
                        [attr.title]="'Head to head'"
                        matTooltipPosition="left"
                        matTooltipHideDelay="100"
                        type="button"
                    >
                        <mat-icon class="v-mid" fontIcon="compare_arrows"></mat-icon>
                    </a>
                </ng-container>
                <div class="app-match-detail-favorite" *ngIf="!isActive('tracker')">
                    <app-favorite
                        *ngIf="isFavoriteEnabled"
                        [matchId]="data?.id"
                        [type]="'match-detail'"
                        class="app-match-favorite-wrapper__favorite"
                    >
                        <span notactive>{{ 'web.add_match_to_favorites' | translate }}</span>
                        <span active>{{ 'web.remove_match_from_favorites' | translate }}</span>
                    </app-favorite>
                </div>
                <ng-container *ngIf="page.canShare(); else altShare">
                    <button
                        (click)="shareDetail()"
                        *ngIf="client.use('share') && !isActive('tracker')"
                        [class.app-share-button--active]="shareBtnAct"
                        class="btn btn-light dropdown-share-btn app-share-button app-cursor"
                        aria-label="share"
                        type="button"
                    >
                        <svg class="icon"><use xlink:href="#icon-share" /></svg>
                    </button>
                </ng-container>
                <ng-template #altShare>
                    <button
                        (click)="shbClick($event)"
                        *ngIf="client.use('share') && !isActive('tracker')"
                        [class.app-share-button--active]="shareBtnAct"
                        class="btn btn-light dropdown-share-btn app-share-button app-cursor"
                        aria-label="share"
                        type="button"
                    >
                        <svg class="icon"><use xlink:href="#icon-share" /></svg>
                        <div class="app-share-button__links app-share-button__links--alternate" *ngIf="shareBtnAct">
                            <app-shared-buttons
                                [overloadUrl]="shardeOverloadUrl"
                                [matchId]="id"
                                [max]="fullScreen ? -1 : 4"
                                [sportUrl]="data?.sport_code_name + '-' + data?.sport_id"
                                type="match-detail"
                            ></app-shared-buttons>
                        </div>
                    </button>
                </ng-template>
                <div
                    class="tab-pane show tab-match-info-content"
                    [class.active]="isActive('info')"
                    id="tab-match-info-content"
                    role="tabpanel"
                    aria-labelledby="tab-match-info"
                >
                    <!--TODO waiting for implementation of GOLF or other sports with weather info-->
                    <app-weather
                        [matchId]="id"
                        [data]="data?.weather"
                        *ngIf="!first && data?.weather && !this.isAfter && !isCancelled && tab === 'info'"
                    ></app-weather>
                    <div
                        class="py-2 px-3 px-lg-4 text-center bg-secondary text-white match-info"
                        [@.disabled]="isDisabled"
                    >
                        <div class="py-3 px-lg-3 text-center sticky-p">
                            <div class="d-flex justify-content-center">
                                <!-- <svg class="icon icon--12 mx-2"><use href="#icon-heart"/></svg> -->
                                <div class="team-name mt-4" data-cy="team-name">
                                    <div class="img-team img-team--md">
                                        <a
                                            [routerLink]="[
                                                '/page/sport/participant',
                                                data?.sport_code_name + '-' + data?.sport_id,
                                                participants.home_team?.id
                                            ]"
                                            class="text-reset app-match-detail-logo-anchor"
                                        >
                                            <img
                                                (error)="imageError($event)"
                                                [attr.src]="
                                                    cdn + participants.home_team?.image
                                                        | responsive : 'participant' : 'medium'
                                                "
                                                alt="{{ participants.home_team?.name }}"
                                                class="img-fluid app-cursor app-match-detail-participant-logo"
                                            />
                                        </a>
                                    </div>
                                    <div class="app-match-detail-text-team">
                                        <a
                                            [routerLink]="[
                                                '/page/sport/participant',
                                                data?.sport_code_name + '-' + data?.sport_id,
                                                participants.home_team?.id
                                            ]"
                                            class="text-reset d-block"
                                            [class.font-weight-bold]="isWinnerHome()"
                                        >
                                            <span class="sticky-match-detail-show d-none">{{
                                                participants.home_team?.name_short || participants.home_team.name
                                            }}</span>
                                            <span class="sticky-match-detail-no-show">{{
                                                participants.home_team?.name
                                            }}</span>
                                        </a>
                                        <ng-container *ngIf="page.debug">
                                            <br />
                                            ( {{ participants.home_team?.id }} )
                                        </ng-container>
                                        <span
                                            *ngIf="participants.home_team?.tennis_tournament_info !== null"
                                            class="small font-weight-normal text-muted d-none d-lg-inline ml-1"
                                            >({{ participants.home_team?.tennis_tournament_info?.short_name }})</span
                                        >
                                        <span
                                            *ngIf="
                                                sportConfig.isTennis(data?.sport_code_name) &&
                                                participantRanking(participants.home_team?.id) !== 'NDF'
                                            "
                                            class="badge badge-secondary--light badge--rank"
                                        >
                                            {{ participantRanking(participants.home_team?.id) }}
                                        </span>
                                    </div>
                                </div>
                                <div class="d-flex flex-column px-4 app-match-detail-score">
                                    <div class="text-muted small app-sticky-detail-date">
                                        {{ data?.start_date | livDate : 'localDateTimeShort' }}
                                    </div>
                                    <div class="text-primary">
                                        <app-match-time
                                            *ngIf="data?.match_state == 'inplay-live' && data?.display_time"
                                            [time]="data?.minute"
                                            [matchId]="data.id"
                                            [addTime]="data?.added_time"
                                            [code_state]="data?.code_state"
                                            [lastTimeUpdate]="data?.last_time_update"
                                            [sportName]="data?.sport_code_name"
                                            [periodLength]="data?.period_length"
                                            [stoppageTimeAnnounced]="data!.stoppage_time_announced"
                                            class="match-detail-time"
                                        ></app-match-time>
                                    </div>
                                    <div class="h2 font-weight-bolder my-0">
                                        <!-- <span *ngIf="data?.score.service && data?.score.service=='home'"><i class="fas fa-circle" ></i></span> -->

                                        <app-score
                                            [sport]="data?.sport_code_name"
                                            [data]="data?.score"
                                            [match_state]="data?.match_state"
                                            [codeState]="data?.code_state"
                                            [onlyPeriods]="false"
                                            [onlyFinal]="true"
                                            [type]="'short'"
                                        ></app-score>
                                        <!-- <span *ngIf="data?.score.service && data?.score.service=='away'"><i class="fas fa-circle" ></i></span> -->
                                    </div>
                                    <div class="text-muted hide-me">
                                        <app-score
                                            *ngIf="oneline"
                                            [sport]="data?.sport_code_name"
                                            [match_state]="data?.match_state"
                                            [codeState]="data?.code_state"
                                            [data]="data?.score"
                                            [onlyPeriods]="true"
                                            [type]="'short'"
                                        ></app-score>
                                    </div>
                                    <div
                                        class="text-muted sticky-match-detail-no-show"
                                        *ngIf="data?.code_state !== 'postponed'"
                                    >
                                        <app-favorite
                                            [matchId]="data?.id"
                                            [type]="'match'"
                                            [classMatchDef]="'icon icon--12'"
                                            class="d-block"
                                        >
                                            <span notactive>{{ 'web.add_match_to_favorites' | translate }}</span>
                                            <span active>{{ 'web.remove_match_from_favorites' | translate }}</span>
                                        </app-favorite>
                                    </div>

                                    <div class="text-muted small sticky-match-detail-status">
                                        {{ 'status.' + data?.sport_code_name + '.' + data?.code_state | translate }}
                                    </div>

                                    <div
                                        class="text-muted small-sm-1"
                                        *ngIf="scoreSeries"
                                        [innerHtml]="scoreSeriesText"
                                    ></div>
                                </div>
                                <div class="team-name mt-4" data-cy="team-name">
                                    <div class="img-team img-team--md">
                                        <a
                                            [routerLink]="[
                                                '/page/sport/participant',
                                                data?.sport_code_name + '-' + data?.sport_id,
                                                participants.away_team.id
                                            ]"
                                            class="text-reset app-match-detail-logo-anchor"
                                        >
                                            <img
                                                (error)="imageError($event)"
                                                [attr.src]="
                                                    cdn + participants.away_team?.image
                                                        | responsive : 'participant' : 'medium'
                                                "
                                                alt="{{ participants.away_team?.name }}"
                                                class="img-fluid app-cursor app-match-detail-participant-logo"
                                            />
                                        </a>
                                    </div>
                                    <div class="app-match-detail-text-team">
                                        <a
                                            [routerLink]="[
                                                '/page/sport/participant',
                                                data?.sport_code_name + '-' + data?.sport_id,
                                                participants.away_team?.id
                                            ]"
                                            class="text-reset d-block"
                                            [class.font-weight-bold]="isWinnerAway()"
                                        >
                                            <span class="sticky-match-detail-show d-none">{{
                                                participants.away_team?.name_short || participants.away_team?.name
                                            }}</span>
                                            <span class="sticky-match-detail-no-show">{{
                                                participants.away_team?.name
                                            }}</span>
                                        </a>
                                        <ng-container *ngIf="page.debug">
                                            <br />
                                            ( {{ participants.away_team?.id }} )
                                        </ng-container>
                                        <span
                                            *ngIf="participants.away_team?.tennis_tournament_info !== null"
                                            class="small font-weight-normal text-muted d-none d-lg-inline ml-1"
                                            >({{ participants.away_team?.tennis_tournament_info?.short_name }})</span
                                        >
                                        <span
                                            *ngIf="
                                                sportConfig.isTennis(data?.sport_code_name) &&
                                                participantRanking(participants.away_team?.id) !== 'NDF'
                                            "
                                            class="badge badge-secondary--light badge--rank"
                                        >
                                            {{ participantRanking(participants.away_team?.id) }}
                                        </span>
                                    </div>
                                </div>
                                <!-- <svg class="icon icon--12 mx-2"><use href="#icon-heart"/></svg> -->
                            </div>
                            <!-- Hide until BetsApi resolve this table or developer-->
                            <!-- <ng-container *ngIf="isEsports && context">
                                <table class="table table--parts mb-0 mt-3">
                                    <thead>
                                        <tr class="score-label ng-tns-c90-45">
                                            <th
                                                class="ng-tns-c90-45"
                                                *ngFor="let item of context; trackBy: trackByIndex"
                                            >
                                                {{ 'web.context_' + item[0] | lowercase | translate }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td
                                                *ngFor="let item of context; trackBy: trackByIndex"
                                                class="table--parts__name text-center"
                                            >
                                                {{ (data?.participantStatistics)!.home![item[0]] }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                *ngFor="let item of context; trackBy: trackByIndex"
                                                class="table--parts__name text-center"
                                            >
                                                {{ (data?.participantStatistics)!.away![item[0]] }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </ng-container> -->
                            <ng-container *ngIf="!oneline">
                                <app-score
                                    [sport]="data?.sport_code_name"
                                    [data]="data?.score"
                                    [match_state]="data?.match_state"
                                    [codeState]="data?.code_state"
                                    [onlyPeriods]="true"
                                    [participants]="participants"
                                    [oneline]="oneline"
                                    [type]="'match-detail'"
                                ></app-score>
                            </ng-container>
                            <ng-container *ngIf="showBets">
                                <app-bets-detail
                                    class="app-bets-detail {{ data?.match_state }}"
                                    *ngIf="$odds | async as odds"
                                    (onLoad)="onOddsLoad($event)"
                                    [odds]="odds"
                                    [isBeforeGame]="data?.match_state === 'before'"
                                    [participanNames]="
                                        participants.home_team.name + ' - ' + participants.away_team.name
                                    "
                                ></app-bets-detail>
                            </ng-container>
                            <ol
                                class="app-small-nav app-small-nav--tiny"
                                *ngIf="isScoreAggregate || (previousScore && data?.hasCupTree)"
                            >
                                <li class="crumb ng-star-inserted">
                                    <span class="crumb__link"
                                        ><i class="fas fa-info-circle"></i>
                                        <ng-container *ngIf="previousScore && data?.hasCupTree">
                                            {{ 'web.last_match' | translate }}
                                            {{ previousScore.start_date | livDate : 'localDateTimeShort' }}
                                            ({{ previousScore.home_score }}:{{
                                                previousScore.away_score
                                            }})</ng-container
                                        ><ng-container *ngIf="previousScore && isScoreAggregate">, </ng-container>
                                        <ng-container *ngIf="isScoreAggregate">
                                            {{ 'web.score_aggregate' | translate }}
                                            <strong>{{ data?.score?.home_aggregate }}</strong
                                            >:<strong>{{ data?.score?.away_aggregate }}</strong>
                                        </ng-container>
                                    </span>
                                </li>
                            </ol>
                            <ng-container *ngIf="probabilityInfo">
                                <div class="app-probability d-flex flex-column">
                                    <div class="app-probability__info">
                                        {{ 'web.probability_title' | translate }}
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <div
                                            class="app-probability__item"
                                            *ngFor="let probability of probabilityValue.outcomes; trackBy: trackByIndex"
                                        >
                                            {{ 'web.probability_' + probability.name | translate }}:
                                            <strong class="--probability-emph">{{ probability.probability }}%</strong>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div
                    class="tab-pane show"
                    *ngIf="isActive('tracker')"
                    [class.active]="isActive('tracker')"
                    id="tab-match-tracker-content"
                    role="tabpanel"
                    aria-labelledby="tab-match-tracker"
                >
                    <div>
                        <app-auth-container [authOff]="!client.use('signin')" permission="tracker">
                            <app-tracker [id]="data?.metainfo?.tracker" class="app-tracker"></app-tracker>
                        </app-auth-container>
                    </div>

                    <div class="text-center p-4" *ngIf="false">
                        <span class="d-inline-block bg-primary rounded-circle p-3"
                            ><svg class="icon mx-auto icon--28 icon--white"><use href="#icon-key" /></svg
                        ></span>
                        <div class="p-3 text-center">{{ 'web.tracker_auth_text' | translate }}</div>
                        <a (click)="openSignIn()" class="btn btn-outline-dark">{{ 'web.sign_in' | translate }}</a>
                    </div>
                </div>
                <div
                    class="tab-pane show"
                    *ngIf="isActive('stream')"
                    [class.active]="isActive('stream')"
                    id="tab-match-stream-content"
                    role="tabpanel"
                    aria-labelledby="tab-match-stream"
                >
                    <app-auth-container [authOff]="!client.use('signin')" permission="stream">
                        <div class="text-center app-stream-content">
                            <app-youtube
                                [video]="data?.youtube_video"
                                [videoImage]="data?.youtube_preview"
                                [matchId]="id"
                                *ngIf="data?.youtube_video"
                            ></app-youtube>
                            <app-nhl
                                [video]="data?.nhl_video"
                                [fullScreen]="fullScreen"
                                *ngIf="data?.nhl_video"
                            ></app-nhl>
                            <app-twitch
                                [channel]="data?.twitch_channel"
                                *ngIf="data?.twitch_video || data?.twitch_channel"
                                [video]="data?.twitch_video"
                            ></app-twitch>
                            <app-isport
                                [video]="data?.isport_video"
                                [fullScreen]="fullScreen"
                                *ngIf="data?.isport_video"
                            ></app-isport>
                            <app-tvcom [video]="data?.tvcom_video" *ngIf="data?.tvcom_video"></app-tvcom>
                            <app-vimeo [video]="data?.vimeo_video" *ngIf="data?.vimeo_video"></app-vimeo>
                        </div>
                    </app-auth-container>
                </div>
            </div>

            <div class="nav-wrapper">
                <ul
                    class="nav nav-tabs nav-fill nav-tabs--white nav-tabs--arrow text-uppercase nav-pills-match-detail"
                    scrollEnd
                    role="tablist"
                >
                    <li
                        class="nav-item"
                        role="tab"
                        aria-controls="tab-match-summary-content"
                        aria-selected="true"
                        id="tab-match-summary"
                    >
                        <a
                            class="nav-link"
                            data-cy="match-detail-overview"
                            (click)="activeSub('overview')"
                            [class.active]="isActiveSub('overview')"
                            data-toggle="tab"
                            href="#tab-match-summary-content"
                            >{{ 'web.overview' | translate | transinit }}</a
                        >
                    </li>
                    <li
                        class="nav-item"
                        *ngIf="
                            (((hasNoTrackerStatistics || isNotBRAndhasNoTrackerStatistics || hasTracker) &&
                                sportConfig.hasStatistics(data?.sport_code_name) &&
                                !isBefore) ||
                                seasonStats) &&
                            client.use('matchDetailStatistics')
                        "
                        role="tab"
                        aria-controls="tab-match-stats-content"
                        aria-selected="false"
                        id="tab-match-stats"
                    >
                        <a
                            class="nav-link"
                            data-cy="match-detail-statistics"
                            (click)="activeSub('statistics')"
                            [class.active]="isActiveSub('statistics')"
                            data-toggle="tab"
                            href="#tab-match-stats-content"
                            >{{ 'web.match_statistics' | translate | transinit }}</a
                        >
                    </li>
                    <li
                        class="nav-item"
                        *ngIf="hasOptaStats && !isBefore"
                        role="tab"
                        aria-controls="tab-match-stats-extended-content"
                        aria-selected="false"
                        id="tab-match-extended-stats"
                    >
                        <a
                            class="nav-link"
                            data-cy="match-detail-extended-statistics"
                            (click)="activeSub('statistics-extended')"
                            [class.active]="isActiveSub('statistics-extended')"
                            data-toggle="tab"
                            title="new"
                            href="#tab-match-stats-extended-content"
                            >{{ 'web.match_extended_statistics' | translate | transinit }}
                        </a>
                    </li>
                    <li
                        class="nav-item app-live-table"
                        *ngIf="
                            data?.periodStatistics &&
                            (data?.periodStatistics)!.length > 0 &&
                            client.use('matchDetailPeriodStats')
                        "
                        role="tab"
                        aria-controls="tab-match-period-stats"
                        aria-selected="false"
                        id="tab-match-table"
                    >
                        <a
                            class="nav-link"
                            data-cy="match-detail-period-stats"
                            (click)="activeSub('periodStats')"
                            [class.active]="isActiveSub('periodStats')"
                            data-toggle="tab"
                            href="#tab-match-period-stats"
                            >{{ 'web.period_stats' | translate | transinit }}</a
                        >
                    </li>
                    <li
                        class="nav-item app-live-table --active"
                        [class.--active]="isTableLive && isLive"
                        *ngIf="data?.hasTable && client.use('matchDetailLeagueTable')"
                        role="tab"
                        aria-controls="tab-match-table-content"
                        aria-selected="false"
                        id="tab-match-table"
                    >
                        <div
                            *ngIf="!isActiveSub('table')"
                            (click)="activeSub('table')"
                            class="app-cursor app-live-table__text-parent"
                        >
                            <div class="app-live-table__text app-word-nowrap">{{ 'web.live' | translate }}</div>
                        </div>
                        <a
                            class="nav-link"
                            data-cy="match-detail-league-table"
                            (click)="activeSub('table')"
                            [class.active]="isActiveSub('table')"
                            data-toggle="tab"
                            href="#tab-match-table-content"
                            >{{ 'web.league_table' | translate | transinit }}</a
                        >
                    </li>
                    <li
                        class="nav-item app-live-table"
                        *ngIf="hasInnings"
                        role="tab"
                        aria-controls="tab-match-innings-content"
                        aria-selected="false"
                        id="tab-match-innings"
                    >
                        <a
                            class="nav-link"
                            data-cy="match-detail-innings"
                            (click)="activeSub('innings_player_stats')"
                            [class.active]="isActiveSub('innings_player_stats')"
                            data-toggle="tab"
                            href="#tab-match-innings-content"
                            >{{ 'web.player_stats' | translate | transinit }}</a
                        >
                    </li>
                    <li
                        class="nav-item app-live-table"
                        *ngIf="hasWicket"
                        role="tab"
                        aria-controls="tab-match-wicket-content"
                        aria-selected="false"
                        id="tab-match-wicket"
                    >
                        <a
                            class="nav-link"
                            data-cy="match-detail-wicket"
                            (click)="activeSub('cricket_wicket')"
                            [class.active]="isActiveSub('cricket_wicket')"
                            data-toggle="tab"
                            href="#tab-match-wicket-content"
                            >{{ 'web.cricket_widget_wickets' | translate | transinit }}</a
                        >
                    </li>
                    <li
                        class="nav-item"
                        *ngIf="hasBettingStats && client.use('matchDetailBetting')"
                        role="tab"
                        aria-controls="tab-match-bet-content"
                        aria-selected="false"
                        id="tab-match-bet"
                    >
                        <a
                            class="nav-link"
                            data-cy="match-detail-bet"
                            (click)="activeSub('betting')"
                            [class.active]="isActiveSub('betting')"
                            data-toggle="tab"
                            href="#tab-match-bet-content"
                            >{{ 'web.betting' | translate | transinit }}</a
                        >
                    </li>
                    <li
                        class="nav-item"
                        *ngIf="hasPlayers && client.use('matchDetailPlayers')"
                        role="tab"
                        aria-controls="tab-match-players-content"
                        aria-selected="false"
                        id="tab-match-match-players"
                    >
                        <a
                            class="nav-link"
                            data-cy="match-detail-players"
                            (click)="activeSub('players')"
                            [class.active]="isActiveSub('players')"
                            data-toggle="tab"
                            href="#tab-match-players-content"
                            >{{ 'web.match_players' | translate | transinit }}</a
                        >
                    </li>
                    <li
                        class="nav-item"
                        *ngIf="hasOptaStats && !isBefore"
                        role="tab"
                        aria-controls="tab-match-stats-player-ratings"
                        aria-selected="false"
                        id="tab-match-player-ratings"
                    >
                        <a
                            class="nav-link"
                            data-cy="match-detail-player-ratings"
                            (click)="activeSub('player-ratings')"
                            [class.active]="isActiveSub('player-ratings')"
                            data-toggle="tab"
                            title="new"
                            href="#tab-match-stats-extended-content"
                            >{{ 'web.match_player_ratings' | translate | transinit }}
                        </a>
                    </li>
                    <!-- <li class="nav-item" *ngIf="isBasketball">
                        <a
                            class="nav-link"
                            id="tab-basket-box-score"
                            data-cy="match-detail-basket-box-score"
                            (click)="activeSub('basket-box-score')"
                            [class.active]="isActiveSub('basket-box-score')"
                            data-toggle="tab"
                            href="#tab-basket-box-score"
                            role="tab"
                            aria-controls="tab-match-basket-box-score"
                            aria-selected="false"
                            >{{ 'web.basket_box_score' | translate | transinit }}</a
                        >
                    </li> -->
                    <li
                        class="nav-item"
                        role="tab"
                        *ngIf="client.use('matchDetailH2H')"
                        aria-controls="tab-match-matches-content"
                        aria-selected="false"
                        id="tab-match-matches"
                    >
                        <a
                            class="nav-link"
                            data-cy="match-detail-h2h"
                            (click)="activeSub('h2h')"
                            [class.active]="isActiveSub('h2h')"
                            data-toggle="tab"
                            href="#tab-match-matches-content"
                            >{{ 'web.match_h2h' | translate | transinit }}</a
                        >
                    </li>

                    <li
                        class="nav-item"
                        *ngIf="data?.hasCupTree && client.use('matchDetailCuptree')"
                        role="tab"
                        aria-controls="tab-match-cup-tree-content"
                        aria-selected="false"
                        id="tab-match-cup-tree"
                    >
                        <a
                            class="nav-link"
                            data-cy="match-detail-cuptree"
                            (click)="activeSub('cupTree'); openCupTree()"
                            [class.active]="isActiveSub('cupTree')"
                            data-toggle="tab"
                            href="#tab-match-cup-tree-content"
                            >{{ 'web.cup_tree' | translate | transinit }}</a
                        >
                    </li>
                    <li
                        class="nav-item"
                        *ngIf="showTopPlayers && client.use('matchDetailTopPlayers')"
                        role="tab"
                        aria-controls="tab-match-top-scorer-content"
                        aria-selected="false"
                        id="tab-match-top-scorer"
                    >
                        <a
                            class="nav-link"
                            data-cy="match-detail-topScorer"
                            (click)="activeSub('topScorer')"
                            [class.active]="isActiveSub('topScorer')"
                            data-toggle="tab"
                            href="#tab-match-top-scorer-content"
                            >{{ 'web.top_scorer' | translate | transinit }}</a
                        >
                    </li>

                    <li
                        class="nav-item"
                        *ngIf="data?.news && client.use('news')"
                        role="tab"
                        aria-controls="tab-match-news-content"
                        aria-selected="false"
                        id="tab-match-news"
                        title="{{ data?.id }} | {{ participants.home_team.name }} - {{ participants.away_team.name }}"
                    >
                        <a
                            class="nav-link"
                            data-cy="match-detail-news"
                            (click)="activeSub('news')"
                            [class.active]="isActiveSub('news')"
                            data-toggle="tab"
                            data-title="{{ data?.id }} | {{ participants.home_team.name }} - {{
                                participants.away_team.name
                            }}"
                            href="#tab-match-news-content"
                            >{{ 'web.news' | translate | transinit }}</a
                        >
                    </li>
                </ul>
            </div>
            <div class="tab-content bg-white">
                <div
                    class="tab-pane show tab-match-detail-tabs-sub"
                    [class.active]="isActiveSub('innings_player_stats')"
                    id="tab-match-innings-content"
                    role="tabpanel"
                    aria-labelledby="tab-match-innings"
                >
                    <ul
                        class="nav nav-pills match-detail-subnav bg-light px-2"
                        role="tablist"
                        aria-label="Match sub tabs innings"
                    >
                        <li
                            class="nav-item"
                            role="tab"
                            aria-controls="tab-match-summary-25-content"
                            [attr.aria-selected]="activeInning === 0"
                            id="tab-match-summary-25"
                        >
                            <a
                                class="nav-link active"
                                data-cy="match-detail-sub-actions"
                                [class.active]="activeInning === 0"
                                (click)="setInning(0)"
                                data-toggle="tab"
                                href="#tab-match-summary-25-content"
                                >{{ firstInningTeam }} 1. {{ 'web.inning' | translate }}</a
                            >
                        </li>
                        <li
                            class="nav-item"
                            role="tab"
                            aria-controls="tab-match-summary-25-content"
                            [attr.aria-selected]="activeInning === 1"
                            id="tab-match-summary-26"
                            *ngIf="secondInningTeam"
                        >
                            <a
                                class="nav-link active"
                                data-cy="match-detail-sub-actions"
                                [class.active]="activeInning === 1"
                                (click)="setInning(1)"
                                data-toggle="tab"
                                href="#tab-match-summary-25-content"
                                >{{ secondInningTeam }} 2. {{ 'web.inning' | translate }}</a
                            >
                        </li>
                        <li
                            class="nav-item"
                            role="tab"
                            aria-controls="tab-match-summary-25-content"
                            [attr.aria-selected]="activeInning === 2"
                            id="tab-match-summary-26"
                            *ngIf="thirdInningTeam"
                        >
                            <a
                                class="nav-link active"
                                data-cy="match-detail-sub-actions"
                                [class.active]="activeInning === 2"
                                (click)="setInning(2)"
                                data-toggle="tab"
                                href="#tab-match-summary-25-content"
                                >{{ thirdInningTeam }} 3. {{ 'web.inning' | translate }}</a
                            >
                        </li>
                        <li
                            class="nav-item"
                            role="tab"
                            aria-controls="tab-match-summary-25-content"
                            [attr.aria-selected]="activeInning === 3"
                            id="tab-match-summary-26"
                            *ngIf="fourthInningTeam"
                        >
                            <a
                                class="nav-link active"
                                data-cy="match-detail-sub-actions"
                                [class.active]="activeInning === 3"
                                (click)="setInning(3)"
                                data-toggle="tab"
                                href="#tab-match-summary-25-content"
                                >{{ fourthInningTeam }} 4. {{ 'web.inning' | translate }}</a
                            >
                        </li>
                    </ul>
                </div>

                <div
                    class="tab-pane show tab-match-detail-tabs-sub"
                    *ngIf="isActiveSub('innings_player_stats')"
                    [class.active]="isActiveSub('innings_player_stats')"
                    id="tab-match-innings-content"
                    role="tabpanel"
                    aria-labelledby="tab-match-innings"
                >
                    <div
                        class="tab-pane show active"
                        id="tab-match-summary-25-content"
                        role="tabpanel"
                        aria-labelledby="tab-match-summary-25"
                    >
                        <app-cricket-player-statistics [inning]="inning"></app-cricket-player-statistics>
                    </div>
                </div>

                <div
                    class="tab-pane show tab-match-detail-tabs-sub"
                    [class.active]="isActiveSub('cricket_wicket')"
                    id="tab-match-wicket-content"
                    role="tabpanel"
                    aria-labelledby="tab-match-wicket"
                >
                    <ul
                        class="nav nav-pills match-detail-subnav bg-light px-2"
                        role="tablist"
                        aria-label="Match sub tabs innings"
                    >
                        <li
                            class="nav-item"
                            role="tab"
                            aria-controls="tab-match-summary-25-content"
                            [attr.aria-selected]="activeInning === 0"
                            id="tab-match-summary-25"
                        >
                            <a
                                class="nav-link active"
                                data-cy="match-detail-sub-actions"
                                [class.active]="activeInning === 0"
                                (click)="setInning(0)"
                                data-toggle="tab"
                                href="#tab-match-summary-25-content"
                                >{{ firstInningTeam }} 1. {{ 'web.inning' | translate }}</a
                            >
                        </li>
                        <li
                            class="nav-item"
                            role="tab"
                            aria-controls="tab-match-summary-25-content"
                            [attr.aria-selected]="activeInning === 1"
                            id="tab-match-summary-26"
                            *ngIf="secondInningTeam"
                        >
                            <a
                                class="nav-link active"
                                data-cy="match-detail-sub-actions"
                                [class.active]="activeInning === 1"
                                (click)="setInning(1)"
                                data-toggle="tab"
                                href="#tab-match-summary-25-content"
                                >{{ secondInningTeam }} 2. {{ 'web.inning' | translate }}</a
                            >
                        </li>
                        <li
                            class="nav-item"
                            role="tab"
                            aria-controls="tab-match-summary-25-content"
                            [attr.aria-selected]="activeInning === 2"
                            id="tab-match-summary-26"
                            *ngIf="thirdInningTeam"
                        >
                            <a
                                class="nav-link active"
                                data-cy="match-detail-sub-actions"
                                [class.active]="activeInning === 2"
                                (click)="setInning(2)"
                                data-toggle="tab"
                                href="#tab-match-summary-25-content"
                                >{{ thirdInningTeam }} 3. {{ 'web.inning' | translate }}</a
                            >
                        </li>
                        <li
                            class="nav-item"
                            role="tab"
                            aria-controls="tab-match-summary-25-content"
                            [attr.aria-selected]="activeInning === 3"
                            id="tab-match-summary-26"
                            *ngIf="fourthInningTeam"
                        >
                            <a
                                class="nav-link active"
                                data-cy="match-detail-sub-actions"
                                [class.active]="activeInning === 3"
                                (click)="setInning(3)"
                                data-toggle="tab"
                                href="#tab-match-summary-25-content"
                                >{{ fourthInningTeam }} 4. {{ 'web.inning' | translate }}</a
                            >
                        </li>
                    </ul>
                </div>

                <div
                    class="tab-pane show tab-match-detail-tabs-sub"
                    *ngIf="isActiveSub('cricket_wicket')"
                    [class.active]="isActiveSub('cricket_wicket')"
                    id="tab-match-wicket-content"
                    role="tabpanel"
                    aria-labelledby="tab-match-wicket"
                >
                    <div
                        class="tab-pane show active"
                        id="tab-match-summary-25-content"
                        role="tabpanel"
                        aria-labelledby="tab-match-summary-25"
                    >
                        <app-cricket-wicket [wickets]="wickets"></app-cricket-wicket>
                    </div>
                </div>

                <div
                    class="tab-pane show tab-match-detail-tabs-sub"
                    [class.active]="isActiveSub('overview')"
                    id="tab-match-summary-content"
                    role="tabpanel"
                    aria-labelledby="tab-match-summary"
                >
                    <ul
                        class="nav nav-pills match-detail-subnav bg-light px-2"
                        role="tablist"
                        aria-label="Match sub tabs"
                    >
                        <li
                            class="nav-item"
                            *ngIf="hasScoreActions"
                            role="tab"
                            aria-controls="tab-match-summary-7-content"
                            [attr.aria-selected]="isActiveSub2('match-info-7')"
                            id="tab-match-summary-7"
                        >
                            <a
                                class="nav-link active"
                                data-cy="match-detail-sub-actions"
                                [class.active]="isActiveSub2('match-info-7')"
                                (click)="activeSub2('match-info-7')"
                                data-toggle="tab"
                                href="#tab-match-summary-7-content"
                                >{{ 'web.match_actions_score' | translate | transinit }}</a
                            >
                        </li>
                        <li
                            class="nav-item"
                            *ngIf="hasMatchActions && client.use('matchDetailMatchActions')"
                            role="tab"
                            aria-controls="tab-match-summary-1-content"
                            [attr.aria-selected]="isActiveSub2('match-info-1')"
                            id="tab-match-summary-1"
                        >
                            <a
                                class="nav-link"
                                [class.active]="!hasScoreActions"
                                data-cy="match-detail-sub-actions"
                                [class.active]="isActiveSub2('match-info-1')"
                                (click)="activeSub2('match-info-1')"
                                data-toggle="tab"
                                href="#tab-match-summary-1-content"
                                >{{ 'web.match_actions' | translate | transinit }}</a
                            >
                        </li>
                        <li
                            class="nav-item"
                            role="tab"
                            aria-controls="tab-match-summary-2-content"
                            [attr.aria-selected]="isActiveSub2('match-info-2')"
                            id="tab-match-summary-2"
                        >
                            <a
                                class="nav-link"
                                data-cy="match-detail-sub-info"
                                [class.active]="isActiveSub2('match-info-2')"
                                (click)="activeSub2('match-info-2')"
                                data-toggle="tab"
                                href="#tab-match-summary-2-content"
                                >{{ 'web.match_info' | translate | transinit }}</a
                            >
                        </li>
                        <li
                            *ngIf="hasCommentary && client.use('matchDetailCommentary')"
                            class="nav-item"
                            role="tab"
                            aria-controls="tab-match-summary-9-content"
                            [attr.aria-selected]="isActiveSub2('match-info-9')"
                            id="tab-match-summary-9"
                        >
                            <a
                                (click)="activeSub2('match-info-9')"
                                [class.active]="isActiveSub2('match-info-9')"
                                class="nav-link"
                                data-cy="match-detail-sub-actions"
                                data-toggle="tab"
                                href="#tab-match-summary-9-content"
                                >{{ 'web.match_commentary' | translate | transinit }}</a
                            >
                        </li>
                        <li
                            class="nav-item"
                            *ngIf="formWidget && !probabilityWidget && client.use('matchDetailForm')"
                            role="tab"
                            aria-controls="tab-match-summary-6-content"
                            [attr.aria-selected]="isActiveSub2('match-info-6')"
                            id="tab-match-summary-6"
                        >
                            <a
                                class="nav-link"
                                data-cy="match-detail-sub-form"
                                [class.active]="isActiveSub2('match-info-6')"
                                (click)="activeSub2('match-info-6')"
                                data-toggle="tab"
                                href="#tab-match-summary-6-content"
                                >{{ 'web.widget_form_title' | translate | transinit }}</a
                            >
                        </li>
                        <li
                            class="nav-item"
                            *ngIf="probabilityWidget && client.use('matchDetailProbability')"
                            role="tab"
                            aria-controls="tab-match-summary-5-content"
                            [attr.aria-selected]="isActiveSub2('match-info-5')"
                            id="tab-match-summary-5"
                        >
                            <a
                                class="nav-link"
                                data-cy="match-detail-sub-win"
                                [class.active]="isActiveSub2('match-info-5')"
                                (click)="activeSub2('match-info-5')"
                                data-toggle="tab"
                                href="#tab-match-summary-5-content"
                                >{{ 'web.widget_win_probability_title' | translate | transinit }}</a
                            >
                        </li>

                        <li
                            class="nav-item"
                            *ngIf="data?.funfacts && client.use('matchDetailFunFacts')"
                            role="tab"
                            aria-controls="tab-match-summary-5-content"
                            [attr.aria-selected]="isActiveSub2('funfacts')"
                            id="tab-match-summary-5"
                        >
                            <a
                                class="nav-link"
                                data-cy="match-detail-sub-funfacts"
                                [class.active]="isActiveSub2('funfacts')"
                                (click)="activeSub2('funfacts')"
                                data-toggle="tab"
                                href="#tab-match-summary-5-content"
                                >{{ 'web.funfacts' | translate | transinit }}</a
                            >
                        </li>
                        <li
                            class="nav-item"
                            *ngIf="
                                data.channels &&
                                data.channels.length &&
                                client.use('channels') &&
                                client.use('matchDetailChannels')
                            "
                            role="tab"
                            aria-controls="tab-match-summary-19-content"
                            [attr.aria-selected]="isActiveSub2('channels')"
                            id="tab-match-summary-19"
                        >
                            <a
                                class="nav-link"
                                data-cy="match-detail-sub-funfacts"
                                [class.active]="isActiveSub2('channels')"
                                (click)="activeSub2('channels')"
                                data-toggle="tab"
                                href="#tab-match-summary-19-content"
                                >{{ 'web.channels' | translate | transinit }} <i class="fas fa-broadcast-tower"></i
                            ></a>
                        </li>
                        <li
                            class="nav-item"
                            *ngIf="hasMatchEvents && client.use('matchDetailEvents')"
                            role="tab"
                            aria-controls="tab-match-summary-8-content"
                            [attr.aria-selected]="isActiveSub2('match-events')"
                            id="tab-match-summary-8"
                        >
                            <a
                                class="nav-link"
                                data-cy="match-detail-sub-match-events"
                                [class.active]="isActiveSub2('match-events')"
                                (click)="activeSub2('match-events')"
                                data-toggle="tab"
                                href="#tab-match-summary-8-content"
                                >{{ 'web.match_events' | translate | transinit }}</a
                            >
                        </li>

                        <!-- <li class="nav-item" *ngIf="showScoreForSport && data?.match_state == 'after'">
                            <a
                                class="nav-link"
                                id="tab-match-summary-3"
                                [class.active]="isActiveSub2('match-info-3')"
                                (click)="activeSub2('match-info-3')"
                                data-toggle="tab"
                                href="#tab-match-summary-3-content"
                                role="tab"
                                aria-controls="tab-match-summary-3-content"
                                aria-selected="false"
                                >{{ 'web.detail_score' | translate | transinit }}</a
                            >
                        </li> -->
                        <li
                            class="nav-item"
                            *ngIf="data?.tennis_rankings.length > 0"
                            role="tab"
                            aria-controls="tab-match-summary-4-content"
                            aria-selected="true"
                            id="tab-match-summary-4"
                        >
                            <a
                                class="nav-link active"
                                [class.active]="isActiveSub2('match-info-4')"
                                (click)="activeSub2('match-info-4')"
                                data-toggle="tab"
                                href="#tab-match-summary-4-content"
                                >{{ 'web.tennis_rankings' | translate | transinit }}</a
                            >
                        </li>
                    </ul>
                    <div class="tab-content sticky-fallback-css">
                        <div
                            class="tab-pane show active"
                            [class.active]="isActiveSub2('match-info-1')"
                            id="tab-match-summary-1-content"
                            role="tabpanel"
                            aria-labelledby="tab-match-summary-1"
                        >
                            <ng-container *ngIf="isBasketball; else notBasketball">
                                <app-tracker
                                    *ngIf="
                                        !hasTrackerError &&
                                        hasTracker &&
                                        isBasketballAndNotBefore &&
                                        wasActiveSub('overview')
                                    "
                                    [sportName]="data?.sport_code_name"
                                    class="app-tracker"
                                    [id]="data?.metainfo?.tracker"
                                    [type]="'timeline'"
                                ></app-tracker>
                            </ng-container>
                            <ng-template #notBasketball>
                                <app-tracker
                                    *ngIf="
                                        !isHockey(data?.sport_code_name) &&
                                        hasTracker &&
                                        !this.isTennis &&
                                        !hasTrackerError
                                    "
                                    (ontrackerError)="ontrackerError($event)"
                                    [sportName]="data?.sport_code_name"
                                    class="app-tracker"
                                    [id]="data?.metainfo.tracker"
                                    [type]="'timeline'"
                                ></app-tracker>
                            </ng-template>
                            <app-match-actions
                                *ngIf="
                                    !isFootball &&
                                    !isBasketball &&
                                    data?.match_actions.length > 0 &&
                                    data?.live_timelines?.length == 0
                                "
                                [sportName]="data?.sport_code_name"
                                [actions]="matchActions"
                            ></app-match-actions>

                            <app-timeline-base
                                *ngIf="
                                    (!isFootball || hasTrackerError || !hasTracker) &&
                                    !isBasketball &&
                                    data?.live_timelines &&
                                    (data?.live_timelines)!.length > 0
                                "
                                [sportName]="data?.sport_code_name"
                                [sportId]="data?.sport_id"
                                [codeState]="data?.code_state"
                                [isLive]="isLive2"
                                [data]="data?.live_timelines"
                                [home]="participants.home_team.name"
                                [away]="participants.away_team.name"
                                [winner]="data?.winner"
                            ></app-timeline-base>
                        </div>
                        <div
                            *ngIf="isActiveSub2('match-info-9')"
                            [class.active]="isActiveSub2('match-info-9')"
                            aria-labelledby="tab-match-summary-9"
                            class="tab-pane show"
                            id="tab-match-summary-9-content"
                            role="tabpanel"
                        >
                            <ng-container *ngIf="hasInternalCommentary; else externalCommentary">
                                <app-commentaries [data]="data?.match_commentaries"></app-commentaries>
                            </ng-container>
                            <ng-template #externalCommentary>
                                <app-opta-commentary
                                    *ngIf="hasOptaStats; else brCommentary"
                                    [matchId]="data?.opta_match"
                                    [competitionId]="data?.opta_competition"
                                    [seasonId]="data?.opta_season"
                                ></app-opta-commentary>
                                <ng-template #brCommentary>
                                    <app-tracker
                                        *ngIf="isActiveSub2('match-info-9')"
                                        [id]="data?.metainfo.tracker"
                                        [sportName]="data?.sport_code_name"
                                        class="app-tracker"
                                        type="commentary"
                                    ></app-tracker>
                                </ng-template>
                            </ng-template>
                        </div>
                        <div
                            class="tab-pane show active"
                            [class.active]="isActiveSub2('match-info-7')"
                            id="tab-match-summary-7-content"
                            role="tabpanel"
                            aria-labelledby="tab-match-summary-7"
                        >
                            <app-match-actions-score
                                *ngIf="hasScoreActions"
                                [sportName]="data?.sport_code_name"
                                [actions]="data?.match_actions_score"
                            ></app-match-actions-score>
                        </div>

                        <div
                            class="tab-pane match-detail-infromations"
                            [class.active]="isActiveSub2('match-info-2')"
                            id="tab-match-summary-2-content"
                            role="tabpanel"
                            aria-labelledby="tab-match-summary-2"
                        >
                            <h6 class="text-center border-top text-uppercase match-summary__head">
                                {{ 'web.informations' | translate }}
                            </h6>
                            <div class="row border-top py-1 px-3 small">
                                <div class="col-4">{{ 'web.start_date' | translate }}</div>
                                <div class="col-8" data-cy="match-detail-date">
                                    {{ data?.start_date | livDate : 'localDateTime' }}
                                </div>
                            </div>

                            <div class="row border-top py-1 px-3 small" *ngIf="data?.sport_event_conditions?.type">
                                <div class="col-4">{{ 'web.type_of_match' | translate }}</div>
                                <div class="col-8" data-cy="match-detail-date">
                                    {{ 'web.match_type_' + data?.sport_event_conditions?.type | translate }}
                                </div>
                            </div>

                            <div class="row border-top py-1 px-3 small" *ngIf="data?.sport_event_conditions?.day_night">
                                <div class="col-4">{{ 'web.day_night' | translate }}</div>
                                <div class="col-8" data-cy="match-detail-date">
                                    {{ 'web.day_night_' + data?.sport_event_conditions?.day_night | translate }}
                                </div>
                            </div>

                            <div
                                class="row border-top py-1 px-3 small"
                                *ngIf="data?.sport_event_conditions?.weather_info"
                            >
                                <div class="col-4">
                                    <div class="d-flex flex-column align-items-stretch app-h-100">
                                        <div>{{ 'web.weather_info' | translate }}</div>
                                        <div class="align-self-center d-flex align-items-center" style="flex-grow: 1">
                                            <i class="wi fa-3x" [ngClass]="weatherIcon"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-8" data-cy="match-detail-referee">
                                    <table>
                                        <tr *ngIf="data?.sport_event_conditions?.weather_info?.sky_conditions">
                                            <td class="font-weight-bold">
                                                {{
                                                    'web.sky_conditions_' +
                                                        data?.sport_event_conditions?.weather_info?.sky_conditions
                                                        | translate
                                                }}
                                            </td>
                                            <td class="">- {{ 'web.sky_conditions' | translate }}</td>
                                        </tr>
                                        <tr *ngIf="data?.sport_event_conditions?.weather_info?.rain_conditions">
                                            <td class="font-weight-bold">
                                                {{
                                                    'web.rain_conditions_' +
                                                        data?.sport_event_conditions?.weather_info?.rain_conditions
                                                        | translate
                                                }}
                                            </td>
                                            <td class="">- {{ 'web.rain_conditions' | translate }}</td>
                                        </tr>
                                        <tr *ngIf="data?.sport_event_conditions?.weather_info?.temperature_range">
                                            <td class="font-weight-bold">
                                                {{
                                                    'web.temperature_range_' +
                                                        data?.sport_event_conditions?.weather_info?.temperature_range
                                                        | translate
                                                }}
                                            </td>
                                            <td class="">- {{ 'web.temperature_range' | translate }}</td>
                                        </tr>
                                        <tr *ngIf="data?.sport_event_conditions?.weather_info?.wind_conditions">
                                            <td class="font-weight-bold">
                                                {{
                                                    'web.wind_conditions_' +
                                                        data?.sport_event_conditions?.weather_info?.wind_conditions
                                                        | translate
                                                }}
                                            </td>
                                            <td class="">- {{ 'web.wind_conditions' | translate }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div
                                class="row border-top py-1 px-3 small"
                                *ngIf="data?.sport_event_conditions?.pitch_info"
                            >
                                <div class="col-4">
                                    {{ 'web.pitch_info' | translate }}
                                </div>
                                <div class="col-8" data-cy="match-detail-referee">
                                    <table>
                                        <tr *ngIf="data?.sport_event_conditions?.pitch_info?.boundary_position">
                                            <td class="font-weight-bold">
                                                {{
                                                    'web.boundary_position_' +
                                                        data?.sport_event_conditions?.pitch_info?.boundary_position
                                                        | translate
                                                }}
                                            </td>
                                            <td class="">- {{ 'web.boundary_position' | translate }}</td>
                                        </tr>
                                        <tr *ngIf="data?.sport_event_conditions?.pitch_info?.grass_cover">
                                            <td class="font-weight-bold">
                                                {{
                                                    'web.grass_cover_' +
                                                        data?.sport_event_conditions?.pitch_info?.grass_cover
                                                        | translate
                                                }}
                                            </td>
                                            <td class="">- {{ 'web.grass_cover' | translate }}</td>
                                        </tr>
                                        <tr *ngIf="data?.sport_event_conditions?.pitch_info?.pitch_moisture">
                                            <td class="font-weight-bold">
                                                {{
                                                    'web.pitch_moisture_' +
                                                        data?.sport_event_conditions?.pitch_info?.pitch_moisture
                                                        | translate
                                                }}
                                            </td>
                                            <td class="">- {{ 'web.pitch_moisture' | translate }}</td>
                                        </tr>
                                        <tr *ngIf="data?.sport_event_conditions?.pitch_info?.pitch_quality">
                                            <td class="font-weight-bold">
                                                {{
                                                    'web.pitch_quality_' +
                                                        data?.sport_event_conditions?.pitch_info?.pitch_quality
                                                        | translate
                                                }}
                                            </td>
                                            <td class="">- {{ 'web.pitch_quality' | translate }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div
                                class="row border-top py-1 px-3 small"
                                *ngIf="data?.sport_event_conditions?.outfield_info"
                            >
                                <div class="col-4">{{ 'web.outfield_info' | translate }}</div>
                                <div class="col-8" data-cy="match-detail-date">
                                    {{
                                        'web.outfield_info_' +
                                            data?.sport_event_conditions?.outfield_info?.outfield_conditions | translate
                                    }}
                                </div>
                            </div>

                            <div
                                class="row border-top py-1 px-3 small"
                                *ngIf="!referees && data?.metainfo?.referee && (data?.metainfo?.referee)!.length > 0"
                            >
                                <div class="col-4">{{ 'web.referee' | translate }}</div>
                                <div class="col-8" data-cy="match-detail-referee">
                                    {{ data?.metainfo?.referee }}
                                </div>
                            </div>
                            <div
                                class="row border-top py-1 px-3 small"
                                *ngIf="!referees && data?.metainfo?.referees_text"
                            >
                                <div class="col-4">{{ 'web.referee' | translate }}</div>
                                <div class="col-8" data-cy="match-detail-referee">
                                    {{ data?.metainfo?.referees_text }}
                                </div>
                            </div>
                            <div class="row border-top py-1 px-3 small" *ngIf="referees">
                                <div class="col-4">{{ 'web.referees' | translate }}</div>
                                <div class="col-8" data-cy="match-detail-referee">
                                    <table>
                                        <tr *ngIf="basicReferee">
                                            <td class="font-weight-bold" [innerHTML]="basicReferee"></td>
                                            <td class="font-weight-bold">&nbsp;</td>
                                        </tr>
                                        <tr *ngIf="mainReferee">
                                            <td class="font-weight-bold">{{ mainReferee }}</td>
                                            <td class="font-weight-bold">- {{ 'web.main_referee' | translate }}</td>
                                        </tr>
                                        <tr *ngIf="firstReferee">
                                            <td>{{ firstReferee }}</td>
                                            <td>- {{ 'web.first_referee' | translate }}</td>
                                        </tr>
                                        <tr *ngIf="secondReferee">
                                            <td>{{ secondReferee }}</td>
                                            <td>- {{ 'web.second_referee' | translate }}</td>
                                        </tr>
                                        <tr *ngIf="videoReferee">
                                            <td>{{ videoReferee }}</td>
                                            <td>- {{ 'web.video_referee' | translate }}</td>
                                        </tr>
                                        <tr *ngIf="fourthReferee">
                                            <td>{{ fourthReferee }}</td>
                                            <td>- {{ 'web.fourth_referee' | translate }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div
                                class="row border-top py-1 px-3 small"
                                *ngIf="data?.metainfo?.stadium && (data?.metainfo?.stadium)!.length > 0"
                            >
                                <div class="col-4">{{ 'web.stadium' | translate }}</div>
                                <div class="col-8">
                                    {{ data?.metainfo?.stadium }}
                                    <ng-container *ngIf="client.use('map') && this.data?.metainfo?.stadium_coords">
                                        (<a class="link ext-link app-cursor" (click)="map = !map">{{
                                            'web.map' | translate
                                        }}</a
                                        >)
                                    </ng-container>
                                </div>
                            </div>
                            <div class="row border-top py-1 px-3 small" *ngIf="data?.metainfo?.stadium_capacity">
                                <div class="col-4">{{ 'web.stadium_capacity' | translate }}</div>
                                <div class="col-8">{{ data?.metainfo?.stadium_capacity | appnumber }}</div>
                            </div>
                            <div class="row border-top py-1 px-3 small" *ngIf="data?.metainfo?.attendance">
                                <div class="col-4">{{ 'web.attendance' | translate }}</div>
                                <div class="col-8">{{ data?.metainfo?.attendance | appnumber }}</div>
                            </div>
                            <div
                                class="row border-top py-1 px-3 small"
                                *ngIf="data?.metainfo?.place && data?.metainfo?.place?.length > 0"
                            >
                                <div class="col-4">{{ 'web.place' | translate }}</div>
                                <div class="col-8">{{ data?.metainfo?.place }}</div>
                            </div>
                            <div class="row border-top py-1 px-3 small" *ngIf="data?.comments.length > 0">
                                <div class="col">
                                    <i class="fas fa-info-circle"></i> {{ data?.comments?.join(', ') }}
                                </div>
                            </div>
                        </div>
                        <div
                            class="tab-pane"
                            *ngIf="isActiveSub2('match-info-5')"
                            [class.active]="isActiveSub2('match-info-5')"
                            id="tab-match-summary-5-content"
                            role="tabpanel"
                            aria-labelledby="tab-match-summary-5"
                        >
                            <div>
                                <iframe
                                    style="width: 100%; height: 300px"
                                    frameborder="0"
                                    scrolling="no"
                                    [src]="
                                        '/widgets/w/3way;notitle=1;odds=' +
                                            odds.join(',') +
                                            ';id=' +
                                            data?.tracker +
                                            '?lang=' +
                                            lang.getLangSnapshot() | safe
                                    "
                                ></iframe>
                            </div>
                        </div>
                        <div
                            *ngIf="hasMatchEvents"
                            class="tab-pane"
                            [class.active]="isActiveSub2('match-events')"
                            id="tab-match-summary-8-content"
                            role="tabpanel"
                            aria-labelledby="tab-match-summary-8"
                        >
                            <div class="row match-actions" *ngFor="let phrase of mapPhrases; trackBy: trackByIndex">
                                <div class="col-2 match-actions__time text-right p-1">
                                    <div *ngIf="phrase.time !== null">{{ phrase.time }}`</div>
                                </div>
                                <div class="col-1 align-self-center d-sm-block match-actions__icon p-1">
                                    <img
                                        height="20"
                                        width="20"
                                        [alt]="phrase.type.description"
                                        class="match-actions__icon-img ml-1"
                                        #img
                                        (error)="img.style.display = 'none'"
                                        [src]="
                                            '/generated/img/match-data/' +
                                            data?.sport_code_name +
                                            '/' +
                                            phraseImg(phrase.type?.id) +
                                            '.svg?color=' +
                                            color
                                        "
                                    />
                                </div>
                                <div class="col-9 match-actions__text p-1 pl-2">
                                    {{ phrase.text }}
                                    <div class="match-actions__text--small">{{ phrase.type.description }}</div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="tab-pane"
                            *ngIf="formWidget && !probabilityWidget && isActiveSub2('match-info-6')"
                            [class.active]="isActiveSub2('match-info-6')"
                            id="tab-match-summary-5-content"
                            role="tabpanel"
                            aria-labelledby="tab-match-summary-5"
                        >
                            <div>
                                <iframe
                                    style="width: 100%; height: 250px"
                                    frameborder="0"
                                    scrolling="no"
                                    [src]="
                                        '/widgets/w/form;notitle=1;id=' +
                                            data?.tracker +
                                            '?lang=' +
                                            lang.getLangSnapshot() | safe
                                    "
                                ></iframe>
                            </div>
                        </div>
                        <div
                            class="tab-pane funfacts"
                            [class.active]="isActiveSub2('funfacts')"
                            id="tab-match-summary-5-content"
                            role="tabpanel"
                            aria-labelledby="tab-match-summary-5"
                        >
                            <div
                                *ngFor="let funfact of data?.funfacts"
                                class="row border-top py-1 px-4 small match-detail-info-text"
                            >
                                {{ funfact }}
                            </div>
                        </div>
                        <div
                            class="tab-pane"
                            [class.active]="isActiveSub2('channels')"
                            id="tab-match-summary-19-content"
                            role="tabpanel"
                            aria-labelledby="tab-match-summary-19"
                        >
                            <div class="row border-top py-1 px-1 small match-detail-info-text">
                                <app-channels [channels]="data.channels" class="w-100"></app-channels>
                            </div>
                        </div>
                        <div
                            class="tab-pane"
                            [class.active]="isActiveSub2('match-info-3')"
                            id="tab-match-summary-3-content"
                            role="tabpanel"
                            aria-labelledby="tab-match-summary-3"
                        >
                            <app-score
                                [sport]="data?.sport_code_name"
                                [match_state]="data?.match_state"
                                [codeState]="data?.code_state"
                                [data]="data?.score"
                                [onlyPeriods]="true"
                                [head]="true"
                            ></app-score>
                        </div>
                        <div
                            class="tab-pane"
                            [class.active]="isActiveSub2('match-info-4')"
                            id="tab-match-summary-4-content"
                            role="tabpanel"
                            aria-labelledby="tab-match-summary-4"
                        >
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">{{ 'web.category' | translate }}</th>
                                        <th scope="col">{{ 'web.player_name' | translate }}</th>
                                        <th scope="col" class="text-center">{{ 'web.rank' | translate }}</th>
                                        <th scope="col" class="text-right">{{ 'web.player_points' | translate }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="
                                            let player of data?.tennis_rankings | slice : 0 : 5;
                                            trackBy: trackByIndex;
                                            first as isFirst2
                                        "
                                    >
                                        <td>
                                            {{ player.category | uppercase }}
                                            {{ 'web.tennis_' + player.sd | translate }}
                                        </td>
                                        <td>
                                            <a
                                                [routerLink]="[
                                                    '/page/sport/participant/' +
                                                        data?.sport_code_name +
                                                        '-' +
                                                        data?.sport_id +
                                                        '/' +
                                                        player.participantId
                                                ]"
                                                class="text-reset"
                                                >{{ player.info.name }}</a
                                            >
                                        </td>
                                        <th scope="row " class="text-center">{{ player.ranking }}</th>
                                        <td class="text-right">{{ player.points }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div
                    class="tab-pane"
                    *ngIf="isActiveSub('statistics') || wasActiveSub('statistics')"
                    [class.active]="isActiveSub('statistics')"
                    id="tab-match-stats-content"
                    role="tabpanel"
                    aria-labelledby="tab-match-stats"
                >
                    <app-opta-match-stats-team-graph-pro
                        [matchId]="data?.opta_match"
                        [competitionId]="data?.opta_competition"
                        [seasonId]="data?.opta_season"
                        *ngIf="hasOptaStats; else brStats"
                    ></app-opta-match-stats-team-graph-pro>
                    <ng-template #brStats>
                        <div
                            class="py-4"
                            *ngIf="
                                (seasonStats || hasNoTrackerStatistics) &&
                                    (isNotBRAndhasNoTrackerStatistics ||
                                        hasTrackerError ||
                                        !hasTracker ||
                                        (!isFootball && !this.isBasketball));
                                else trackerStats
                            "
                        >
                            <app-statistics-total
                                *ngIf="!seasonStats"
                                [sportName]="data?.sport_code_name"
                                [stats]="data?.participantStatistics"
                                [seasonStats]="false"
                                [home]="participants.home_team.name"
                                [away]="participants.away_team.name"
                            ></app-statistics-total>
                            <app-statistics-total
                                *ngIf="seasonStats"
                                [sportName]="data?.sport_code_name"
                                [seasonStats]="true"
                                [stats]="seasonStats!"
                                [home]="participants.home_team.name"
                                [away]="participants.away_team.name"
                            ></app-statistics-total>
                        </div>

                        <ng-template #trackerStats>
                            <app-tracker
                                (ontrackerError)="ontrackerError($event)"
                                class="app-tracker"
                                [id]="data?.metainfo.tracker"
                                [isMode]="!!modeType"
                                [type]="isTennis ? 'statsTennis' : 'stats'"
                            ></app-tracker>
                        </ng-template>
                    </ng-template>
                </div>
                <div
                    class="tab-pane"
                    *ngIf="isActiveSub('statistics-extended') || wasActiveSub('statistics-extended')"
                    [class.active]="isActiveSub('statistics-extended')"
                    id="tab-match-stats-extended-content"
                    role="tabpanel"
                    aria-labelledby="tab-match-stats"
                >
                    <app-auth-container [authOff]="!client.use('signin') || disableAuth" permission="premium_stats">
                        <!-- <app-opta-match-stats-team-graph></app-opta-match-stats-team-graph> -->
                        <app-opta-heatmap
                            [matchId]="data?.opta_match"
                            [competitionId]="data?.opta_competition"
                            [seasonId]="data?.opta_season"
                        ></app-opta-heatmap>

                        <app-opta-action-areas
                            [matchId]="data?.opta_match"
                            [competitionId]="data?.opta_competition"
                            [seasonId]="data?.opta_season"
                        ></app-opta-action-areas>
                        <app-opta-attacking-thirds
                            [matchId]="data?.opta_match"
                            [competitionId]="data?.opta_competition"
                            [seasonId]="data?.opta_season"
                        ></app-opta-attacking-thirds>
                        <app-opta-pass-matrix
                            [matchId]="data?.opta_match"
                            [competitionId]="data?.opta_competition"
                            [seasonId]="data?.opta_season"
                        >
                        </app-opta-pass-matrix>

                        <app-opta-average-positions
                            [matchId]="data?.opta_match"
                            [competitionId]="data?.opta_competition"
                            [seasonId]="data?.opta_season"
                        ></app-opta-average-positions>
                        <app-opta-penalty-touch-map
                            [matchId]="data?.opta_match"
                            [competitionId]="data?.opta_competition"
                            [seasonId]="data?.opta_season"
                        ></app-opta-penalty-touch-map>
                        <app-opta-average-positions
                            [matchId]="data?.opta_match"
                            [competitionId]="data?.opta_competition"
                            [seasonId]="data?.opta_season"
                            template="passing"
                        ></app-opta-average-positions>

                        <!-- <app-opta-defensive-zones
                          [matchId]="data?.opta_match"
                          [competitionId]="data?.opta_competition"
                          [seasonId]="data?.opta_season"
                      ></app-opta-defensive-zones> -->
                        <app-opta-expected-goals
                            [matchId]="data?.opta_match"
                            [competitionId]="data?.opta_competition"
                            [seasonId]="data?.opta_season"
                        ></app-opta-expected-goals>
                        <app-opta-chalkboard
                            [matchId]="data?.opta_match"
                            [competitionId]="data?.opta_competition"
                            [seasonId]="data?.opta_season"
                        ></app-opta-chalkboard>
                    </app-auth-container>
                </div>
                <div
                    class="tab-pane"
                    *ngIf="isActiveSub('player-ratings') || wasActiveSub('player-ratings')"
                    [class.active]="isActiveSub('player-ratings')"
                    id="tab-match- player-ratings-content"
                    role="tabpanel"
                    aria-labelledby="tab-match-player-ratings"
                >
                    <app-auth-container [authOff]="!client.use('signin') || disableAuth" permission="premium_stats">
                        <app-opta-player-ratings
                            [matchId]="data?.opta_match"
                            [competitionId]="data?.opta_competition"
                            [seasonId]="data?.opta_season"
                        ></app-opta-player-ratings>
                    </app-auth-container>
                </div>

                <!-- <div
                    class="tab-pane"
                    *ngIf="isActiveSub('basket-box-score')"
                    [class.active]="isActiveSub('basket-box-score')"
                    id="tab-match-basket-box-score"
                    role="tabpanel"
                    aria-labelledby="tab-match-basket-box-score"
                >
                    <app-tracker class="app-tracker" [id]="data?.metainfo.tracker" type="basketBoxScore"></app-tracker>
                </div> -->
                <div
                    class="tab-pane"
                    [class.active]="isActiveSub('players')"
                    *ngIf="(isActiveSub('players') || wasActiveSub('players')) && hasPlayers"
                    id="tab-match-players-content"
                    role="tabpanel"
                    aria-labelledby="tab-match-players"
                >
                    <ng-container>
                        <app-players
                            [home]="participants.home_team?.name_short || participants.home_team?.name"
                            [away]="participants.away_team?.name_short || participants.away_team?.name"
                            [participantHome]="participants.home_team"
                            [participantAway]="participants.away_team"
                            [formation_home]="participants.home_team?.formation"
                            [formation_away]="participants.away_team?.formation"
                            [showTable]="!hasOptaStats"
                            [data]="data?.players"
                            [statistics]="data?.playerStatistics"
                            [sportName]="data?.sport_code_name"
                            [sportId]="data?.sport_id"
                            [seasonId]="data?.season_id"
                        ></app-players>
                    </ng-container>
                    <ng-container *ngIf="hasOptaStats">
                        <!-- <app-opta-matchday-live
                            [matchId]="data?.opta_match"
                            [competitionId]="data?.opta_competition"
                            [seasonId]="data?.opta_season"
                        ></app-opta-matchday-live> -->
                        <app-opta-match-teamsheet
                            [matchId]="data?.opta_match"
                            [competitionId]="data?.opta_competition"
                            [seasonId]="data?.opta_season"
                        ></app-opta-match-teamsheet>
                        <app-opta-match-player-compare
                            [matchId]="data?.opta_match"
                            [competitionId]="data?.opta_competition"
                            [seasonId]="data?.opta_season"
                        ></app-opta-match-player-compare>
                    </ng-container>
                </div>
                <div
                    class="tab-pane"
                    *ngIf="isActiveSub('h2h')"
                    [class.active]="isActiveSub('h2h')"
                    id="tab-match-matches-content"
                    role="tabpanel"
                    aria-labelledby="tab-match-matches"
                >
                    <app-h2h
                        *ngIf="data?.h2h?.total"
                        [home]="participants.home_team?.name"
                        [away]="participants.away_team?.name"
                        [homeShort]="participants.home_team?.name_short"
                        [awayShort]="participants.away_team?.name_short"
                        [home_logo]="participants.home_team?.image"
                        [away_logo]="participants.away_team?.image"
                        [data]="data?.h2h"
                        [tracker]="data?.metainfo.tracker"
                        [sportName]="data?.sport_code_name"
                        [sportId]="data?.sport_id"
                    ></app-h2h>
                    <ng-container *ngIf="!extendH2HVar">
                        <div class="text-center mt-3 mb-4">
                            <button
                                class="btn btn-outline-secondary btn-sm px-5 mb-2"
                                data-cy="btn-h2h-more"
                                (click)="extendH2H()"
                            >
                                {{ 'web.h2h_more_results' | translate }}
                            </button>
                        </div>
                    </ng-container>
                </div>
                <div
                    class="tab-pane"
                    [class.active]="isActiveSub('table')"
                    *ngIf="data?.hasTable"
                    id="tab-match-table-content"
                    role="tabpanel"
                    aria-labelledby="tab-match-table"
                >
                    <app-league-table
                        *ngIf="isActiveSub('table')"
                        [sportName]="data?.sport_code_name"
                        [sportId]="data?.sport_id"
                        [onlyOne]="true"
                        [participantHome]="participants.home_team.id"
                        [participantAway]="participants.away_team.id"
                        [subTournamentId]="data?.sub_tournament_id"
                        [seasonId]="data?.season_id"
                        [isLive]="isTableLive && isLive"
                    ></app-league-table>
                </div>
                <div
                    class="tab-pane"
                    [class.active]="isActiveSub('periodStats')"
                    *ngIf="isActiveSub('periodStats')"
                    id="tab-match-period-stats"
                    role="tabpanel"
                    aria-labelledby="tab-match-period-stats"
                >
                    <app-statistics-period
                        [stats]="data?.periodStatistics"
                        [sportName]="data?.sport_code_name!"
                        [home]="participants.home_team?.name"
                        [away]="participants.away_team?.name"
                    ></app-statistics-period>
                </div>
                <div
                    class="tab-pane"
                    [class.active]="isActiveSub('betting')"
                    *ngIf="isActiveSub('betting')"
                    id="tab-match-bet-content"
                    role="tabpanel"
                    aria-labelledby="tab-bet-table"
                >
                    <app-auth-container [authOff]="!client.use('signin')" permission="betting_widgets">
                        <app-bets-match-stats
                            [sportName]="data?.sport_code_name"
                            [fullScreen]="fullScreen"
                            [tracker]="data?.tracker"
                            [periodsCount]="periodsCount"
                        ></app-bets-match-stats>
                    </app-auth-container>
                </div>
                <div
                    *ngIf="isActiveSub('topScorer')"
                    class="tab-pane"
                    [class.active]="isActiveSub('topScorer')"
                    id="tab-match-top-scorer-content"
                    role="tabpanel"
                    aria-labelledby="tab-match-top-scorer"
                >
                    <app-player-stats
                        class="d-block mt-1"
                        [subTournamentId]="data?.sub_tournament_id"
                        (onError)="onPlayersError()"
                        (onLoad)="onPlayersLoaded()"
                        [seasonId]="data?.season_id"
                        view="match-detail"
                        [sportId]="data?.sport_id"
                        [sportName]="data?.sport_code_name"
                    ></app-player-stats>
                </div>
                <div
                    class="tab-pane"
                    *ngIf="isActiveSub('news')"
                    [class.active]="isActiveSub('news')"
                    id="tab-match-news-content"
                    role="tabpanel"
                    aria-labelledby="tab-match-news"
                >
                    <app-news [data]="data?.news"></app-news>
                </div>
            </div>
        </ng-template>
    </ng-template>
</ng-container>

<div *ngIf="hasOptaStats && !isBefore && data?.opta_match && !first && !modeLineupOpta">
    <app-opta-goal-replay
        [matchId]="data?.opta_match"
        [competitionId]="data?.opta_competition"
        [seasonId]="data?.opta_season"
    ></app-opta-goal-replay>
    <app-opta-action-areas
        [matchId]="data?.opta_match"
        [competitionId]="data?.opta_competition"
        [seasonId]="data?.opta_season"
        template="table"
    ></app-opta-action-areas>
    <app-auth-container [authOff]="!client.use('signin') || disableAuth" permission="premium_stats">
        <app-opta-opta-penalty-history
            [matchId]="data?.opta_match"
            [competitionId]="data?.opta_competition"
            [seasonId]="data?.opta_season"
        >
        </app-opta-opta-penalty-history>
    </app-auth-container>
</div>

<ng-template #elseBlock>
    <app-placeholder-detail></app-placeholder-detail>
</ng-template>

<app-ads-container position="match_detail" [platforms]="['all']">
    <app-ads match-detail #ads></app-ads>
</app-ads-container>

<app-banner-container *ngIf="!noAdds">
    <app-banner block #banners></app-banner>
</app-banner-container>
<google-modal [mapCoordinates]="data?.metainfo?.stadium_coords" [(visible)]="map"></google-modal>
