/**
 *  Animation statistics bar
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2022 livescore
 */
import {
    trigger,
    style,
    animate,
    transition,

} from '@angular/animations';

export const STATSBAR = trigger('statsbar', [
    transition(':enter', [
        style({ width: '0%' }),
        animate(800, style({ width: '*' })),
    ]),
]);
