import { WindowRefService } from '@/shared/window-ref';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-vimeo',
    templateUrl: './vimeo.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VimeoComponent implements OnInit {
    @Input()
    public video: string;

    constructor(private win: WindowRefService) {}

    ngOnInit(): void {}

    public get url(): string {
        return `https://player.vimeo.com/video/${this.video}`;
    }
}
