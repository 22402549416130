/**
 *  League table sort pipe
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2020 livescore
 */
import { Pipe, PipeTransform } from '@angular/core';
import { sortBy } from 'lodash-es';
import { TableSort } from '@interfaces/league-table.interface';

@Pipe({
    name: 'tableSort',
    pure: true,
})
export class LeagueTableSortPipe implements PipeTransform {
    public transform(value: any, sort?: TableSort | null): any {
        if (!sort) {
            return value;
        }
        value = sortBy(value, (o: any) => +o.columns[sort.index]);

        if (sort.direction === 'desc') {
            return value.reverse();
        }
        return value;
    }
}
