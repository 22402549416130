<ng-container *ngIf="data">
    <div class="funfacts">
        <div
            *ngFor="let val of data; trackBy: trackByIndex"
            class="row border-top py-1 px-4 small match-detail-info-text"
        >
            <div class="col-2">{{ val.time }}</div>
            <div class="col" [innerHTML]="val.message"></div>
        </div>
    </div>
</ng-container>
