import { BetsMarketInterface, BetsMarketOddTypes } from 'src/app/interfaces/bets-market.interface';
import { Store } from '@ngxs/store';

import { BaseComponent } from '../base.component';

export default class BetsBaseComponent extends BaseComponent {
    public constructor(protected store2: Store) {
        super();
    }

    public getMarket(marketId: number): BetsMarketInterface | null {
        const markets = this.store2.selectSnapshot(
            (state: any): any => state.bets.markets,
        );

        if (!markets || markets === null) {
            return null;
        }

        const market = markets.find((m: BetsMarketInterface): boolean => m.id === marketId);

        if (!market || !market.odd_types) {
            return null;
        }

        return market;
    }

    /**
     * Get markets specific column name
     * @param  {number} marketId
     * @param  {number} colId
     * @return {string}
     */
    public getMarketColumn(marketId: number, colId: number): string | null {
        const markets = this.store2.selectSnapshot(
            (state: any): any => state.bets.markets,
        );

        if (!markets) {
            return null;
        }

        const market = markets.find((m: BetsMarketInterface): boolean => m.id === marketId);

        if (!market || !market.odd_types) {
            return null;
        }

        const column = market.odd_types.find((m: BetsMarketOddTypes): boolean => m.id === colId);

        return column ? column.name : null;
    }
}
