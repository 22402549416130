import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy } from '@angular/core';


import { SportConfigToken } from '@config/sport.config';

import { TranslateService } from '@ngx-translate/core';

import { OptaWidgetBase } from '../base.service';

declare const Opta: any;

@Component({
    selector: 'app-opta-commentary',
    templateUrl: './commentary.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptaWidgetCommentaryComponent extends OptaWidgetBase implements AfterViewInit, OnDestroy {
    @Input()
    matchId: string | number | null | undefined;

    @Input()
    competitionId: string | number | null | undefined;

    @Input()
    seasonId: string | number | null | undefined;

      #headlineTrans = 'web.opta_commentary';
      private transHeadline = '';


      public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>,
          public translate: TranslateService) {
          super();
      }

      public ngAfterViewInit(): void {
          this.transHeadline = this.translate.instant(this.#headlineTrans);
          setTimeout(() => {
              const element = document.querySelector('#opta-commentary') as HTMLElement;

              if (element) {
                  element.innerHTML = this.getHtml();
                  const optaWidgetTags = document.querySelector('opta-widget[load="false"]');

                  if (optaWidgetTags) {
                      optaWidgetTags.removeAttribute('load');
                      Opta.start();
                  }
              }
          });
      }

      ngOnDestroy(): void {
          if (Opta.widgets.ref_widget_commentary) {
              Opta.widgets.ref_widget_commentary.destroy(true);
          }
      }

      getHtml(): string {
          return `
          <opta-widget widget="commentary"  widget_id="ref_widget_commentary" 
          competition="${this.competitionId}" season="${this.seasonId}" match="${this.matchId}"  template="normal" 
          live="true" data_type="auto" load="false" order_by="time_descending" show_event_icons="true"
           show_minor_events="true" fixed_height_comments="0" show_live="true"
            title="${this.transHeadline}"
            show_logo="false" show_title="false" breakpoints="400" sport="football"></opta-widget>    
          `;
      }
}
