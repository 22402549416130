import { Component, OnChanges, Input, NgZone, OnInit, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { MatchType, TableData, TableMatches, TableSort, TableSubsDataType } from '@interfaces/league-table.interface';
import $ from 'src/app/shared/jquery';
import { isUndefined, head, cloneDeep } from 'lodash-es';
import SPORT_CONFIG from '@config/sport.config';
import { TranslateService } from '@ngx-translate/core';

type ColumnsType = { short: string; name: string };

@Component({
    selector: 'app-league-table-table',
    templateUrl: './table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeagusTableTableComponent implements OnInit, OnChanges {
    @Input()
    public data!: TableData[];

    @Input()
    public columns!: ColumnsType[];

    @Input()
    public participantHome: number;

    @Input()
    public participantAway: number;

    @Input()
    public form: boolean = false;

    @Input()
    public sportName: string;

    @Input()
    public sportId: number;

    @Input()
    public dataType: TableSubsDataType;

    @Input()
    public isLive: boolean = false;

    private tempData: TableData[];

    public sportConfig: typeof SPORT_CONFIG = SPORT_CONFIG;

    public sort: TableSort | null = null;

    private timeout: number | null = null;

    private underOverLimit: number = 0.5;

    private underOverColumns:ColumnsType[];
    private halfFullTimeColumns:ColumnsType[];

    constructor(private ngZone: NgZone, private trans: TranslateService) {}

    public ngOnInit():void {
        this.initTempData();
        this.underOverColumns = [
            { short: this.trans.instant('web.table_head_over_short'),
                name: this.trans.instant('web.table_head_over_long') },
            { short: this.trans.instant('web.table_head_under_short'),
                name: this.trans.instant('web.table_head_under_long') },
        ];
        this.halfFullTimeColumns = [
            { short: this.trans.instant('web.hf_ww_short'), name: this.trans.instant('web.hf_ww_long') },
            { short: this.trans.instant('web.hf_wt_short'), name: this.trans.instant('web.hf_wt_long') },
            { short: this.trans.instant('web.hf_wl_short'), name: this.trans.instant('web.hf_wl_long') },
            { short: this.trans.instant('web.hf_tw_short'), name: this.trans.instant('web.hf_tw_long') },
            { short: this.trans.instant('web.hf_tt_short'), name: this.trans.instant('web.hf_tt_long') },
            { short: this.trans.instant('web.hf_tl_short'), name: this.trans.instant('web.hf_tl_long') },
            { short: this.trans.instant('web.hf_lw_short'), name: this.trans.instant('web.hf_lw_long') },
            { short: this.trans.instant('web.hf_lt_short'), name: this.trans.instant('web.hf_lt_long') },
            { short: this.trans.instant('web.hf_ll_short'), name: this.trans.instant('web.hf_ll_long') },

        ];
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.nullSort(isUndefined(changes.data));

        if (changes.dataType && changes.dataType.previousValue !== changes.dataType.currentValue) {
            this.underOverLimit = 0.5;
        }
        this.initTempData();
        setTimeout((): void => {
            $('#leagueTab li:first-child a').tab('show');
        }, 100);
    }

    public trackByIndex(index: any): any {
        return index;
    }

    public trackByName(index: number, val: Record<string, any>): any {
        return val.name;
    }

    public trackByPlace(index: number, val: Record<string, any>): any {
        return val.place;
    }

    /**
    * Check if is home or away selected participant
    * @return {boolean}
    */
    public isSelectedParticipant(id: number): boolean {
        return id === this.participantHome || id === this.participantAway;
    }

    /**
     * Sort By column
     */
    public sortBy(index: number): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.initTempData();

        const exampleData = this.tempData[0]?.columns[index];

        if (exampleData && /[:]+/.test(exampleData)) {
            return;
        }

        let direction = this.sort?.direction ?? 'desc';
        direction = direction === 'asc' ? 'desc' : 'asc';
        this.sort = { index, direction };

        // @ts-ignore
        this.timeout = setTimeout(() => {
            $('[data-toggle="tooltip"]').tooltip('hide');
        }, 2000);
    }

    public get countedColumns(): ColumnsType[] {
        if (this.isUnderOver) {
            return [...this.underOverColumns, ...this.columns] as ColumnsType[];
        }
        if (this.isHalfFullTime) {
            return [...this.halfFullTimeColumns, head(this.columns)] as ColumnsType[];
        }

        return this.columns;
    }

    public get countedData(): TableData[] {
        if (this.isUnderOver) {
            return this.tempData;
        }

        if (this.isHalfFullTime) {
            return this.tempData;
        }
        return this.data;
    }

    public isEmph(index: number): boolean {
        const len = this.countedData?.[0].columns.length || 0;
        return index >= len - 10;
    }

    private halfFullTimeMap() {
        return (team: TableData): TableData => {
            let ww: number = 0;
            let wt: number = 0;
            let wl: number = 0;
            let tw: number = 0;
            let tt: number = 0;
            let tl: number = 0;
            let lw: number = 0;
            let lt: number = 0;
            let ll: number = 0;


            team.matches.forEach(
                (match: TableMatches): void => {
                    if (match.type !== MatchType.UPCOMING && match.score_normal_time && match.score_period_1) {
                        const [home, away] = match.score_normal_time.split(':', 2);
                        const [homeHalf, awayHalf] = match.score_period_1.split(':', 2);

                        const isHome = team.name === match.home_team;
                        let endWin: boolean;
                        let endLose: boolean;
                        let endTie: boolean;

                        let halfWin: boolean;
                        let halfLose: boolean;
                        let halfTie: boolean;
                        if (home && away && homeHalf && awayHalf) {
                            if (isHome) {
                                endWin = +home > +away;
                                endLose = +home < +away;
                                endTie = +home === +away;

                                halfWin = +homeHalf > +awayHalf;
                                halfLose = +homeHalf < +awayHalf;
                                halfTie = +homeHalf === +awayHalf;
                            } else {
                                endWin = +home < +away;
                                endLose = +home > +away;
                                endTie = +home === +away;

                                halfWin = +homeHalf < +awayHalf;
                                halfLose = +homeHalf > +awayHalf;
                                halfTie = +homeHalf === +awayHalf;
                            }


                            if (halfWin && endWin) {
                                ww += 1;
                            } else if (halfWin && endTie) {
                                wt += 1;
                            } else if (halfWin && endLose) {
                                wl += 1;
                            } else if (halfTie && endWin) {
                                tw += 1;
                            } else if (halfTie && endTie) {
                                tt += 1;
                            } else if (halfTie && endLose) {
                                tl += 1;
                            } else if (halfLose && endWin) {
                                lw += 1;
                            } else if (halfLose && endTie) {
                                lt += 1;
                            } else if (halfLose && endLose) {
                                ll += 1;
                            }
                        }
                    }
                },
            );

            team.columns = [head(team.columns)];
            team.columns.unshift(ww, wt, wl, tw, tt, tl, lw, lt, ll);

            return team;
        };
    }

    private underOverMap() {
        return (team: TableData): TableData => {
            let under: number = 0;
            let over: number = 0;

            team.matches.forEach(
                (match: TableMatches): void => {
                    if (match.type !== MatchType.UPCOMING && match.score_normal_time) {
                        const [home, away] = match.score_normal_time.split(':', 2);
                        const total: number = +home + +away;

                        if (total > this.underOverLimit) {
                            over += 1;
                        } else {
                            under += 1;
                        }
                    }
                },
            );

            team.columns.unshift(over, under);

            return team;
        };
    }

    public get isUnderOver(): boolean {
        return this.dataType === 'under_over';
    }

    public get isHalfFullTime(): boolean {
        return this.dataType === 'half_full';
    }

    public emphVal(i: number, isLast?:boolean): boolean {
        if (this.isUnderOver) {
            return i < 2;
        }
        if (this.isHalfFullTime) {
            return i < 9;
        }

        return isLast ?? false;
    }

    public setUnderOverLimit(limit: number): void {
        this.underOverLimit = limit;
        this.nullSort();
        this.initTempData();
    }

    public nullSort(ch: boolean = true): void {
        this.ngZone.runOutsideAngular((): void => {
            $('[data-toggle="tooltip"]').tooltip('hide');
        });

        if (ch) { this.sort = null; }
    }

    /**
    * Get class for promotion
    * @param  {object} row
    * @return {string}
    */
    public getClass(row: Record<string, any>): string {
        const ob = '';

        if (row.promotion.color !== null) {
            return row.promotion.color;
        }
        return ob;
    }

    /**
     * Reset temp data
     */
    private initTempData(): void {
        this.tempData = cloneDeep(this.data);
        if (this.isUnderOver) {
            this.tempData.map(this.underOverMap());
        }

        if (this.isHalfFullTime) {
            this.tempData.map(this.halfFullTimeMap());
        }
    }
}
