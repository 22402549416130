<ng-container *ngIf="actions != null">
    <!-- <ng-container *ngFor="let period of actions; trackBy: trackByFn2">
        <h6 class="text-center mt-4 text-uppercase">
            {{ period.period.name }}
        </h6>

        <div class="row border-top py-1 px-3 small" *ngFor="let action of period.actions; trackBy: trackByFn2" [@newLine]>
            <div class="col border-right">
                <ng-container *ngIf="action.team == 'home'">
                    <span *ngIf="action.time !== null">{{ action.time }}`</span>
                    &nbsp;<img
                        [src]="action.image + '?color=black'"
                        height="12"
                        *ngIf="action.image && action.image.length > 0"
                        alt="{{ action.name }}"
                    />
                    <span>&nbsp;{{ action.name }}</span>
                    <span *ngIf="isBasketball()"
                        ><strong>{{ action.points }}</strong> &nbsp;&nbsp;&nbsp;&nbsp;{{ action.actual_score }}</span
                    >
                    <span *ngIf="action.sub_name && action.sub_name.length > 0" class="font-weight-normal">
                        ({{ action.sub_name.join(', ') }})
                    </span>
                </ng-container>
            </div>
            <div class="col">
                <ng-container *ngIf="action.team == 'away'">
                    <span *ngIf="action.time !== null">{{ action.time }}`</span>
                    &nbsp;<img
                        [src]="action.image + '?color=black'"
                        height="12"
                        *ngIf="action.image && action.image.length > 0"
                        alt="{{ action.name }}"
                    />
                    <span>&nbsp;{{ action.name }}</span>
                    <span *ngIf="isBasketball()"
                        ><strong>{{ action.points }}</strong> &nbsp;&nbsp;&nbsp;&nbsp;{{ action.actual_score }}</span
                    >
                    <span *ngIf="action.sub_name && action.sub_name.length > 0" class="font-weight-normal">
                        ({{ action.sub_name.join(', ') }})
                    </span>
                </ng-container>
            </div>
        </div>
    </ng-container> -->

    <div class="tab-content match-summary">
        <div
            *ngFor="let period of actions; trackBy: trackByFn2"
            class="tab-pane show active"
            id="tab-match-summary-1-content"
            role="tabpanel"
            aria-labelledby="tab-match-summary-1"
        >
            <h6 class="text-center text-uppercase match-summary__head">{{ period.period.name }}</h6>
            <div
                *ngFor="let action of period.actions; trackBy: trackByFn2"
                [@newLine]
                class="row no-gutters border-top py-1 px-3 small"
            >
                <div
                    class="col-10 font-weight-bold"
                    [class.offset-2]="action.team == 'away'"
                    [class.text-right]="action.team == 'away'"
                >
                    <span *ngIf="action.time !== null && action.team == 'home'"
                        >{{ action.time }}<ng-container *ngIf="showMinute(action); else space1">`</ng-container
                        ><ng-template #space1>&nbsp;&nbsp;</ng-template></span
                    >
                    <img
                        [src]="action.image + '?color=black'"
                        height="12"
                        *ngIf="action.image && action.image.length > 0 && action.team == 'home'"
                        alt="{{ action.name }}"
                    />
                    {{ action.name }}
                    <span *ngIf="isBasketball()" class="font-weight-normal"
                        ><strong>{{ action.points }}</strong> &nbsp;&nbsp;&nbsp;&nbsp;{{ action.actual_score }}</span
                    >
                    <span *ngIf="action.sub_name && action.sub_name.length > 0" class="font-weight-normal">
                        ({{ action.sub_name.join(', ') }})
                    </span>
                    <img
                        [src]="action.image + '?color=black'"
                        height="12"
                        *ngIf="action.image && action.image.length > 0 && action.team == 'away'"
                        alt="{{ action.name }}"
                    />
                    <span *ngIf="action.time !== null && action.team == 'away'">
                        {{ action.time }}<ng-container *ngIf="showMinute(action)">`</ng-container></span
                    >
                </div>
            </div>
        </div>
    </div>
</ng-container>
