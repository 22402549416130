<div class="modal" [class.modal--open]="visible" *ngIf="visible">
    <!-- Modal content -->
    <div class="modal-content">
        <span class="close app-cursor" (click)="close()">&times;</span>
        <div class="mapouter">
            <div class="gmap_canvas">
                <iframe
                    width="100%"
                    height="100%"
                    id="gmap_canvas"
                    [src]="src | safe"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                ></iframe>
            </div>
        </div>
    </div>
</div>
