import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import APP_CONFIG from '@config/url.config';

import { WindowRefService } from '@/shared/window-ref';


@Component({
    selector: 'app-youtube',
    templateUrl: './youtube.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YoutubeComponent {
    @Input()
    public matchId?: number;

    @Input()
    public stageId?: number;

    @Input()
    public video?: string;

    @Input()
    public videoImage?: string | null;

    constructor(private win: WindowRefService) {}


    public get url(): string {
        return `https://www.youtube.com/embed/${this.video}`;
    }

    public get urlBlank(): string {
        return `https://www.youtube.com//watch?v=${this.video}`;
    }

    public get onlyScreenshot(): boolean {
        return !!(this.video && this.videoImage);
    }

    public get imagePath(): string {
        return this.matchId ? APP_CONFIG.video.youtubeScreenshots : APP_CONFIG.video.stageYoutubeScreenshots;
    }

    public get id(): number | string {
        return this.matchId ?? this.stageId ?? '';
    }
}
