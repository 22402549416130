import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy } from '@angular/core';


import { SportConfigToken } from '@config/sport.config';

import { TranslateService } from '@ngx-translate/core';

import { OptaWidgetBase } from '../base.service';

declare const Opta: any;

@Component({
    selector: 'app-opta-pass-matrix',
    templateUrl: './pass-matrix.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptaWidgetPassMatrixComponent extends OptaWidgetBase implements AfterViewInit, OnDestroy {
    @Input()
        matchId: string | number | null | undefined;

    @Input()
        competitionId: string | number | null | undefined;

    @Input()
        seasonId: string | number | null | undefined;

    @Input()
        template = 'normal';

    #headlineTrans = 'web.opta_match_teamsheet';
    private transHeadline = '';


    public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>,
        public translate: TranslateService) {
        super();
    }

    public ngAfterViewInit(): void {
        this.transHeadline = this.translate.instant(this.#headlineTrans);
        this.#headlineTrans = 'web.opta_pass_matrix';
        setTimeout(() => {
            const element = document.querySelector('#opta-pass-matrix') as HTMLElement;

            if (element) {
                element.innerHTML = this.getHtml();
                const optaWidgetTags = document.querySelector('opta-widget[load="false"]');

                if (optaWidgetTags) {
                    optaWidgetTags.removeAttribute('load');
                    Opta.start();
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (Opta.widgets.ref_widget_pass_matrix && this.template === 'normal') {
            Opta.widgets.ref_widget_pass_matrix.destroy(true);
        }
    }

    getHtml(): string {
        return `
<opta-widget widget="pass_matrix" 
competition="${this.competitionId}" season="${this.seasonId}" match="${this.matchId}" load="false" 
 template="table" live="true" navigation="tabs_more" default_nav="1"
  side="both" sorting="true" order_by="alphabetical" show_match_header="false"
   show_crests="false" show_team_formation="true" show_competition_name="false" 
   show_subs="used" show_goalkeepers="true" show_defenders="true" show_midfielders="true" 
   show_attackers="true" show_timecontrols="true" player_name_columns="true" blanks="-"
    one_direction="false" competition_naming="brief" show_date="false" 
ate_format="dddd D MMMM YYYY" narrow_date_format="DD/MM/YY" team_naming="full"     title="${this.transHeadline}" 
player_naming="full" show_tooltips="true" show_logo="false" show_title="true" 
breakpoints="400, 700" sport="football"></opta-widget>

              
        

             
              `;
    }
}
