<div class="d-flex table-scrollable">
    <div class="table-scrollable__fixed">
        <div class="table-scrollable__fixed-shadow"></div>
        <table class="table-scrollable__fixed__table">
            <thead>
                <tr>
                    <th class="table-scrollable__table-th">&nbsp;</th>
                    <th class="table-scrollable__table-th table-scrollable__fixed__table__image">&nbsp;</th>
                    <th class="table-scrollable__table-th table-scrollable__fixed__table__text">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    class="table-scrollable__table-row"
                    *ngFor="let row of dataTotal; trackBy: trackByIndex; index as i"
                    [class.app-table-selected-participant]="isIndex(i)"
                >
                    <td class="table-scrollable__table-td">
                        <div
                            class="table-scrollable__table-place"
                            data-toggle="tooltip"
                            data-placement="top"
                            [attr.data-original-title]="row.promotion.name"
                            [attr.title]="row.promotion.name"
                        >
                            <span class="span {{ row.promotion.color }} {{ getClass(row) }}">{{ row.place }}</span>
                        </div>
                    </td>
                    <td class="table-scrollable__table-td text-center">
                        <img *ngIf="row.image !== null" [src]="row.image" [alt]="row.name" height="15" class="mr-1" />
                    </td>
                    <td class="table-scrollable__table-td">
                        <a
                            [routerLink]="['/page/sport/participant', sportName + '-' + sportId, row.participant_id]"
                            class="text-reset table-scrollable-team"
                            >{{ row.name }}
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="table-scrollable__data flex-grow-1" tableScroll>
        <table class="table-scrollable__data__table">
            <thead>
                <tr>
                    <ng-container>
                        <th
                            [class.app-table-selected-participant]="isIndex2(i3)"
                            class="table-scrollable__table-th table-scrollable__data__table-th matrix-table__th"
                            *ngFor="
                                let head of dataTotal;
                                trackBy: trackByIndex;
                                last as isLast;
                                first as isFirst;
                                index as i3
                            "
                            data-toggle="tooltip"
                            data-placement="top"
                            title="{{ head.name }}"
                        >
                            <ng-container *ngIf="!isIndex2(i3)"
                                ><img
                                    *ngIf="head.image !== null"
                                    [src]="head.image"
                                    [alt]="head.name"
                                    height="15"
                                    class="mr-1"
                            /></ng-container>
                            <ng-container *ngIf="isIndex2(i3)"
                                ><small>{{ head.name }}</small>
                            </ng-container>
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <ng-container>
                    <tr
                        class="table-scrollable__table-row"
                        *ngFor="let row of data; trackBy: trackByIndex; index as i2"
                        (mouseover)="onMouseOver(i2)"
                        (mouseout)="onMouseOver()"
                    >
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td matrix-table__td {{
                                columnClass(column)
                            }}"
                            *ngFor="let column of row; trackBy: trackByIndex; index as i4"
                            (mouseover)="onMouseOver2(i4)"
                            (mouseout)="onMouseOver2()"
                        >
                            <ng-container *ngIf="isSelf(column)"> &nbsp; </ng-container>
                            <ng-container *ngIf="isUpcoming(column)">
                                <small>{{ column[0].start_date | livDate : 'localDateTimeShort' }}</small>
                            </ng-container>
                            <ng-container *ngIf="isMoreUpcoming(column)">
                                <small>{{ column[0].start_date | livDate : 'localDateTimeShort' }}</small>
                            </ng-container>
                            <ng-container *ngIf="isResult(column)">
                                {{ showResult(column) }}
                                <i
                                    class="fas fa-bars float-right"
                                    *ngIf="column.length > 1"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    data-html="true"
                                    [attr.title]="matches(column)"
                                ></i>
                            </ng-container>
                            &nbsp;
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
