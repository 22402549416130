/* eslint-disable @typescript-eslint/naming-convention */
import { BaseComponent } from '@components/base.component';
import { Component, Input, OnInit } from '@angular/core';
import { TotalStatistics } from '@interfaces/match-data.interface';
import { snakeCase, some } from 'lodash-es';
import { STATSBAR } from '@animations/statistics.animation';


type ObjectTypes = 'home' | 'away' | 'prct' | 'homePrct' | 'awayPrct' | 'originalName' | 'name';


@Component({
    selector: 'app-statistics-total',
    templateUrl: './total-statistics.component.html',
    animations: [STATSBAR],
})
export class TotalMatchStatsComponent extends BaseComponent implements OnInit {
    @Input() stats: TotalStatistics;
    @Input() sportName: string;
    @Input() home: string;
    @Input() away: string;
    @Input() seasonStats: boolean = false;

    private excludeStats = ['GoalsConceded', 'Shutouts', 'tournamentName', 'tournamentYear', 'Checkouts'];

    [key: string]: any;
    /**
     * Definiton of sport groups
     */
    private groups: Record<string, string[]> = {
        tennis: ['serviceReturnStats', 'gameStats', 'pointStats'],
        'ice-hockey': ['gameStatsAttackHockey', 'gameStatsDefenseHockey', 'gameStatsHockey'],
        handball: ['gameStatsHandball'],
        rugby: ['gameBasicRugby', 'gameSpecRugby'],
        snooker: ['gameBasicSnooker', 'gameBallpottedSnooker'],
        soccer: ['gameBasicSoccer', 'gameAttackSoccer', 'gameDefenseSoccer', 'gameCardsSoccer', 'gameOtherSoccer'],
        basketball: ['gameScoringBasketball', 'gameBasicBasketball', 'gameDefenceBasketball',
            'gameAdvantageBasketball'],
    };

    public groupsData: Record<string, string[]> = {


        gameScoringBasketball: [
            'FreeThrowAttemptsTotal',
            'FreeThrowAttemptsSuccessful',
            'ThreePointAttemptsTotal',
            'ThreePointAttemptsSuccessful',
            'TwoPointAttemptsTotal',
            'TwoPointAttemptsSuccessful',

        ],
        gameBasicBasketball: [

            'BallPossession',
            'Timeouts',
            'Assists',
            'Rebounds',
            'Fouls',
            'LeaderPoints',
            'LeaderAssists',
            'LeaderRebounds',
            'DefensiveRebounds',
            'OffensiveRebounds',
            'FieldGoalsMade',
            'Minutes',


        ],

        gameDefenceBasketball: [
            'ShotsBlocked',
            'Steals',
            'TeamRebounds',
            'TeamTurnovers',
            'Turnovers',

        ],

        gameAdvantageBasketball: [
            'BiggestLead',
            'TeamLeads',

            'timeSpentInLeadMinutes',


        ],

        gameBasicSoccer: [
            'MatchesPlayed',
            'AverageBallPossession',
            'GoalsScored',
            'GoalsScoredFirstHalf',
            'GoalsScoredSecondHalf',
            'GoalsConceded',
            'GoalsConcededFirstHalf',
            'GoalsConcededSecondHalf',
            'GoalsByFoot',
            'GoalsByHead',
            'BallPossession',
            'BallSafe',

        ],

        gameAttackSoccer: [
            'ShotsTotal',
            'ShotsOnTarget',
            'ShotsOnGoal',
            'ShotsOffTarget',
            'ShotsOnPost',
            'ShotsOnBar',
            'GoalAttempts',

            'Attacks',
            'DangerousAttacks',
            'Crosses',
            'CrossingAccuracy',
            'KeyPasses',
            'PassingAccuracy',


        ],
        gameDefenseSoccer: [
            'ShotsBlocked',
            'Saves',
        ],
        gameCardsSoccer: [
            'CardsGiven',
            'RedCards',
            'YellowRedCards',
            'YellowCards',

        ],
        gameOtherSoccer: [
            'FreeKicks',
            'Offsides',
            'CornerKicks',
            'PenaltiesMissed',
            'Substitutions',
            'Fouls',
            'Injuries',

        ],

        gameBasicSnooker: [
            'Points',
            'BallsPotted',
            'ShotsTaken',
            'Breaks50Plus',
            'Breaks100Plus',
            'HighestBreak',
            'FoulsConceded',
            'TimeOnTablePercentage',
            'timeOnTableSeconds',
        ],
        gameBallpottedSnooker: [
            'RedBallsPotted',
            'YellowBallsPotted',
            'GreenBallsPotted',
            'BrownBallsPotted',
            'BlueBallsPotted',
            'PinkBallsPotted',
            'BlackBallsPotted',
        ],
        gameBasicRugby: [
            'Tries',
            'Conversions',
            'BallPossession',
            'PenaltiesConceded',
            'PenaltyGoals',
            'YellowCards',
            'RedCards',
        ],
        gameSpecRugby: [
            'TotalScrums',
            'Tackles',
            'TackleMissed',
            'Carries',
            'LineoutsWon',
            'Passes',
            'TryAssist',
            'MetersRun',
            'ScrumsLost',
            'ScrumsWon',
            'CleanBreaks',
            'Offloads',

        ],
        gameStatsHandball: [
            'shotsEfficence',
            'Shots',
            'ShotsOnGoal',
            'ShotsOffGoal',
            // 'ShotAccuracy',
            'ShotsAgainst',
            'FieldGoals',
            'SevenMGoals',
            'Assists',
            'savesEfficence',
            'Saves',
            'Blocks',
            'Steals',

            'Suspensions',
            'TechnicalFouls',
            'YellowCards',
            'RedCards',
        ],
        gameStatsAttackHockey: [
            'Goals',
            'Shots',
            'ShotsOnGoal',
            'ShootingPercentage',

        ],
        gameStatsDefenseHockey: [
            'GoalsConceded',
            'Saves',
            'SavePercentage',
        ],
        gameStatsHockey: [
            'MatchesPlayed',
            'MatchesWon',
            'MatchesLost',
            'NumberOfPenalties',
            'OvertimeLosses',

            'goals_allowed',

            'PuckPossession',
            'Penalties',
            'PenaltyMinutes',
            'GoalsInPowerPlay',
            'GoalsWhileShortHanded',

            'PowerPlays',

            'Shutouts',
        ],

        serviceReturnStats: [
            'Aces',
            'DoubleFaults',
            'FirstServePercentage',
            'break_point_conversions',
            'firstServeEfficence',
            'secondServeEfficence',
            'firstServePointsEfficence',
            'secondServePointsEfficence',
            'FirstServeSuccessful',
            'SecondServeSuccessful',
            'FirstServePointsWon',
            'SecondServePointsWon',
            'ServicePointsWon',
            'ServicePointsLost',
            'ServiceGamesWon',
            'ReturnWinners',
            'ReturnErrors',

        ],
        gameStats: [
            'breakpointsEfficence',
            'BreakpointsWon',
            'TotalBreakpoints',
            'GamesWon',
            'PointsWonFromLast10',
            'MaxGamesInARow',
            'MaxPointsInARow',
            'PointsWon',
            'TiebreaksWon',

        ],
        pointStats: [
            'ForehandWinners',
            'ForehandErrors',
            'ForehandUnforcedErrors',
            'BackhandWinners',
            'BackhandErrors',
            'BackhandUnforcedErrors',
            'GroundstrokeWinners',
            'GroundstrokeErrors',
            'GroundstrokeUnforcedErrors',
            'OverheadStrokeWinners',
            'OverheadStrokeErrors',
            'OverheadStrokeUnforcedErrors',
            'DropShotWinners',
            'DropShotUnforcedErrors',
            'LobWinners',
            'LobUnforcedErrors',
            'VolleyWinners',
            'VolleyUnforcedErrors',

        ] };


    /**
     * Attributes in prct %
     */
    private percentageValues: string[] = ['PuckPossession', 'BallPossession', 'TimeOnTablePercentage',
        'FirstServePercentage', 'break_point_conversions',
        'CheckoutPercentage', 'ShootingPercentage', 'SavePercentage', 'AverageBallPossession',
        'ShotAccuracy', 'average_ball_possession', 'shooting_percentage', 'save_percentage'];

    private keys: string[] | null = null;

    constructor() {
        super();
    }

    ngOnInit(): void {
        const keys = new Set([...Object.keys(this.stats.home), ...Object.keys(this.stats.away)]);
        this.keys = Array.from(keys).filter(k => !this.excludeStats.includes(k));
    }

    private firstServePointsEfficence(stat: ObjectTypes): string | number | boolean {
        if (this.isBeforeGame) {
            return '-';
        }
        const originalStat = stat;

        if (stat === 'name') {
            return 'firstServePointsEfficence';
        } if (stat === 'prct') {
            return false;
        } if (stat === 'homePrct' || stat === 'awayPrct') {
            stat = stat === 'homePrct' ? 'home' : 'away';
        }

        const value = +this.val('FirstServePointsWon', stat) || 0;
        const total = +this.val('FirstServeSuccessful', stat) || 0;

        let prct = Math.round(value / total * 100) || 0;

        if (!Number.isFinite(prct)) {
            prct = 0;
        }

        if (originalStat === 'homePrct' || originalStat === 'awayPrct') {
            return prct;
        }
        if (total === 0) {
            return '-';
        }
        return `${value}/${total} (${prct}%)`;
    }


    private timeSpentInLeadMinutes(stat: ObjectTypes): string | number | boolean {
        if (this.isBeforeGame) {
            return '-';
        }


        if (stat === 'name') {
            return 'timeSpentInLeadMinutes';
        } if (stat === 'prct') {
            return false;
        }

        const time = +this.val('TimeSpentInLead', stat) || 0;
        const minutes = Math.floor(time / 60);
        const seconds = time - minutes * 60;

        let minutesText = '';
        let secondsText = '';

        if (minutes === 0) {
            minutesText = '';
        } else {
            minutesText = `${minutes} m.`;
        }


        if (seconds === 0) {
            secondsText = '';
        } else {
            secondsText = `${seconds} s.`;
        }
        return `${minutesText} ${secondsText}`;
    }

    private secondServePointsEfficence(stat: ObjectTypes): string | number | boolean {
        if (this.isBeforeGame) {
            return '-';
        }
        const originalStat = stat;

        if (stat === 'name') {
            return 'secondServePointsEfficence';
        } if (stat === 'prct') {
            return false;
        } if (stat === 'homePrct' || stat === 'awayPrct') {
            stat = stat === 'homePrct' ? 'home' : 'away';
        }

        const value = +this.val('SecondServePointsWon', stat) || 0;
        const total = (+this.val('SecondServeSuccessful', stat) + +this.val('DoubleFaults', stat)) || 0;
        let prct = Math.round(value / total * 100) || 0;
        if (!Number.isFinite(prct)) {
            prct = 0;
        }

        if (originalStat === 'homePrct' || originalStat === 'awayPrct') {
            return prct;
        }

        if (total === 0) {
            return '-';
        }
        return `${value}/${total} (${prct}%)`;
    }


    private firstServeEfficence(stat: ObjectTypes): string | number | boolean {
        if (this.isBeforeGame) {
            return '-';
        }
        const originalStat = stat;

        if (stat === 'name') {
            return 'firstServeEfficence';
        } if (stat === 'prct') {
            return false;
        } if (stat === 'homePrct' || stat === 'awayPrct') {
            stat = stat === 'homePrct' ? 'home' : 'away';
        }

        const value = +this.val('FirstServeSuccessful', stat) || 0;
        const total = (+this.val('FirstServeSuccessful', stat) + +this.val('SecondServeSuccessful', stat) +
         +this.val('DoubleFaults', stat)) || 0;
        let prct = Math.round(value / total * 100) || 0;

        if (!Number.isFinite(prct)) {
            prct = 0;
        }

        if (originalStat === 'homePrct' || originalStat === 'awayPrct') {
            return prct;
        }

        if (total === 0) {
            return '-';
        }

        return `${value}/${total} (${prct}%)`;
    }

    private secondServeEfficence(stat: ObjectTypes): string | number | boolean {
        if (this.isBeforeGame) {
            return '-';
        }
        const originalStat = stat;

        if (stat === 'name') {
            return 'secondServeEfficence';
        } if (stat === 'prct') {
            return false;
        } if (stat === 'homePrct' || stat === 'awayPrct') {
            stat = stat === 'homePrct' ? 'home' : 'away';
        }

        const value = +this.val('SecondServeSuccessful', stat) || 0;
        const total = (+this.val('SecondServeSuccessful', stat) + +this.val('DoubleFaults', stat)) || 0;
        let prct = Math.round(value / total * 100) || 0;

        if (!Number.isFinite(prct)) {
            prct = 0;
        }

        if (originalStat === 'homePrct' || originalStat === 'awayPrct') {
            return prct;
        }

        if (total === 0) {
            return '-';
        }
        return `${value}/${total} (${prct}%)`;
    }


    private shotsEfficence(stat: ObjectTypes): string | number | boolean {
        if (this.isBeforeGame) {
            return '-';
        }
        const originalStat = stat;

        if (stat === 'name') {
            return 'shotsefficence';
        } if (stat === 'prct') {
            return false;
        } if (stat === 'homePrct' || stat === 'awayPrct') {
            stat = stat === 'homePrct' ? 'home' : 'away';
        }

        const goal = +this.val('FieldGoals', stat) + +this.val('SevenMGoals', stat);
        const shots = +this.val('Shots', stat);
        let prct = Math.round(goal / shots * 100);

        if (!Number.isFinite(prct)) {
            prct = 0;
        }

        if (originalStat === 'homePrct' || originalStat === 'awayPrct') {
            return prct;
        }

        return `${goal}/${shots} (${prct}%)`;
    }

    private savesEfficence(stat: ObjectTypes): string | number | boolean {
        if (this.isBeforeGame) {
            return '-';
        }
        const originalStat = stat;

        if (stat === 'name') {
            return 'savesefficence';
        } if (stat === 'prct') {
            return false;
        } if (stat === 'homePrct' || stat === 'awayPrct') {
            stat = stat === 'homePrct' ? 'home' : 'away';
        }


        const saves = +this.val('Saves', stat);
        const shots = +this.val('ShotsAgainst', stat);
        let prct = Math.round(saves / shots * 100);

        if (!Number.isFinite(prct)) {
            prct = 0;
        }

        if (originalStat === 'homePrct' || originalStat === 'awayPrct') {
            return prct;
        }

        return `${saves}/${shots} (${prct}%)`;
    }

    private breakpointsEfficence(stat: ObjectTypes): string | number | boolean {
        if (this.isBeforeGame) {
            return '-';
        }
        const originalStat = stat;

        if (stat === 'name') {
            return 'breakpointsEfficence';
        } if (stat === 'prct') {
            return false;
        } if (stat === 'homePrct' || stat === 'awayPrct') {
            stat = stat === 'homePrct' ? 'home' : 'away';
        }

        const value = +this.val('BreakpointsWon', stat) || 0;
        const total = +this.val('TotalBreakpoints', stat) || 0;
        let prct = Math.round(value / total * 100) || 0;

        if (!Number.isFinite(prct)) {
            prct = 0;
        }

        if (originalStat === 'homePrct' || originalStat === 'awayPrct') {
            return prct;
        }

        if (total === 0) {
            return '-';
        }
        return `${value}/${total} (${prct}%)`;
    }

    private timeOnTableSeconds(stat: ObjectTypes): string | number | boolean {
        const originalStat = stat;

        if (stat === 'name') {
            return 'timeOnTableSeconds';
        } if (stat === 'prct') {
            return false;
        } if (stat === 'homePrct' || stat === 'awayPrct') {
            stat = stat === 'homePrct' ? 'home' : 'away';
        }


        const saves = +this.val('TimeOnTableSeconds', originalStat === 'homePrct' ? 'home' : 'away');
        const total = +this.val('TimeOnTableSeconds', 'home') + +this.val('TimeOnTableSeconds', 'away');
        let prct = Math.round(saves / total * 100);

        if (!Number.isFinite(prct)) {
            prct = 0;
        }

        if (originalStat === 'homePrct' || originalStat === 'awayPrct') {
            return prct;
        }

        const goal = +this.val('TimeOnTableSeconds', stat);


        return Math.floor(goal / 60);
    }

    get actions(): Record<ObjectTypes, string | number>[] {
        if (!this.keys) {
            return [];
        }

        return this.keys.map((val: string): any => {
            if (this.stats.home?.[val] && this.percentageValues.includes(val) && this.stats.away?.[val]) {
                const sum = +this.stats.away[val] + +this.stats.home[val];

                if (sum < 2) {
                    this.stats.home[val] = +this.stats.home[val] * 100;
                    this.stats.away[val] = +this.stats.away[val] * 100;
                }
            }

            const resp: Partial<Record<ObjectTypes, any>> =
            { prct: this.percentageValues.includes(val),
                name: snakeCase(val),
                originalName: val,
                home: this.stats.home?.[val] && this.percentageValues.includes(val)
                    ? (+this.stats.home?.[val]).toFixed(2) : (this.stats.home?.[val] ?? null),
                away: this.stats.away?.[val] && this.percentageValues.includes(val)
                    ? (+this.stats.away?.[val]).toFixed(2) : (this.stats.away?.[val] ?? null) };

            const total = +resp.home + +resp.away;

            resp.homePrct = total ? Math.ceil(100 * (resp.home / total)) : 50;
            resp.awayPrct = 100 - resp.homePrct;

            return resp;
        }).sort(a => ((+a.home + +a.away) === 0 ? 1 : 0));
    }

    public isAtLastOneStats(group?: string[]): boolean {
        return some(group, (val: string) => this.val(val, 'name') !== '-');
    }

    public val(key: string, stat: ObjectTypes, calculate: boolean = false): string | number | boolean {
        if (key in this) {
            return this[key](stat);
        }

        const val: Record<ObjectTypes, string | number> | undefined = this.actions
            .find((v): boolean => v.originalName === key);

        if (!calculate) {
            let home = null;
            let away = null;

            if (stat === 'home' || stat === 'away' || stat === 'homePrct' || stat === 'awayPrct') {
                home = this.val(key, 'home', true);
                away = this.val(key, 'away', true);
            }


            if ((stat === 'home' || stat === 'away') &&
            ((home === 0 && away === 0) || (home === '0' && away === '0'))) {
                return '-';
            } if ((stat === 'homePrct' || stat === 'awayPrct') &&
            ((home === 0 && away === 0) || (home === '0' && away === '0'))) {
                return 10;
            }
        }


        return val?.[stat] ?? '-';
    }

    public valSimple(val: Record<ObjectTypes, string | number>, stat: ObjectTypes): number | string {
        const { home, away } = val;

        if ((home === 0 && away === 0) || (home === '0' && away === '0')) {
            return '-';
        }
        return val?.[stat] ?? '-';
    }

    get tournamentName(): string | null {
        if (this.isBeforeGame) {
            return `${this.stats?.home?.TournamentName} ${this.stats?.home?.TournamentYear}`;
        }
        return null;
    }

    get isBeforeGame(): boolean {
        return this.stats?.home && this.stats?.away &&
        ('TournamentName' in this.stats.home || 'TournamentName' in this.stats.away);
    }

    get simpleView(): boolean {
        return (!this.isTennisSport(this.sportName) && !this.isHockey(this.sportName) &&
        !this.isSoccer(this.sportName) &&
        !this.isSnooker(this.sportName) &&
        !this.isHandball(this.sportName) && !this.isRugby(this.sportName) && !this.isBasketballSport(this.sportName));
    }

    get groupStats(): string[] {
        return this.groups[this.sportName] ?? [];
    }
}
