import { Directive, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';

@Directive({
    selector: '[scrollEnd]',
})
export class ScrollEndDirective implements AfterViewInit {
    state: number | null = null;

    constructor(private el: ElementRef) {
        this.state = Math.abs(this.scrollWidth - this.clientWidth - this.scrollLeft);
    }

    public ngAfterViewInit(): void {
        interval(500).pipe(take(1)).subscribe(() => {
            this.updateState();
        });
    }

    get element(): HTMLElement {
        return this.el.nativeElement;
    }

    get scrollWidth(): number {
        return this.element.scrollWidth;
    }

    get scrollLeft(): number {
        return this.element.scrollLeft;
    }

    get clientWidth(): number {
        return this.element.clientWidth;
    }

    // @HostBinding('class.app-mob-nav-wrapper-sub') get isNotEnd(): boolean {
    //     return !this.state || this.state > this.clientWidth;
    // }

    @HostListener('scroll') onScroll(): void {
        this.updateState();
    }

    private updateState(): void {
        this.state = Math.abs(this.scrollWidth - this.clientWidth - this.scrollLeft);

        if (this.state === null || this.state >= 1) {
            this.element.parentElement!.classList.add('app-mob-nav-wrapper-sub');
            return;
        }

        this.element.parentElement!.classList.remove('app-mob-nav-wrapper-sub');
    }
}
