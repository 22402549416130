<div class="row">
    <ng-container *ngFor="let channel of channels; trackBy: trackByFn">
        <div class="col-6 p-1">
            <div class="row">
                <div class="col-2">
                    <img
                        *ngIf="channel.country.flag"
                        [alt]="channel.country.name"
                        [attr.title]="channel.country.name"
                        [src]="channel.country.flag"
                        class="mr-2 channel-img"
                        data-placement="right"
                        data-toggle="tooltip"
                        height="17"
                    />
                </div>
                <div class="col">
                    <ng-container *ngIf="channel.url; else nochannel">
                        <a [href]="channel.url" class="channel-text" target="_blank">{{ channel.name }}</a>
                    </ng-container>
                    <ng-template #nochannel>
                        <span class="channel-text">{{ channel.name }}</span>
                    </ng-template>
                </div>
            </div>
        </div>
    </ng-container>
</div>
