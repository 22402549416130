<aside class="weather app-cursor" *ngIf="temperetureCelsium" (click)="dismiss()" [class.d-none]="weatherDisabled">
    <div class="weather-icon weather-icon--sunny" *ngIf="temperetureIcon">
        <img
            src="https://openweathermap.org/img/wn/{{ temperetureIcon }}@2x.png"
            [alt]="temperetureDescription"
            width="60"
        />
    </div>

    <div class="weather__back-bottom weather__back-bottom--active">
        <div class="weather__back-bottom__main">
            {{ 'web.match_day_weather' | translate }}

            <strong>
                {{ temperetureCelsium }}
            </strong>
            <ng-container *ngIf="temperetureCelsiumMin && temperetureCelsiumMax">
                <span class="weather__back__main--small">{{ temperetureCelsiumMin }}</span>
                <span class="weather__back__main--small">&ndash;</span>
                <span class="weather__back__main--small">{{ temperetureCelsiumMax }} </span>
            </ng-container>
            <strong>&#8451;</strong>
        </div>

        <div class="weather__back-bottom__main2">
            <strong>{{ temperetureDescription }}</strong> &nbsp;&nbsp;&nbsp;&nbsp; <i class="fas fa-wind"></i>
            {{ 'web.weather_wind' | translate }} {{ winSpeed }}m/s &nbsp;&nbsp;&nbsp;&nbsp;

            <i class="fas fa-water"></i>
            {{ 'web.weather_humidity' | translate }} {{ humidity }}% &nbsp;&nbsp;&nbsp;&nbsp;

            <i class="fas fa-angle-double-down"></i>
            {{ 'web.weather_pressure' | translate }} {{ pressure }}hPa &nbsp;&nbsp;&nbsp;&nbsp;<i
                class="fas fa-cloud"
            ></i>
            {{ 'web.weather_cloud_cover' | translate }} {{ clouds }}%
        </div>
    </div>
</aside>
