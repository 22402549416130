/**
 *  Score formatter
 *  @author Livescore <live@score-stats.com>
 *  @copyright 2019 livescore
 */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'uni',
})
export class UniversalPipe implements PipeTransform {
    public transform(value: any, fce: (ar: any) => any): any {
        return fce(value);
    }
}
