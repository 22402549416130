import { Directive, HostListener } from '@angular/core';
import * as $ from 'jquery';

@Directive({
    selector: '[tableScroll]',
})
export class ScrollDirective {
    @HostListener('scroll', ['$event'])
    private onScroll(event: any): void {
        const scrollLeft: number = +event.target.scrollLeft;
        if (scrollLeft > 0) {
            $('.table-scrollable__fixed-shadow').show();
        } else {
            // $(".table-scrollable__fixed-shadow").hide();
        }
    }
}
