import { Component, Input } from '@angular/core';
import { News } from '@interfaces/match-data.interface';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
})
export class NewsComponent {
    @Input()
    public data: News[];

    constructor() {
    }


    public trackByIndex(index: number): number {
        return index;
    }

    image(news: News): string {
        return news.image_small || news.image_original;
    }
}
