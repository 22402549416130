import { WindowRefService } from '@/shared/window-ref';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-nhl',
    templateUrl: './nhl.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NhlComponent implements OnInit {
    @Input()
    public video: string;

    @Input()
    public fullScreen: boolean = false;

    private hostName: string;

    public currentStyles: Record<string, string> = {};

    constructor(private win: WindowRefService) {}

    ngOnInit(): void {
        const { hostname } = this.win.nativeWindow.location;
        this.hostName = hostname;
        this.setCurrentStyles();
    }

    public get url(): string {
        return `https://www.nhl.com/en/video/embed/${this.video}?autostart=false`;
    }

    private setCurrentStyles(): void {
        if (this.fullScreen) {
            this.currentStyles = {
                height: 'calc(54vw)',
            };
        }
    }
}
