<ng-container *ngIf="authOff || (isLoggedIn && hasPermission); else notauth">
    <ng-content></ng-content>
</ng-container>
<ng-template #notauth>
    <ng-container *ngIf="!isLoggedIn">
        <section
            class="app-permission-sign d-flex flex-column py-6 justify-content-center align-items-center"
            [ngClass]="classMade"
        >
            <div class="--layer"></div>
            <div><i class="fas fa-lock fa-2x --icon"></i></div>
            <div class="text my-4 text-center" [innerHTML]="'web.auth_signin_text' | translate | markdown"></div>
            <div>
                <a
                    data-cy="signin-submit"
                    [routerLink]="['.']"
                    [queryParams]="{ registration: '' }"
                    class="btn btn-primary btn-lg btn-block-xs-only"
                    >{{ 'web.registration' | translate }}</a
                >
            </div>
            <div class="text2 mt-2">
                {{ 'web.do_you_have_account' | translate }}
                <a [routerLink]="['.']" [queryParams]="{ signin: '' }">{{ 'web.signin' | translate }}</a>
            </div>
        </section>
    </ng-container>
    <ng-container *ngIf="isLoggedIn && !hasPermission">
        <section
            class="app-permission-sign --none --authorization d-flex flex-column py-6 justify-content-center align-items-center"
        >
            <div class="--layer"></div>
            <div><i class="fas fa-lock fa-2x --icon"></i></div>
            <div class="text my-4" [innerHTML]="'web.auth_authorization_text' | translate | markdown"></div>
            <div>
                <a
                    data-cy="signin-submit"
                    [routerLink]="['/page/account/dashboard']"
                    class="btn btn-primary btn-lg btn-block-xs-only"
                    >{{ 'web.subscription' | translate }}</a
                >
            </div>
            <div class="text2 mt-2">
                {{ 'web.auth_authorization_text2' | translate }}
            </div>
        </section>
    </ng-container>
</ng-template>
