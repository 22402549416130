import { BaseComponent } from '@components/base.component';
import { Component, Input, OnInit } from '@angular/core';
import { DefaultMapFce } from '@interfaces/match-data.interface';
import { toPairs } from 'lodash-es';
import { MATCH_ACTION_ANIMATION } from '@shared/animations/match-actions.animation';
import { trigger } from '@angular/animations';
import { ClientService } from '@services/client.service';
import SPORT_CONFIG from '@config/sport.config';
import { SourceId } from '@interfaces/source.interface';

@Component({
    selector: 'app-timeline-default',
    templateUrl: './default-timeline.component.html',
    animations: [trigger('newLine', MATCH_ACTION_ANIMATION)],
})
export class DefaultTimelineComponent extends BaseComponent implements OnInit {
    @Input() data: DefaultMapFce;
    @Input() sportName: string;
    @Input() sportId: number;

    public isUnknown = false;

    constructor(private client: ClientService) {
        super();
    }


    ngOnInit(): void {
        // console.log(`${this.sportName} data`, _.toPairs(this.data));

        this.isUnknown = Object.values(this.data)[0]!
            .some((value:Record<string, any>) => value.source_id === SourceId.BETSAPI);
    }

    get actions(): [string, Record<string, any>[]][] {
        return toPairs(this.data);
    }

    get color(): string {
        return this.client.has('colors', 'icons') || 'black';
    }

    isTypeUnknown(type: string): boolean {
        return this.isUnknown &&
        (type === SPORT_CONFIG.timeline.eventTypes.unknown || type === SPORT_CONFIG.timeline.eventTypes.redCard ||
            type === SPORT_CONFIG.timeline.eventTypes.yellowCard ||
            type === SPORT_CONFIG.timeline.eventTypes.cornerKick ||
            type === SPORT_CONFIG.timeline.eventTypes.goalKick ||
            type === SPORT_CONFIG.timeline.eventTypes.substitution);
    }

    isTypeText(type: string): boolean {
        return type === SPORT_CONFIG.timeline.eventTypes.technicalRuleFault ||
        type === SPORT_CONFIG.timeline.eventTypes.technicalBallFault ||

        type === SPORT_CONFIG.timeline.eventTypes.suspension ||
        type === SPORT_CONFIG.timeline.eventTypes.sevenMAwarded ||
        type === SPORT_CONFIG.timeline.eventTypes.timeout;
    }
}
