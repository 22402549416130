import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy } from '@angular/core';


import { SportConfigToken } from '@config/sport.config';

import { TranslateService } from '@ngx-translate/core';

import { OptaWidgetBase } from '../base.service';

declare const Opta: any;

@Component({
    selector: 'app-opta-player-ratings',
    templateUrl: './player-ratings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptaWidgetPlayerRatingsComponent extends OptaWidgetBase implements AfterViewInit, OnDestroy {
    @Input()
    matchId: string | number | null | undefined;

    @Input()
    competitionId: string | number | null | undefined;

    @Input()
    seasonId: string | number | null | undefined;

      #headlineTrans = 'web.opta_player_ratings';
      private transHeadline = '';


      public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>,
          public translate: TranslateService) {
          super();
      }

      public ngAfterViewInit(): void {
          this.transHeadline = this.translate.instant(this.#headlineTrans);
          setTimeout(() => {
              const element = document.querySelector('#opta-player-ratings') as HTMLElement;

              if (element) {
                  element.innerHTML = this.getHtml();
                  const optaWidgetTags = document.querySelector('opta-widget[load="false"]');

                  if (optaWidgetTags) {
                      optaWidgetTags.removeAttribute('load');
                      Opta.start();
                  }
              }
          });
      }

      ngOnDestroy(): void {
          if (Opta.widgets.ref_widget_player_ratings) {
              Opta.widgets.ref_widget_player_ratings.destroy(true);
          }
      }

      getHtml(): string {
          return `

          <opta-widget widget="player_ratings"  widget_id="ref_widget_player_ratings"
          competition="${this.competitionId}" season="${this.seasonId}" match="${this.matchId}"
          template="normal" live="false" side="both" animation="true" show_match_header="false"  load="false"
          show_crests="false" show_competition_name="true" show_date="true" date_format="dddd D MMMM YYYY"
           narrow_date_format="DD/MM/YY" show_images="false" show_subs="true" competition_naming="full"
            team_naming="full" player_naming="full" show_logo="false" show_title="false" 
            title="${this.transHeadline}"
            breakpoints="400" show_jersey_numbers="true" sport="football"></opta-widget>

      

 
          `;
      }
}
