import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TournamentService } from 'src/app/services/tournament.service';
import * as Hammer from 'hammerjs';
import * as $ from 'jquery';

@Component({
    selector: 'app-cup-tree',
    templateUrl: './cup-tree.component.html',
})
export class CupTreeComponent implements OnInit {
    public cuptree: any;

    private scale: number = 1;

    private transform: string;

    public enableDrag: boolean = false;

    public displayActions: boolean = true;

    public constructor(
        public dialogRef: MatDialogRef<CupTreeComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { subId: number; seasonId: number },
        private tournament: TournamentService,
    ) {}

    public ngOnInit(): void {
        this.cuptree = this.tournament.getCupTree(
            this.data.seasonId,
            this.data.subId,
        );

        const myElement3 = document.getElementById('app-cuptree-content');
        const mc3 = new Hammer(myElement3 as HTMLElement);
        mc3.on('swiperight', (): void => {
            setTimeout((): void => {
                $('.navbar-mobile').removeClass('show');
                $('body').removeClass('lock-scroll');
                $('.overlay').remove();
            }, 10);
        });

        // TODO: remove mouse wheele event MAC problem, remove this in future
        // window.addEventListener('wheel', (event): void => {
        //     const delta = Math.sign(event.deltaY);

        //     if (delta === 1) {
        //         this.scale += 0.1;
        //         this.scale = Math.min(3, this.scale);
        //     } else if (delta === -1) {
        //         this.scale -= 0.1;
        //         this.scale = Math.max(0.9, this.scale);
        //     }

        //     this.transform = `scale(${this.scale})`;
        // });
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }
}
