import { SportConfigToken } from '@config/sport.config';
import { Component, Inject, Input } from '@angular/core';
import { Player, PlayerStatistics } from '@interfaces/match-data.interface';
import $ from '@shared/jquery';
import { ClientService } from '@services/client.service';
import { PageService } from '@services/page.service';

@Component({
    selector: 'app-lineups',
    templateUrl: './lineups.component.html',
})
export class LineupsComponent {
    @Input()
    public players: Player[] | undefined;

    @Input()
    public sportName: string;

    @Input()
    public extendedStats: boolean = false;

    @Input()
    public sportId: number;

    @Input()
    public seasonId: number;

    @Input()
    public homeAway: string;

    @Input()
    public header: string;

    @Input()
    public home?: string;

    @Input()
    public away?: string;

    @Input()
    public statistics: PlayerStatistics | null = null;

    private activeCell = 0;

    constructor(@Inject(SportConfigToken) private sportConfig: Record<string, any>,
        public client: ClientService, public page: PageService) {
    }


    public over(p: number, side: string = 'home'): void {
        $('.app-players-field').addClass('d-none');
        $(`#p_${side}_${p}`).removeClass('d-none');
    }

    public out(): void {
        $('.app-players-field').addClass('d-none');
    }

    public trackByFn(index: number): number {
        return index;
    }

    get assists(): boolean {
        return this.sportConfig.isSoccer(this.sportName) || this.sportConfig.isFutsal(this.sportName) ||
        this.sportConfig.isHockey(this.sportName) || this.sportConfig.isBasketball(this.sportName) ||
        this.sportConfig.isHandball(this.sportName);
    }

    get isSoccer(): boolean {
        return this.sportConfig.isSoccer(this.sportName);
    }


    get soccerBall(): boolean {
        return this.sportConfig.isSoccer(this.sportName) || this.sportConfig.isFutsal(this.sportName);
    }

    get hockeyPoint(): boolean {
        return this.sportConfig.isHockey(this.sportName);
    }

    get rugbyPoint(): boolean {
        return this.sportConfig.isRugby(this.sportName);
    }

    get handballPoint(): boolean {
        return this.sportConfig.isHandball(this.sportName);
    }

    get isBasketball(): boolean {
        return this.sportConfig.isBasketball(this.sportName);
    }

    get card(): boolean {
        return this.sportConfig.isSoccer(this.sportName) || this.sportConfig.isFutsal(this.sportName) ||
        this.sportConfig.isHandball(this.sportName);
    }

    public formClass(form: number): Record<string, boolean> {
        form = +form;
        if (form >= 6 && form <= 6.7) {
            return { '--form-medium': true };
        }

        if (form > 6.7 && form <= 7.8) {
            return { '--form-better': true };
        }

        if (form > 7.8 && form < 9) {
            return { '--form-best': true };
        }

        if (form >= 9) {
            return { '--form-superstar': true };
        }

        return { '--form-poor': true };
    }

    get extendPlayers(): any[] {
        return this.players!.map((player: Player) => {
            const pId = player.id;
            const statistics = this.statistics?.home?.[pId]?.statistics ?? this.statistics?.away?.[pId]?.statistics ??
            null;
            return { ...player, statistics };
        });
    }

    public playerMatchInfo(player: Player): void {
        this.page.setPlayerMatchDetail({ ...player, ...{ home: this.home, away: this.away } });
    }

    public setCell(num: number = 0): void {
        this.activeCell = num;
    }

    public isCellActive(num: number = 0): boolean {
        return this.activeCell === num;
    }
}
