/**
 *  Order odds
 *  @author Livescore <live@score-stats.com>
 *  @copyright 2019 livescore
 */
import { Pipe, PipeTransform } from '@angular/core';
import { each, sortBy, cloneDeep } from 'lodash-es';
import { BetsMarketInterface } from 'src/app/interfaces/bets-market.interface';

@Pipe({
    name: 'orderOdds',
})
export class OrderOddsPipe implements PipeTransform {
    public transform(value: any, market: BetsMarketInterface | null): any {
        if (market === null) {
            return value;
        }

        const nvalue = cloneDeep(value);

        each(market.odd_types, (val): void => {
            const index = nvalue.findIndex((odd: Record<string, any>): boolean => odd.id === val.id);

            if (index !== -1) {
                nvalue[index].position = val.position;
            }
        });

        return sortBy(nvalue, 'position');
    }
}
