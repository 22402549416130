/* eslint-disable camelcase */
/**
 *   Source interfaces and enums
 *  @author Livescore <it@score-stats.com>
 *  @copyright 2023 24live
 */

export enum Sources {
    BETRADAR = 'Betradar',
    BETSAPI = 'BetsAPI',
    BETCONSTRUCT = 'BetConstruct',
    MANUAL = 'Manual',
}

export enum SourceId {
    BETRADAR = 1,
    BETSAPI = 16,
    ESPORT = 22,
}
