<div class="app-lineup-container">
    <div
        *ngIf="players && players.length > 0"
        [class.extended-lineup]="extendedStats"
        class="match-info__table__head text-center"
    >
        {{ header | translate }}
    </div>
    <div style="overflow: auto; width: 100%">
        <div [class.extended-lineup]="extendedStats" class="match-info__table small pb-2 text-center">
            <ng-container *ngIf="players && players.length > 0">
                <div class="match-info__table__row">
                    <div *ngIf="isSoccer">&nbsp;</div>
                    <div *ngIf="!extendedStats" class="match-info__table__cell">&nbsp;</div>
                    <div *ngIf="!extendedStats" class="match-info__table__cell">&nbsp;</div>
                    <div class="match-info__table__cell">&nbsp;</div>
                    <div class="match-info__table__cell">&nbsp;</div>
                    <div
                        *ngIf="rugbyPoint && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_carries' | translate }}"
                    >
                        {{ 'web.playerstats_short_carries' | translate }}
                    </div>
                    <div
                        *ngIf="rugbyPoint && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_clean_breaks' | translate }}"
                    >
                        {{ 'web.playerstats_short_clean_breaks' | translate }}
                    </div>
                    <div
                        *ngIf="rugbyPoint && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_meters_run' | translate }}"
                    >
                        {{ 'web.playerstats_short_meters_run' | translate }}
                    </div>
                    <div
                        *ngIf="rugbyPoint && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_offloads' | translate }}"
                    >
                        {{ 'web.playerstats_short_offloads' | translate }}
                    </div>
                    <div
                        *ngIf="rugbyPoint && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_passes' | translate }}"
                    >
                        {{ 'web.playerstats_short_passes' | translate }}
                    </div>
                    <div
                        *ngIf="rugbyPoint && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_tackles' | translate }}"
                    >
                        {{ 'web.playerstats_short_tackles' | translate }}
                    </div>
                    <div
                        *ngIf="rugbyPoint && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_tackle_missed' | translate }}"
                    >
                        {{ 'web.playerstats_short_tackle_missed' | translate }}
                    </div>
                    <div
                        *ngIf="rugbyPoint && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_try_assist' | translate }}"
                    >
                        {{ 'web.playerstats_short_try_assist' | translate }}
                    </div>
                    <!-- <div
                        *ngIf="rugbyPoint && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_turnovers_won' | translate }}"
                    >
                        {{ 'web.playerstats_short_turnovers_won' | translate }}
                    </div> -->
                    <div
                        *ngIf="soccerBall && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_goals' | translate }}"
                    >
                        <i class="far fa-futbol v-mid ng-star-inserted"></i>
                    </div>
                    <div
                        *ngIf="soccerBall && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(1)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_minutes_played' | translate }}"
                    >
                        {{ 'web.playerstats_short_minutes_played' | translate }}
                    </div>
                    <div
                        *ngIf="soccerBall && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(2)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_passes_successfull' | translate }}"
                    >
                        {{ 'web.playerstats_short_passes_successfull' | translate }}
                    </div>
                    <div
                        *ngIf="soccerBall && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(3)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_long_passes_total' | translate }}"
                    >
                        {{ 'web.playerstats_short__long_passes_total' | translate }}
                    </div>
                    <div
                        *ngIf="soccerBall && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(4)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_corner_kicks' | translate }}"
                    >
                        {{ 'web.playerstats_short_corner_kicks' | translate }}
                    </div>
                    <div
                        *ngIf="soccerBall && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(5)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_offsides' | translate }}"
                    >
                        {{ 'web.playerstats_short_offsides' | translate }}
                    </div>
                    <div
                        *ngIf="soccerBall && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(6)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_clearances' | translate }}"
                    >
                        {{ 'web.playerstats_short_clearances' | translate }}
                    </div>

                    <div
                        *ngIf="soccerBall && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(7)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_chances_created' | translate }}"
                    >
                        {{ 'web.playerstats_short_chances_created' | translate }}
                    </div>
                    <div
                        *ngIf="soccerBall && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(8)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_interceptions' | translate }}"
                    >
                        {{ 'web.playerstats_short_interceptions' | translate }}
                    </div>
                    <div
                        *ngIf="soccerBall && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(9)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_fouls_committed' | translate }}"
                    >
                        {{ 'web.playerstats_short_fouls_committed' | translate }}
                    </div>
                    <div
                        *ngIf="soccerBall && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(10)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_was_fouled' | translate }}"
                    >
                        {{ 'web.playerstats_short_was_fouled' | translate }}
                    </div>
                    <div
                        *ngIf="soccerBall && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(11)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_tackles_total' | translate }}"
                    >
                        {{ 'web.playerstats_short_tackles_total' | translate }}
                    </div>
                    <div
                        *ngIf="hockeyPoint && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.num_goals' | translate }}"
                    >
                        <img
                            [src]="'/generated/img/match-data/ice-hockey/goal.svg?color=' + client.iconColor()"
                            alt="{{ 'web.num_goals' | translate }}"
                            class="order-sm-0 ng-tns-c232-7 ng-star-inserted"
                            height="12"
                        />
                    </div>
                    <div
                        *ngIf="handballPoint && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.num_goals' | translate }}"
                    >
                        <img
                            [src]="'/generated/img/match-data/handball/goal.svg?color=' + client.iconColor()"
                            alt="{{ 'web.num_goals' | translate }}"
                            class="order-sm-0 ng-tns-c232-7 ng-star-inserted"
                            height="12"
                        />
                    </div>
                    <div
                        *ngIf="handballPoint && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(12)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.blocks' | translate }}"
                    >
                        {{ 'web.short_blocks' | translate }}
                    </div>
                    <div
                        *ngIf="handballPoint && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(31)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.field_goals' | translate }}"
                    >
                        {{ 'web.short_field_goals' | translate }}
                    </div>
                    <div
                        *ngIf="handballPoint && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(13)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.shots' | translate }}"
                    >
                        {{ 'web.short_shots' | translate }}
                    </div>
                    <div
                        *ngIf="handballPoint && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(14)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.shot_accuracy' | translate }}"
                    >
                        {{ 'web.short_shot_accuracy' | translate }}
                    </div>
                    <div
                        *ngIf="handballPoint && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(15)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.steals' | translate }}"
                    >
                        {{ 'web.short_steals' | translate }}
                    </div>
                    <div
                        *ngIf="handballPoint && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(16)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.suspensions' | translate }}"
                    >
                        {{ 'web.short_suspensions' | translate }}
                    </div>
                    <div
                        *ngIf="handballPoint && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(17)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.technical_fouls' | translate }}"
                    >
                        {{ 'web.short_technical_fouls' | translate }}
                    </div>
                    <div
                        *ngIf="hockeyPoint && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.penalty_minutes' | translate }}"
                    >
                        <img
                            [src]="'/generated/img/match-data/ice-hockey/whistle.svg?color=' + client.iconColor()"
                            alt="{{ 'web.penalty_minutes' | translate }}"
                            class="order-sm-0 ng-tns-c232-7 ng-star-inserted"
                            height="12"
                        />
                    </div>
                    <div
                        *ngIf="hockeyPoint && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(18)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.shots_on_goal' | translate }}"
                    >
                        {{ 'web.short_shots_on_goal' | translate }}
                    </div>
                    <div
                        *ngIf="hockeyPoint && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(19)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.first_assists' | translate }}"
                    >
                        {{ 'web.short_first_assists' | translate }}
                    </div>
                    <div
                        *ngIf="hockeyPoint && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(20)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.second_assists' | translate }}"
                    >
                        {{ 'web.short_second_assists' | translate }}
                    </div>
                    <div
                        *ngIf="isBasketball && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.total_points' | translate }}"
                    >
                        <img
                            [src]="'/generated/img/match-data/basketball/goal.svg?color=' + client.iconColor()"
                            alt="{{ 'web.total_points' | translate }}"
                            class="order-sm-0 ng-tns-c232-7 ng-star-inserted"
                            height="12"
                        />
                    </div>
                    <div
                        *ngIf="isBasketball && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.three_points_made' | translate }}"
                    >
                        <span class="badge badge-dark">3</span>
                    </div>
                    <div
                        *ngIf="isBasketball && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.free_throws_made' | translate }}"
                    >
                        <span class="badge badge-dark">{{ 'web.free_throws_made_text' | translate }}</span>
                    </div>
                    <div
                        *ngIf="hockeyPoint && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.hockey_plusminus' | translate }}"
                    >
                        <strong>+/-</strong>
                    </div>
                    <div
                        *ngIf="card && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_yellow_cards' | translate }}"
                    >
                        <svg class="icon icon--12 icon--yellow" id="yellow-card">
                            <use href="#icon-card-yellow"></use>
                        </svg>
                    </div>
                    <div
                        *ngIf="card && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.playerstats_tooltip_red_cards' | translate }}"
                    >
                        <svg class="icon icon--12 icon--danger" id="red-card"><use href="#icon-card-yellow"></use></svg>
                    </div>

                    <div
                        *ngIf="soccerBall && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.total_shoots' | translate }}"
                    >
                        <img
                            alt="{{ 'web.total_shoots' | translate }}"
                            height="16"
                            width="16"
                            src="/img/icos/soccer-boot.png"
                        />
                    </div>
                    <div
                        *ngIf="assists && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.assists' | translate }}"
                    >
                        <strong>A</strong>
                    </div>
                    <div
                        *ngIf="hockeyPoint && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.saves' | translate }}"
                    >
                        <i class="far fa-hand-paper"></i>
                    </div>
                    <div
                        *ngIf="isBasketball && !extendedStats"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.minutes_played' | translate }}"
                    >
                        <i class="fas fa-stopwatch"></i>
                    </div>
                    <div
                        *ngIf="isBasketball && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(21)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.offensive_rebounds' | translate }}"
                    >
                        {{ 'web.short_offensive_rebounds' | translate }}
                    </div>
                    <div
                        *ngIf="isBasketball && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(22)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.defensive_rebounds' | translate }}"
                    >
                        {{ 'web.short_defensive_rebounds' | translate }}
                    </div>
                    <div
                        *ngIf="isBasketball && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(23)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.field_goals_attempted' | translate }}"
                    >
                        {{ 'web.short_field_goals_attempted' | translate }}
                    </div>
                    <div
                        *ngIf="isBasketball && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(24)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.field_goals_made' | translate }}"
                    >
                        {{ 'web.short_field_goals_made' | translate }}
                    </div>

                    <div
                        *ngIf="isBasketball && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(29)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.personal_fouls' | translate }}"
                    >
                        {{ 'web.short_personal_fouls' | translate }}
                    </div>
                    <div
                        *ngIf="isBasketball && extendedStats"
                        [class.app-lineup-row--active]="isCellActive(30)"
                        class="match-info__table__cell text-center"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="{{ 'web.technical_fouls' | translate }}"
                    >
                        {{ 'web.short_technical_fouls' | translate }}
                    </div>
                </div>
            </ng-container>
            <div
                (mouseout)="out()"
                (mouseover)="over(player.lineupPosition, homeAway)"
                *ngFor="let player of extendPlayers; trackBy: trackByFn"
                [attr.data-cy]="extendedStats ? 'lineup-player-ext' : 'lineup-player'"
                class="match-info__table__row app-lineup-row player-stats-row"
            >
                <div class="match-info__table__cell match-info__table__cell--img">
                    <img [attr.alt]="" [attr.src]="player.flag" height="16" />
                </div>
                <div *ngIf="!extendedStats" class="match-info__table__cell match-info__table__cell--number">
                    {{ player.number !== 0 && player.number !== '0' ? player.number : '-' }}
                </div>
                <div
                    class="match-info__table__cell match-info__table__cell--name text-left player-stats-row__player-name"
                    data-cy="player-name-stats"
                >
                    <a
                        [queryParams]="{ season: seasonId }"
                        [routerLink]="['/page/sport/player', sportName + '-' + sportId, player.id]"
                        class="text-reset"
                        >{{ player.name_short ? player.name_short : player.name }}
                        <svg
                            *ngIf="player.statistics?.SubstitutedOut == 1 && !extendedStats"
                            class="icon icon--12 icon--danger"
                            title="{{ 'web.substituted_out' | translate }}"
                        >
                            <use href="#icon-arrow-down"></use>
                        </svg>
                        <svg
                            *ngIf="player.statistics?.SubstitutedIn == 1 && !extendedStats"
                            class="icon icon--12 icon--success"
                            title="{{ 'web.substituted_in' | translate }}"
                        >
                            <use href="#icon-arrow-up"></use>
                        </svg>
                        <span *ngIf="player.missing_reason && player.missing_registered"
                            >({{ player.missing_reason | translate }} - {{ 'web.missing-from' | translate }}
                            {{ player.missing_registered | livDate : 'dateShort'
                            }}<span *ngIf="player.missing_expected_back"
                                >, {{ 'web.expected-back' | translate }}
                                {{ player.missing_expected_back | livDate : 'dateShort' }}</span
                            ><span *ngIf="!player.missing_global">, {{ 'web.missing-in-tournament' | translate }}</span
                            >)
                        </span>
                    </a>
                    <div
                        class="player-stats"
                        data-placement="top"
                        data-toggle="tooltip"
                        data-cy="player-stats"
                        title="{{ 'web.playerstats_match_info' | translate }}"
                    >
                        <button class="btn btn-link p-0 m-0" (click)="playerMatchInfo(player)">
                            <svg class="icon"><use href="#icon-graph" /></svg>
                        </button>
                    </div>
                </div>
                <div *ngIf="!extendedStats" class="match-info__table__cell match-info__table__cell--number">
                    <ng-container *ngIf="player.position !== null">
                        <span class="d-none d-md-inline">
                            {{
                                'web.position_' + sportName + '_' + (player.matchPosition ?? player.position)
                                    | lowercase
                                    | translate
                            }}
                        </span>
                        <span class="d-md-none app-word-nowrap">
                            {{
                                'web.position_' + sportName + '_' + (player.matchPosition ?? player.position) + '_short'
                                    | lowercase
                                    | translate
                            }}
                        </span>
                    </ng-container>
                </div>
                <ng-container *ngIf="isSoccer">
                    <div class="match-info__table__cell match-info__table__cell--number text-center">
                        <div
                            class="player-form__lineup"
                            [ngClass]="formClass(player.statistics?.PlayerForm)"
                            *ngIf="player.statistics?.PlayerForm; else noForm"
                        >
                            {{ player.statistics?.PlayerForm | number : '1.1' }}
                        </div>
                        <ng-template #noForm> &nbsp; </ng-template>
                    </div>
                </ng-container>
                <div
                    *ngIf="rugbyPoint && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ +player.statistics?.Carries ? +player.statistics?.Carries : '-' }}
                </div>
                <div
                    *ngIf="rugbyPoint && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ +player.statistics?.CleanBreaks ? +player.statistics?.CleanBreaks : '-' }}
                </div>
                <div
                    *ngIf="rugbyPoint && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ +player.statistics?.MetersRun ? +player.statistics?.MetersRun : '-' }}
                </div>
                <div
                    *ngIf="rugbyPoint && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ +player.statistics?.Offloads ? +player.statistics?.Offloads : '-' }}
                </div>
                <div
                    *ngIf="rugbyPoint && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ +player.statistics?.Passes ? +player.statistics?.Passes : '-' }}
                </div>
                <div
                    *ngIf="rugbyPoint && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ +player.statistics?.Tackles ? +player.statistics?.Tackles : '-' }}
                </div>
                <div
                    *ngIf="rugbyPoint && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ +player.statistics?.TackleMissed ? +player.statistics?.TackleMissed : '-' }}
                </div>
                <div
                    *ngIf="rugbyPoint && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ +player.statistics?.TryAssist ? +player.statistics?.TryAssist : '-' }}
                </div>
                <!-- <div
                    *ngIf="rugbyPoint && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ +player.statistics?.TurnoversWon ? +player.statistics?.TurnoversWon : '-' }}
                </div> -->
                <div
                    *ngIf="hockeyPoint && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{
                        +player.statistics?.Points && +player.statistics?.Points - +player.statistics?.Assists
                            ? +player.statistics?.Points - +player.statistics?.Assists
                            : '-'
                    }}
                </div>
                <div
                    *ngIf="handballPoint && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ +player.statistics?.GoalsScored ? +player.statistics?.GoalsScored : '-' }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(12)"
                    *ngIf="handballPoint && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(12)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ +player.statistics?.Blocks ? +player.statistics?.Blocks : '-' }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(31)"
                    *ngIf="handballPoint && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(31)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ +player.statistics?.FieldGoals ? +player.statistics?.FieldGoals : '-' }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(13)"
                    *ngIf="handballPoint && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(13)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ +player.statistics?.Shots ? +player.statistics?.Shots : '-' }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(14)"
                    *ngIf="handballPoint && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(14)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ +player.statistics?.ShotAccuracy ? +player.statistics?.ShotAccuracy + '%' : '-' }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(15)"
                    *ngIf="handballPoint && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(15)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ +player.statistics?.Steals ? +player.statistics?.Steals : '-' }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(16)"
                    *ngIf="handballPoint && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(16)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ +player.statistics?.Suspensions ? +player.statistics?.Suspensions : '-' }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(17)"
                    *ngIf="handballPoint && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(17)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ +player.statistics?.TechnicalFouls ? +player.statistics?.TechnicalFouls : '-' }}
                </div>
                <div
                    *ngIf="isBasketball && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ +player.statistics?.Points ? +player.statistics?.Points : '-' }}
                </div>
                <div
                    *ngIf="isBasketball && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ player.statistics?.ThreePointersMade ? +player.statistics?.ThreePointersMade : '-' }}
                    <ng-container *ngIf="player.statistics?.ThreePointersAttempted">
                        / {{ player.statistics?.ThreePointersAttempted }}
                    </ng-container>
                </div>
                <div
                    *ngIf="isBasketball && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ player.statistics?.FreeThrowsMade ? +player.statistics?.FreeThrowsMade : '-' }}
                    <ng-container *ngIf="player.statistics?.FreeThrowsAttempted">
                        / {{ player.statistics?.FreeThrowsAttempted }}
                    </ng-container>
                </div>
                <div
                    *ngIf="hockeyPoint && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{
                        player.statistics?.PenaltyMinutes && +player.statistics?.PenaltyMinutes
                            ? player.statistics?.PenaltyMinutes + "'"
                            : '-'
                    }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(18)"
                    *ngIf="hockeyPoint && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(18)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{
                        player.statistics?.ShotsOnGoal && +player.statistics?.ShotsOnGoal
                            ? player.statistics?.ShotsOnGoal
                            : '-'
                    }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(19)"
                    *ngIf="hockeyPoint && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(19)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{
                        player.statistics?.FirstAssists && +player.statistics?.FirstAssists
                            ? player.statistics?.FirstAssists
                            : '-'
                    }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(20)"
                    *ngIf="hockeyPoint && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(20)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{
                        player.statistics?.SecondAssists && +player.statistics?.SecondAssists
                            ? player.statistics?.SecondAssists
                            : '-'
                    }}
                </div>
                <div
                    *ngIf="hockeyPoint && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{
                        player.statistics?.PlusMinus && +player.statistics?.PlusMinus
                            ? player.statistics?.PlusMinus
                            : '-'
                    }}
                </div>
                <div
                    *ngIf="soccerBall && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                    data-cy="lineup-player-soccer-goal"
                >
                    {{ +player.statistics?.GoalsScored ? player.statistics?.GoalsScored : '-' }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(1)"
                    *ngIf="soccerBall && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(1)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                    data-cy="lineup-player-minutes-played"
                >
                    {{ +player.statistics?.MinutesPlayed ? player.statistics?.MinutesPlayed + "'" : '-' }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(2)"
                    *ngIf="soccerBall && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(2)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                    data-cy="lineup-player-pass-successfull"
                >
                    {{ +player.statistics?.PassesSuccessful ? player.statistics?.PassesSuccessful : '-' }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(3)"
                    *ngIf="soccerBall && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(3)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                    data-cy="lineup-player-soccer-goal"
                >
                    {{ +player.statistics?.LongPassesTotal ? player.statistics?.LongPassesTotal : '-' }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(4)"
                    *ngIf="soccerBall && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(4)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                    data-cy="lineup-player-soccer-goal"
                >
                    {{ +player.statistics?.CornerKicks ? player.statistics?.CornerKicks : '-' }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(5)"
                    *ngIf="soccerBall && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(5)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                    data-cy="lineup-player-soccer-goal"
                >
                    {{ +player.statistics?.Offsides ? player.statistics?.Offsides : '-' }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(6)"
                    *ngIf="soccerBall && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(6)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                    data-cy="lineup-player-soccer-goal"
                >
                    {{ +player.statistics?.Clearances ? player.statistics?.Clearances : '-' }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(7)"
                    *ngIf="soccerBall && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(7)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                    data-cy="lineup-player-soccer-goal"
                >
                    {{ +player.statistics?.ChancesCreated ? player.statistics?.ChancesCreated : '-' }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(8)"
                    *ngIf="soccerBall && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(8)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                    data-cy="lineup-player-soccer-goal"
                >
                    {{ +player.statistics?.Interceptions ? player.statistics?.Interceptions : '-' }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(9)"
                    *ngIf="soccerBall && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(9)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                    data-cy="lineup-player-soccer-goal"
                >
                    {{ +player.statistics?.FoulsCommitted ? player.statistics?.FoulsCommitted : '-' }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(10)"
                    *ngIf="soccerBall && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(10)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                    data-cy="lineup-player-soccer-goal"
                >
                    {{ +player.statistics?.WasFouled ? player.statistics?.WasFouled : '-' }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(11)"
                    *ngIf="soccerBall && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(11)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                    data-cy="lineup-player-soccer-goal"
                >
                    {{ +player.statistics?.TacklesTotal ? player.statistics?.TacklesTotal : '-' }}
                </div>
                <div
                    *ngIf="card && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center"
                    data-cy="lineup-player-yellow-card"
                >
                    {{ +player.statistics?.YellowCards ? player.statistics?.YellowCards : '-' }}
                </div>
                <div
                    *ngIf="card && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center"
                    data-cy="lineup-player-soccer-red-card"
                >
                    {{
                        player.statistics?.YellowRedCards &&
                        player.statistics?.RedCards &&
                        +player.statistics?.RedCards &&
                        +player.statistics?.YellowRedCards
                            ? +player.statistics?.RedCards + +player.statistics?.YellowRedCards
                            : player.statistics?.RedCards && +player.statistics?.RedCards
                            ? +player.statistics?.RedCards
                            : '-'
                    }}
                </div>
                <div
                    *ngIf="soccerBall && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center"
                >
                    {{
                        +player.statistics?.ShotsOnTarget
                            ? +player.statistics?.ShotsOnTarget +
                              +(player.statistics?.ShotsOffTarget ? player.statistics?.ShotsOffTarget : 0)
                            : '-'
                    }}
                </div>
                <div
                    *ngIf="assists && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center"
                    data-cy="lineup-player-assists"
                >
                    {{ +player.statistics?.Assists ? player.statistics?.Assists : '-' }}
                </div>
                <div
                    *ngIf="hockeyPoint && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center"
                    data-cy="lineup-player-saves"
                >
                    {{ +player.statistics?.Saves ? player.statistics?.Saves : '-' }}
                </div>
                <div
                    *ngIf="isBasketball && !extendedStats"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{ player.statistics?.Minutes ? player.statistics?.Minutes : '-' }}
                </div>

                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(21)"
                    *ngIf="isBasketball && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(21)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{
                        player.statistics?.OffensiveRebounds && +player.statistics?.OffensiveRebounds
                            ? player.statistics?.OffensiveRebounds
                            : '-'
                    }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(22)"
                    *ngIf="isBasketball && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(22)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{
                        player.statistics?.DefensiveRebounds && +player.statistics?.DefensiveRebounds
                            ? player.statistics?.DefensiveRebounds
                            : '-'
                    }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(23)"
                    *ngIf="isBasketball && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(23)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{
                        player.statistics?.FieldGoalsAttempted && +player.statistics?.FieldGoalsAttempted
                            ? player.statistics?.FieldGoalsAttempted
                            : '-'
                    }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(24)"
                    *ngIf="isBasketball && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(24)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{
                        player.statistics?.FieldGoalsMade && +player.statistics?.FieldGoalsMade
                            ? player.statistics?.FieldGoalsMade
                            : '-'
                    }}
                </div>

                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(29)"
                    *ngIf="isBasketball && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(29)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{
                        player.statistics?.PersonalFouls && +player.statistics?.PersonalFouls
                            ? player.statistics?.PersonalFouls
                            : '-'
                    }}
                </div>
                <div
                    (mouseout)="setCell(0)"
                    (mouseover)="setCell(30)"
                    *ngIf="isBasketball && extendedStats"
                    [class.app-lineup-row--active]="isCellActive(30)"
                    class="match-info__table__cell match-info__table__cell--number text-center font-weight-bold"
                >
                    {{
                        player.statistics?.TechnicalFouls && +player.statistics?.TechnicalFouls
                            ? player.statistics?.TechnicalFouls
                            : '-'
                    }}
                </div>
            </div>
        </div>
    </div>
</div>
