/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
import { Component, OnInit, Input, HostListener, NgZone, AfterViewChecked, OnChanges }
    from '@angular/core';
import * as __ from 'underscore';
import { sortBy, groupBy, filter, uniqBy } from 'lodash-es';
import { Jersey, Player, PlayerInfo, PlayerStatistics, PlayerStatsInfo,
    StatsTournament, Team } from '@interfaces/match-data.interface';

import APP_CONFIG from '@config/app.config';
import SPORT_CONFIG from '@config/sport.config';

import $ from '@shared/jquery';
import { BaseComponent } from '@components/base.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-players',
    templateUrl: './players.component.html',

})
export class PlayersComponent extends BaseComponent implements OnInit, OnChanges, AfterViewChecked {
    @Input()
    public home?: string;

    @Input()
    public away?: string;

    @Input('formation_home')
    public formationHome?: string;

    @Input('formation_away')
    public formationAway?: string;

    @Input()
    public participant: boolean = false;

    @Input()
    public participantHome?: Team;

    @Input()
    public participantAway?: Team;

    @Input()
    public showField: boolean = true;

    @Input()
    public showTable: boolean = true;

    @Input()
    set data(v: Player[]) {
        v = v.map((val: Player) => {
            val.sort = 100;
            if (val.position === 'goalkeeper' || val.position?.toLowerCase() === 'g' ||
            val.position?.toLowerCase() === 'goalie' ||
                val.position?.toLowerCase() === 'pr') {
                val.sort = 1;
            } else if (val.position === 'defender' || val.position?.toLowerCase() === 'd' ||
             val.position?.toLowerCase() === 'right back' || val.position?.toLowerCase() === 'left back' ||
                val.position?.toLowerCase() === 'ho' || val.position?.toLowerCase() === 'g-f') {
                val.sort = 2;
            } else if (val.position === 'midfielder' || val.position?.toLowerCase() === 'm' ||
             val.position?.toLowerCase() === 'left wink' ||
                val.position?.toLowerCase() === 'l' || val.position?.toLowerCase() === 'c-f' ||
                val.position?.toLowerCase() === 'hb') {
                val.sort = 3;
            } else if (val.position === 'forward' || val.position?.toLowerCase() === 'f' ||
            val.position?.toLowerCase() === 'right wink' ||
                val.position?.toLowerCase() === 'br' || val.position?.toLowerCase() === 'uf') {
                val.sort = 4;
            } else if (val.position?.toLowerCase() === 'sh' || val.position?.toLowerCase() === 'center' ||
            val.position?.toLowerCase() === 'fb') {
                val.sort = 5;
            } else if (val.position?.toLowerCase() === 'fh') {
                val.sort = 6;
            } else if (val.position?.toLowerCase() === 'c') {
                val.sort = 7;
            } else if (val.position?.toLowerCase() === 'w') {
                val.sort = 8;
            } else if (val.position?.toLowerCase() === 'fl') {
                val.sort = 9;
            } else if (val.position?.toLowerCase() === 'ub') {
                val.sort = 9;
            }

            return val;
        }).filter((val: Player) => !val.role?.includes('-duplicity'));
        this.dataVal = sortBy(v, ['sort', 'position']);
    }

    get data(): Player[] {
        return this.dataVal;
    }

    @Input()
    public statistics: PlayerStatistics | null = null;

    @Input()
    public sportName: string;

    @Input()
    public sportId: number;

    @Input()
    public seasonId: number;

    private dataVal: Player[];

    public subHome: Player[];

    public subAway: Player[];

    public players: { [prop: string]: Player[] };

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public _isHome: boolean = true;

    public positionCss: Record<string, string> = {};

    private playerSelectedVal: number | null = null;

    public field: PlayerInfo = 'form';

    public constructor(

        private deviceService: DeviceDetectorService,
        private ngZone: NgZone,

    ) {
        super();
    }

    public ngOnChanges(): void {
        this.ngOnInit();
    }

    public ngOnInit(): void {
        // this.data = _.sortBy(this.data, 'position');
        this.players = groupBy(this.data, 'team');

        if (this.players.home && this.players.away) {
            this.players.home = this.players.home.map(this.mapPlayers);
            this.players.away = this.players.away.map(this.mapPlayers);
        }

        this.subHome = filter(this.players.home, (val): boolean => val.substitute === 1);
        this.subAway = filter(this.players.away, (val): boolean => val.substitute === 1);
        this.players.missingHome = filter(this.players.home, (val): boolean => val.substitute === 2);
        this.players.missingAway = filter(this.players.away, (val): boolean => val.substitute === 2);
        this.players.home = filter(this.players.home, (val): boolean => val.substitute === 0 ||
            val.substitute === null);
        this.players.away = filter(this.players.away, (val): boolean => val.substitute === 0 ||
            val.substitute === null);

        this.players.home = this.players.home.map((player: Player) => {
            const pId = player.id;
            const statistics = this.statistics?.home?.[pId]?.statistics ?? null;
            return { ...player, statistics };
        });

        this.players.away = this.players.away.map((player: Player) => {
            const pId = player.id;
            const statistics = this.statistics?.away?.[pId]?.statistics ?? null;
            return { ...player, statistics };
        });

        $('#myTab li:first-child a').tab('show');

        let cssIndex = 1;
        this.data.forEach((val: Player) => {
            if (!this.positionCss[val.position]) {
                this.positionCss[val.position] = `players-position-${cssIndex}`;
                cssIndex += 1;
            }
        });
    }

    public formClass(form: number): Record<string, boolean> {
        form = +form;
        if (form >= 6 && form <= 6.7) {
            return { '--form-medium': true };
        }

        if (form > 6.7 && form <= 7.8) {
            return { '--form-better': true };
        }

        if (form > 7.8 && form < 9) {
            return { '--form-best': true };
        }

        if (form >= 9) {
            return { '--form-superstar': true };
        }


        return { '--form-poor': true };
    }

    public ngAfterViewChecked(): void {
        $('[data-toggle="tooltip"]').tooltip();
    }

    get someHasForm(): boolean {
        if (this.players.home && this.players.home.length > 0) {
            return this.players.home.some(p => p.statistics.PlayerForm);
        }

        if (this.players.away && this.players.away.length > 0) {
            return this.players.away.some(p => p.statistics.PlayerForm);
        }

        return false;
    }

    @HostListener('window:scroll', ['$event'])
    private onScroll(): void {
        this.ngZone.runOutsideAngular((): void => {
            const el = document.getElementById('players-squad');
            if (!el) {
                return;
            }
            const el2 = document.getElementById('players-squad-virtual');
            const rect = el!.getBoundingClientRect();

            if (rect.y < 0) {
                let num = Math.abs(rect.y);
                if (this.deviceService.isMobile()) {
                    num += 60;
                }

                el2!.style.top = `${num}px`;
            } else {
                el2!.style.top = '0px';
            }
        });
    }


    public fieldInfo(type:PlayerInfo): void {
        this.field = type;
    }

    private mapPlayers(val: Player): Player {
        if (val.flag === null) {
            val.flag = APP_CONFIG.defaultAvatar;
        }
        return val;
    }

    get totalFormHome(): number {
        let total = 0;
        let sum = 0;

        if (!this.players?.home) {
            return total;
        }

        this.players!.home!.forEach((p) => {
            if (p.statistics?.PlayerForm) {
                total += 1;
                sum += +p.statistics?.PlayerForm;
            }
        });

        if (total === 0) {
            return total;
        }

        return +((sum / total).toFixed(1));
    }

    get totalFormAway(): number {
        let total = 0;
        let sum = 0;

        if (!this.players?.away) {
            return total;
        }

        this.players!.away!.forEach((p) => {
            if (p.statistics?.PlayerForm) {
                total += 1;
                sum += +p.statistics?.PlayerForm;
            }
        });

        if (total === 0) {
            return total;
        }

        return +((sum / total).toFixed(1));
    }

    get hasStringPlayers(): boolean {
        return !!(this.participantHome?.lineup_string && this.participantHome?.lineup_string);
    }

    get homeJersey(): Record<Jersey['jerseyType'], Jersey> | null {
        if (typeof this.participantHome === 'undefined' || !this.participantHome.jerseys) {
            return null;
        }

        return __.indexBy(this.participantHome.jerseys, 'jerseyType') as any;
    }

    get awayJersey(): Record<Jersey['jerseyType'], Jersey> | null {
        if (typeof this.participantAway === 'undefined' || !this.participantAway.jerseys) {
            return null;
        }

        return __.indexBy(this.participantAway.jerseys, 'jerseyType') as any;
    }

    public jerseyColor(type: 'home' | 'away', index?: number | string,
        part: 'base' | 'number' | 'sleeve' | 'shirtType' = 'base'): string {
        // eslint-disable-next-line no-nested-ternary, eqeqeq
        const position: Jersey['jerseyType'] = index == 1 ? 'goalkeeper' : (type === 'home' ? 'home' : 'away');
        if (type === 'away') {
            return this.awayJersey?.[position]?.[part] ? `#${this.awayJersey?.[position]?.[part]}` : 'inherit';
        }


        return this.homeJersey?.[position]?.[part] ? `#${this.homeJersey?.[position]?.[part]}` : 'inherit';
    }

    /**
     * Is hpme tab active
     * @return {boolean}
     */
    public get isHome(): boolean {
        return this._isHome;
    }

    get homePlayers():{[key: string]: Player[] } {
        const key = this.data.some(v => v.lineupFormation) ? 'lineupFormation' : 'formation';
        const players = groupBy(this.data, 'team');
        const formations = groupBy(players['home'], key);
        return formations;
    }

    get awayPlayers():{[key: string]: Player[] } {
        const key = this.data.some(v => v.lineupFormation) ? 'lineupFormation' : 'formation';
        const players = groupBy(this.data, 'team');
        const formations = groupBy(players['away'], key);

        return formations;
    }

    get isLineupComplete(): boolean {
        let allValid = true;

        if (!this.playerIsDefined(this.homePlayers) || !this.playerIsDefined(this.awayPlayers)) {
            return false;
        }


        const hp1 = this.homePlayers['1'] ? this.homePlayers['1'].length : 0;
        const hp2 = this.homePlayers['2'] ? this.homePlayers['2'].length : 0;
        const hp3 = this.homePlayers['3'] ? this.homePlayers['3'].length : 0;

        const ap1 = this.awayPlayers['1'] ? this.awayPlayers['1'].length : 0;
        const ap2 = this.awayPlayers['2'] ? this.awayPlayers['2'].length : 0;
        const ap3 = this.awayPlayers['3'] ? this.awayPlayers['3'].length : 0;

        for (const [key, value] of Object.entries(this.homePlayers)) {
            if (key === '1' || key === '2' || key === '3') {
                allValid = !!(value && value
                    .every(v => v.lineupFormation && v.lineupPosition) && allValid);
                const mapPositions = uniqBy(value, 'lineupPosition');
                allValid = mapPositions.length === 5 && allValid;
            }
        }
        for (const [key, value] of Object.entries(this.awayPlayers)) {
            if (key === '1' || key === '2' || key === '3') {
                allValid = !!(value && value
                    .every(v => v.lineupFormation && v.lineupPosition) && allValid);
                const mapPositions = uniqBy(value, 'lineupPosition');
                allValid = mapPositions.length === 5 && allValid;
            }
        }


        return (hp1 + hp2 + hp3) === 15 && (ap1 + ap2 + ap3) === 15 && allValid;
    }

    playerIsDefined(val: {[key: string]: Player[] | undefined}): val is {[key: string]: Player[] } {
        return typeof val !== 'undefined';
    }

    public over(p: number, side: string = 'home'): void {
        $('.app-players-field').addClass('d-none');
        $(`#p_${side}_${p}`).removeClass('d-none');
    }

    public out(): void {
        $('.app-players-field').addClass('d-none');
    }

    public trackByFn(index: number): number {
        return index;
    }

    public activePlayerT(id: number): void {
        this.playerSelectedVal = id;
    }

    public playerData(stats: StatsTournament[], type: PlayerStatsInfo): number | string {
        const tournament = stats.find((val: StatsTournament) => val.tournament_id === this.playerSelectedT);

        if (this.playerSelectedT === 0) {
            let total = 0;
            stats.forEach((val: StatsTournament) => { total += +val[type]!; });

            return total || '-';
        }

        if (tournament?.[type] === '0' || tournament?.[type] === 0) {
            return '-';
        }
        return tournament?.[type] ?? '-';
    }

    get numPlayers(): number {
        const type = this._isHome ? 'home' : 'away';

        return this.data.filter(v => v.team === type && !v.missing_global).length;
    }

    get avgAgePlayers(): number {
        const type = this._isHome ? 'home' : 'away';

        const sum: any = this.data.filter(v => v.team === type && !v.missing_global)
            .reduce((acc, current) => acc + (current.age ?? 0), 0);


        return Math.floor(sum / this.numPlayers);
    }

    get hasCards(): boolean {
        return SPORT_CONFIG.playerStats.redYellowCard.includes(this.sportName);
    }

    get playerTournament(): {name: string;id: number}[] {
        let tournaments: {name: string;id: number}[] = [];
        this.data.forEach((player: Player) => {
            tournaments = [...tournaments, ...player.statsTournament!.map(
                (val: StatsTournament) => ({ id: val.tournament_id, name: val.tournament_name })),
            ];
        });

        return uniqBy(tournaments, 'id');
    }


    get playerSelectedT(): number | null {
        if (this.playerSelectedVal === null && this.playerTournament[0]) {
            return this.playerTournament[0]?.id;
        }
        return this.playerSelectedVal;
    }

    get showMatchPlayed(): boolean {
        return this.isSoccer(this.sportName);
    }

    get showStats(): boolean {
        return this.isHockey(this.sportName) || this.isSoccer(this.sportName) || this.isHandball(this.sportName);
    }

    get hasAssists(): boolean {
        return this.isHockey(this.sportName) || this.isSoccer(this.sportName) || this.isHandball(this.sportName);
    }

    get allHome(): Player[] {
        return [...this.players.home as Player[], ...this.subHome];
    }

    get allAway(): Player[] {
        return [...this.players.away as Player[], ...this.subAway];
    }

    get extendedStats(): boolean {
        return this.isHockey(this.sportName) || this.isSoccer(this.sportName) ||
        this.isHandball(this.sportName) || this.isBasketballSport(this.sportName);
    }

    /**
     * Check if formation is set
     * @return {boolean}
     */
    public hasFormation(): boolean {
        return !!(this.formationAway !== null && this.formationHome !== null);
    }
}
