import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { Status } from '@interfaces/participant.interface';
import { isUndefined } from 'lodash-es';

import { TableData, TableMatches, MatchType } from '@/interfaces/league-table.interface';
import { LiveDatePipe } from '@/shared/pipes/live-datetime.pipe';

@Component({
    selector: 'app-matrix-table',
    templateUrl: './matrix.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatrixComponent {
    @Input()
    public data: TableData;

    @Input()
    public dataTotal: TableData;

    @Input()
    public participantHome: number;

    @Input()
    public participantAway: number;

    @Input()
    public sportName: string;

    @Input()
    public sportId: number;

    private activeRowIndex: number = -1;
    private activeColumnIndex: number = -1;

    public constructor(private datePipe: LiveDatePipe) {}


    public trackByIndex(index: any): any {
        return index;
    }

    public columnClass(column: TableMatches[]): string {
        const isUpcoming = this.isUpcoming(column);
        const isMoreUpcoming = this.isMoreUpcoming(column);
        const resultVal: string = this.showResult(column);
        let result: number[] | string[] = Array.of(0, 0);
        result = resultVal.split(':');
        const isWin = !isUpcoming && +result[0] > +result[1];
        const isLose = !isUpcoming && +result[0] < +result[1];
        const isDraw = !isUpcoming && !isMoreUpcoming && +result[0] === +result[1];

        let ret = '';
        if (this.isSelf(column)) {
            ret = `${ret} matrix-table__td--empty`;
        }
        if (isWin) {
            ret = `${ret} matrix-table__td--win`;
        }
        if (isLose) {
            ret = `${ret} matrix-table__td--lose`;
        }
        if (isDraw) {
            ret = `${ret} matrix-table__td--draw`;
        }
        if (isUpcoming) {
            ret = `${ret} matrix-table__td--upcoming`;
        }
        return ret;
    }

    public isSelf(column: TableMatches[]): boolean {
        return column.length === 0;
    }

    public isUpcoming(column: TableMatches[]): boolean {
        return column.length === 1 && column[0].type === MatchType.UPCOMING;
    }

    public isMoreUpcoming(column: TableMatches[]): boolean {
        const pastExists = column.find((val: TableMatches): boolean => val.type !== MatchType.UPCOMING);
        return column.length > 1 && isUndefined(pastExists);
    }

    public isResult(column: TableMatches[]): boolean {
        const pastExists = column.find((val: TableMatches): boolean => val.type === MatchType.PAST);
        return column.length >= 1 && !isUndefined(pastExists);
    }

    public matches(column: TableMatches[]): string {
        let ret = '';
        column.forEach((val): void => {
            if (val.type === MatchType.UPCOMING) {
                const date = this.datePipe.transform(val.start_date, 'localDateTimeShort');

                ret = `${ret} ${val.away_team} (${date})<br>`;
            }
            if (val.type === MatchType.PAST) {
                ret = `${ret} ${val.away_team} (${val.score}) <br>`;
            }
        });

        return ret;
    }

    public showResult(column: TableMatches[]): string {
        if (column.length === 1 && column[0].score) {
            return column[0].score;
        }

        if (column.length > 1) {
            let win = 0;
            let lose = 0;
            column.forEach((val): void => {
                if (val.status === Status.WIN || val.status === Status.OTWIN) {
                    win += 1;
                } else if (val.status === Status.LOSE || val.status === Status.OTLOSE) {
                    lose += 1;
                } else if (val.status === Status.DRAW) {
                    win += 1;
                    lose += 1;
                }
            });

            return `${win}:${lose}`;
        }

        return '-';
    }

    public isIndex(index: number): boolean {
        return index === this.activeRowIndex;
    }

    public onMouseOver(index: number): void {
        this.activeRowIndex = index;
    }

    public onMouseOut(): void {
        this.activeRowIndex = -1;
    }

    public isIndex2(index: number): boolean {
        return index === this.activeColumnIndex;
    }

    public onMouseOver2(index: number): void {
        this.activeColumnIndex = index;
    }

    public onMouseOut2(): void {
        this.activeColumnIndex = -1;
    }

    /**
     * Get class for promotion
     * @param  {object} row
     * @return {string}
     */
    public getClass(row: Record<string, any>): string {
        const ob = '';

        if (row.promotion.color !== null) {
            return row.promotion.color;
        }
        return ob;
    }
}
