import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy } from '@angular/core';


import { SportConfigToken } from '@config/sport.config';

import { TranslateService } from '@ngx-translate/core';

import { OptaWidgetBase } from '../base.service';

declare const Opta: any;

@Component({
    selector: 'app-opta-match-player-compare',
    templateUrl: './match-player-compare.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptaWidgetMatchPlayerCompareComponent extends OptaWidgetBase implements AfterViewInit, OnDestroy {
    @Input()
    matchId: string | number | null | undefined;

    @Input()
    competitionId: string | number | null | undefined;

    @Input()
    seasonId: string | number | null | undefined;

      #headlineTrans = 'web.opta_match_player_compare';
      private transHeadline = '';


      public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>,
          public translate: TranslateService) {
          super();
      }

      public ngAfterViewInit(): void {
          this.transHeadline = this.translate.instant(this.#headlineTrans);
          setTimeout(() => {
              const element = document.querySelector('#opta-match-player-compare') as HTMLElement;

              if (element) {
                  element.innerHTML = this.getHtml();
                  const optaWidgetTags = document.querySelector('opta-widget[load="false"]');

                  if (optaWidgetTags) {
                      optaWidgetTags.removeAttribute('load');
                      Opta.start();
                  }
              }
          });
      }

      ngOnDestroy(): void {
          if (Opta.widgets.ref_widget_match_player_compare) {
              Opta.widgets.ref_widget_match_player_compare.destroy(true);
          }
      }

      getHtml(): string {
          return `
          <opta-widget widget="match_player_compare" widget_id="ref_widget_match_player_compare" 
          competition="${this.competitionId}" season="${this.seasonId}" match="${this.matchId}" load="false" 
           template="normal" live="true" navigation="tabs_more" default_nav="1" show_match_header="false"
            show_selects="true" show_images="true" show_crests="false" show_competition_name="false" 
            competition_naming="full" team_naming="full" player_naming="full" show_date="false" 
            date_format="dddd D MMMM YYYY" narrow_date_format="DD/MM/YY" show_logo="false" 
            title="${this.transHeadline}" show_title="true" breakpoints="400, 700" sport="football"></opta-widget>
      

          `;
      }
}
