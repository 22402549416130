/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/naming-convention */
/**
 *  Tracker component
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import {
    Component,
    OnInit,
    OnChanges,
    OnDestroy,
    Input,
    SimpleChanges,
    NgZone,
    ViewChild,
    ElementRef,
    Output,
    EventEmitter,
} from '@angular/core';
import * as $ from 'jquery';
import * as _ from 'underscore';

import { Subscription, interval } from 'rxjs';

import { take } from 'rxjs/operators';

import SPORT_CONFIG from '../../config/sport.config';
import { LangService } from '../../services/lang.service';

const WIDGET = {
    field: 'match.lmtPlus',
    commentary: 'match.lmtPlus',
    simpleField: 'match.lmt',
    stats: 'match.generalStatistics',
    statsTennis: 'match.lmtPlus',
    'stats-h2h': 'match.HeadToHead',
    timeline: 'match.verticalTimeline',
    basketBoxScore: 'match.basketball.boxScore',
    null: 'null',
};

type WidgetType = keyof typeof WIDGET;

@Component({
    selector: 'app-tracker',
    templateUrl: './tracker.component.html',
})
export class TrackerComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('tracker')
        tr: ElementRef;

    @Input()
    public id: number | undefined;

    @Input()
    public sportName: string | undefined;

    @Input()
    public type: WidgetType = 'field';

    @Input()
    public isMode: boolean;

    @Output()
        ontrackerError: EventEmitter<boolean> = new EventEmitter();

    private WIDGET_CONFIG: Record<string, any> = {};

    public typeBr: string;

    public wId: string;

    private checker: Subscription;

    public notDisplay: boolean = true;

    public constructor(private lang: LangService, private ngZone: NgZone) {}

    public ngOnInit(): void {
        this.initConfig();
        $('[brtracker]').remove();
        this.wId = this.WIDGET_CONFIG[this.type].id;


        if (this.type !== 'field') {
            if (this.type !== 'stats' || !this.isMode) {
                this.notDisplay = false;
            }
            this.checker = interval(1000).pipe(take(1)).subscribe(() => {
                const el = document.querySelector('.app-tracker-container .sr-error__container');
                if (el !== null) {
                    this.ontrackerError.emit(true);
                }


                this.notDisplay = false;
            });
        } else {
            this.notDisplay = false;
        }

        setTimeout((): void => {
            const _this = this;
            if (this.type === 'field') {
                this.ngZone.runOutsideAngular((): void => {
                    $('<script brtracker>')
                        .text(
                            `if (SIR) {SIR('addWidget', '#${_this.WIDGET_CONFIG[this.type].id}', '${
                                WIDGET[this.type]
                            }', {theme: 'sportradar', showOdds:false,adsFrequency:false,tabsPosition: 'top', matchId: ${
                                _this.id
                            }});
                            SIR('changeLanguage', '${_this.lang.getLangSnapshot()}');}`,
                        )
                        .appendTo('head');
                });
            } else if (this.type === 'commentary') {
                this.ngZone.runOutsideAngular((): void => {
                    $('<script brtracker>')
                        .text(
                            `if (SIR) {SIR('addWidget', '#${_this.WIDGET_CONFIG[this.type].id}', '${
                                WIDGET[this.type]
                            }', {theme: 'sportradar', showOdds:false,adsFrequency:false,tabsPosition: 'bottom',
                            disableWidgetHeader: true,
                            tabs: { 1: ['timeline'], 2: ['timeline'], 4: ['timeline'], 5: ['timeline'] },
                            detailedScoreboard: 'disable', scoreboard: 'disable',
                                disableWidgetHeader: true, disablePitch: true, matchId: ${this.id}});
                            SIR('changeLanguage', '${_this.lang.getLangSnapshot()}');}`,
                        )
                        .appendTo('head');
                });
            } else if (this.type === 'simpleField') {
                this.ngZone.runOutsideAngular((): void => {
                    $('<script brtracker>')
                        .text(
                            `if (SIR){ SIR('addWidget', '#${_this.WIDGET_CONFIG[this.type].id}', '${
                                WIDGET[this.type]
                            }', {showOdds:false, language: 'de', matchId: ${_this.id}});
                            SIR('changeLanguage', '${_this.lang.getLangSnapshot()}');}`,
                        )
                        .appendTo('head');
                });
            } else if (this.type === 'basketBoxScore') {
                this.ngZone.runOutsideAngular((): void => {
                    $('<script brtracker>')
                        .text(
                            `if (SIR) {SIR('addWidget', '#${_this.WIDGET_CONFIG[this.type].id}', '${
                                WIDGET[this.type]
                            }', {theme: 'sportradar',
                            disableWidgetHeader: true,momentum:'disable',
                            matchId: ${_this.id}});
                            SIR('changeLanguage', '${_this.lang.getLangSnapshot()}');}`,
                        )
                        .appendTo('head');
                });
            } else if (this.type === 'stats') {
                this.ngZone.runOutsideAngular((): void => {
                    $('<script brtracker>')
                        .text(
                            `if (SIR) {SIR('addWidget', '#${_this.WIDGET_CONFIG[this.type].id}', '${
                                WIDGET[this.type]
                            }', {theme: 'sportradar',showOdds:false,adsFrequency:false,
                            disableWidgetHeader: true,momentum:'disable', scoreboard:'disable',
                            disablePitch: true, tabsPosition: 'top', matchId: ${_this.id}});
                            SIR('changeLanguage', '${_this.lang.getLangSnapshot()}');}`,
                        )
                        .appendTo('head');
                });
            } else if (this.type === 'statsTennis') {
                this.ngZone.runOutsideAngular((): void => {
                    $('<script brtracker>')
                        .text(
                            `if (SIR) {SIR('addWidget', '#${_this.WIDGET_CONFIG[this.type].id}', '${
                                WIDGET[this.type]
                            }', {theme: 'sportradar',showOdds:false,adsFrequency:false,
                            disableWidgetHeader: true,momentum:'disable', scoreboard:'disable',
                            disablePitch: true, tabsPosition: 'top', matchId: ${_this.id}});
                            SIR('changeLanguage', '${_this.lang.getLangSnapshot()}');}`,
                        )
                        .appendTo('head');
                });
            } else if (this.type === 'stats-h2h') {
                this.ngZone.runOutsideAngular((): void => {
                    $('<script brtracker>')
                        .text(
                            `if (SIR) {SIR('addWidget', '#${_this.WIDGET_CONFIG[this.type].id}', '${
                                WIDGET[this.type]
                            }', {theme: 'sportradar',showOdds:false,adsFrequency:false,
                    disableWidgetHeader: true,momentum:'disable', scoreboard:'disable',
                    disablePitch: true, disableSeasonStats: true, tabsPosition: 'top', matchId: ${_this.id}});
                    SIR('changeLanguage', '${_this.lang.getLangSnapshot()}');}`,
                        )
                        .appendTo('head');
                });
            } else if (this.type === 'timeline') {
                let filter = '';

                if (SPORT_CONFIG.isSoccer(this.sportName)) {
                    filter = 'goals,cards,substitutions,penalties';
                } else if (SPORT_CONFIG.isHockey(this.sportName)) {
                    filter =
                        'score,penalties,penalty,timeout,penalty_missed,penalty_nhl,penalty_missed_nhl,foul,suspension';
                } else if (SPORT_CONFIG.isBasketball(this.sportName)) {
                    filter = 'score';
                }

                this.ngZone.runOutsideAngular((): void => {
                    $('<script brtracker>')
                        .text(
                            `if (SIR) {SIR('addWidget', '#${_this.WIDGET_CONFIG[this.type].id}', '${
                                WIDGET[this.type]
                            }',
                    {theme: 'sportradar', filter:'${filter}',sortByPeriods: true, 
                    disableWidgetHeader: true,  matchId: ${_this.id}});
                    SIR('changeLanguage', '${_this.lang.getLangSnapshot()}');}`,
                        )
                        .appendTo('head');
                });
            }
        });
    }

    public ngOnDestroy(): void {
        if (_.isUndefined(this.WIDGET_CONFIG[this.type])) {
            return;
        }
        const _this = this;
        this.ngZone.runOutsideAngular((): void => {
            $('<script brtracker>')
                .text(`if (SIR) {SIR('removeWidget', '#${_this.WIDGET_CONFIG[this.type].id}');}`)
                .appendTo('head');
        });

        if (this.checker instanceof Subscription) {
            this.checker.unsubscribe();
        }
    }

    public initConfig(): void {
        this.WIDGET_CONFIG = {
            field: { showSidebar: 'false', id: `sr-widget-lmt-${this.id}` },
            commentary: { showSidebar: 'false', id: `sr-widget-commentary-${this.id}` },
            simpleField: {
                showSidebar: 'false',
                id: `sr-widget-simple-lmt-${this.id}`,
            },
            basketBoxScore: {
                id: `sr-widget-box-score-${this.id}`,
            },
            stats: {
                showSidebar: 'true',
                id: `sr-widget-statistics-${this.id}`,
            },
            statsTennis: {
                showSidebar: 'false',
                id: `sr-widget-statisticsTennis-${this.id}`,
            },
            'stats-h2h': {
                showSidebar: 'true',
                id: `sr-widget-statistics-h2h-${this.id}`,
            },
            timeline: { id: `sr-widget-timeline-${this.id}` },
        };
    }

    public get isEmpty(): boolean {
        return (
            this.tr?.nativeElement?.getElementsByClassName('sr-wwrap').length === 0
        );
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!_.isNull(changes.id) && changes.id.previousValue !== changes.id.currentValue) {
            this.ngOnDestroy();
            this.ngOnInit();
        } else {
            this.initConfig();
            this.typeBr = WIDGET[this.type];
            this.wId = this.WIDGET_CONFIG[this.type].id;
        }
    }
}
