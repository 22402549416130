<div class="tab-content match-summary live-timeline">
    <div class="py-2 pl-2">
        <span class="second-service">CHA</span> {{ 'web.is_checkout_attempt' | translate }}
        <span class="match-ball">B</span> {{ 'web.is_bust' | translate }}
    </div>
    <div class="tab-pane show active" *ngIf="data.firstThrow">
        <h6 class="text-center text-uppercase match-summary__head">
            {{ 'web.' + sportName + '_first_throw' | lowercase | translate }}
        </h6>
        <div class="row no-gutters border-top py-1 px-3 small">
            <div class="col-5 align-self-center font-weight-bold font-1x text-left">
                <ng-container *ngIf="data.firstThrow === 'home'">
                    &nbsp;<img [src]="data.image + '?color=' + color" height="13" *ngIf="data.image" />&nbsp;{{
                        data.home
                    }}</ng-container
                >
            </div>
            <div class="col-2 align-self-center font-weight-bold text-center font-1-1x">&nbsp;</div>
            <div class="col-5 align-self-center font-weight-bold font-1x text-right">
                <ng-container *ngIf="data.firstThrow === 'away'"
                    >{{ data.away }} &nbsp;<img
                        [src]="data.image + '?color=' + color"
                        height="13"
                        *ngIf="data.image"
                    />&nbsp;
                </ng-container>
            </div>
        </div>
    </div>
    <div *ngFor="let leg of data.data; trackBy: trackByIndex" class="tab-pane show active">
        <h6 class="text-center text-uppercase match-summary__head">
            {{ 'web.' + sportName + '_leg' | lowercase | translate }}
        </h6>

        <ng-container *ngFor="let action of leg.score; trackBy: trackByIndex; last as isLastMain">
            <div class="row no-gutters border-top py-1 px-0 small" style="overflow: hidden">
                <div class="col-5 align-self-center font-weight-bold font-0-8x font-md-0-9x px-1 text-right">
                    <ng-container *ngIf="action.darts[0]?.competitor === 'home'">
                        <ng-container *ngFor="let point of action.darts; trackBy: trackByIndex; last as isLast">
                            {{ point.dart_score_total }}
                            <small>({{ point.dart_score_multiplier }}x{{ point.dart_score }})</small>

                            <span *ngIf="point.is_bust" class="match-ball" title="{{ 'web.is_bust' | translate }}"
                                >B</span
                            >
                            <span
                                *ngIf="point.is_checkout_attempt"
                                class="double-fault font-0-8x p-0"
                                title="{{ 'web.is_checkout_attempt' | translate }}"
                                >CHA</span
                            >

                            <ng-container *ngIf="!isLast">,</ng-container>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="col-2 align-self-center font-weight-bold text-center font-1x">
                    <span [class.emph-text-node]="action.isHomeWinner">{{ action.homeScore }}</span>
                    -
                    <span [class.emph-text-node]="action.isHomeWinner">{{ action.awayScore }}</span>
                </div>
                <div class="col-5 align-self-center font-weight-bold font-0-8x font-md-0-9x px-1 text-left">
                    <ng-container *ngIf="action.darts[0]?.competitor === 'away'">
                        <ng-container *ngFor="let point of action.darts; trackBy: trackByIndex; last as isLast">
                            {{ point.dart_score_total }}
                            <small>({{ point.dart_score_multiplier }}x{{ point.dart_score }})</small>

                            <span *ngIf="point.is_bust" class="match-ball" title="{{ 'web.is_bust' | translate }}"
                                >B</span
                            >
                            <span
                                *ngIf="point.is_checkout_attempt"
                                class="double-fault font-0-8x p-0"
                                title="{{ 'web.is_checkout_attempt' | translate }}"
                                >CHA</span
                            >

                            <ng-container *ngIf="!isLast">,</ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-container>

        <div *ngIf="leg.lastDarts?.length > 0" class="row no-gutters border-top py-1 px-3 small">
            <div
                class="col-12 align-self-center font-0-9x"
                [class.text-right]="leg.lastDarts[0]?.competitor === 'away'"
                [class.text-left]="leg.lastDarts[0]?.competitor === 'home'"
            >
                <ng-container *ngFor="let point of leg.lastDarts; trackBy: trackByIndex; last as isLast">
                    {{ point.dart_score_total }}
                    <small>({{ point.dart_score_multiplier }}x{{ point.dart_score }})</small>

                    <span *ngIf="point.is_bust" class="match-ball" title="{{ 'web.is_bust' | translate }}">B</span>
                    <span
                        *ngIf="point.is_checkout_attempt"
                        class="double-fault font-0-8x p-0"
                        title="{{ 'web.is_checkout_attempt' | translate }}"
                        >CHA</span
                    >

                    <ng-container *ngIf="!isLast">,</ng-container>
                </ng-container>
            </div>
        </div>

        <div class="row no-gutters border-top py-1 px-3 small">
            <div class="col-3 align-self-center font-weight-bold font-1x text-right">
                &nbsp;
                <img [src]="data.image + '?color=' + color" height="13" *ngIf="leg.result === 'home'" />
            </div>
            <div class="col-6 align-self-center font-weight-bold text-center font-1-2x">
                <span [class.emph-text-node]="leg.result === 'home'">{{ leg.home_score }}</span>
                -
                <span [class.emph-text-node]="leg.result === 'away'">{{ leg.away_score }}</span>
            </div>
            <div class="col-3 align-self-center font-weight-bold font-1x text-left">
                &nbsp;
                <img [src]="data.image + '?color=' + color" height="13" *ngIf="leg.result === 'away'" />
            </div>
        </div>
    </div>
</div>
