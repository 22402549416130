/* eslint-disable camelcase */
/**
 *  Component Weather
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2022 livescore
 */
import { WeatherService } from '@services/weather.service';
import {
    Component,
    OnInit,
    Input,


} from '@angular/core';

import { LangService } from '@/services/lang.service';


@Component({
    selector: 'app-weather',
    templateUrl: './weather.component.html',
    providers: [],
})
export class WeatherComponent implements OnInit {
    @Input()
    public sportName: string;

    @Input()
    public matchId: number | undefined = undefined;

    @Input()
    data?: Record<string, any> | null;

    public weatherDisabled = false;

    public constructor(private weather: WeatherService, private lang: LangService) {}

    public ngOnInit(): void {
        if (this.weather.isWeatherViewed(this.matchId)) {
            this.dismiss();
        }

        this.weather.viewWeather(this.matchId);
    }


    get temperetureCelsium(): number | null {
        return Math.round(this.data?.main?.temp) ?? null;
    }

    get temperetureCelsiumMin(): number | null {
        return Math.floor(this.data?.main?.temp_min) ?? null;
    }

    get temperetureCelsiumMax(): number | null {
        return Math.ceil(this.data?.main?.temp_max) ?? null;
    }

    get humidity(): number | null {
        return this.data?.main?.humidity ?? null;
    }

    get pressure(): number | null {
        return this.data?.main?.pressure ?? null;
    }

    get clouds(): number | null {
        return this.data?.clouds?.all ?? null;
    }

    get winSpeed(): number | null {
        return this.data?.wind?.speed ?? null;
    }

    get temperetureDescription(): string {
        const lang = this.lang.getLangSnapshot();
        return this.data?.weather?.[0][`description_${lang}`] ?? this.data?.weather?.[0].description ?? '';
    }

    get temperetureIcon(): string | null {
        return this.data?.weather?.[0].icon ?? null;
    }


    public dismiss(): void {
        this.weatherDisabled = true;
    }
}
