<ng-container *ngIf="show">
    <!-- <share-buttons
        [include]="include"
        [exclude]="['tumblr', 'mix', 'line', 'sms']"
        [show]="num"
        [size]="-5"
        [theme]="'material-light'"
        [autoSetMeta]="false"
        [url]="url"
    ></share-buttons> -->
    <button
        class="btn btn-outline-secondary btn-block btn-white btn-no-margin"
        shareButton="facebook"
        [style.color]="share.prop.facebook.color"
        [url]="url"
    >
        <fa-icon [icon]="share.prop.facebook.icon" size="sm"></fa-icon>
    </button>
    <button
        class="btn btn-outline-secondary btn-block btn-white btn-no-margin"
        shareButton="twitter"
        [style.color]="share.prop.twitter.color"
        [url]="url"
    >
        <img src="/img/twitter.png" width="16" class="" alt="Twitter" />
    </button>
    <button
        class="btn btn-outline-secondary btn-block btn-white btn-no-margin"
        shareButton="telegram"
        [style.color]="share.prop.telegram.color"
        [url]="url"
    >
        <fa-icon [icon]="share.prop.telegram.icon" size="sm"></fa-icon>
    </button>
    <button
        class="btn btn-outline-secondary btn-block btn-white btn-no-margin"
        shareButton="whatsapp"
        [style.color]="share.prop.whatsapp.color"
        [url]="url"
    >
        <fa-icon [icon]="share.prop.whatsapp.icon" size="sm"></fa-icon>
    </button>
    <button
        class="btn btn-outline-secondary btn-block btn-white btn-no-margin"
        shareButton="linkedin"
        [style.color]="share.prop.linkedin.color"
        [url]="url"
    >
        <fa-icon [icon]="share.prop.linkedin.icon" size="sm"></fa-icon>
    </button>
    <ng-container *ngIf="deviceService.isMobile()">
        <button
            class="btn btn-outline-secondary btn-block btn-white btn-no-margin"
            shareButton="pinterest"
            [style.color]="share.prop.pinterest.color"
            [url]="url"
        >
            <fa-icon [icon]="share.prop.pinterest.icon" size="sm"></fa-icon>
        </button>
        <button
            class="btn btn-outline-secondary btn-block btn-white btn-no-margin"
            shareButton="messenger"
            [style.color]="share.prop.messenger.color"
            [url]="url"
        >
            <fa-icon [icon]="share.prop.messenger.icon" size="sm"></fa-icon>
        </button>
    </ng-container>
    <button
        class="btn btn-outline-secondary btn-block btn-white btn-no-margin"
        shareButton="copy"
        [style.color]="share.prop.copy.color"
        [url]="url"
    >
        <fa-icon [icon]="share.prop.copy.icon" size="sm"></fa-icon>
    </button>
</ng-container>
