import { Component, OnInit, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'google-modal',
    templateUrl: './google-modal.component.html',
    styleUrls: ['./google-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapGoogleModalComponent implements OnInit {
    @Input()
    public mapCoordinates: string | null | undefined = null;

    @Input()
    visible: boolean = false;

    @Output()
    visibleChange: EventEmitter<boolean> = new EventEmitter();


    public constructor() {}

    public ngOnInit(): void {}

    public close(): void {
        this.visibleChange.emit(false);
    }

    get src(): string {
        return `https://maps.google.com/maps?q=${this.mapCoordinates}&t=&z=13&ie=UTF8&iwloc=&output=embed`;
    }
}
