<ul class="nav nav-pills bg-light px-2">
    <li class="nav-item">
        <a
            class="nav-link active"
            id="tab-match-h2h-1"
            data-toggle="tab"
            href="#tab-match-h2h-1-content"
            role="tab"
            aria-controls="tab-match-h2h-1-content"
            aria-selected="true"
            >{{ 'web.total' | translate }}</a
        >
    </li>
    <li class="nav-item">
        <a
            class="nav-link"
            id="tab-match-h2h-2"
            *ngIf="data.home"
            data-toggle="tab"
            href="#tab-match-h2h-2-content"
            role="tab"
            aria-controls="tab-match-h2h-2-content"
            aria-selected="false"
            >{{ home }} {{ 'web.home' | translate }}</a
        >
    </li>
    <li class="nav-item">
        <a
            class="nav-link"
            id="tab-match-h2h-3"
            *ngIf="data.away"
            data-toggle="tab"
            href="#tab-match-h2h-3-content"
            role="tab"
            aria-controls="tab-match-h2h-3-content"
            aria-selected="false"
            >{{ away }} {{ 'web.away' | translate }}</a
        >
    </li>
</ul>

<div class="tab-content">
    <div class="tab-pane show active" id="tab-match-h2h-1-content" role="tabpanel" aria-labelledby="tab-match-h2h-1">
        <!--Home-->
        <div class="match-info__table__head d-flex justify-content-between">
            <div>
                <img [attr.src]="homeLogo" alt="" width="17" />&nbsp;&nbsp;<span>{{ home }}</span>
            </div>
            <div class="app-h2h-balance app-last-matches-badge badge badge-gray2">
                <span [class.font-weight-bold-extend-max]="true">{{ balance.totalHome[0] }}</span
                ><span>:</span><span>{{ balance.totalHome[1] }}</span>
            </div>
        </div>
        <div class="table-responsive">
            <div class="match-info__table small pb-2">
                <div
                    data-cy="h2h-row"
                    class="match-info__table__row app-data-row-data app-cursor"
                    (click)="goToMatch(match.id)"
                    *ngFor="let match of data.total.home_team; trackBy: trackByFn"
                >
                    <div class="match-info__table__cell match-info__table__cell--date">
                        {{ match.date | livDate : 'dateShort' }}
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--date">
                        <img
                            *ngIf="match.flag"
                            src="{{ match.flag }}"
                            alt=""
                            height="18"
                            data-toggle="tooltip"
                            data-placement="right"
                            [attr.title]="match.tournament"
                            class="mr-2"
                        />
                    </div>
                    <div
                        class="match-info__table__cell match-info__table__cell--team match-info__table__cell--h2h-special text-left"
                    >
                        <span [class.font-weight-bold-extend]="isWinnerHome(match)">{{ match.home_team }}</span>
                    </div>
                    <div
                        class="match-info__table__cell match-info__table__cell--team match-info__table__cell--h2h-special"
                    >
                        <span [class.font-weight-bold-extend]="isWinnerAway(match)">{{ match.away_team }}</span>
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--score text-center">
                        <app-score
                            [sport]="sportName"
                            [data]="match.score"
                            [onlyFinal]="true"
                            [type]="'short'"
                            [match_state]="match.match_state"
                            [code_state]="match.code_state"
                        ></app-score>
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--result text-center">
                        <span
                            *ngIf="langLoaded && match.badge"
                            class="badge app-last-matches-badge {{ match.badge_class }}"
                            >{{ form(match.badge) }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-1 justify-content-center" *ngIf="data.total.home_team.length == 0">
            <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                <div class="text-center pt-3">
                    <h4 class="font-weight-bold">{{ 'web.no_data_found' | translate }}</h4>
                </div>
            </div>
        </div>
        <!--Away-->

        <div class="match-info__table__head d-flex justify-content-between">
            <div>
                <img [attr.src]="awayLogo" alt="" width="17" />&nbsp;&nbsp;<span>{{ away }}</span>
            </div>
            <div class="app-h2h-balance app-last-matches-badge badge badge-gray2">
                <span [class.font-weight-bold-extend-max]="true">{{ balance.totalAway[0] }}</span
                ><span>:</span><span>{{ balance.totalAway[1] }}</span>
            </div>
        </div>
        <div class="table-responsive">
            <div class="match-info__table small pb-2">
                <div
                    data-cy="h2h-row"
                    class="match-info__table__row app-data-row-data app-cursor"
                    (click)="goToMatch(match.id)"
                    *ngFor="let match of data.total.away_team; trackBy: trackByFn"
                >
                    <div class="match-info__table__cell match-info__table__cell--date">
                        {{ match.date | livDate : 'dateShort' }}
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--date">
                        <img
                            *ngIf="match.flag"
                            src="{{ match.flag }}"
                            alt=""
                            height="18"
                            data-toggle="tooltip"
                            data-placement="right"
                            [attr.title]="match.tournament"
                            class="mr-2"
                        />
                    </div>
                    <div
                        class="match-info__table__cell match-info__table__cell--team match-info__table__cell--h2h-special text-left"
                    >
                        <span [class.font-weight-bold-extend]="isWinnerHome(match)">{{ match.home_team }}</span>
                    </div>
                    <div
                        class="match-info__table__cell match-info__table__cell--team match-info__table__cell--h2h-special"
                    >
                        <span [class.font-weight-bold-extend]="isWinnerAway(match)">{{ match.away_team }}</span>
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--score text-center">
                        <app-score
                            [sport]="sportName"
                            [data]="match.score"
                            [onlyFinal]="true"
                            [type]="'short'"
                            [match_state]="match.match_state"
                            [code_state]="match.code_state"
                        ></app-score>
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--result text-center">
                        <span
                            *ngIf="langLoaded && match.badge"
                            class="badge app-last-matches-badge {{ match.badge_class }}"
                            >{{ form(match.badge) }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-1 justify-content-center" *ngIf="data.total.away_team.length == 0">
            <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                <div class="text-center pt-3">
                    <h4 class="font-weight-bold">{{ 'web.no_data_found' | translate }}</h4>
                </div>
            </div>
        </div>
        <!--h2h-->

        <div class="match-info__table__head d-flex justify-content-between" *ngIf="!sportConfig.isCricket(sportName)">
            <div>
                <span>{{ home }} : {{ away }}</span>
            </div>
            <div class="app-h2h-balance app-last-matches-badge badge badge-gray2">
                <span>{{ balance.h2h[0] }}</span
                ><span>:</span><span>{{ balance.h2h[1] }}</span>
            </div>
        </div>

        <div class="table-responsive" *ngIf="!sportConfig.isCricket(sportName)">
            <div class="match-info__table small pb-2">
                <div
                    data-cy="h2h-row"
                    class="match-info__table__row app-data-row-data app-cursor"
                    (click)="goToMatch(match.id)"
                    *ngFor="let match of data.total.h2h; trackBy: trackByFn"
                >
                    <div class="match-info__table__cell match-info__table__cell--date">
                        {{ match.date | livDate : 'dateShort' }}
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--date">
                        <img
                            *ngIf="match.flag"
                            src="{{ match.flag }}"
                            alt=""
                            height="18"
                            data-toggle="tooltip"
                            data-placement="right"
                            [attr.title]="match.tournament"
                            class="mr-2"
                        />
                    </div>
                    <div
                        class="match-info__table__cell match-info__table__cell--team match-info__table__cell--h2h-special text-left"
                    >
                        <span [class.font-weight-bold-extend]="isWinnerHome(match)">{{ match.home_team }}</span>
                    </div>
                    <div
                        class="match-info__table__cell match-info__table__cell--team match-info__table__cell--h2h-special"
                    >
                        <span [class.font-weight-bold-extend]="isWinnerAway(match)">{{ match.away_team }}</span>
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--score text-center">
                        <app-score
                            [sport]="sportName"
                            [data]="match.score"
                            [onlyFinal]="true"
                            [type]="'full'"
                            [match_state]="match.match_state"
                            [code_state]="match.code_state"
                        ></app-score>
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--result text-center">
                        <span class="badge app-last-matches-badge">&nbsp;</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-1 justify-content-center" *ngIf="data.total.h2h.length == 0">
            <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                <div class="text-center pt-3">
                    <h4 class="font-weight-bold">{{ 'web.no_data_found' | translate }}</h4>
                </div>
            </div>
        </div>
    </div>
    <div
        class="tab-pane show"
        id="tab-match-h2h-2-content"
        *ngIf="data.home"
        role="tabpanel"
        aria-labelledby="tab-match-h2h-1"
    >
        <!--Home-->
        <div class="match-info__table__head">
            <img [attr.src]="homeLogo" width="17" alt="" />&nbsp;&nbsp;<span>{{ home }}</span>
        </div>
        <div class="table-responsive">
            <div class="match-info__table small pb-2">
                <div
                    data-cy="h2h-row"
                    class="match-info__table__row app-data-row-data app-cursor"
                    (click)="goToMatch(match.id)"
                    *ngFor="let match of data.home.home_team; trackBy: trackByFn"
                >
                    <div class="match-info__table__cell match-info__table__cell--date">
                        {{ match.date | livDate : 'dateShort' }}
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--date">
                        <img
                            *ngIf="match.flag"
                            src="{{ match.flag }}"
                            alt=""
                            height="18"
                            data-toggle="tooltip"
                            data-placement="right"
                            [attr.title]="match.tournament"
                            class="mr-2"
                        />
                    </div>
                    <div
                        class="match-info__table__cell match-info__table__cell--team match-info__table__cell--h2h-special text-left"
                    >
                        <span [class.font-weight-bold-extend]="isWinnerHome(match)">{{ match.home_team }}</span>
                    </div>
                    <div
                        class="match-info__table__cell match-info__table__cell--team match-info__table__cell--h2h-special"
                    >
                        <span [class.font-weight-bold-extend]="isWinnerAway(match)"> {{ match.away_team }}</span>
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--score text-center">
                        <app-score
                            [sport]="sportName"
                            [data]="match.score"
                            [onlyFinal]="true"
                            [type]="'short'"
                            [match_state]="match.match_state"
                            [code_state]="match.code_state"
                        ></app-score>
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--result text-center">
                        <span
                            *ngIf="langLoaded && match.badge"
                            class="badge app-last-matches-badge {{ match.badge_class }}"
                            >{{ form(match.badge) }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-1 justify-content-center" *ngIf="data.home.home_team.length == 0">
            <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                <div class="text-center pt-3">
                    <h4 class="font-weight-bold">{{ 'web.no_data_found' | translate }}</h4>
                </div>
            </div>
        </div>
        <!--h2h-->
        <div class="match-info__table__head" *ngIf="!sportConfig.isCricket(sportName)">
            <span>{{ home }} : {{ away }}</span>
        </div>
        <div class="table-responsive" *ngIf="!sportConfig.isCricket(sportName)">
            <div class="match-info__table small pb-2">
                <div
                    data-cy="h2h-row"
                    class="match-info__table__row app-data-row-data app-cursor"
                    (click)="goToMatch(match.id)"
                    *ngFor="let match of data.home.h2h; trackBy: trackByFn"
                >
                    <div class="match-info__table__cell match-info__table__cell--date">
                        {{ match.date | livDate : 'dateShort' }}
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--date">
                        <img
                            *ngIf="match.flag"
                            src="{{ match.flag }}"
                            alt=""
                            height="18"
                            data-toggle="tooltip"
                            data-placement="right"
                            [attr.title]="match.tournament"
                            class="mr-2"
                        />
                    </div>
                    <div
                        class="match-info__table__cell match-info__table__cell--team match-info__table__cell--h2h-special text-left"
                    >
                        <span [class.font-weight-bold-extend]="isWinnerHome(match)">{{ match.home_team }}</span>
                    </div>
                    <div
                        class="match-info__table__cell match-info__table__cell--team match-info__table__cell--h2h-special"
                    >
                        <span [class.font-weight-bold-extend]="isWinnerAway(match)">{{ match.away_team }}</span>
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--score text-center">
                        <app-score
                            [sport]="sportName"
                            [data]="match.score"
                            [onlyFinal]="true"
                            [type]="'full'"
                            [match_state]="match.match_state"
                            [code_state]="match.code_state"
                        ></app-score>
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--result text-center">
                        <span class="badge app-last-matches-badge">&nbsp;</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-1 justify-content-center" *ngIf="data.home.h2h.length == 0">
            <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                <div class="text-center pt-3">
                    <h4 class="font-weight-bold">{{ 'web.no_data_found' | translate }}</h4>
                </div>
            </div>
        </div>
    </div>
    <div
        class="tab-pane show"
        *ngIf="data.away"
        id="tab-match-h2h-3-content"
        role="tabpanel"
        aria-labelledby="tab-match-h2h-1"
    >
        <!--Away-->
        <div class="match-info__table__head">
            <img [attr.src]="awayLogo" width="17" alt="" />&nbsp;&nbsp;<span>{{ away }}</span>
        </div>
        <div class="table-responsive">
            <div class="match-info__table small pb-2">
                <div
                    data-cy="h2h-row"
                    class="match-info__table__row app-data-row-data app-cursor"
                    (click)="goToMatch(match.id)"
                    *ngFor="let match of data.away.away_team; trackBy: trackByFn"
                >
                    <div class="match-info__table__cell match-info__table__cell--date">
                        {{ match.date | livDate : 'dateShort' }}
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--date">
                        <img
                            *ngIf="match.flag"
                            src="{{ match.flag }}"
                            alt=""
                            height="18"
                            data-toggle="tooltip"
                            data-placement="right"
                            [attr.title]="match.tournament"
                            class="mr-2"
                        />
                    </div>
                    <div
                        class="match-info__table__cell match-info__table__cell--team match-info__table__cell--h2h-special text-left"
                    >
                        <span [class.font-weight-bold-extend]="isWinnerHome(match)">{{ match.home_team }}</span>
                    </div>
                    <div
                        class="match-info__table__cell match-info__table__cell--team match-info__table__cell--h2h-special"
                    >
                        <span [class.font-weight-bold-extend]="isWinnerAway(match)"> {{ match.away_team }}</span>
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--score text-center">
                        <app-score
                            [sport]="sportName"
                            [data]="match.score"
                            [onlyFinal]="true"
                            [type]="'short'"
                            [match_state]="match.match_state"
                            [code_state]="match.code_state"
                        ></app-score>
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--result text-center">
                        <span
                            *ngIf="langLoaded && match.badge"
                            class="badge app-last-matches-badge {{ match.badge_class }}"
                            >{{ form(match.badge) }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-1 justify-content-center" *ngIf="data.away.away_team.length == 0">
            <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                <div class="text-center pt-3">
                    <h4 class="font-weight-bold">{{ 'web.no_data_found' | translate }}</h4>
                </div>
            </div>
        </div>
        <!--h2h-->
        <div class="match-info__table__head" *ngIf="!sportConfig.isCricket(sportName)">
            <span>{{ home }} : {{ away }}</span>
        </div>
        <div class="table-responsive" *ngIf="!sportConfig.isCricket(sportName)">
            <div class="match-info__table small pb-2">
                <div
                    data-cy="h2h-row"
                    class="match-info__table__row app-data-row-data app-cursor"
                    (click)="goToMatch(match.id)"
                    *ngFor="let match of data.away.h2h; trackBy: trackByFn"
                >
                    <div class="match-info__table__cell match-info__table__cell--date">
                        {{ match.date | livDate : 'dateShort' }}
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--date">
                        <img
                            *ngIf="match.flag"
                            src="{{ match.flag }}"
                            alt=""
                            height="18"
                            data-toggle="tooltip"
                            data-placement="right"
                            [attr.title]="match.tournament"
                            class="mr-2"
                        />
                    </div>
                    <div
                        class="match-info__table__cell match-info__table__cell--team match-info__table__cell--h2h-special text-left"
                    >
                        <span [class.font-weight-bold-extend]="isWinnerHome(match)">{{ match.home_team }}</span>
                    </div>
                    <div
                        class="match-info__table__cell match-info__table__cell--team match-info__table__cell--h2h-special"
                    >
                        <span [class.font-weight-bold-extend]="isWinnerAway(match)">{{ match.away_team }}</span>
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--score text-center">
                        <app-score
                            [sport]="sportName"
                            [data]="match.score"
                            [onlyFinal]="true"
                            [type]="'full'"
                            [match_state]="match.match_state"
                            [code_state]="match.code_state"
                        ></app-score>
                    </div>
                    <div class="match-info__table__cell match-info__table__cell--result text-center">
                        <span class="badge app-last-matches-badge">&nbsp;</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-1 justify-content-center" *ngIf="data.away.h2h.length == 0">
            <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                <div class="text-center pt-3">
                    <h4 class="font-weight-bold">{{ 'web.no_data_found' | translate }}</h4>
                </div>
            </div>
        </div>
    </div>
</div>
