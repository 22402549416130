/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'underscore';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import SPORT_CONFIG from '@config/sport.config';

import $ from '../../shared/jquery';
import { PlayerStatsInterface } from '../../interfaces/player-stats.interface';
import { TournamentService } from '../../services/tournament.service';


@Component({
    selector: 'app-player-stats',
    templateUrl: './player-stats.component.html',
})
export class PlayerStatsComponent implements OnInit {
    @Input()
    public subTournamentId: number;

    @Input()
    public seasonId: number | any = null;

    @Input()
    public view: string | null = null;

    @Input()
    public sportName: string;

    @Input()
    public sportId: number;

    @Output()
    public onError: EventEmitter<boolean> = new EventEmitter();

    @Output()
    public onLoad: EventEmitter<boolean> = new EventEmitter();

    @Output()
    public onPlayersNum: EventEmitter<number> = new EventEmitter();

    public sliceNum: number = 100;

    public sliceColumnNum: number = 10;

    public playerStats: Observable<PlayerStatsInterface[]>;

    public constructor(private tournamentService: TournamentService) {}

    public ngOnInit(): void {
        if (this.view === 'match-detail') {
            this.sliceNum = 20;
            this.sliceColumnNum = 2;
        }
    }

    public ngOnChanges(): void {
        if (_.isUndefined(this.seasonId)) {
            this.seasonId = '';
        }

        this.playerStats = this.tournamentService.getPlayerStats(
            this.seasonId,
            this.subTournamentId,
        ).pipe(
            map((val): any => {
                if (!val || Object.keys(val).length === 0) {
                    this.onError.emit(true);
                    return val;
                }
                const key: any = Object.keys(val)[0];
                if (val[key].players.length > 0 && !_.isNull(val[key].players[0].team_total_goals)) {
                    val[key].showColumns.splice(val[key].showColumns.length, 0, 'team_total_goals' as never);
                }

                if (this.isHockey) {
                    val[key].showColumns.splice(2, 1);
                    val[key].showColumns.splice(0, 0, 'points' as never);
                }

                this.onPlayersNum.emit(val[key].players.length);
                this.onLoad.emit(true);

                return val;
            }),
            catchError((val) => {
                this.onError.emit(true);
                return throwError(val);
            }),
        );

        setTimeout((): void => {
            $('[data-toggle=\'tooltip\']').tooltip();
        }, 1000);
    }

    get isHockey(): boolean {
        return SPORT_CONFIG.isHockey(this.sportName);
    }

    /**
     * Calculate player goal coverage per team in px
     * @param  {number} playerGoals
     * @param  {number} teamGoals
     * @return   {string}
     */
    public getGoalPrct(playerGoals: number, teamGoals: number): string {
        return `${Math.round((playerGoals / teamGoals) * 100)}%`;
    }
}
