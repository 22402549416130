/**
 *  Animation show
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { trigger, style, animate, transition } from '@angular/animations';

export function setup(name = 'showme', duration = '1s', delay = '0s'): any {
    return trigger(name, [
        transition(':enter', [style({ opacity: 0 }), animate(`${duration} ${delay}`, style({ opacity: 1 }))]),
    ]);
}
