<ng-container *ngIf="tab; else elseTable">
    <div class="app-table-container" data-cy="app-table-container">
        <div class="pt-3 col-12 col-sm-6" *ngIf="tab.tables.length > 1">
            <ng-container *ngIf="!isMobile; else nomobile">
                <select class="custom-select border-light2 mb-3" [(ngModel)]="selected">
                    <option *ngIf="tab.tables.length > 1" [value]="-1">
                        {{ 'web.table_select_all' | translate }}
                    </option>
                    <option *ngFor="let name of tab.tables; trackBy: trackByIndex; index as i" [value]="i">
                        {{ name.name }}
                    </option>
                </select>
            </ng-container>
            <ng-template #nomobile>
                <select class="custom-select border-light2 mb-3" [(ngModel)]="selected">
                    <option *ngIf="tab.tables.length > 1" [value]="-1">
                        {{ 'web.table_select_all' | translate }}
                    </option>
                    <option *ngFor="let name of tab.tables; trackBy: trackByIndex; index as i" [value]="i">
                        {{ name.name }}
                    </option>
                </select>
            </ng-template>
        </div>

        <div *ngFor="let tableOb of tab.tables; trackBy: trackByIndex; index as i; last as isLast">
            <app-league-table-container
                *ngIf="i === +selected || selected === -1 || selected === '-1'"
                [tableOb]="tableOb"
                [subTournamentId]="subTournamentId"
                [seasonId]="seasonId"
                [participantHome]="participantHome"
                [participantAway]="participantAway"
                [i]="i"
                [showFooterInfo]="isLast"
                [sportName]="sportName"
                [sportId]="sportId"
                [isLive]="isLive"
            >
                <h4
                    class="head mt-2 ml-2 ml-xl-0"
                    [class.d-none]="(selected !== -1 && selected !== '-1') || tab.tables.length <= 1"
                >
                    {{ tableOb.name }}
                </h4>
            </app-league-table-container>
        </div>
    </div>
</ng-container>

<ng-template #elseTable>
    <app-placeholder-table></app-placeholder-table>
</ng-template>
