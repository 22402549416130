/**
 *  Service for weather
 *  @author Livescore <score-stats.com>
 *  @copyright 2022 livescore
 */

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class WeatherService {
    private weather: number[] = [];

    viewWeather(matchId: number | undefined): void {
        if (!matchId) {
            return;
        }
        this.weather.push(matchId);
    }

    isWeatherViewed(matchId: number | undefined): boolean {
        if (!matchId) {
            return false;
        }

        return this.weather.filter(v => v === matchId).length > 1;
    }
}
