<div *ngIf="isActive" class="app-period-statistics" aria-label="Period match statistics">
    <ng-container *ngFor="let map of stats; trackBy: trackByIndex; index as i">
        <h6 class="text-center text-uppercase match-summary__head">{{ 'web.esport_map' | translate }} {{ i + 1 }}</h6>

        <div class="d-flex table-scrollable">
            <div class="table-scrollable__data flex-grow-1" tableScroll>
                <table
                    data-cy="app-table-right-data"
                    class="table-scrollable__data__table"
                    style="table-layout: fixed; width: 100%"
                >
                    <thead>
                        <tr>
                            <th
                                class="table-scrollable__table-th table-scrollable__data__table-th app-cursor"
                                title=""
                                scope="col"
                            >
                                &nbsp;
                            </th>
                            <th
                                *ngFor="let head of heads; trackBy: trackByIndex"
                                class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                                title=""
                                scope="col"
                            >
                                {{ 'web.esport_' + head | translate }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="table-scrollable__table-row">
                            <td class="table-scrollable__table-td table-scrollable__data__table-td text-bold">
                                {{ home }}
                            </td>
                            <td
                                *ngFor="let head of heads; trackBy: trackByIndex"
                                class="table-scrollable__table-td table-scrollable__data__table-td"
                            >
                                <ng-container *ngIf="map[head]; else elseTmp">
                                    {{ map[head].home }}
                                </ng-container>
                                <ng-template #elseTmp> - </ng-template>
                            </td>
                        </tr>
                        <tr class="table-scrollable__table-row">
                            <td class="table-scrollable__table-td table-scrollable__data__table-td text-bold">
                                {{ away }}
                            </td>
                            <td
                                *ngFor="let head of heads; trackBy: trackByIndex"
                                class="table-scrollable__table-td table-scrollable__data__table-td"
                            >
                                <ng-container *ngIf="map[head]; else elseTmp">
                                    {{ map[head].away }}
                                </ng-container>
                                <ng-template #elseTmp> - </ng-template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-container>
</div>
