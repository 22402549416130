/* eslint-disable camelcase */
import { AfterViewInit, Component, ComponentFactoryResolver, Inject, Input,
    ViewChild, OnChanges, ComponentRef } from '@angular/core';
import { DartsMapFce, DefaultMapFce, Periods, TennisMapFce, Timeline, TimelineType, Winner }
    from '@interfaces/match-data.interface';


import { SportConfigToken } from '@config/sport.config';

import { TennisTimelineComponent } from '@components/match/timeline/tennis/tennis-timeline.component';
import { DefaultTimelineComponent } from '@components/match/timeline/default/default-timeline.component';


import { groupBy, forEach, reverse, toPairs, uniq } from 'lodash-es';

import { AdTimelineDirective } from './timeline.directive';
import { DartsTimelineComponent } from './darts/darts-timeline.component';

import { CricketTimelineComponent } from './cricket/cricket-timeline.component';

import { SourceId } from '@/interfaces/source.interface';

declare const moment: any;


export interface AdComponent {
    data: any;
    sportName?: string;
    sportId?: number;
    codeState?: string | null;
    isLive: boolean;
    ngOnInit: () => void;
}

@Component({
    selector: 'app-timeline-base',
    templateUrl: './base-timeline.component.html',
})
export class BaseTimelineComponent implements AfterViewInit, OnChanges {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('data') timelineData: Timeline[];
    @Input() sportName: string;
    @Input() sportId: number;
    @Input() codeState?: string | null;
    @Input() isLive: boolean;
    @Input() home: string = '';
    @Input() away: string = '';
    @Input() winner: number | undefined = 0;

    @ViewChild(AdTimelineDirective, { static: true }) adHost!: AdTimelineDirective;

    private componentRef: ComponentRef<AdComponent>;

    /* Creating a dictionary of components. */
    private components: Record<string, {component: any, mapFce:
    () => TennisMapFce | {home: string;away: string;home2: string| null;away2: string | null;
        groupedInnings:Record<string, [string, TimelineType[]][]>}}> = {
            tennis: { component: TennisTimelineComponent, mapFce: this.tennisMap },
            cricket: { component: CricketTimelineComponent, mapFce: this.cricketMap },
            darts: { component: DartsTimelineComponent, mapFce: this.dartsMap },
            default: { component: DefaultTimelineComponent, mapFce: this.defaultMap },
        };

    constructor(@Inject(SportConfigToken) private sportConfig: Record<string, any>,
        private componentFactoryResolver: ComponentFactoryResolver,
    ) {

    }

    ngAfterViewInit(): void {
        this.loadComponent();
    }

    ngOnChanges(changes: any): void {
        const old = JSON.stringify(changes.timelineData?.previousValue);
        const newVal = JSON.stringify(changes.timelineData?.currentValue);

        if (this.componentRef && old !== newVal) {
            // TODO: For BetsAPI and tannis we display default timeline
            const sport = this.isBetsAPI && this.sportConfig.isTennis(this.sportName) ? 'default' : this.sportName;

            const adItem = this.components[sport] ?? this.components.default;

            if (changes.sportName?.previousValue !== changes.sportName?.currentValue || 'home' in changes) {
                const componentFactory =
                this.componentFactoryResolver.resolveComponentFactory<AdComponent>(adItem?.component);

                const { viewContainerRef } = this.adHost;
                viewContainerRef.clear();

                this.componentRef = viewContainerRef.createComponent<AdComponent>(componentFactory);
            }

            this.componentRef.instance.data = adItem!.mapFce.call(this);
            this.componentRef.instance.codeState = this.codeState;
            this.componentRef.instance.isLive = this.isLive;
            this.componentRef.instance.sportName = this.sportName;
            this.componentRef.instance.sportId = this.sportId;
            this.componentRef.instance.ngOnInit();
        }
    }

    get isBetsAPI(): boolean {
        return this.timelineData.some((value:Record<string, any>) => value.source_id === SourceId.BETSAPI);
    }

    private loadComponent(): void | never {
        // TODO: For BetsAPI and tannis we display default timeline
        const sport = this.isBetsAPI && this.sportConfig.isTennis(this.sportName) ? 'default' : this.sportName;

        const adItem = this.components[sport] ?? this.components.default;

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory<AdComponent>(adItem?.component);

        const { viewContainerRef } = this.adHost;
        viewContainerRef.clear();

        this.componentRef = viewContainerRef.createComponent<AdComponent>(componentFactory);
        this.componentRef.instance.data = adItem!.mapFce.call(this);
        this.componentRef.instance.sportName = this.sportName;
        this.componentRef.instance.sportId = this.sportId;
        this.componentRef.instance.codeState = this.codeState;
        this.componentRef.instance.isLive = this.isLive;
        this.componentRef.instance.ngOnInit();
    }

    /**
     *
     * @returns Default mapping for other sports than specific
     */
    private defaultMap(): DefaultMapFce {
        const validTypes: string[] =
            [this.sportConfig.timeline.eventTypes.suspension,
                this.sportConfig.timeline.eventTypes.scoreChange,
                this.sportConfig.timeline.eventTypes.technicalRuleFault,
                this.sportConfig.timeline.eventTypes.technicalBallFault,
                this.sportConfig.timeline.eventTypes.timeout,
                this.sportConfig.timeline.eventTypes.substitution,
                this.sportConfig.timeline.eventTypes.sevenMAwarded,
                this.sportConfig.timeline.eventTypes.yellowCard,
                this.sportConfig.timeline.eventTypes.redCard,
                this.sportConfig.timeline.eventTypes.periodScore,
                this.sportConfig.timeline.eventTypes.ballPot,
                this.sportConfig.timeline.eventTypes.unknown,
                this.sportConfig.timeline.eventTypes.cornerKick,
                this.sportConfig.timeline.eventTypes.goalKick,
            ];
        const resp: DefaultMapFce = {};
        let period: string | null = null;

        let prevHomeScore: number = 0;
        let prevAwayScore: number = 0;
        let periodCount = 1;

        this.timelineData.forEach((timeline: Timeline, key: number) => {
            period = timeline.period_name;
            /**
             * Move penalties last scorer to correct period (penalties)
             */
            if (this.sportConfig.isHockey(this.sportName) &&
                timeline.type === this.sportConfig.timeline.eventTypes.scoreChange &&
                timeline.match_time === '65' && period !== Periods.PENALTIES) {
                this.timelineData.splice(this.timelineData.length, 0, timeline);
                this.timelineData.splice(key, 1);
            }

            if (this.sportConfig.isHockey(this.sportName) &&
                timeline.type === this.sportConfig.timeline.eventTypes.periodStart &&
                period === Periods.INTERRUPTED) {
                const interruptedDateTime = moment(timeline.time);
                let findNextPeriod = false;
                this.timelineData.forEach((timeline2: Timeline, key2: number) => {
                    if (timeline2.type === this.sportConfig.timeline.eventTypes.periodStart && !findNextPeriod) {
                        const dateTime = moment(timeline2.time);

                        if (dateTime.isAfter(interruptedDateTime)) {
                            findNextPeriod = true;
                            timeline2.period_name += '_continue';
                            this.timelineData.splice(key2, 0, timeline);
                            this.timelineData.splice(key, 1);
                        }
                    }
                });
            }
        });

        period = null;
        this.timelineData.forEach((timeline: Timeline) => {
            if (timeline.type === this.sportConfig.timeline.eventTypes.periodScore &&
                (this.sportConfig.isHockey(this.sportName) ||
                this.sportConfig.isBaseball(this.sportName) ||
                this.sportConfig.isWaterPolo(this.sportName) ||
                this.sportConfig.isAmericanSoccer(this.sportName) ||

                this.sportConfig.isFloorball(this.sportName) ||
                this.sportConfig.isFieldHockey(this.sportName))) {
                return;
            }

            if (timeline.type === this.sportConfig.timeline.eventTypes.scoreChange &&
                (this.sportConfig.isBadminton(this.sportName) || this.sportConfig.isBeachVolleyball(this.sportName))) {
                return;
            }

            const prevPeriod = period;
            /** Snooker periods */
            if (timeline.frame_number && timeline.type === this.sportConfig.timeline.eventTypes.ballPot &&
                this.sportConfig.isSnooker(this.sportName)) {
                period = `frame_${timeline.frame_number}`;
                if (period !== prevPeriod) { resp[period as string] = []; }
            } else if (timeline.type === this.sportConfig.timeline.eventTypes.periodStart) {
                period = timeline.period_name ?? `period${periodCount}`;
                resp[period as string] = [];
                if (period !== prevPeriod) {
                    periodCount += 1;
                }
            } else if (period === null && timeline.type !== this.sportConfig.timeline.eventTypes.matchStarted) {
                period = 'defaultPeriod';
                resp[period as string] = [];
            }

            if (period !== null && validTypes.includes(timeline.type as string)) {
                let image = null;

                if (this.sportConfig.timeline?.image?.[this.sportName] &&
                    this.sportConfig.timeline?.image?.[this.sportName][timeline.type!]) {
                    image =
                        (this.sportConfig.timeline.image[this.sportName] as Record<string, any>)[timeline.type!];
                }

                /** Change for Volleyball, Table tennis */
                if (this.sportConfig.isVolleyball(this.sportName) || this.sportConfig.isTableTennis(this.sportName) ||
                this.sportConfig.isSquash(this.sportName)) {
                    if (timeline.type === this.sportConfig.timeline.eventTypes.scoreChange) {
                        return;
                    }
                    if (timeline.type === this.sportConfig.timeline.eventTypes.periodScore) {
                        timeline.type = this.sportConfig.timeline.eventTypes.scoreChange;
                        if (prevHomeScore < +timeline.home_score!) {
                            timeline.competitor = 'home';
                        } else if (prevAwayScore < +timeline.away_score!) {
                            timeline.competitor = 'away';
                        }
                    }
                }

                /** Changes for hockey */
                if (this.sportConfig.isHockey(this.sportName)) {
                    if (timeline.type === this.sportConfig.timeline.eventTypes.suspension &&
                        timeline.suspension_minutes) {
                        const len = resp[period as string]?.length ?? null;
                        if (len) {
                            const [last] = (resp[period as string] as any[]).slice(len - 1, len);

                            const playerEqual = timeline.players && last.players &&
                            last.players[0] && timeline.players[0] &&
                            last.players[0].id === timeline.players[0].id;
                            if (last.type === this.sportConfig.timeline.eventTypes.suspension &&

                                playerEqual &&
                                typeof last.suspension_minutes === 'number') {
                                last.suspension_minutes = `${last.suspension_minutes} + ${timeline.suspension_minutes}`;

                                return;
                            }
                        }
                    }
                }

                /** Count diff between current and prev score */
                if (timeline.type === this.sportConfig.timeline.eventTypes.scoreChange) {
                    timeline.homeScoreDiff = timeline.home_score ? timeline.home_score - prevHomeScore : 0;
                    timeline.awayScoreDiff = timeline.away_score ? timeline.away_score - prevAwayScore : 0;
                    prevHomeScore = +timeline.home_score!;
                    prevAwayScore = +timeline.away_score!;
                }

                /** Count diff between current and prev score for Snooker */
                if (timeline.type === this.sportConfig.timeline.eventTypes.ballPot) {
                    timeline.homeScoreDiff = timeline.competitor === 'home' ? timeline.points : 0;
                    timeline.awayScoreDiff = timeline.competitor === 'away' ? timeline.points : 0;
                }

                if (timeline.frame_score && timeline.frame_score !== null) {
                    const [homeScore, awayScore] = timeline.frame_score.split(':');
                    timeline.frameScoreHome = homeScore ? +homeScore : undefined;
                    timeline.frameScoreAway = awayScore ? +awayScore : undefined;
                }

                timeline.mainPlayers = [];
                timeline.assistsPlayers = [];
                timeline.suspensionPlayers = [];
                timeline.fouledPlayers = [];
                timeline.substitutionPlayers = { in: [], out: [] };
                timeline.players?.forEach((player, key) => {
                    if (timeline.type === this.sportConfig.timeline.eventTypes.suspension) {
                        timeline.suspensionPlayers.push(player);
                    }
                    if (timeline.type === this.sportConfig.timeline.eventTypes.substitution) {
                        if (player.type === 'substituted_out' || (player.type === null && key > 0)) {
                            timeline.substitutionPlayers.out.push(player);
                        }
                        if (player.type === 'substituted_in' || (player.type === null && key === 0)) {
                            timeline.substitutionPlayers.in.push(player);
                        }
                    }
                    if (timeline.type === this.sportConfig.timeline.eventTypes.sevenMAwarded) {
                        timeline.fouledPlayers.push(player);
                    }
                    if (player.type === this.sportConfig.timeline.playerTypes.assist ||
                        player.type === this.sportConfig.timeline.playerTypes.primaryAssist ||
                        player.type === this.sportConfig.timeline.playerTypes.secondaryAssist) {
                        timeline.assistsPlayers.push(player);
                    }

                    const isPlayerSet = timeline.suspensionPlayers.length > 0 ||
                      timeline.fouledPlayers.length > 0 || timeline.substitutionPlayers.in.length > 0 ||
                      timeline.substitutionPlayers.out.length > 0;
                    if (!isPlayerSet && (player.type === this.sportConfig.timeline.playerTypes.scorer ||
                        !player.type)) {
                        timeline.mainPlayers.push(player);
                    }
                });

                resp[period as string]!.push({ ...timeline, image });
            }
        });

        return resp;
    }

    private cricketMap():
    {home: string;away: string;home2: string| null;away2: string | null;
        groupedInnings:Record<string, [string, TimelineType[]][]>} {
        const participants = this.timelineData.filter(
            (timeline: Timeline) => timeline.type === this.sportConfig.timeline.eventTypes.periodStart);

        // eslint-disable-next-line prefer-const
        let [home, away, home2 = null, away2 = null] =
            Object.keys(groupBy(participants, v => v.period_name))
                .filter(v => v.includes('home') || v.includes('away'));


        if (!home) {
            home = 'cricket_first_inning';
        }

        if (!away) {
            away = 'cricket_second_inning';
        }

        const resp = this.timelineData.filter(
            (timeline: Timeline) => timeline.type !== this.sportConfig.timeline.eventTypes.periodStart &&
            timeline.type !== this.sportConfig.timeline.eventTypes.matchStarted &&
            timeline.type !== this.sportConfig.timeline.eventTypes.matchEnded);

        const groupedInnings: any = groupBy(resp, v => v.inning);


        forEach(groupedInnings, (_v: any, k: string) => {
            if (groupedInnings[k]) {
                groupedInnings[k] = reverse(toPairs(groupBy(groupedInnings[k], v => v.over_number)));

                groupedInnings[k].forEach((over: any) => {
                    let total = 0;
                    let bowler: string[] = [];
                    let batter: string[] = [];
                    const commentariesAll: string[] = [];
                    over[1] = over[1].map((ball: any) => {
                        if (ball.batting_params?.runs_scored) {
                            total += ball.batting_params?.runs_scored;
                        }

                        if (ball.batting_params?.striker) {
                            batter.push(ball.batting_params?.striker.name);
                            batter = uniq(batter);
                        }
                        if (ball.bowling_params?.bowler) {
                            bowler.push(ball.bowling_params?.bowler.name);
                            bowler = uniq(bowler);
                        }

                        if (ball.commentaries && ball.commentaries.length > 0) {
                            commentariesAll.push(...ball.commentaries);
                        }
                        return { ...ball, totalRuns: total + 0, bowler, batter, commentariesAll };
                    });
                });
            }
        });


        // eslint-disable-next-line max-len
        return { home, away, home2, away2, groupedInnings } as {home: string;away: string; home2: string| null;away2: string | null;groupedInnings:Record<string, [string, TimelineType[]][]>};
    }

    /* Creating a function that will be used to create a new TennisMap object. */
    private dartsMap(): DartsMapFce {
        const validTypes: string[] =
            [this.sportConfig.timeline.eventTypes.dart,
                this.sportConfig.timeline.eventTypes.scoreChange,
                this.sportConfig.timeline.eventTypes.legScoreChange,
                this.sportConfig.timeline.eventTypes.firstThrow,
            ];


        const resp: Record<string, any> = { firstThrow: null,
            home: null,
            away: null,
            data: [],
            image: this.sportConfig.timeline?.image?.darts.score_change };
        let data: DartsMapFce = { score: [] };
        let darts: DartsMapFce[] = [];

        this.timelineData.forEach((timeline: Timeline) => {
            if (validTypes.includes(timeline.type as string)) {
                if (timeline.type === this.sportConfig.timeline.eventTypes.firstThrow) {
                    resp.firstThrow = timeline.competitor ?? null;
                    resp.home = this.home;
                    resp.away = this.away;
                }
                if (timeline.type === this.sportConfig.timeline.eventTypes.legScoreChange) {
                    const lastDarts = darts.length > 0 ? darts : null;
                    const dataVal = { ...data, ...timeline, lastDarts };
                    resp.data.push(dataVal);
                    data = { score: [] };
                    darts = [];
                }
                if (timeline.type === this.sportConfig.timeline.eventTypes.dart) {
                    darts.push(timeline);
                }
                if (timeline.type === this.sportConfig.timeline.eventTypes.scoreChange) {
                    data.score.push({ homeScore: timeline.home_score, awayScore: timeline.away_score, darts });
                    darts = [];
                }
            }
        });

        return resp;
    }

    /* Creating a function that will be used to create a new TennisMap object. */
    private tennisMap(): TennisMapFce {
        const validTypes: string[] =
            [this.sportConfig.timeline.eventTypes.point, this.sportConfig.timeline.eventTypes.periodScore];
        const resp: TennisMapFce = {};
        let period: string | null = null;
        let i: number = 0;
        let lastTimeline: Record<string, any> | null = null;

        this.timelineData.forEach((timeline: Timeline) => {
            if (timeline.type === this.sportConfig.timeline.eventTypes.periodStart ||
                (timeline.type === this.sportConfig.timeline.eventTypes.matchResumed && timeline.period_name !== null)
            ) {
                i = 0;

                if (period !== timeline.period_name &&
                    ((lastTimeline?.score?.home_score === 6 && lastTimeline?.score?.away_score === 7) ||
                    (lastTimeline?.score?.home_score === 7 && lastTimeline?.score?.away_score === 6))) {
                    lastTimeline.score.tie_break = true;
                }
                period = timeline.period_name;
                resp[period as string] = [];
                (resp[period as string] as Record<string, any>[])[i] = { score: null, point: [] };
            }

            if (timeline.type === 'match_suspended') {
                // TODO: suspended implementation resp.match_suspended = true;
            }

            if (period !== null && validTypes.includes(timeline.type as string)) {
                if (!(resp[period as string] as Record<string, any>[])[i]) {
                    (resp[period as string] as Record<string, any>[])[i] = { score: null, point: [] };
                }
                if (timeline.type === validTypes[1]) {
                    const homeWin = (timeline.server === 'home' &&
                    (timeline.result === 'ace' || timeline.result === 'server_won')) ||
                        (timeline.server !== 'home' &&
                        (timeline.result === 'double_fault' || timeline.result === 'receiver_won'));


                    (resp[period as string] as Record<string, any>[])[i]?.point.forEach(
                        (timeline2: Record<string, any>) => {
                            const isTiebreak = ((timeline!.home_score === 6 &&
                                timeline!.away_score === 7) ||
                                (timeline!.home_score === 7 && timeline!.away_score === 6)) &&
                                (timeline2.home_score >= 6 || timeline2.away_score >= 6);

                            const lastTimeline2 = (resp[period as string] as Record<string, any>[])?.[i - 1] ?? null;

                            if ((timeline2.home_score === 40 || timeline2.home_score === 50 || isTiebreak) &&
((lastTimeline2 && lastTimeline2.score?.home_score > lastTimeline2.score?.away_score) || isTiebreak) &&
                            timeline2.home_score > timeline2.away_score &&
                            lastTimeline2?.score?.home_score >= 5) {
                                timeline2.isSB = true;
                            } else if ((timeline2.away_score === 40 || timeline2.away_score === 50 || isTiebreak) &&
((lastTimeline2 && lastTimeline2.score?.home_score < lastTimeline2.score?.away_score) || isTiebreak) &&
                            timeline2.home_score < timeline2.away_score &&
                            lastTimeline2?.score?.away_score >= 5

                            ) {
                                timeline2.isSB = true;
                            }
                        });

                    (resp[period as string] as Record<string, any>[])[i]!.score =
                        { ...timeline, isHomeWinner: homeWin };
                    i += 1;
                } else if (timeline.type === validTypes[0]) {
                    const mapTimeline = { ...timeline, isBB: false, isSB: false };
                    if (timeline.home_score !== null && timeline.away_score !== null) {
                        if ((timeline.home_score === 40 || timeline.home_score === 50) &&
                            timeline.home_score > timeline.away_score && timeline.server === 'away') {
                            mapTimeline.isBB = true;
                        } else if ((timeline.away_score === 40 || timeline.away_score === 50) &&
                            timeline.home_score < timeline.away_score && timeline.server === 'home') {
                            mapTimeline.isBB = true;
                        }
                        (resp[period as string] as Record<string, any>[])[i]?.point.push(mapTimeline);
                    }
                }
            }

            if (period !== null && timeline.type === this.sportConfig.timeline.eventTypes.matchEnded) {
                const index: number = Math.max(0, i - 1);
                (resp[period as string] as Record<string, any>[])[index]?.point.push({ matchEnded: true });
                (resp[period as string] as Record<string, any>[])[index]?.point.forEach(
                    (point: Record<string, any>): void => {
                        const { score } = (resp[period as string] as Record<string, any>[])[index]!;

                        let isHomeWinner = Winner.NOT_SET;
                        if (lastTimeline && lastTimeline.score &&
                                lastTimeline.score.type === this.sportConfig.timeline.eventTypes.periodScore) {
                            if (lastTimeline.score.home_score > lastTimeline.score.away_score) {
                                isHomeWinner = Winner.HOME;
                            } else if (lastTimeline.score.home_score < lastTimeline.score.away_score) {
                                isHomeWinner = Winner.AWAY;
                            }
                        }

                        if (!point.matchEnded) {
                            const isTiebreak = ((score.home_score === 6 &&
                                score.away_score === 7) ||
                                (score.home_score === 7 && score.away_score === 6)) &&
                                (point.home_score >= 6 || point.away_score >= 6);

                            if ((point.home_score === 40 || point.home_score === 50 || isTiebreak) &&
                            point.home_score > point.away_score && isHomeWinner === Winner.HOME) {
                                point.isMB = true;
                            } else if ((point.away_score === 40 || point.away_score === 50 || isTiebreak) &&
                            point.home_score < point.away_score && isHomeWinner === Winner.AWAY) {
                                point.isMB = true;
                            }
                        }
                    });
            }


            lastTimeline = (resp[period as string] as Record<string, any>[])?.[i] ??
            (resp[period as string] as Record<string, any>[])?.[i - 1] ?? null;
        });
        return resp;
    }
}
