import { ChangeDetectionStrategy, Component, Input } from '@angular/core';


@Component({
    selector: 'app-tvcom',
    templateUrl: './tvcom.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TvComComponent {
    @Input()
    public video?: string;

    constructor() {}


    public get url(): string {
        return `//embed.tvcom.cz/${this.video}`;
    }
}
