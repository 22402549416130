<iframe
    width="100%"
    height="300"
    scrolling="no"
    [src]="url | safe"
    frameborder="0"
    webkitallowfullscreen="true"
    mozallowfullscreen="true"
    allowfullscreen="true"
    [ngStyle]="currentStyles"
>
    <a [href]="url | safe">{{ 'web.stream_download' | translate }}</a>
</iframe>
