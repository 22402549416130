/**
 *  Number format pipe
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'appnumber',
})
export class AppNumberPipe implements PipeTransform {
    public transform(value: any, tousand: string = ' '): any {
        if (!value) {
            return value;
        }

        return value
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${tousand}`);
    }
}
