import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy } from '@angular/core';


import { SportConfigToken } from '@config/sport.config';

import { TranslateService } from '@ngx-translate/core';

import { OptaWidgetBase } from '../base.service';

declare const Opta: any;

@Component({
    selector: 'app-opta-penalty-touch-map',
    templateUrl: './penalty-touch-map.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptaWidgetPenaltyTouchMapSeasonComponent extends OptaWidgetBase implements AfterViewInit, OnDestroy {
    @Input()
        matchId: string | number | null | undefined;

    @Input()
        competitionId: string | number | null | undefined;

    @Input()
        seasonId: string | number | null | undefined;

    #headlineTrans = 'web.opta_penalty_touch_map';
    private transHeadline = '';


    public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>,
        public translate: TranslateService) {
        super();
    }

    public ngAfterViewInit(): void {
        this.transHeadline = this.translate.instant(this.#headlineTrans);
        setTimeout(() => {
            const element = document.querySelector('#penalty-touch-map') as HTMLElement;

            if (element) {
                element.innerHTML = this.getHtml();
                const optaWidgetTags = document.querySelector('opta-widget[load="false"]');

                if (optaWidgetTags) {
                    optaWidgetTags.removeAttribute('load');
                    Opta.start();
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (Opta.widgets.ref_widget_penalty_touch_map) {
            Opta.widgets.ref_widget_penalty_touch_map.destroy(true);
        }
    }

    getHtml(): string {
        return `

        <opta-widget widget="heatmap" 
        widget_id="ref_widget_penalty_touch_map" 
        competition="${this.competitionId}" season="${this.seasonId}" match="${this.matchId}" load="false" 
         preselected_player="all" template="penalty_touchmap" live="true" side="both" 
         show_match_header="false" show_score="false" show_halftime_score="false" 
         show_crests="false" show_team_formation="false" show_competition_name="false" 
         show_date="false" date_format="dddd D MMMM YYYY" narrow_date_format="DD/MM/YY" 
         show_team_sheets="false" show_subs="false" show_position="true" show_timecontrols="true" 
         title="${this.transHeadline}" 
         show_timecontrols_buttons="true" show_timecontrols_bar="true" show_tooltips="true"
          competition_naming="brief" team_naming="brief" player_naming="full" show_logo="false"
           show_title="true" breakpoints="400, 700" sport="football"></opta-widget>


  

            `;
    }
}
