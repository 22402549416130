<ul
    class="nav nav-pills nav-pills-sub nav-pills-sub--special bg-light-sub px-2 table-subnav-level-2"
    *ngIf="isUnderOver"
    id="subNav2"
>
    <li class="nav-item" *ngFor="let uo of sportConfig.statistics.underOver; first as isFirst; trackBy: trackByIndex">
        <a
            class="nav-link"
            data-toggle="tab"
            href="#home"
            role="tab"
            (click)="setUnderOverLimit(uo)"
            [class.active]="isFirst"
            >{{ uo }}</a
        >
    </li>
</ul>

<div class="d-flex table-scrollable">
    <div class="table-scrollable__fixed">
        <div class="table-scrollable__fixed-shadow"></div>
        <table class="table-scrollable__fixed__table" data-cy="app-table-left-data">
            <thead>
                <tr>
                    <th class="table-scrollable__table-th text-center app-cursor" colspan="3" (click)="nullSort()">
                        <i class="fas fa-table"></i>
                    </th>
                    <!-- <th class="table-scrollable__table-th table-scrollable__fixed__table__image">&nbsp;</th>
                    <th class="table-scrollable__table-th table-scrollable__fixed__table__text">&nbsp;</th> -->
                </tr>
            </thead>
            <tbody>
                <tr
                    class="table-scrollable__table-row"
                    *ngFor="let row of countedData | tableSort : sort; trackBy: trackByPlace; index as i"
                    [class.app-table-selected-participant]="isSelectedParticipant(row.participant_id)"
                >
                    <td class="table-scrollable__table-td">
                        <div
                            class="table-scrollable__table-place"
                            data-toggle="tooltip"
                            data-placement="top"
                            [attr.data-original-title]="row.promotion.name"
                            [attr.title]="row.promotion.name"
                        >
                            <span class="span {{ row.promotion.color }} {{ getClass(row) }}">{{ row.place }}</span>
                        </div>
                    </td>
                    <td class="table-scrollable__table-td text-center">
                        <img *ngIf="row.image !== null" [src]="row.image" [alt]="row.name" height="18" class="mr-1" />
                        <img
                            *ngIf="row.image === null"
                            [src]="'/img/sports/' + sportName + '.png'"
                            [alt]="row.name"
                            height="18"
                            class="mr-1"
                        />
                    </td>
                    <td class="table-scrollable__table-td" [class.font-weight-bold]="i === 0">
                        <ng-container *ngIf="row.comment">
                            <svg
                                class="icon icon--12 float-right"
                                data-toggle="tooltip"
                                data-placement="top"
                                [attr.data-original-title]="row.comment"
                                [attr.title]="row.comment"
                            >
                                <use xlink:href="#icon-info" />
                            </svg>
                        </ng-container>
                        <a
                            [routerLink]="['/page/sport/participant', sportName + '-' + sportId, row.participant_id]"
                            class="text-reset table-scrollable-team"
                            >{{ row.name }}

                            <ng-container *ngIf="+row.trend != 0">
                                <svg *ngIf="+row.trend > 0" class="icon icon--8 icon--success ml-2">
                                    <use xlink:href="#icon-arrow-up"></use>
                                </svg>
                                <svg *ngIf="+row.trend < 0" class="icon icon--8 icon--danger ml-2">
                                    <use xlink:href="#icon-arrow-down"></use>
                                </svg>
                                &nbsp;<small
                                    [class.text-success]="+row.trend > 0"
                                    [class.text-danger]="+row.trend < 0"
                                    >{{ row.trend }}</small
                                >
                            </ng-container>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="table-scrollable__data flex-grow-1" tableScroll>
        <table class="table-scrollable__data__table" data-cy="app-table-right-data">
            <thead>
                <tr>
                    <th
                        class="table-scrollable__table-th table-scrollable__data__table-th app-cursor"
                        *ngFor="
                            let head of countedColumns;
                            trackBy: trackByIndex;
                            last as isLast;
                            first as isFirst;
                            index as index
                        "
                        data-toggle="tooltip"
                        data-placement="top"
                        (click)="sortBy(index)"
                        title="{{ head.name }}"
                        [attr.data-original-title]="head.name"
                        [class.font-weight-bold-extend]="emphVal(index, isLast)"
                    >
                        {{ head.short }}
                        <i class="fas fa-sort-up" *ngIf="sort?.index === index && sort?.direction === 'asc'"></i>
                        <i class="fas fa-sort-down" *ngIf="sort?.index === index && sort?.direction === 'desc'"></i>
                    </th>

                    <ng-container *ngIf="form">
                        <th class="table-scrollable__table-th table-scrollable__data__table-th text-right">
                            {{ 'web.table_form' | translate }}
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <tr
                    class="table-scrollable__table-row"
                    *ngFor="let row of countedData | tableSort : sort; trackBy: trackByPlace"
                    [class.app-table-selected-participant]="isSelectedParticipant(row.participant_id)"
                >
                    <td
                        class="table-scrollable__table-td table-scrollable__data__table-td"
                        [class.text-bold]="emphVal(i, isLast)"
                        *ngFor="
                            let column of row.columns;
                            trackBy: trackByIndex;
                            last as isLast;
                            first as isFirst;
                            index as i
                        "
                    >
                        <span
                            [class.emph-text-node-box]="
                                isSelectedParticipant(row.participant_id) && isEmph(i) && isLive
                            "
                            >{{ column }}</span
                        >
                    </td>
                    <ng-container *ngIf="form">
                        <td class="table-scrollable__table-td table-scrollable__data__table-td text-right">
                            <app-last-matches [matches]="row.matches" [limit]="5"></app-last-matches>
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>
</div>
