import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy } from '@angular/core';


import { SportConfigToken } from '@config/sport.config';

import { TranslateService } from '@ngx-translate/core';

import { OptaWidgetBase } from '../base.service';

declare const Opta: any;

@Component({
    selector: 'app-opta-opta-penalty-history',
    templateUrl: './penalty-history.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptaWidgetPenaltyHistoryComponent extends OptaWidgetBase implements AfterViewInit, OnDestroy {
    @Input()
        matchId: string | number | null | undefined;

    @Input()
        competitionId: string | number | null | undefined;

    @Input()
        seasonId: string | number | null | undefined;

    @Input()
        template = 'normal';

    #headlineTrans = 'web.opta_penalty_history';
    private transHeadline = '';


    public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>,
        public translate: TranslateService) {
        super();
    }

    public ngAfterViewInit(): void {
        this.transHeadline = this.translate.instant(this.#headlineTrans);
        this.#headlineTrans = 'web.opta_penalty_history';
        setTimeout(() => {
            const element = document.querySelector('#opta-penalty-history') as HTMLElement;

            if (element) {
                element.innerHTML = this.getHtml();
                const optaWidgetTags = document.querySelector('opta-widget[load="false"]');

                if (optaWidgetTags) {
                    optaWidgetTags.removeAttribute('load');
                    Opta.start();
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (Opta.widgets.ref_widget_penalty_history && this.template === 'normal') {
            Opta.widgets.ref_widget_penalty_history.destroy(true);
        }
    }

    getHtml(): string {
        return `
        <opta-widget widget="penalty_history"
       competition="${this.competitionId}" season="${this.seasonId}" match="${this.matchId}" load="false" 
       title="${this.transHeadline}" 
        preselected_player="all" template="normal" show_selects="true" show_tooltips="true" show_goals="true"
         show_penalties_missed="true" show_grid="true" show_subs="true" show_match_header="false"
          show_score="false" show_crests="false" show_attendance="false" show_date="false"
           date_format="dddd D MMMM YYYY HH:mm" narrow_date_format="DD/MM/YY" show_team_formation="true" 
           show_halftime_score="false" show_referee="false" show_venue="false" show_images="true" 
           show_competition_name="true" competition_naming="full" team_naming="full" player_naming="full" 
           show_logo="false" show_title="true" breakpoints="400" sport="football"></opta-widget>

             
              `;
    }
}
