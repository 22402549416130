import { WindowRefService } from '@/shared/window-ref';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-twitch',
    templateUrl: './twitch.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwitchComponent implements OnInit {
    @Input()
    public channel: string;

    @Input()
    public video: string;

    private hostName: string;

    constructor(private win: WindowRefService) {

    }

    ngOnInit(): void {
        const { hostname } = this.win.nativeWindow.location;
        this.hostName = hostname;
    }

    public get url(): string {
        const type = this.video ? 'video' : 'channel';
        const typeVal = this.video ?? this.channel;
        return `https://player.twitch.tv/?${type}=${typeVal}&parent=${this.hostName}`;
    }
}
