<ng-container *ngIf="!participant">
    <app-lineups-ice-hockey-field
        *ngIf="isLineupComplete && this.isHockey(sportName)"
        [formationH]="homePlayers"
        [formationA]="awayPlayers"
        [statistics]="statistics"
    ></app-lineups-ice-hockey-field>
    <ul class="nav nav-tabs nav-justified nav-tabs-lineups">
        <li class="nav-item">
            <a
                class="nav-link active"
                id="tab-match-lineups-1-home"
                data-toggle="tab"
                href="#home"
                (click)="_isHome = true"
                role="tab"
                aria-controls="tab-match-lineups-1-home-content"
                aria-selected="true"
            >
                {{ home }}
            </a>
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                id="away-tab"
                data-toggle="tab"
                href="#away"
                (click)="_isHome = false"
                role="tab"
                aria-controls="tab-match-lineups-1-away-content"
                aria-selected="false"
            >
                {{ away }}
            </a>
        </li>
    </ul>

    <div class="lineups-container" *ngIf="hasFormation() && showField">
        <div *ngIf="someHasForm" class="app-small-nav m-2 d-flex justify-content-center w-100">
            <a class="crumb__link app-cursor mr-1" [class.active]="field === 'form'" (click)="fieldInfo('form')"
                >{{ 'web.player_form' | translate }}
            </a>
            <a
                class="crumb__link app-cursor mr-1"
                [class.active]="field === 'nationality'"
                (click)="fieldInfo('nationality')"
                >{{ 'web.player_nationality' | translate }}
            </a>
            <a class="crumb__link app-cursor mr-1" [class.active]="field === 'height'" (click)="fieldInfo('height')"
                >{{ 'web.player_height' | translate }}
            </a>
            <a class="crumb__link app-cursor mr-1" [class.active]="field === 'age'" (click)="fieldInfo('age')"
                >{{ 'web.player_age' | translate }}
            </a>
        </div>
        <div class="playground d-block">
            <div class="playground-half-container home lu-{{ formationHome }}" [class.d-none]="!isHome">
                <div
                    class="player-form__field player-form__field__total"
                    style="transform: scaleX(-1.1)"
                    [ngClass]="formClass(totalFormHome)"
                    *ngIf="totalFormHome !== 0"
                >
                    <div>&#10672;</div>
                    {{ totalFormHome | number : '1.1' }}
                </div>
                <picture>
                    <source srcset="/img/lineups/playground-home.avif" type="image/avif" />
                    <img src="/img/lineups/playground-home.png" class="playground-half horizontal" />
                </picture>
                <picture>
                    <source srcset="/img/lineups/playground-home-vertical.avif" type="image/avif" />
                    <img src="/img/lineups/playground-home-vertical.png" class="playground-half vertical" />
                </picture>

                <span class="formation-label-container">
                    <span class="formation-label">{{ formationHome }}</span>
                </span>

                <span
                    class="player no-{{ player.lineupPosition }}"
                    *ngFor="let player of players.home; index as i; trackBy: trackByFn"
                    [attr.data-cy]="player.name"
                >
                    <div
                        class="player-form__field"
                        [ngClass]="formClass(player.statistics?.PlayerForm)"
                        *ngIf="player.statistics?.PlayerForm"
                    >
                        <ng-container *ngIf="field === 'form'">
                            {{ player.statistics.PlayerForm | number : '1.1' }}
                        </ng-container>
                        <ng-container *ngIf="field === 'age'">
                            {{ player.age }}
                        </ng-container>
                        <ng-container *ngIf="field === 'height'"> {{ player.height }} cm </ng-container>
                        <ng-container *ngIf="field === 'nationality'">
                            <img
                                [attr.src]="player.flag"
                                *ngIf="player.flag !== null"
                                [attr.alt]="player.name"
                                height="16"
                                class=""
                            />
                        </ng-container>
                    </div>
                    <svg
                        *ngIf="+player.lineupPosition! == 1"
                        version="1.2"
                        baseProfile="tiny"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 600 600"
                        xml:space="preserve"
                    >
                        <g>
                            <g>
                                <g>
                                    <circle
                                        cx="300"
                                        cy="300"
                                        r="300"
                                        fill="white"
                                        id="p_home_{{ player.lineupPosition }}"
                                        class="d-none app-players-field"
                                    />
                                    <path
                                        [style.fill]="jerseyColor('home', player.lineupPosition, 'sleeve')"
                                        class="grey2"
                                        d="M492,117.4c0,0,53.3,21.3,53.3,106.7v64c0,0-10.7,32-106.7,21.3H161.2c-96,10.7-106.7-21.3-106.7-21.3 v-64c0-85.3,53.3-106.7,53.3-106.7H492z"
                                    ></path>
                                    <path
                                        [style.fill]="jerseyColor('home', player.lineupPosition, 'base')"
                                        class="yellow1"
                                        d="M385.3,53.4c30.7,28.5,67,50.3,106.7,64c0,0-64,160-64,298.7c-0.9,65.8,7.5,56.9,19.8,121.5 c2.3,10.8-4,21.6-14.6,24.8c-20.5,6.2-60.2,13.9-130.7,13.9s-110.2-7.7-130.7-13.9c-10.6-3.2-17-14-14.6-24.8 c12.3-64.6,15.6-55.7,14.7-121.5c0-138.7-64-298.7-64-298.7c39.6-13.7,76-35.5,106.7-64H385.3z"
                                    ></path>
                                    <g>
                                        <path
                                            class="yellow1"
                                            d="M299.9,53.4c-20.1-0.2-39.8-5.6-57.2-15.6c-4.2-3-10-2.6-13.7,1.1l-14.5,14.6c0,0,21.3,74.7,85.3,96"
                                        ></path>
                                        <path
                                            class="yellow1"
                                            d="M299.9,53.4c20.1-0.2,39.8-5.6,57.2-15.6c4.2-3,10-2.6,13.7,1.1l14.5,14.6c0,0-21.3,74.7-85.3,96"
                                        ></path>
                                    </g>
                                    <g>
                                        <path
                                            class="grey2"
                                            d="M299.9,160.1c-1.1,0-2.3-0.2-3.4-0.5c-68.6-22.9-91.3-99.9-92.2-103.2c-1.1-3.7,0-7.7,2.7-10.5 l14.5-14.5c7.3-7.3,18.8-8.3,27.3-2.3c15.6,8.8,33.2,13.5,51.1,13.7c5.9,0,10.7,4.8,10.7,10.7s-4.8,10.7-10.7,10.7 c-22.3-0.2-44.1-6.2-63.3-17.5l-9.8,9.8c6.4,17.1,28.7,67.1,76.5,83c5,1.7,8,6.7,7.2,11.8S305.2,160.1,299.9,160.1z"
                                        ></path>
                                        <path
                                            class="grey2"
                                            d="M299.9,160.1c-5.2,0-9.7-3.8-10.5-9c-0.8-5.2,2.2-10.2,7.2-11.8c48-16,70.2-65.9,76.5-83l-9.8-9.8 c-19.2,11.4-41,17.5-63.3,17.7c-5.9,0-10.7-4.8-10.7-10.7s4.8-10.7,10.7-10.7c17.9-0.2,35.5-4.9,51.1-13.7 c8.5-5.9,20-5,27.3,2.3l14.5,14.5c2.7,2.7,3.8,6.7,2.7,10.5c-0.9,3.3-23.7,80.4-92.2,103.2C302.2,159.9,301.1,160.1,299.9,160.1 z"
                                        ></path>
                                    </g>
                                    <g>
                                        <path
                                            fill="#FFFFFF"
                                            d="M161.2,149.4c-5.2,0-9.7-3.8-10.5-9c-0.8-5.2,2.2-10.2,7.2-11.8c13.8-4.2,26.8-10.5,38.5-18.8 c4.2-4,10.8-4,15,0.1c4.1,4.1,4.2,10.8,0.1,15c-12.1,12.1-43.3,22.7-46.8,23.9C163.5,149.2,162.4,149.4,161.2,149.4z"
                                        ></path>
                                        <path
                                            fill="#FFFFFF"
                                            d="M438.6,149.4c-1.1,0-2.3-0.2-3.4-0.5c-3.5-1.2-34.7-11.8-46.8-23.9c-4-4.2-4-10.8,0.1-15 c4.1-4.1,10.8-4.2,15-0.1c11.7,8.3,24.7,14.6,38.5,18.8c5,1.7,8,6.7,7.2,11.8C448.3,145.6,443.8,149.4,438.6,149.4z"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <svg
                        *ngIf="+player.lineupPosition! != 1"
                        version="1.2"
                        baseProfile="tiny"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 600 600"
                        xml:space="preserve"
                    >
                        <g>
                            <g>
                                <g>
                                    <circle
                                        cx="300"
                                        cy="300"
                                        r="300"
                                        fill="white"
                                        id="p_home_{{ player.lineupPosition }}"
                                        class="d-none app-players-field"
                                    />
                                    <path
                                        [style.fill]="jerseyColor('home', player.lineupPosition, 'sleeve')"
                                        class="red2"
                                        d="M492,117.4c0,0,53.3,21.3,53.3,106.7v64c0,0-10.7,32-106.7,21.3H161.2c-96,10.7-106.7-21.3-106.7-21.3 v-64c0-85.3,53.3-106.7,53.3-106.7H492z"
                                    ></path>
                                    <path
                                        [style.fill]="jerseyColor('home', player.lineupPosition, 'base')"
                                        class="red1"
                                        d="M385.3,53.4c30.7,28.5,67,50.3,106.7,64c0,0-64,160-64,298.7c-0.9,65.8,7.5,56.9,19.8,121.5 c2.3,10.8-4,21.6-14.6,24.8c-20.5,6.2-60.2,13.9-130.7,13.9s-110.2-7.7-130.7-13.9c-10.6-3.2-17-14-14.6-24.8 c12.3-64.6,15.6-55.7,14.7-121.5c0-138.7-64-298.7-64-298.7c39.6-13.7,76-35.5,106.7-64H385.3z"
                                    ></path>
                                    <g>
                                        <path
                                            class="white"
                                            d="M299.9,53.4c-20.1-0.2-39.8-5.6-57.2-15.6c-4.2-3-10-2.6-13.7,1.1l-14.5,14.6c0,0,21.3,74.7,85.3,96"
                                        ></path>
                                        <path
                                            class="white"
                                            d="M299.9,53.4c20.1-0.2,39.8-5.6,57.2-15.6c4.2-3,10-2.6,13.7,1.1l14.5,14.6c0,0-21.3,74.7-85.3,96"
                                        ></path>
                                    </g>
                                    <g>
                                        <path
                                            [style.fill]="jerseyColor('home', player.lineupPosition, 'shirtType')"
                                            class="grey2"
                                            d="M299.9,160.1c-1.1,0-2.3-0.2-3.4-0.5c-68.6-22.9-91.3-99.9-92.2-103.2c-1.1-3.7,0-7.7,2.7-10.5 l14.5-14.5c7.3-7.3,18.8-8.3,27.3-2.3c15.6,8.8,33.2,13.5,51.1,13.7c5.9,0,10.7,4.8,10.7,10.7s-4.8,10.7-10.7,10.7 c-22.3-0.2-44.1-6.2-63.3-17.5l-9.8,9.8c6.4,17.1,28.7,67.1,76.5,83c5,1.7,8,6.7,7.2,11.8S305.2,160.1,299.9,160.1z"
                                        ></path>
                                        <path
                                            [style.fill]="jerseyColor('home', player.lineupPosition, 'shirtType')"
                                            class="grey2"
                                            d="M299.9,160.1c-5.2,0-9.7-3.8-10.5-9c-0.8-5.2,2.2-10.2,7.2-11.8c48-16,70.2-65.9,76.5-83l-9.8-9.8 c-19.2,11.4-41,17.5-63.3,17.7c-5.9,0-10.7-4.8-10.7-10.7s4.8-10.7,10.7-10.7c17.9-0.2,35.5-4.9,51.1-13.7 c8.5-5.9,20-5,27.3,2.3l14.5,14.5c2.7,2.7,3.8,6.7,2.7,10.5c-0.9,3.3-23.7,80.4-92.2,103.2C302.2,159.9,301.1,160.1,299.9,160.1 z"
                                        ></path>
                                    </g>
                                    <g>
                                        <path
                                            fill="#FFFFFF"
                                            d="M161.2,149.4c-5.2,0-9.7-3.8-10.5-9c-0.8-5.2,2.2-10.2,7.2-11.8c13.8-4.2,26.8-10.5,38.5-18.8 c4.2-4,10.8-4,15,0.1c4.1,4.1,4.2,10.8,0.1,15c-12.1,12.1-43.3,22.7-46.8,23.9C163.5,149.2,162.4,149.4,161.2,149.4z"
                                        ></path>
                                        <path
                                            fill="#FFFFFF"
                                            d="M438.6,149.4c-1.1,0-2.3-0.2-3.4-0.5c-3.5-1.2-34.7-11.8-46.8-23.9c-4-4.2-4-10.8,0.1-15 c4.1-4.1,10.8-4.2,15-0.1c11.7,8.3,24.7,14.6,38.5,18.8c5,1.7,8,6.7,7.2,11.8C448.3,145.6,443.8,149.4,438.6,149.4z"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span class="shirt-number" [style.color]="jerseyColor('home', player.lineupPosition, 'number')">{{
                        player.number
                    }}</span>
                    <span class="name-wrapper">
                        <span class="name-container">
                            <span class="cards">
                                <img
                                    *ngIf="
                                        (player.statistics?.RedCards &&
                                            +player.statistics?.RedCards + +player.statistics?.YellowRedCards != 0) ||
                                        +player.red_card != 0
                                    "
                                    src="/img/lineups/red.svg"
                                    class="lineup-card yellow"
                                    alt="red card"
                                />
                                <img
                                    *ngIf="
                                        (player.statistics?.YellowCards && +player.statistics?.YellowCards != 0) ||
                                        +player.yellow_card != 0
                                    "
                                    src="/img/lineups/yellow.svg"
                                    class="lineup-card yellow"
                                    alt="yellow card"
                                />
                            </span>
                            <a
                                [routerLink]="['/page/sport/player', sportName + '-' + sportId, player.id]"
                                [queryParams]="{ season: seasonId }"
                                class="text-reset"
                                >{{ player.name_short }}</a
                            >
                        </span>
                    </span>
                    <img
                        *ngIf="
                            (player.statistics?.GoalsScored && +player.statistics?.GoalsScored !== 0) ||
                            +player.goals !== 0
                        "
                        src="/img/lineups/goal.png"
                        class="goal"
                        data-cy="goal"
                        alt="goal"
                    />

                    <span
                        *ngIf="+player.statistics?.GoalsScored > 1 || +player.goals > 1"
                        class="name-wrapper"
                        style="top: 42%; right: -7%; left: auto"
                    >
                        <span class="name-container"
                            ><span class="text-reset">{{ player.statistics?.GoalsScored || player.goals }}</span></span
                        >
                    </span>
                    <img
                        *ngIf="(player.statistics?.Assists && +player.statistics?.Assists != 0) || +player.assist != 0"
                        alt="assist"
                        class="assist"
                        src="/img/lineups/assist.png"
                    />
                    <img
                        *ngIf="
                            (player.statistics?.SubstitutedOut && +player.statistics?.SubstitutedOut != 0) ||
                            player.substitute != 0
                        "
                        alt="substitution"
                        class="substitution"
                        src="/img/lineups/sub_off.svg"
                    />
                </span>
            </div>
            <div class="playground-half-container away lu-{{ formationAway }}" [class.d-none]="isHome">
                <div
                    class="player-form__field player-form__field__total"
                    [ngClass]="formClass(totalFormAway)"
                    style="transform: scaleX(1.1)"
                    *ngIf="totalFormAway !== 0"
                >
                    <div>&#10672;</div>
                    {{ totalFormAway | number : '1.1' }}
                </div>
                <picture>
                    <source srcset="/img/lineups/playground-away.avif" type="image/avif" />
                    <img src="/img/lineups/playground-away.png" class="playground-half horizontal" />
                </picture>
                <picture>
                    <source srcset="/img/lineups/playground-away-vertical.avif" type="image/avif" />
                    <img src="/img/lineups/playground-away-vertical.png" class="playground-half vertical" />
                </picture>

                <span class="formation-label-container">
                    <span class="formation-label">{{ formationAway }}</span>
                </span>

                <span
                    class="player no-{{ player.lineupPosition }}"
                    *ngFor="let player of players.away; index as i; trackBy: trackByFn"
                    [attr.data-cy]="player.name"
                >
                    <div
                        class="player-form__field"
                        [ngClass]="formClass(player.statistics?.PlayerForm)"
                        *ngIf="player.statistics?.PlayerForm"
                    >
                        <ng-container *ngIf="field === 'form'">
                            {{ player.statistics.PlayerForm | number : '1.1' }}
                        </ng-container>
                        <ng-container *ngIf="field === 'age'">
                            {{ player.age }}
                        </ng-container>
                        <ng-container *ngIf="field === 'height'"> {{ player.height }} cm </ng-container>
                        <ng-container *ngIf="field === 'nationality'">
                            <img
                                [attr.src]="player.flag"
                                *ngIf="player.flag !== null"
                                [attr.alt]="player.name"
                                height="16"
                                class=""
                            />
                        </ng-container>
                    </div>
                    <svg
                        *ngIf="player.lineupPosition == 1"
                        version="1.2"
                        baseProfile="tiny"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 600 600"
                        xml:space="preserve"
                    >
                        <g>
                            <g>
                                <g>
                                    <circle
                                        cx="300"
                                        cy="300"
                                        r="300"
                                        fill="white"
                                        id="p_away_{{ player.lineupPosition }}"
                                        class="d-none app-players-field"
                                    />
                                    <path
                                        [style.fill]="jerseyColor('away', player.lineupPosition, 'sleeve')"
                                        class="black"
                                        d="M492,117.4c0,0,53.3,21.3,53.3,106.7v64c0,0-10.7,32-106.7,21.3H161.2c-96,10.7-106.7-21.3-106.7-21.3 v-64c0-85.3,53.3-106.7,53.3-106.7H492z"
                                    ></path>
                                    <path
                                        [style.fill]="jerseyColor('away', player.lineupPosition, 'base')"
                                        class="orange1"
                                        d="M385.3,53.4c30.7,28.5,67,50.3,106.7,64c0,0-64,160-64,298.7c-0.9,65.8,7.5,56.9,19.8,121.5 c2.3,10.8-4,21.6-14.6,24.8c-20.5,6.2-60.2,13.9-130.7,13.9s-110.2-7.7-130.7-13.9c-10.6-3.2-17-14-14.6-24.8 c12.3-64.6,15.6-55.7,14.7-121.5c0-138.7-64-298.7-64-298.7c39.6-13.7,76-35.5,106.7-64H385.3z"
                                    ></path>
                                    <g>
                                        <path
                                            class="orange1"
                                            d="M299.9,53.4c-20.1-0.2-39.8-5.6-57.2-15.6c-4.2-3-10-2.6-13.7,1.1l-14.5,14.6c0,0,21.3,74.7,85.3,96"
                                        ></path>
                                        <path
                                            class="orange1"
                                            d="M299.9,53.4c20.1-0.2,39.8-5.6,57.2-15.6c4.2-3,10-2.6,13.7,1.1l14.5,14.6c0,0-21.3,74.7-85.3,96"
                                        ></path>
                                    </g>
                                    <g>
                                        <path
                                            class="black"
                                            d="M299.9,160.1c-1.1,0-2.3-0.2-3.4-0.5c-68.6-22.9-91.3-99.9-92.2-103.2c-1.1-3.7,0-7.7,2.7-10.5 l14.5-14.5c7.3-7.3,18.8-8.3,27.3-2.3c15.6,8.8,33.2,13.5,51.1,13.7c5.9,0,10.7,4.8,10.7,10.7s-4.8,10.7-10.7,10.7 c-22.3-0.2-44.1-6.2-63.3-17.5l-9.8,9.8c6.4,17.1,28.7,67.1,76.5,83c5,1.7,8,6.7,7.2,11.8S305.2,160.1,299.9,160.1z"
                                        ></path>
                                        <path
                                            class="black"
                                            d="M299.9,160.1c-5.2,0-9.7-3.8-10.5-9c-0.8-5.2,2.2-10.2,7.2-11.8c48-16,70.2-65.9,76.5-83l-9.8-9.8 c-19.2,11.4-41,17.5-63.3,17.7c-5.9,0-10.7-4.8-10.7-10.7s4.8-10.7,10.7-10.7c17.9-0.2,35.5-4.9,51.1-13.7 c8.5-5.9,20-5,27.3,2.3l14.5,14.5c2.7,2.7,3.8,6.7,2.7,10.5c-0.9,3.3-23.7,80.4-92.2,103.2C302.2,159.9,301.1,160.1,299.9,160.1 z"
                                        ></path>
                                    </g>
                                    <g>
                                        <path
                                            fill="#FFFFFF"
                                            d="M161.2,149.4c-5.2,0-9.7-3.8-10.5-9c-0.8-5.2,2.2-10.2,7.2-11.8c13.8-4.2,26.8-10.5,38.5-18.8 c4.2-4,10.8-4,15,0.1c4.1,4.1,4.2,10.8,0.1,15c-12.1,12.1-43.3,22.7-46.8,23.9C163.5,149.2,162.4,149.4,161.2,149.4z"
                                        ></path>
                                        <path
                                            fill="#FFFFFF"
                                            d="M438.6,149.4c-1.1,0-2.3-0.2-3.4-0.5c-3.5-1.2-34.7-11.8-46.8-23.9c-4-4.2-4-10.8,0.1-15 c4.1-4.1,10.8-4.2,15-0.1c11.7,8.3,24.7,14.6,38.5,18.8c5,1.7,8,6.7,7.2,11.8C448.3,145.6,443.8,149.4,438.6,149.4z"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <svg
                        *ngIf="player.lineupPosition != 1"
                        version="1.2"
                        baseProfile="tiny"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 600 600"
                        xml:space="preserve"
                    >
                        <g>
                            <g>
                                <g>
                                    <circle
                                        cx="300"
                                        cy="300"
                                        r="300"
                                        fill="white"
                                        id="p_away_{{ player.lineupPosition }}"
                                        class="d-none app-players-field"
                                    />
                                    <path
                                        [style.fill]="jerseyColor('away', player.lineupPosition, 'sleeve')"
                                        class="blue2"
                                        d="M492,117.4c0,0,53.3,21.3,53.3,106.7v64c0,0-10.7,32-106.7,21.3H161.2c-96,10.7-106.7-21.3-106.7-21.3 v-64c0-85.3,53.3-106.7,53.3-106.7H492z"
                                    ></path>
                                    <path
                                        [style.fill]="jerseyColor('away', player.lineupPosition, 'base')"
                                        class="blue1"
                                        d="M385.3,53.4c30.7,28.5,67,50.3,106.7,64c0,0-64,160-64,298.7c-0.9,65.8,7.5,56.9,19.8,121.5 c2.3,10.8-4,21.6-14.6,24.8c-20.5,6.2-60.2,13.9-130.7,13.9s-110.2-7.7-130.7-13.9c-10.6-3.2-17-14-14.6-24.8 c12.3-64.6,15.6-55.7,14.7-121.5c0-138.7-64-298.7-64-298.7c39.6-13.7,76-35.5,106.7-64H385.3z"
                                    ></path>
                                    <g>
                                        <path
                                            class="white"
                                            d="M299.9,53.4c-20.1-0.2-39.8-5.6-57.2-15.6c-4.2-3-10-2.6-13.7,1.1l-14.5,14.6c0,0,21.3,74.7,85.3,96"
                                        ></path>
                                        <path
                                            class="white"
                                            d="M299.9,53.4c20.1-0.2,39.8-5.6,57.2-15.6c4.2-3,10-2.6,13.7,1.1l14.5,14.6c0,0-21.3,74.7-85.3,96"
                                        ></path>
                                    </g>
                                    <g>
                                        <path
                                            [style.fill]="jerseyColor('away', player.lineupPosition, 'shirtType')"
                                            d="M299.9,160.1c-1.1,0-2.3-0.2-3.4-0.5c-68.6-22.9-91.3-99.9-92.2-103.2c-1.1-3.7,0-7.7,2.7-10.5 l14.5-14.5c7.3-7.3,18.8-8.3,27.3-2.3c15.6,8.8,33.2,13.5,51.1,13.7c5.9,0,10.7,4.8,10.7,10.7s-4.8,10.7-10.7,10.7 c-22.3-0.2-44.1-6.2-63.3-17.5l-9.8,9.8c6.4,17.1,28.7,67.1,76.5,83c5,1.7,8,6.7,7.2,11.8S305.2,160.1,299.9,160.1z"
                                        ></path>
                                        <path
                                            [style.fill]="jerseyColor('away', player.lineupPosition, 'shirtType')"
                                            d="M299.9,160.1c-5.2,0-9.7-3.8-10.5-9c-0.8-5.2,2.2-10.2,7.2-11.8c48-16,70.2-65.9,76.5-83l-9.8-9.8 c-19.2,11.4-41,17.5-63.3,17.7c-5.9,0-10.7-4.8-10.7-10.7s4.8-10.7,10.7-10.7c17.9-0.2,35.5-4.9,51.1-13.7 c8.5-5.9,20-5,27.3,2.3l14.5,14.5c2.7,2.7,3.8,6.7,2.7,10.5c-0.9,3.3-23.7,80.4-92.2,103.2C302.2,159.9,301.1,160.1,299.9,160.1 z"
                                        ></path>
                                    </g>
                                    <g>
                                        <path
                                            fill="#FFFFFF"
                                            d="M161.2,149.4c-5.2,0-9.7-3.8-10.5-9c-0.8-5.2,2.2-10.2,7.2-11.8c13.8-4.2,26.8-10.5,38.5-18.8 c4.2-4,10.8-4,15,0.1c4.1,4.1,4.2,10.8,0.1,15c-12.1,12.1-43.3,22.7-46.8,23.9C163.5,149.2,162.4,149.4,161.2,149.4z"
                                        ></path>
                                        <path
                                            fill="#FFFFFF"
                                            d="M438.6,149.4c-1.1,0-2.3-0.2-3.4-0.5c-3.5-1.2-34.7-11.8-46.8-23.9c-4-4.2-4-10.8,0.1-15 c4.1-4.1,10.8-4.2,15-0.1c11.7,8.3,24.7,14.6,38.5,18.8c5,1.7,8,6.7,7.2,11.8C448.3,145.6,443.8,149.4,438.6,149.4z"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span class="shirt-number" [style.color]="jerseyColor('away', player.lineupPosition, 'number')">{{
                        player.number
                    }}</span>
                    <span class="name-wrapper">
                        <span class="name-container">
                            <span class="cards">
                                <img
                                    *ngIf="
                                        (player.statistics?.RedCards &&
                                            +player.statistics?.RedCards + +player.statistics?.YellowRedCards != 0) ||
                                        +player.red_card != 0
                                    "
                                    src="/img/lineups/red.svg"
                                    class="lineup-card yellow"
                                    alt="red card"
                                />
                                <img
                                    *ngIf="
                                        (player.statistics?.YellowCards && +player.statistics?.YellowCards != 0) ||
                                        +player.yellow_card != 0
                                    "
                                    src="/img/lineups/yellow.svg"
                                    class="lineup-card yellow"
                                    alt="yellow card"
                                />
                            </span>
                            <a
                                [routerLink]="['/page/sport/player', sportName + '-' + sportId, player.id]"
                                [queryParams]="{ season: seasonId }"
                                class="text-reset"
                                >{{ player.name_short }}</a
                            >
                        </span>
                    </span>
                    <img
                        *ngIf="
                            (player.statistics?.GoalsScored && +player.statistics?.GoalsScored !== 0) ||
                            +player.goals !== 0
                        "
                        src="/img/lineups/goal.png"
                        class="goal"
                        data-cy="goal"
                        alt="goal"
                    />
                    <span
                        *ngIf="+player.statistics?.GoalsScored > 1 || +player.goals > 1"
                        class="name-wrapper"
                        style="top: 42%; right: -7%; left: auto"
                    >
                        <span class="name-container"
                            ><span class="text-reset">{{
                                player.statistics?.GoalsScored || player.goals != 0
                            }}</span></span
                        >
                    </span>
                    <img
                        *ngIf="(player.statistics?.Assists && +player.statistics?.Assists != 0) || +player.assist != 0"
                        alt="assist"
                        class="assist"
                        src="/img/lineups/assist.png"
                    />
                    <img
                        *ngIf="
                            (player.statistics?.SubstitutedOut && +player.statistics?.SubstitutedOut != 0) ||
                            player.substitute != 0
                        "
                        alt="substitution"
                        class="substitution"
                        src="/img/lineups/sub_off.svg"
                    />
                </span>
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="numPlayers > 0" class="d-flex justify-content-between app-lineup-extra">
    <div class="app-lineup-extra__label align-self-center">{{ 'web.squad_num_players' | translate }}</div>
    <div class="app-lineup-extra__value align-self-center">{{ numPlayers }}</div>
    <div class="app-lineup-extra__label align-self-center">{{ 'web.squad_avg_age_players' | translate }}</div>
    <div class="app-lineup-extra__value align-self-center">{{ avgAgePlayers }}</div>
</div>
<ng-container *ngIf="showTable">
    <ng-container *ngIf="!participant; else elseBlock">
        <div class="tab-content">
            <div class="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <ng-container *ngIf="hasStringPlayers">
                    <div class="match-info__table__head text-center ng-star-inserted">
                        {{ 'web.lineup_players' | translate }}
                    </div>
                    <div class="p-2">{{ participantHome?.lineup_string }}</div>
                    <div class="match-info__table__head text-center ng-star-inserted">
                        {{ 'web.substitution_players' | translate }}
                    </div>
                    <div class="p-2">{{ participantHome?.bench_string }}</div>
                </ng-container>
                <app-lineups
                    [home]="home"
                    [away]="away"
                    [header]="'web.lineup_players'"
                    [players]="players.home"
                    [sportId]="sportId"
                    [sportName]="sportName"
                    [homeAway]="'home'"
                    [seasonId]="seasonId"
                    [statistics]="statistics"
                ></app-lineups>
                <app-lineups
                    [home]="home"
                    [away]="away"
                    [header]="'web.substitution_players'"
                    [players]="subHome"
                    [homeAway]="'home'"
                    [seasonId]="seasonId"
                    [sportId]="sportId"
                    [sportName]="sportName"
                    [statistics]="statistics"
                ></app-lineups>
                <app-lineups
                    [home]="home"
                    [away]="away"
                    *ngIf="extendedStats"
                    [extendedStats]="true"
                    [header]="'web.extended_stats_players'"
                    [players]="allHome"
                    [sportId]="sportId"
                    [sportName]="sportName"
                    [homeAway]="'home'"
                    [seasonId]="seasonId"
                    [statistics]="statistics"
                ></app-lineups>
                <app-lineups
                    [home]="home"
                    [away]="away"
                    [header]="'web.missing_players'"
                    [players]="players.missingHome"
                    [sportId]="sportId"
                    [sportName]="sportName"
                    [homeAway]="'home'"
                    [seasonId]="seasonId"
                ></app-lineups>
            </div>
            <div class="tab-pane" id="away" role="tabpanel" aria-labelledby="away-tab">
                <ng-container *ngIf="hasStringPlayers">
                    <div class="match-info__table__head text-center ng-star-inserted">
                        {{ 'web.lineup_players' | translate }}
                    </div>
                    <div class="p-2">{{ participantAway?.lineup_string }}</div>
                    <div class="match-info__table__head text-center ng-star-inserted">
                        {{ 'web.substitution_players' | translate }}
                    </div>
                    <div class="p-2">{{ participantAway?.bench_string }}</div>
                </ng-container>
                <app-lineups
                    [home]="home"
                    [away]="away"
                    [header]="'web.lineup_players'"
                    [players]="players.away"
                    [sportId]="sportId"
                    [sportName]="sportName"
                    [homeAway]="'away'"
                    [seasonId]="seasonId"
                    [statistics]="statistics"
                ></app-lineups>
                <app-lineups
                    [home]="home"
                    [away]="away"
                    [header]="'web.substitution_players'"
                    [players]="subAway"
                    [sportId]="sportId"
                    [sportName]="sportName"
                    [homeAway]="'away'"
                    [seasonId]="seasonId"
                    [statistics]="statistics"
                ></app-lineups>
                <app-lineups
                    [home]="home"
                    [away]="away"
                    *ngIf="extendedStats"
                    [extendedStats]="true"
                    [header]="'web.extended_stats_players'"
                    [homeAway]="'home'"
                    [players]="allAway"
                    [sportId]="sportId"
                    [sportName]="sportName"
                    [seasonId]="seasonId"
                    [statistics]="statistics"
                ></app-lineups>
                <app-lineups
                    [home]="home"
                    [away]="away"
                    [header]="'web.missing_players'"
                    [players]="players.missingAway"
                    [sportId]="sportId"
                    [sportName]="sportName"
                    [homeAway]="'away'"
                    [seasonId]="seasonId"
                    [statistics]="statistics"
                ></app-lineups>
            </div>
        </div>
    </ng-container>
    <ng-template #elseBlock>
        <ul class="nav nav-pills bg-light px-2 app-league-table-container">
            <li class="nav-item" *ngFor="let playerT of playerTournament; trackBy: trackByIndex">
                <a
                    class="nav-link active app-cursor"
                    (click)="activePlayerT(playerT.id)"
                    [class.active]="playerT.id === playerSelectedT"
                    >{{ playerT.name }}</a
                >
            </li>
            <li *ngIf="playerTournament.length > 0">
                <a
                    class="nav-link active app-cursor"
                    (click)="activePlayerT(0)"
                    [class.active]="0 === playerSelectedT"
                    >{{ 'web.total' | translate }}</a
                >
            </li>
        </ul>
        <div class="d-flex table-scrollable">
            <div class="table-scrollable__fixed">
                <div class="table-scrollable__fixed-shadow"></div>
                <table class="table-scrollable__fixed__table" data-cy="app-table-left-data">
                    <thead>
                        <tr>
                            <th class="table-scrollable__table-th text-center app-cursor" colspan="3">
                                <i class="fas fa-table"></i>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            class="table-scrollable__table-row {{ positionCss[player.position] || '' }}"
                            *ngFor="let player of data; trackBy: trackByFn"
                        >
                            <td class="table-scrollable__table-td">
                                <div class="table-scrollable__table-place">
                                    <span class="span">
                                        <ng-container *ngIf="player.number != 0">{{ player.number }}</ng-container>
                                    </span>
                                </div>
                            </td>
                            <td class="table-scrollable__table-td text-center">
                                <img
                                    [attr.src]="player.flag"
                                    *ngIf="player.flag !== null"
                                    [attr.alt]=""
                                    height="16"
                                    class="mr-1"
                                />
                            </td>
                            <td class="table-scrollable__table-td">
                                <a
                                    [routerLink]="['/page/sport/player', sportName + '-' + sportId, player.id]"
                                    [queryParams]="{ season: seasonId }"
                                    class="text-reset table-scrollable-team"
                                    >{{ player.name }}
                                    <ng-container *ngIf="player.injury || player.missing_global">
                                        &nbsp;<i class="fas fa-plus liv-scratch"></i
                                    ></ng-container>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-scrollable__data flex-grow-1" tableScroll>
                <table class="table-scrollable__data__table" data-cy="players-squad" id="players-squad">
                    <thead>
                        <tr
                            id="players-squad-virtual"
                            class="players-squad-virtual"
                            style="position: relative; top: 0px"
                        >
                            <th class="table-scrollable__table-th table-scrollable__data__table-th">
                                {{ 'web.player_position' | translate }}
                            </th>
                            <th class="table-scrollable__table-th table-scrollable__data__table-th">
                                {{ 'web.player_age' | translate }}
                            </th>
                            <th
                                *ngIf="showMatchPlayed"
                                class="table-scrollable__table-th table-scrollable__data__table-th"
                                title="{{ 'web.matches_played' | translate }}"
                            >
                                <i class="fas fa-stopwatch"></i>
                            </th>
                            <th
                                *ngIf="showStats"
                                class="table-scrollable__table-th table-scrollable__data__table-th"
                                title="{{ 'web.goal' | translate }}"
                            >
                                <i class="fas fa-hockey-puck v-mid" *ngIf="isHockey(sportName); else other"></i>
                                <ng-template #other><i class="far fa-futbol v-mid"></i></ng-template>
                            </th>
                            <th
                                *ngIf="hasAssists"
                                class="table-scrollable__table-th table-scrollable__data__table-th"
                                title="{{ 'web.assists_for_team' | translate }}"
                            >
                                <strong>A</strong>
                            </th>
                            <ng-container *ngIf="hasCards">
                                <th
                                    class="table-scrollable__table-th table-scrollable__data__table-th"
                                    title="{{ 'web.yellow_card' | translate }}"
                                >
                                    <svg class="icon icon--12 icon--yellow" id="yellow-card">
                                        <use href="#icon-card-yellow" />
                                    </svg>
                                </th>
                                <th
                                    class="table-scrollable__table-th table-scrollable__data__table-th"
                                    title="{{ 'web.red_card' | translate }}"
                                >
                                    <svg class="icon icon--12 icon--danger" id="red-card">
                                        <use href="#icon-card-yellow" />
                                    </svg>
                                </th>
                            </ng-container>
                            <th
                                class="table-scrollable__table-th table-scrollable__data__table-th"
                                title="{{ 'web.player_missing' | translate }}"
                            >
                                &nbsp;
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            class="table-scrollable__table-row"
                            data-cy="players-squad-row"
                            *ngFor="let player of data; trackBy: trackByFn"
                        >
                            <td class="table-scrollable__table-td table-scrollable__data__table-td">
                                <ng-container *ngIf="player.position !== null; else noposition">{{
                                    'web.position_' + sportName + '_' + player.position | lowercase | translate
                                }}</ng-container>
                                <ng-template #noposition>
                                    <ng-container *ngIf="player.role == 'manager'; else elsenoposition">{{
                                        'web.coach' | translate
                                    }}</ng-container>
                                    <ng-template #elsenoposition></ng-template>
                                </ng-template>
                            </td>
                            <td class="table-scrollable__table-td table-scrollable__data__table-td">
                                {{ player.age ? player.age : '-' }}
                            </td>

                            <td
                                *ngIf="showMatchPlayed"
                                class="table-scrollable__table-td table-scrollable__data__table-td"
                            >
                                {{ playerData(player.statsTournament, 'matches_played') }}
                            </td>
                            <td *ngIf="showStats" class="table-scrollable__table-td table-scrollable__data__table-td">
                                {{ playerData(player.statsTournament, 'goals_scored') }}
                            </td>
                            <td *ngIf="hasAssists" class="table-scrollable__table-td table-scrollable__data__table-td">
                                {{ playerData(player.statsTournament, 'assists') }}
                            </td>
                            <ng-container *ngIf="hasCards">
                                <td class="table-scrollable__table-td table-scrollable__data__table-td">
                                    {{ playerData(player.statsTournament, 'yellow_cards') }}
                                </td>
                                <td class="table-scrollable__table-td table-scrollable__data__table-td">
                                    {{ playerData(player.statsTournament, 'red_cards') }}
                                </td>
                            </ng-container>

                            <td class="table-scrollable__table-td table-scrollable__data__table-td">
                                <div
                                    *ngIf="player.missing_reason"
                                    class="text-left app-word-wrap-important app-no-wrap"
                                >
                                    {{ player.missing_reason | translate }}
                                    <!--                                {{ 'web.missing-from' | translate }}-->
                                    <!--                                {{ player.missing_registered | livDate: 'dateShort' }} ({{-->
                                    <!--                                    player.missing_reason | translate-->
                                    <!--                                }})<span *ngIf="player.missing_expected_back"-->
                                    <!--                                    >. {{ 'web.expected-back' | translate }}-->
                                    <!--                                    {{ player.missing_expected_back | livDate: 'dateShort' }}</span-->
                                    <!--                                >-->
                                    <!--                                <span *ngIf="!player.missing_global"-->
                                    <!--                                    >, {{ 'web.missing-in-tournament' | translate }}-->
                                    <!--                                    {{ player.missing_subtournament }}</span-->
                                    <!--                                >-->
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-template>
</ng-container>
