<ng-container *ngIf="isMatchDetail">
    <div class="match-info__bets mt-2 mx-4" *ngIf="odds && odds[0] && odds[0].odd_types.length > 0">
        <div class="match-info__bets__logo">
            <span class="px-3 py-2"><img src="img/logos/{{ image }}" alt="" width="105" /></span>
        </div>
        <ul
            class="d-flex justify-content-around mt-2 tooltip-class"
            data-toggle="tooltip"
            data-placement="top"
            data-trigger="manual"
            id="desktop_oddTooltip_{{ odds[0].id }}"
            title="{{ 'web.bet_add_to_ticket' | translate }}"
        >
            <li
                class="app-bets"
                *ngFor="
                    let odd of odds[0].odd_types | orderOdds : getMarket(odds[0].market_id) | slice : 0 : 3;
                    trackBy: trackByIndex
                "
            >
                <span
                    (click)="oddLink('desktop', odds[0].partner_match_id, odds[0].partner_item_id, odd.partner_item_id)"
                    class="d-inline-block badge badge-primary px-2 bg-primary mr-1 app-bets-odd-text app-cursor"
                    >{{ getMarketColumn(odds[0].market_id, odd.id) }}</span
                >
                <a
                    [title]="partnerName + ' | ' + participanNames"
                    [attr.href]="href"
                    (click)="oddLink('desktop', odds[0].partner_match_id, odds[0].partner_item_id, odd.partner_item_id)"
                    target="_blank"
                    class="app-bets-odd text-reset app-cursor"
                >
                    <ng-container *ngIf="odd.rate !== '-'; else oddData">{{ odd.rate | number : '1.2' }}</ng-container>
                    <ng-template #oddData>-</ng-template>
                </a>
            </li>
        </ul>
        <ng-container *ngIf="client.has('pages', 'footerText')">
            <div class="app-white">{{ client.has('pages', 'footerText') }}</div>
        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="isMobilMatchlist && (isBeforeGame || client.has('bet', 'isOddsOnlyBeforeAndAfter'))">
    <div
        class="table-odds__cell table-odds__cell--odds tooltip-class"
        data-toggle="tooltip"
        data-placement="left"
        data-trigger="manual"
        id="mobile_oddTooltip_{{ odds[0].id }}"
        title="{{ 'web.bet_add_to_ticket' | translate }}"
        *ngIf="odds && odds.length > 0 && odds[0] && odds[0].odd_types.length > 0"
    >
        <div
            class="table-odds__cell--odds__item"
            [class.bg-primary]="false"
            [class.font-weight-bold]="false"
            (click)="
                goToPage(
                    isBeforeGame ? odds[0].bet_url : partnerHomeUrl(odds[0].partner_code_name),
                    odds[0].partner_match_id,
                    odds[0].partner_item_id,
                    odd.partner_item_id
                )
            "
            *ngFor="
                let odd of odds[0].odd_types | orderOdds : getMarket(odds[0].market_id) | slice : 0 : 3;
                trackBy: trackByIndex
            "
        >
            <ng-container *ngIf="odd.rate !== '-'; else oddData">{{ odd.rate | number : '1.2' }}</ng-container>
            <ng-template #oddData>-</ng-template>
        </div>
    </div>
</ng-container>
