<div class="app-total-statistics" aria-label="Total match statistics">
    <ng-container *ngIf="simpleView; else groupView">
        <ng-container *ngIf="tournamentName">
            <div class="text-center">
                <strong class="stats-season-header">{{ tournamentName }} </strong>
            </div>
        </ng-container>
        <ng-container *ngFor="let stat of actions; trackBy: trackByIndex">
            <div class="row mx-0 mt-3">
                <div class="col text-left font-weight-bold pl-2">
                    {{ valSimple(stat, 'home') }} {{ stat.prct ? '%' : '' }}
                </div>
                <div class="col text-center font-weight-bold">
                    <ng-container *ngIf="seasonStats; else classicStats">
                        {{ 'web.participant_stats_' + stat.name | translate }}
                    </ng-container>
                    <ng-template #classicStats>
                        {{ 'web.stats_' + stat.name | translate }}
                    </ng-template>
                </div>
                <div class="col text-right font-weight-bold pr-2">
                    {{ valSimple(stat, 'away') }} {{ stat.prct ? '%' : '' }}
                </div>
            </div>
            <div class="app-total-statistics__row d-flex">
                <div class="flex-grow-1">
                    <div
                        [@statsbar]
                        class="app-total-statistics__row__val app-total-statistics__row__val--home float-right"
                        [style.width.%]="stat.homePrct"
                    ></div>
                </div>
                <div class="flex-grow-1">
                    <div
                        [@statsbar]
                        class="app-total-statistics__row__val app-total-statistics__row__val--away"
                        [style.width.%]="stat.awayPrct"
                    ></div>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <ng-template #groupView>
        <ng-container *ngIf="tournamentName">
            <div class="text-center">
                <strong class="stats-season-header">{{ tournamentName }} </strong>
            </div>
        </ng-container>
        <ng-container *ngFor="let group of groupStats; trackBy: trackByIndex; index as i">
            <h6
                *ngIf="isAtLastOneStats(groupsData[group])"
                class="text-center text-uppercase match-summary__head dropdown-toggle"
                data-toggle="collapse"
                [attr.href]="'#collapseAction' + i"
                role="button"
                aria-expanded="false"
                [attr.aria-controls]="'collapseAction' + i"
            >
                {{ 'web.' + group | lowercase | translate }}
            </h6>

            <div class="collapse show mb-2" [attr.id]="'collapseAction' + i">
                <ng-container *ngFor="let stat of groupsData[group]; trackBy: trackByIndex">
                    <ng-container *ngIf="val(stat, 'name') !== '-'">
                        <div class="row mx-0 mt-3">
                            <div class="col text-left font-weight-bold pl-2">
                                {{ val(stat, 'home') }} {{ val(stat, 'prct') ? '%' : '' }}
                            </div>
                            <div class="col text-center font-weight-bold">
                                {{ 'web.stats_' + val(stat, 'name') | lowercase | translate }}
                            </div>
                            <div class="col text-right font-weight-bold pr-2">
                                {{ val(stat, 'away') }} {{ val(stat, 'prct') ? '%' : '' }}
                            </div>
                        </div>
                        <div class="app-total-statistics__row d-flex">
                            <div class="flex-grow-1">
                                <div
                                    [@statsbar]
                                    class="app-total-statistics__row__val app-total-statistics__row__val--home float-right"
                                    [style.width.%]="val(stat, 'homePrct')"
                                ></div>
                            </div>
                            <div class="flex-grow-1">
                                <div
                                    [@statsbar]
                                    class="app-total-statistics__row__val app-total-statistics__row__val--away"
                                    [style.width.%]="val(stat, 'awayPrct')"
                                ></div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </ng-template>
</div>
