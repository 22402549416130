import { Component, Input } from '@angular/core';


@Component({
    selector: 'app-cricket-wicket',
    templateUrl: './cricket-wickets.component.html',
})
export class CricketWicketsComponent {
    @Input()
    public wickets:Record<string, any>[];


    public constructor(


    ) {}


    public trackByFn(index: number): number {
        return index;
    }
}
