import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy } from '@angular/core';


import { SportConfigToken } from '@config/sport.config';

import { TranslateService } from '@ngx-translate/core';

import { OptaWidgetBase } from '../base.service';

declare const Opta: any;

@Component({
    selector: 'app-opta-chalkboard"',
    templateUrl: './chalkboard.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptaWidgetChalkboardComponent extends OptaWidgetBase implements AfterViewInit, OnDestroy {
    @Input()
    matchId: string | number | null | undefined;

    @Input()
    competitionId: string | number | null | undefined;

    @Input()
    seasonId: string | number | null | undefined;

      #headlineTrans = 'web.opta_chalkboard';
      private transHeadline = '';


      public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>,
          public translate: TranslateService) {
          super();
      }

      public ngAfterViewInit(): void {
          this.transHeadline = this.translate.instant(this.#headlineTrans);
          setTimeout(() => {
              const element = document.querySelector('#opta-chalkboard') as HTMLElement;

              if (element) {
                  element.innerHTML = this.getHtml();
                  const optaWidgetTags = document.querySelector('opta-widget[load="false"]');

                  if (optaWidgetTags) {
                      optaWidgetTags.removeAttribute('load');
                      Opta.start();
                  }
              }
          });
      }

      ngOnDestroy(): void {
          if (Opta.widgets.ref_widget_chalkboard) {
              Opta.widgets.ref_widget_chalkboard.destroy(true);
          }
      }

      getHtml(): string {
          return `
          <opta-widget widget="chalkboard"    widget_id="ref_widget_chalkboard" 
          competition="${this.competitionId}" season="${this.seasonId}" match="${this.matchId}"   load="false" 
           preselected_player="all" template="normal" live="true" orientation="horizontal" side="both" 
           one_direction="false" show_match_header="false" show_halftime_score="false" show_crests="false"
            show_team_formation="false" show_competition_name="false" competition_naming="full" 
            show_date="true" date_format="dddd D MMMM YYYY" narrow_date_format="DD/MM/YY" show_team_sheets="false" 
            show_subs="true" show_position="true" show_events="true"  show_images="false"
             show_team_names="true" show_area_selector="false" show_timecontrols="true" 
             show_tooltips="true"
             show_timecontrols_buttons="true" show_timecontrols_bar="true" show_timecontrols_events="true"
              team_naming="full" player_naming="full" team_link="/team/XXXX" show_logo="false" 
              title="${this.transHeadline}"
               show_title="true" breakpoints="400, 700" sport="football"></opta-widget>        
          `;
      }
}
