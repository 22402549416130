import { LangService } from '@services/lang.service';
import { Component, Input, ViewChild, NgZone, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import WIDGET_CONFIG from '@widgets/config/widget.config';
import { toPairs } from 'lodash-es';


import { DeviceDetectorService } from 'ngx-device-detector';
import { SportService } from '@services/sport.service';
import { UtilsService } from '@services/utils.service';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { SwiperOptions, Navigation } from 'swiper';


// install Swiper modules
SwiperCore.use([Navigation]);

const SLIDES = 3;
@Component({
    selector: 'app-bets-match-stats',
    templateUrl: './match-betting-stats.component.html',
})
export class MatchBettingStatsComponent implements AfterViewInit {
    @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

    @Input()
    private sportName: string;

    @Input()
    public tracker: number | undefined | null;

    @Input()
    public periodsCount: number;

    @Input()
    public fullScreen: boolean = false;

    public activeIndex: number = 0;

    public arrowActive: boolean = true;

    private frameHeight: number | null = null;

    public swiperConfig: SwiperOptions = {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        direction: 'horizontal',
        watchOverflow: false,
        slidesPerView: SLIDES,
        loop: false,
    };


    public constructor(
        private device: DeviceDetectorService,
        public lang: LangService,
        private sport: SportService,
        private ngZone: NgZone,
        public ref: ChangeDetectorRef,
    ) {

    }

    // public ngOnInit(): void {
    //     // this.active(0);
    // }

    ngAfterViewInit():void {
        this.active(0);
    }

    public active(i: number): void {
        this.activeIndex = i;
        // eslint-disable-next-line max-len
        const path = `/widgets/w/${this.path};notitle=1;numberOfSets=${this.periodsCount};id=${this.tracker}?lang=${this.lang.getLangSnapshot()}`;
        const ifr = document.getElementById('betting-iframe') as HTMLIFrameElement;
        if (ifr) {
            ifr.contentWindow!.location.replace(path);
        }
    }

    onChange():void {
        this.sport.onBetStatsSwipe.emit(true);
    }

    end(): void {
        this.arrowActive = false;
    }

    public nextSlide(): void {
        this.swiper?.swiperRef.slideNext();
    }

    public onIfameLoad(): void {
        this.frameHeight = null;
        UtilsService.requestTimeout(() => {
            this.frameHeight = null;
            this.ref.markForCheck();
        }, 1000);
    }

    toEdge(): void {
        this.arrowActive = false;
    }

    get isActive(): boolean {
        return this.arrowActive && this.config.length > SLIDES;
    }

    get isFull(): boolean {
        return this.fullScreen && !this.device.isMobile();
    }

    get iheight(): number {
        const iframe: HTMLIFrameElement = document.getElementById('betting-iframe') as HTMLIFrameElement;

        if (!iframe) {
            return 0;
        }

        if (this.frameHeight === null) {
            this.frameHeight =
                iframe.contentWindow?.document.querySelector('.widget-container__object')?.scrollHeight ?? null;

            if (this.frameHeight) {
                this.frameHeight += 10;
            } else {
                this.frameHeight = null;
            }
        }

        return this.frameHeight ?? (this.isFull ? this.activeItem![1].heightF : this.activeItem![1].height);
    }

    get activeItem(): [string, Record<string, any>] | undefined {
        return this.config[this.activeIndex];
    }

    get path(): string {
        return this.activeItem?.[1].path;
    }

    get config(): [string, Record<string, any>][] {
        return toPairs(WIDGET_CONFIG).filter(
            ([, item]) => item.showInPage && item.availSports.includes(this.sportName),
        );
    }

    public trackByIndex(index: number): number {
        return index;
    }
}
