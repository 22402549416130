/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, Input, OnDestroy, EventEmitter, Output, OnChanges } from '@angular/core';
import { Store } from '@ngxs/store';
import { PartnerService } from 'src/app/services/partner.service';

import { cloneDeep } from 'lodash-es';

import $ from '@shared/jquery';

import BetsBaseComponent from '../bets.base.component';

import { BetsInterface, BetsOddTypes } from '@/interfaces/bets-market.interface';
import { WindowRefService } from '@/shared/window-ref';
import { ClientService } from '@/services/client.service';


enum ViewType {
    MatchDetail = 'match-detail',
    MobilMatchList = 'mobil-match-list',
}

@Component({
    selector: 'app-bets-detail',
    templateUrl: './detail.component.html',
})
export class DetailComponent extends BetsBaseComponent implements OnChanges, OnDestroy {
    @Input()
    get odds(): BetsInterface[] {
        return this.oddsVal;
    }

    set odds(odds: BetsInterface[]) {
        this.oddsVal = odds.map((bet: BetsInterface) => {
            const nbet = cloneDeep(bet);
            nbet.odd_types = bet.odd_types.map((type: BetsOddTypes) => {
                if ((typeof type.rate === 'number' && type.rate <= 1) || !type.rate || type.rate === null) {
                    type.rate = '-';
                }
                return type;
            });
            return nbet;
        });
    }


    private oddsVal: BetsInterface[];

    @Input()
    public viewType: ViewType = ViewType.MatchDetail;

    @Input()
    public isBeforeGame: boolean;

    @Input()
    public participanNames: string = '';

    @Output()
        onLoad = new EventEmitter<BetsInterface>();

    public image: string;

    public constructor(
        protected store: Store,
        private win: WindowRefService,
        public partner: PartnerService,
        public client: ClientService,

    ) {
        super(store);
    }


    public ngOnChanges(): void {
        if (this.odds?.length > 0 && this.odds[0]!.partner_code_name) {
            this.image = `${this.odds[0]!.partner_code_name}.png`;
        }

        if (this.odds) {
            this.onLoad.emit(this.odds[0]);
        }
    }

    public ngOnDestroy(): void {
        $('.tooltip-class').tooltip('hide');
    }


    public get href(): string | null {
        if (this.client.has('bet', 'winApi')) {
            return null;
        }

        return this.isBeforeGame ? this.odds[0]!.bet_url : this.partnerHomeUrl(this.odds[0]!.partner_code_name);
    }

    public oddLink(type: string, matchId: number, betId: number, oddId: number): void {
        const target = window.opener || window.top;
        target?.postMessage({ matchId, betId, oddId }, '*');

        if (this.client.has('bet', 'displayConfirm')) {
            $(`#${type}_oddTooltip_${this.odds[0]!.id}`).tooltip('show');
            setTimeout(() => {
                $('.tooltip-class').tooltip('hide');
            }, 2000);
        }

        this.win.nativeWindow.dataLayer.push({
            event: 'click_odd',
            type,
            matchId,
        });
    }

    public goToPage(url: string, matchId: number, betId: number, oddId: number): void {
        if (this.href) {
            this.win.nativeWindow.open(url, '_blank');
        } else {
            this.oddLink('mobile', matchId, betId, oddId);
        }
    }

    public partnerHomeUrl(codeName: string): string {
        const Partner = this.partner.getPartner(codeName);
        return Partner === null ? '' : this.partner.getPartner(codeName)!.domain;
    }

    public get partnerName(): string {
        return (this.odds.length > 0 && this.odds[0] && this.odds[0].partner_code_name) || '';
    }

    public get isMatchDetail(): boolean {
        return this.viewType === ViewType.MatchDetail;
    }

    public get isMobilMatchlist(): boolean {
        return this.viewType === ViewType.MobilMatchList;
    }
}
