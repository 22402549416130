import { BaseComponent } from '@components/base.component';
import { Component, Input, OnInit } from '@angular/core';
import { DartsMapFce } from '@interfaces/match-data.interface';
import { ClientService } from '@services/client.service';


@Component({
    selector: 'app-timeline-darts',
    templateUrl: './darts-timeline.component.html',
})
export class DartsTimelineComponent extends BaseComponent implements OnInit {
    @Input() data: DartsMapFce;
    @Input() sportName: string;
    @Input() sportId: number;

    constructor(private client: ClientService) {
        super();
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngOnInit(): void {
        // console.log('Darts data', this.data);
    }

    get color(): string {
        return this.client.has('colors', 'icons') || 'black';
    }
}
