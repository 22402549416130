<!-- BATTING-->
<div class="match-info__table small pb-2 text-center mt-3" *ngIf="battingTeam">
    <div class="match-info__table__row match-info__table__head">
        <div class="match-info__table__cell text-left">Batsman</div>
        <div class="match-info__table__cell text-left">Status</div>

        <div
            class="match-info__table__cell text-center"
            data-placement="top"
            data-toggle="tooltip"
            title="{{ 'web.cricket_title_runs' | translate }}"
        >
            {{ 'web.cricket_runs' | translate }}
        </div>

        <div
            class="match-info__table__cell text-center"
            data-placement="top"
            data-toggle="tooltip"
            title=" {{ 'web.cricket_title_balls' | translate }}"
        >
            {{ 'web.cricket_balls' | translate }}
        </div>
        <div
            class="match-info__table__cell text-center"
            data-placement="top"
            data-toggle="tooltip"
            title="{{ 'web.cricket_title_minutes' | translate }}"
        >
            {{ 'web.cricket_minutes' | translate }}
        </div>
        <div
            class="match-info__table__cell text-center"
            data-placement="top"
            data-toggle="tooltip"
            title="{{ 'web.cricket_title_fours' | translate }}"
        >
            {{ 'web.cricket_fours' | translate }}
        </div>
        <div
            class="match-info__table__cell text-center"
            data-placement="top"
            data-toggle="tooltip"
            title="{{ 'web.cricket_title_sixes' | translate }}"
        >
            {{ 'web.cricket_sixes' | translate }}
        </div>
        <div
            class="match-info__table__cell text-center"
            data-placement="top"
            data-toggle="tooltip"
            title="{{ 'web.cricket_title_batting_strike_rate' | translate }}"
        >
            {{ 'web.cricket_batting_strike_rate' | translate }}
        </div>
    </div>

    <div
        class="match-info__table__row app-lineup-row player-stats-row"
        *ngFor="let player of battingTeam.statistics.batting.players; trackBy: trackByFn"
    >
        <div
            class="match-info__table__cell match-info__table__cell--name text-left player-stats-row__player-name cricket-innings-player-name"
        >
            {{ player.name }}
        </div>
        <div class="match-info__table__cell match-info__table__cell--name text-left player-stats-row__player-name">
            <ng-container *ngIf="!player.statistics.dismissal && hasDismissalData">
                {{ 'web.cricket_not_out' | translate }}
            </ng-container>
            <ng-container *ngIf="!hasDismissalData"> - </ng-container>
            <ng-container *ngIf="player.statistics.dismissal">
                <small *ngIf="player.statistics?.dismissal?.type">
                    ({{ 'web.cricket_dismissal_type_' + player.statistics.dismissal.type | translate }})</small
                >
                c: {{ player.statistics.dismissal.fielder_name }} b:
                {{ player.statistics.dismissal.bowler_name }}</ng-container
            >
        </div>

        <div class="match-info__table__cell match-info__table__cell--number text-center">
            {{ player.statistics.runs }}
        </div>
        <div class="match-info__table__cell match-info__table__cell--number text-center">
            {{ player.statistics.balls_faced }}
        </div>
        <div class="match-info__table__cell match-info__table__cell--number text-center">
            {{ player.statistics.minutes_at_crease }}
        </div>
        <div class="match-info__table__cell match-info__table__cell--number text-center">
            {{ player.statistics.fours }}
        </div>
        <div class="match-info__table__cell match-info__table__cell--number text-center">
            {{ player.statistics.sixes }}
        </div>
        <div class="match-info__table__cell match-info__table__cell--number text-center">
            {{ player.statistics.strike_rate }}
        </div>
    </div>
</div>
<div class="match-info__table small pb-2 text-center" *ngIf="battingTeam">
    <!-- Extras-->
    <div class="match-info__table__row app-lineup-row player-stats-row border-top">
        <div class="match-info__table__cell match-info__table__cell--name text-left player-stats-row__player-name">
            {{ 'web.cricket_extras' | translate }}
        </div>
        <div class="match-info__table__cell match-info__table__cell--name text-left player-stats-row__player-name">
            <ng-container *ngIf="bowlingTeam && bowlingTeam.statistics?.bowling?.wides"
                >W:{{ bowlingTeam.statistics?.bowling?.wides }}</ng-container
            >
            <ng-container *ngIf="bowlingTeam && bowlingTeam.statistics?.bowling?.byes"
                >&nbsp;B:{{ bowlingTeam.statistics?.bowling?.byes }}</ng-container
            >
            <ng-container *ngIf="bowlingTeam && bowlingTeam.statistics?.bowling?.no_balls"
                >&nbsp;NB:{{ bowlingTeam.statistics?.bowling?.no_balls }}</ng-container
            >
            <ng-container *ngIf="bowlingTeam && bowlingTeam.statistics?.bowling?.leg_byes"
                >&nbsp;LB:{{ bowlingTeam.statistics?.bowling?.leg_byes }}</ng-container
            >
        </div>
        <div class="match-info__table__cell match-info__table__cell--number text-center">&nbsp;</div>
        <div class="match-info__table__cell match-info__table__cell--number text-center">&nbsp;</div>
        <div class="match-info__table__cell match-info__table__cell--number text-center">&nbsp;</div>
        <div class="match-info__table__cell match-info__table__cell--number text-center">&nbsp;</div>
        <div class="match-info__table__cell match-info__table__cell--number text-center">&nbsp;</div>
        <div class="match-info__table__cell match-info__table__cell--number text-center">&nbsp;</div>
    </div>

    <!-- Total-->
    <div class="match-info__table__row app-lineup-row player-stats-row app-no-wrap">
        <div class="match-info__table__cell match-info__table__cell--name text-left player-stats-row__player-name">
            <strong>{{ 'web.cricket_total' | translate }}</strong>
        </div>
        <div class="match-info__table__cell match-info__table__cell--name text-left player-stats-row__player-name">
            <strong
                >{{ battingTeam.statistics.batting.runs }}/{{ battingTeam.statistics.batting.wickets_lost }} ({{
                    inning.overs_completed
                }})</strong
            >
        </div>
        <div
            *ngIf="battingTeam.statistics.batting.run_rate"
            class="match-info__table__cell match-info__table__cell--name text-left player-stats-row__player-name"
        >
            <strong>{{ battingTeam.statistics.batting.run_rate }} {{ 'web.cricket_runs_per_over' | translate }}</strong>
        </div>
    </div>
</div>

<!-- BOWLING-->
<div class="match-info__table small pb-2 text-center mt-3" *ngIf="bowlingTeam">
    <div class="match-info__table__row match-info__table__head">
        <div class="match-info__table__cell text-left">Bowler</div>

        <div
            class="match-info__table__cell text-center"
            data-placement="top"
            data-toggle="tooltip"
            title="{{ 'web.cricket_title_overs' | translate }}"
        >
            {{ 'web.cricket_overs' | translate }}
        </div>

        <div
            class="match-info__table__cell text-center"
            data-placement="top"
            data-toggle="tooltip"
            title="{{ 'web.cricket_title_maidens' | translate }}"
        >
            {{ 'web.cricket_maidens' | translate }}
        </div>
        <div
            class="match-info__table__cell text-center"
            data-placement="top"
            data-toggle="tooltip"
            title="{{ 'web.cricket_title_conceded_runs' | translate }}"
        >
            {{ 'web.cricket_conceded_runs' | translate }}
        </div>
        <div
            class="match-info__table__cell text-center"
            data-placement="top"
            data-toggle="tooltip"
            title="{{ 'web.cricket_title_wickets' | translate }}"
        >
            {{ 'web.cricket_wickets' | translate }}
        </div>
        <div
            class="match-info__table__cell text-center"
            data-placement="top"
            data-toggle="tooltip"
            title="{{ 'web.cricket_title_no_ball' | translate }}"
        >
            {{ 'web.cricket_no_ball' | translate }}
        </div>
        <div
            class="match-info__table__cell text-center"
            data-placement="top"
            data-toggle="tooltip"
            title="{{ 'web.cricket_title_wide_ball' | translate }}"
        >
            {{ 'web.cricket_wide_ball' | translate }}
        </div>
        <div
            class="match-info__table__cell text-center"
            data-placement="top"
            data-toggle="tooltip"
            title="{{ 'web.cricket_title_economy_rate' | translate }}"
        >
            {{ 'web.cricket_economy_rate' | translate }}
        </div>
    </div>

    <div
        class="match-info__table__row app-lineup-row player-stats-row"
        *ngFor="let player of bowlingTeam.statistics.bowling.players; trackBy: trackByFn"
    >
        <div
            class="match-info__table__cell match-info__table__cell--name text-left player-stats-row__player-name cricket-innings-player-name"
        >
            {{ player.name }}
        </div>

        <div class="match-info__table__cell match-info__table__cell--number text-center">
            {{ player.statistics.overs_bowled }}
        </div>
        <div class="match-info__table__cell match-info__table__cell--number text-center">
            {{ player.statistics.maidens }}
        </div>
        <div class="match-info__table__cell match-info__table__cell--number text-center">
            {{ player.statistics.conceded_runs }}
        </div>
        <div class="match-info__table__cell match-info__table__cell--number text-center">
            {{ player.statistics.wickets }}
        </div>
        <div class="match-info__table__cell match-info__table__cell--number text-center">
            {{ player.statistics.no_balls }}
        </div>
        <div class="match-info__table__cell match-info__table__cell--number text-center">
            {{ player.statistics.wides }}
        </div>
        <div class="match-info__table__cell match-info__table__cell--number text-center">
            {{ player.statistics.economy_rate }}
        </div>
    </div>
</div>

<!-- PARTNERSHIP -->
<div
    class="match-info__table small pb-2 text-center mt-3"
    *ngIf="battingTeam && battingTeam.statistics?.batting?.partnerships"
>
    <div class="match-info__table__row match-info__table__head">
        <div class="match-info__table__cell text-left">Partnership</div>

        <div
            class="match-info__table__cell text-center"
            data-placement="top"
            data-toggle="tooltip"
            title="{{ 'web.cricket_title_runs' | translate }}"
        >
            {{ 'web.cricket_runs' | translate }}
        </div>
        <div
            class="match-info__table__cell text-center"
            data-placement="top"
            data-toggle="tooltip"
            title="{{ 'web.cricket_title_minutes_at_crease' | translate }}"
        >
            {{ 'web.cricket_minutes_at_crease' | translate }}
        </div>

        <div
            class="match-info__table__cell text-center"
            data-placement="top"
            data-toggle="tooltip"
            title="{{ 'web.cricket_title_balls_faced' | translate }}"
        >
            {{ 'web.cricket_balls_faced' | translate }}
        </div>
    </div>

    <div
        class="match-info__table__row app-lineup-row player-stats-row"
        *ngFor="let player of battingTeam.statistics.batting.partnerships; trackBy: trackByFn"
    >
        <div
            class="match-info__table__cell match-info__table__cell--name text-left player-stats-row__player-name cricket-innings-player-name"
        >
            {{ player.players[0].name }} ({{ player.players[0].runs }} - {{ player.players[0].balls_faced }}) /
            {{ player.players[1].name }} ({{ player.players[1].runs }} - {{ player.players[1].balls_faced }})
        </div>

        <div class="match-info__table__cell match-info__table__cell--number text-center">
            {{ player.runs }}
        </div>
        <div class="match-info__table__cell match-info__table__cell--number text-center">
            {{ player.minutes_at_crease }}
        </div>
        <div class="match-info__table__cell match-info__table__cell--number text-center">
            {{ player.balls_faced }}
        </div>
    </div>
</div>
