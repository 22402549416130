import { AfterViewChecked, Component, Input } from '@angular/core';

import $ from '@shared/jquery';

@Component({
    selector: 'app-cricket-player-statistics',
    templateUrl: './cricket-player-stats.component.html',
})
export class CricketPlayerStatsComponent implements AfterViewChecked {
    @Input()
    public inning:Record<string, any>;


    public constructor(


    ) {}


    public ngAfterViewChecked(): void {
        $('[data-toggle="tooltip"]').tooltip();
    }

    get battingTeam(): Record<string, any> | false {
        if (!('batting_team' in this.inning)) {
            return false;
        }

        const teamId = this.inning.batting_team;

        const team = this.inning.teams.find((t: Record<string, any>) => t.id === teamId);

        if (!team) {
            return false;
        }

        return team;
    }

    get bowlingTeam(): Record<string, any> | false {
        if (!('bowling_team' in this.inning)) {
            return false;
        }

        const teamId = this.inning.bowling_team;

        const team = this.inning.teams.find((t: Record<string, any>) => t.id === teamId);

        if (!team) {
            return false;
        }

        return team;
    }

    get hasDismissalData(): boolean {
        if (this.battingTeam && this.battingTeam?.statistics?.batting?.players) {
            const status = this.battingTeam.statistics.batting.players.some(
                (v: Record<string, any>) => 'dismissal' in v.statistics,
            );

            return status;
        }

        return false;
    }

    public trackByFn(index: number): number {
        return index;
    }
}
