/* eslint-disable @typescript-eslint/naming-convention */
import { BaseComponent } from '@components/base.component';
import { Component, Input, OnInit } from '@angular/core';
import { CodeState, TennisMapFce } from '@interfaces/match-data.interface';
import { toPairs, last } from 'lodash-es';


@Component({
    selector: 'app-timeline-tennis',
    templateUrl: './tennis-timeline.component.html',
})
export class TennisTimelineComponent extends BaseComponent implements OnInit {
    @Input() data: TennisMapFce;
    @Input() sportName: string;
    @Input() sportId: number;
    @Input() codeState?: CodeState | null;
    @Input() isLive: boolean;

    private stateTrans: Record<string, string> = {
        '1st_set': 'period_1',
        '2nd_set': 'period_2',
        '3rd_set': 'period_3',
        '4th_set': 'period_4',
        '5th_set': 'period_5',
    };

    constructor() {
        super();
    }


    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngOnInit(): void {

    }

    get actions(): [string, boolean | Record<string, any>][] {
        return toPairs(this.data);
    }

    public isClose(period: string): boolean {
        return this.isLive && this.stateTrans[period] !== this.codeState;
    }

    public mbPoint(action:Record<string, any>[]): string {
        return last(action)!.point.some((point:Record<string, any>) => 'matchEnded' in point) ? 'MB' : 'SB';
    }
}
