<ng-container *ngIf="!onlyScreenshot; else screenshot">
    <iframe
        width="100%"
        height="400"
        frameborder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowfullscreen="true"
        scrolling="no"
        [src]="url | safe"
    >
        <a [href]="url | safe">{{ 'web.stream_download' | translate }}</a>
    </iframe>
</ng-container>
<ng-template #screenshot>
    <div class="youtube-screenshot">
        <a [href]="urlBlank | safe" target="_blank">
            <div class="youtube-play app-cursor"></div>
            <img
                [src]="imagePath + '/' + id + '.png'"
                alt="{{ 'web.youtube_page' | translate }}"
                class="responsive-img"
                decoding="async"
                width="100%"
                height="400"
                srcset="
                                    {{ imagePath + '-responsive/' }}{{ id }}-small.png   600w,
                                    {{ imagePath + '-responsive/' }}{{ id }}-medium.png  1024w,
                                    {{ imagePath + '-responsive/' }}{{ id }}-large.png  1600w
                                "
                sizes="(max-width: 576px) 280px, calc(100vw - 2rem)"
        /></a>
    </div>
</ng-template>
