import { Channel } from '@interfaces/match-data.interface';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';


@Component({
    selector: 'app-channels',
    templateUrl: './channel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelComponent {
    @Input()
    set channels(value: Channel[] | undefined) {
        this.dataChannels = value;
    }

    get channels(): Channel[] | undefined {
        // other logic
        return this.dataChannels?.map((data: Channel) => {
            const indexOf = data.name.indexOf('-');

            if (indexOf !== -1) {
                data.name = data.name.substring(0, indexOf);
            }
            return data;
        });
    }


    public dataChannels: Channel[] | undefined;


    public constructor() {

    }


    public trackByFn(index: any): any {
        return index;
    }
}
