<div class="tab-content match-summary live-timeline">
    <div class="py-2 pl-2">
        <span class="break-ball">BB</span> {{ 'web.break_ball' | translate }} <span class="ace">AC</span>
        {{ 'web.ace' | translate }}
        <!-- <span class="second-service">SS</span> {{ 'web.second_service' | translate }} -->
        <span class="double-fault">DF</span> {{ 'web.double_fault' | translate }} <span class="set-ball">SB</span>
        {{ 'web.set_ball' | translate }} <span class="match-ball">MB</span> {{ 'web.match_ball' | translate }}
    </div>
    <div
        *ngFor="let val of actions; trackBy: trackByIndex"
        class="tab-pane show active"
        id="tab-match-summary-1-content"
        role="tabpanel"
        aria-labelledby="tab-match-summary-1"
    >
        <h6
            class="text-center text-uppercase match-summary__head dropdown-toggle"
            data-toggle="collapse"
            [attr.href]="'#collapseAction' + val[0]"
            role="button"
            aria-expanded="false"
            [attr.aria-controls]="'collapseAction' + val[0]"
            [class.collapsed]="isClose(val[0])"
        >
            {{ 'web.' + sportName + '_' + val[0] | lowercase | translate }}
        </h6>
        <div [attr.id]="'collapseAction' + val[0]" [class.show]="!isClose(val[0])" class="collapse show">
            <ng-container *ngFor="let action of val[1]; trackBy: trackByIndex; last as isLastMain">
                <div class="row no-gutters border-top py-1 px-3 small">
                    <div class="col-5 align-self-center font-weight-bold font-1x text-right">
                        <ng-container *ngIf="!action.score?.tie_break">
                            <div
                                *ngIf="
                                    action.score?.server === 'home' &&
                                    (action.score?.result === 'receiver_won' || action.score?.result === 'double_fault')
                                "
                                class="tennis-set-match"
                            >
                                {{ 'web.lost_service' | translate }}
                            </div>
                            <span *ngIf="action.score?.server === 'home'" class="serve serve--tennis-timeline">0</span>
                        </ng-container>
                        &nbsp;
                    </div>
                    <div class="col-2 align-self-center font-weight-bold text-center font-1-1x">
                        <span [class.emph-text-node]="action.score?.isHomeWinner">{{ action.score?.home_score }}</span>
                        -
                        <span [class.emph-text-node]="!action.score?.isHomeWinner">{{ action.score?.away_score }}</span>
                    </div>
                    <div class="col-5 align-self-center font-weight-bold font-1x text-left">
                        <ng-container *ngIf="!action.score?.tie_break">
                            <span *ngIf="action.score?.server === 'away'" class="serve serve--tennis-timeline">0</span>
                            <div
                                *ngIf="
                                    action.score?.server === 'away' &&
                                    (action.score?.result === 'receiver_won' || action.score?.result === 'double_fault')
                                "
                                class="tennis-set-match"
                            >
                                {{ 'web.lost_service' | translate }}
                            </div>
                        </ng-container>
                        &nbsp;
                    </div>
                </div>
                <div class="row no-gutters py-1 px-3 small">
                    <div class="col-12 align-self-center text-center font-0-9x">
                        <ng-container *ngFor="let point of action.point; trackBy: trackByIndex; last as isLast">
                            <ng-container *ngIf="!point.matchEnded">
                                {{ point.home_score === 50 ? 'A' : point.home_score }}:{{
                                    point.away_score === 50 ? 'A' : point.away_score
                                }}

                                <span
                                    *ngIf="false && point.first_serve_fault && point.result !== 'double_fault'"
                                    class="second-service"
                                    title="{{ 'web.second_service' | translate }}"
                                    >SS</span
                                >
                                <span *ngIf="point.isBB" class="break-ball" title="{{ 'web.break_ball' | translate }}">
                                    BB
                                </span>
                                <span
                                    *ngIf="point.result === 'double_fault'"
                                    class="double-fault"
                                    title="{{ 'web.double_fault' | translate }}"
                                    >DF</span
                                >
                                <span *ngIf="point.result === 'ace'" class="ace" title="{{ 'web.ace' | translate }}"
                                    >AC</span
                                >

                                <span
                                    *ngIf="point.isSB && !point.isMB"
                                    class="set-ball"
                                    title="{{ 'web.set_ball' | translate }}"
                                    >{{ mbPoint(val[1]) }}</span
                                >
                                <span *ngIf="point.isMB" class="match-ball" title="{{ 'web.match_ball' | translate }}"
                                    >MB</span
                                >
                                <ng-container *ngIf="!isLast">,</ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <!-- <div *ngIf="data.match_suspended" class="row no-gutters border-top py-1 px-3 small">
        <div class="col-12 align-self-center font-weight-bold font-1x text-center">
            <div class="tennis-set-match text-center">{{ 'web.match_suspended' | translate }}</div>
        </div>
    </div> -->
</div>
