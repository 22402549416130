<div class="box-placeholder">
  <div class="px-4 pt-4 pb-1">
    <span class="category text link"></span>
  </div>

  <div class="excerpt px-4 pb-4">
    <div class="text line"></div>
    <div class="text line"></div>
    <div class="text line"></div>
    <div class="text line"></div>
    <div class="text line"></div>
    <div class="text line"></div>
    <div class="text line"></div>
    <div class="text line"></div>
    <div class="text"></div>
  </div>
</div>
