/**
 *  Match actions component
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { Component, OnInit, Input } from '@angular/core';
import { trigger } from '@angular/animations';
import SPORT_CONFIG from 'src/app/config/sport.config';

import { MatchActions as MatchActionsInterface, MatchActionScoreItem, Periods } from '@interfaces/match-data.interface';
import { MATCH_ACTION_ANIMATION } from '@shared/animations/match-actions.animation';

@Component({
    selector: 'app-match-actions',
    templateUrl: './match-actions.component.html',
    animations: [trigger('newLine', MATCH_ACTION_ANIMATION)],
})
export class MatchActionsComponent implements OnInit {
    @Input()
    public actions: MatchActionsInterface[] | null = null;

    @Input()
    public sportName: string;

    public constructor() {}

    public ngOnInit(): void {}

    public isBasketball(): boolean {
        return this.sportName === SPORT_CONFIG.codeNames.basketball;
    }

    public showMinute(action: MatchActionScoreItem): boolean {
        return action.state !== Periods.PENALTIES && action.state !== Periods.AP;
    }

    public trackByFn(index: number, item: Record<string, any>): any {
        return item.key;
    }

    public trackByFn2(index: any): any {
        return index;
    }
}
