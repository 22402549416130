/**
 *  Match actions component
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { Component, OnInit, Input } from '@angular/core';
import { trigger } from '@angular/animations';
import { MatchActionsScore } from '@interfaces/match-data.interface';
import { MATCH_ACTION_ANIMATION } from '@shared/animations/match-actions.animation';
import { ClientService } from '@services/client.service';

@Component({
    selector: 'app-match-actions-score',
    templateUrl: './match-actions-score.component.html',
    animations: [trigger('newLine', MATCH_ACTION_ANIMATION)],
})
export class MatchActionsScoreComponent implements OnInit {
    @Input()
    public actions: MatchActionsScore[] | null = null;

    @Input()
    public sportName: string;

    public constructor(private client: ClientService) {}

    public ngOnInit(): void {
    }

    public score(actualScore: string, team: 'home' | 'away'): string {
        const [home, away] = actualScore.split(':', 2);

        if (team === 'home') {
            return `<strong class="emph-text-node">${home}</strong>:${away}`;
        }

        return `${home}:<strong class="emph-text-node">${away}</strong>`;
    }

    get color(): string {
        return this.client.has('colors', 'icons') || 'black';
    }

    public trackByFn(index: number, item: Record<string, any>): any {
        return item.key;
    }

    public trackByFn2(index: any): any {
        return index;
    }
}
