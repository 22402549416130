import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import $ from 'src/app/shared/jquery';

import { TournamentService } from 'src/app/services/tournament.service';

import * as _ from 'underscore';
import SPORT_CONFIG from '@config/sport.config';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TableSubsDataType, TableSubs, MatchType } from '@interfaces/league-table.interface';

@Component({
    selector: 'app-league-table-container',
    templateUrl: './table-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeagueTableContainerComponent {
    @Input()
    public subTournamentId: number;

    @Input()
    public seasonId: number | any = '';

    @Input()
    public participantHome: number;

    @Input()
    public participantAway: number;

    @Input()
    public i: number;

    @Input()
    public sportName: string;

    @Input()
    public showFooterInfo = false;

    @Input()
    public sportId: number;

    @Input()
    public tableOb: Record<string, any>;

    @Input()
    public isLive: boolean = false;

    public playersError: boolean = false;

    public subNav: 'total' | 'home' | 'away' = 'total';

    public sportConfig: typeof SPORT_CONFIG;

    public subType: TableSubs | boolean = 'ha';
    public subTypeData: TableSubsDataType = 'overal';

    // eslint-disable-next-line no-useless-constructor
    public constructor(
        private tournamnet: TournamentService,
        private deviceService: DeviceDetectorService,
        private ref: ChangeDetectorRef,
    ) {
        this.sportConfig = SPORT_CONFIG;
    }


    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public trackByIndex(index: any): any {
        return index;
    }

    public subActive(type: TableSubs | boolean, dataType: TableSubsDataType = 'overal'): void {
        $(`#subNav_${this.i} a`)
            .removeClass('active')
            .first()
            .addClass('active');
        this.subNav = 'total';
        this.subType = type;
        this.subTypeData = dataType;

        $('[data-toggle="tooltip"]').tooltip();
    }

    public get isNoHalf(): boolean {
        return this.tableOb.data.total.some((v: Record<string, any>) => v.matches.some(
            (m: Record<string, any>) => m.score_period_1 === null && m.type !== MatchType.UPCOMING));
    }

    public get form(): boolean {
        return this.subTypeData === 'overal';
    }

    public get isMobile(): boolean {
        return this.deviceService.isMobile();
    }

    public get sportSupportExtraTables(): boolean {
        return this.sportConfig.displayType.halfTime.includes(this.sportName);
    }

    public get sportSupportExtra2Tables(): boolean {
        return this.sportConfig.displayType.uo.includes(this.sportName);
    }

    public onPlayersError(): void {
        this.playersError = true;
    }

    public onPlayersNum(num: number): void {
        if (num === 0) {
            this.playersError = true;
        }
    }

    public hasPlayerStats(): boolean {
        return !_.isUndefined(this.tableOb) && this.sportConfig.hasPlayerStats(this.sportName) && !this.playersError;
    }
}
