import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy } from '@angular/core';


import { SportConfigToken } from '@config/sport.config';

import { TranslateService } from '@ngx-translate/core';

import { OptaWidgetBase } from '../base.service';

declare const Opta: any;

@Component({
    selector: 'app-opta-expected-goals',
    templateUrl: './expected-goals.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptaWidgetExpectedGoalsComponent extends OptaWidgetBase implements AfterViewInit, OnDestroy {
    @Input()
        matchId: string | number | null | undefined;

    @Input()
        competitionId: string | number | null | undefined;

    @Input()
        seasonId: string | number | null | undefined;

    #headlineTrans = 'web.opta_expected_goals';
    private transHeadline = '';


    public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>,
        public translate: TranslateService) {
        super();
    }

    public ngAfterViewInit(): void {
        this.transHeadline = this.translate.instant(this.#headlineTrans);
        setTimeout(() => {
            const element = document.querySelector('#opta-expected-goals') as HTMLElement;

            if (element) {
                element.innerHTML = this.getHtml();
                const optaWidgetTags = document.querySelector('opta-widget[load="false"]');

                if (optaWidgetTags) {
                    optaWidgetTags.removeAttribute('load');
                    Opta.start();
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (Opta.widgets.ref_widget_excpected_goals) {
            Opta.widgets.ref_widget_excpected_goals.destroy(true);
        }
    }

    getHtml(): string {
        return `

        <opta-widget widget="expected_goals" widget_id="ref_widget_excpected_goals"
         competition="${this.competitionId}" season="${this.seasonId}" match="${this.matchId}" 
        template="race_chart" live="true" 
        show_match_header="false" show_crests="false" show_competition_name="false" 
        competition_naming="brief" show_date="false" date_format="dddd D MMMM YYYY" narrow_date_format="DD/MM/YY" 
        overview_stats="total_team_xg,total_shots,shots_on_target" xg_format="percent" show_shot_summary="true" 
        shot_summary_columns="minute,pattern_of_play,xg" show_own_goals="false" 
        show_info_tooltips="true" show_tooltips="true" 
        show_proportional_graph="true" order_by="xg_desc" team_naming="brief" player_naming="short_name" 
        show_logo="false" show_title="true" title="${this.transHeadline}" 
         breakpoints="550, 700" sport="football"></opta-widget>

            `;
    }
}
