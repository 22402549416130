import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy } from '@angular/core';


import { SportConfigToken } from '@config/sport.config';

import { TranslateService } from '@ngx-translate/core';

import { OptaWidgetBase } from '../base.service';

declare const Opta: any;

@Component({
    selector: 'app-opta-match-stats-team-graph-pro',
    templateUrl: './match-stats-team-graph-pro.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptaWidgetMatchStatsTeamProGraphComponent extends OptaWidgetBase implements AfterViewInit, OnDestroy {
    @Input()
    matchId: string | number | null | undefined;

    @Input()
    competitionId: string | number | null | undefined;

    @Input()
    seasonId: string | number | null | undefined;

      #headlineTrans = 'web.opta_match_stats_team_graph-pro';
      private transHeadline = '';


      public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>,
          public translate: TranslateService) {
          super();
      }

      public ngAfterViewInit(): void {
          this.transHeadline = this.translate.instant(this.#headlineTrans);
          setTimeout(() => {
              const element = document.querySelector('#opta-match-stats-team-graph-pro') as HTMLElement;

              if (element) {
                  element.innerHTML = this.getHtml();
                  const optaWidgetTags = document.querySelector('opta-widget[load="false"]');

                  if (optaWidgetTags) {
                      optaWidgetTags.removeAttribute('load');
                      Opta.start();
                  }
              }
          });
      }

      ngOnDestroy(): void {
          if (Opta.widgets.ref_widget_matchstats_pro) {
              Opta.widgets.ref_widget_matchstats_pro.destroy(true);
          }
      }

      getHtml(): string {
          return `

          <opta-widget widget="matchstats_pro" 
          competition="${this.competitionId}" season="${this.seasonId}" match="${this.matchId}" load="false"  
          template="team_graphs" widget_id="ref_widget_matchstats_pro" 
           live="true" navigation="tabs_more" show_timecontrols="true" default_nav="1" 
           show_match_header="false" show_score="true" show_timecontrols_buttons="true"
            show_halftime_score="false" show_crests="false" show_team_formation="true" show_competition_name="true" 
            graph_style="full" show_date="false" date_format="dddd D MMMM YYYY" narrow_date_format="DD/MM/YY" 
            show_social="false" show_var="true" rounding="1" competition_naming="full" team_naming="full" 
            show_live="true" show_logo="false"  title="${this.transHeadline}"  show_title="false" 
            breakpoints="400, 700" sport="football"></opta-widget>

          

          `;
      }
}
