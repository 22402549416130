<div class="box-placeholder">
    <div class="p-4">
        <!-- <span class="category text link"></span> -->
        <h4 class="text text--h4 line"></h4>
        <!-- <h4 class="text text--h4"></h4> -->
    </div>
    <hr class="hr" />
    <div class="image">
        <div class="embed-responsive embed-responsive-16by9"></div>
    </div>
    <hr />
    <div class="excerpt p-4">
        <div class="text line"></div>
        <div class="text line"></div>
        <div class="text"></div>
    </div>
</div>
