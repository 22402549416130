<ng-container *ngIf="banner">
    <a
        *ngIf="banner.url; else elseBlock"
        target="_blank"
        href="{{ banner.url }}?{{ utm() }}"
        [id]="bannerPositionId + ' | ' + banner.url"
        class="app-banner-main"
        (click)="click()"
    >
        <img
            src="{{ banner.path }}"
            class="banner"
            alt="{{ banner.altText }}"
            style="width: 100%"
            srcset="{{ banner.small_image }} 600w,
            {{ banner.medium_image }} 1024w,
            {{ banner.large_image }} 1600w"
        />
    </a>
    <ng-template #elseBlock>
        <img
            *ngIf="!banner.url"
            src="{{ banner.path }}"
            class="banner"
            alt="{{ banner.altText }}"
            style="width: 100%"
            srcset="{{ banner.small_image }} 600w,
            {{ banner.medium_image }} 1024w,
            {{ banner.large_image }} 1600w"
        />
    </ng-template>
</ng-container>
