<div class="tab-content match-summary live-timeline">
    <div
        *ngFor="let val of actions; trackBy: trackByIndex"
        class="tab-pane show active"
        id="tab-match-summary-1-content"
        role="tabpanel"
        aria-labelledby="tab-match-summary-1"
    >
        <h6
            *ngIf="!isUnknown"
            class="text-center text-uppercase match-summary__head dropdown-toggle"
            data-toggle="collapse"
            [attr.href]="'#collapseAction' + val[0]"
            role="button"
            aria-expanded="false"
            [attr.aria-controls]="'collapseAction' + val[0]"
        >
            {{ 'web.' + sportName + '_' + val[0] | lowercase | translate }}
        </h6>
        <div class="collapse show" [attr.id]="'collapseAction' + val[0]">
            <div
                *ngFor="let action of val[1]; trackBy: trackByIndex; last as isLastMain"
                [@newLine]
                class="row no-gutters border-top py-1 px-1 small"
            >
                <div *ngIf="isTypeUnknown(action.type)" class="col-12 font-weight-bold text-center">
                    <div class="row">
                        <div class="col-12 d-flex align-self-center live-timeline__data">
                            <div class="text-center">
                                <img [src]="action.image + '?color=' + color" height="12" *ngIf="action.image" />
                                &nbsp;&nbsp;
                                <strong>{{ action.text }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="!isTypeUnknown(action.type)">
                    <div class="col-4 d-flex justify-content-start">
                        <ng-container *ngIf="action.competitor == 'home'; else home">
                            <div class="text-center d-flex flex-column flex-sm-row px-1 align-items-stretch">
                                <div *ngIf="action.match_clock || action.match_time">
                                    <span>{{ action.match_clock || action.match_time }}</span>
                                </div>
                                <div class="pl-sm-1">
                                    <img [src]="action.image + '?color=' + color" height="12" *ngIf="action.image" />
                                </div>

                                <ng-container
                                    *ngIf="action.suspension_minutes && action.suspensionPlayers.length === 0"
                                >
                                    <div class="app-no-wrap">
                                        &nbsp; <strong class="suspension-minute">{{ action.suspension_minutes }}</strong
                                        >&nbsp;
                                    </div>
                                </ng-container>
                            </div>

                            <div class="d-flex flex-column live-timeline__data">
                                <ng-container
                                    *ngFor="let player of action.mainPlayers; trackBy: trackByIndex; first as isFirst"
                                >
                                    <div>
                                        <span [class.text-bold]="isFirst" *ngIf="!player.id">
                                            {{ player.name }}
                                        </span>

                                        <a
                                            *ngIf="player.id"
                                            [routerLink]="['/page/sport/player', sportName + '-' + sportId, player.id]"
                                            class="text-reset"
                                            [class.text-bold]="isFirst"
                                        >
                                            {{ player.name }}</a
                                        >
                                    </div>
                                </ng-container>
                                <ng-container *ngFor="let player of action.suspensionPlayers; trackBy: trackByIndex">
                                    <div class="app-no-wrap">
                                        <span class="text-bold" *ngIf="!player.id">
                                            {{ player.name }}
                                        </span>
                                        <a
                                            *ngIf="player.id"
                                            [routerLink]="['/page/sport/player', sportName + '-' + sportId, player.id]"
                                            class="text-reset text-bold"
                                        >
                                            {{ player.name }}</a
                                        >
                                        <strong *ngIf="action.suspension_minutes" class="suspension-minute"
                                            >{{ action.suspension_minutes }}
                                        </strong>
                                    </div>
                                </ng-container>
                                <ng-container
                                    *ngFor="
                                        let player of action.assistsPlayers;
                                        trackBy: trackByIndex;
                                        last as isLast;
                                        first as isFirst
                                    "
                                >
                                    <div>
                                        <span *ngIf="!player.id">{{ player.name }}</span>
                                        <a
                                            *ngIf="player.id"
                                            [routerLink]="['/page/sport/player', sportName + '-' + sportId, player.id]"
                                            class="text-reset"
                                        >
                                            {{ player.name }}</a
                                        >
                                    </div>
                                </ng-container>
                                <!-- Esport Media players-->
                                <ng-container *ngIf="action.mainPlayers.length === 0 && action.players.length === 0">
                                    <ng-container
                                        *ngFor="
                                            let player of action.players_json;
                                            trackBy: trackByIndex;
                                            last as isLast;
                                            first as isFirst
                                        "
                                    >
                                        <div>
                                            <span>
                                                <span [class.text-bold]="isFirst">{{ player.name }}</span
                                                ><ng-container *ngIf="!isLast">,</ng-container>
                                            </span>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <!-- Fouled players-->
                                <ng-container
                                    *ngFor="let player of action.fouledPlayers; trackBy: trackByIndex; last as isLast"
                                >
                                    <div>
                                        <span>
                                            {{ 'web.player_who_' + player.type | translate }} <br />({{
                                                player.name
                                            }})<ng-container *ngIf="!isLast">,</ng-container>
                                        </span>
                                    </div>
                                </ng-container>
                                <!-- Substitution players-->
                                <ng-container
                                    *ngIf="action.substitutionPlayers?.in && action.substitutionPlayers?.in.length > 0"
                                >
                                    <div>
                                        <ng-container
                                            *ngFor="let player of action.substitutionPlayers.in; trackBy: trackByIndex"
                                        >
                                            <span class="text-bold"> {{ player.name }} </span>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        action.substitutionPlayers?.out && action.substitutionPlayers?.out.length > 0
                                    "
                                >
                                    <ng-container
                                        *ngFor="let player of action.substitutionPlayers.out; trackBy: trackByIndex"
                                    >
                                        <div>
                                            <span> {{ player.name }}</span>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="action.red_balls_left">
                                    <div>
                                        <span class="serve serve--snooker-timeline ml-0">{{
                                            action.red_balls_left
                                        }}</span>
                                        <span class="red_balls_left">
                                            {{ action.red_balls_left }} {{ 'web.red_balls_left' | translate }}
                                        </span>
                                    </div>
                                </ng-container>
                                <div *ngIf="isTypeText(action.type)" class="order-sm-8">
                                    <span class="text-less-emph">
                                        {{ 'web.' + sportName + '_' + action.type | translate }}
                                        <ng-container *ngIf="action.suspension_reason">
                                            -
                                            {{
                                                'web.suspension_' + action.suspension_reason | lowercase | translate
                                            }}</ng-container
                                        >
                                    </span>
                                </div>
                                <div *ngIf="action.zone && action.method !== 'penalty'" class="order-sm-8">
                                    <span class="text-less-emph">
                                        <svg class="icon icon--lg d-none d-lg-inline">
                                            <use href="#icon-net" />
                                        </svg>
                                        {{ 'web.' + sportName + '_' + action.zone | translate }}
                                        <ng-container *ngIf="action.shot_type">
                                            ({{ 'web.' + sportName + '_' + action.shot_type | translate }})
                                        </ng-container>
                                    </span>
                                </div>
                                <div class="order-sm-7">
                                    <span
                                        class="text-less-emph"
                                        *ngIf="isHandball(sportName) && action.method === 'penalty'"
                                    >
                                        {{ 'web.' + sportName + '_penalty' | translate }}
                                    </span>
                                </div>
                            </div>
                        </ng-container>

                        <ng-template #home> &nbsp; </ng-template>
                    </div>
                    <div class="col-4 font-weight-bold text-center">
                        <div class="row">
                            <div
                                class="col-3 d-flex align-self-center"
                                *ngIf="action.substitutionPlayers?.out.length === 0"
                            >
                                <ng-container *ngIf="action.homeScoreDiff > 1">
                                    <div>
                                        <strong class="emph-text-node">+{{ action.homeScoreDiff }}</strong>
                                    </div>
                                </ng-container>
                                &nbsp;
                            </div>
                            <div class="app-no-wrap col" [class.col-6]="action.substitutionPlayers?.out.length === 0">
                                <span
                                    class="font-weight-normal"
                                    *ngIf="
                                        (action.home_score !== null && action.away_score !== null) ||
                                            action.frame_score;
                                        else text
                                    "
                                >
                                    <ng-container *ngIf="action.frame_score; else normalScore">
                                        <span
                                            class="font-1-1x"
                                            [class.text-bold]="action.competitor == 'home'"
                                            title="{{ 'web.snooker_frame_score' | translate }}"
                                            >{{ action.frameScoreHome }}</span
                                        >
                                        :
                                        <span
                                            class="font-1-1x"
                                            [class.text-bold]="action.competitor == 'away'"
                                            title="{{ 'web.snooker_frame_score' | translate }}"
                                            >{{ action.frameScoreAway }}</span
                                        >
                                        <ng-container *ngIf="action.break">
                                            <div title="{{ 'web.snooker_break' | translate }}">{{ action.break }}</div>
                                        </ng-container>
                                    </ng-container>
                                    <ng-template #normalScore>
                                        <span
                                            class="font-1-2x"
                                            [class.emph-text-node]="action.competitor == 'home'"
                                            [class.text-bold]="action.competitor == 'home'"
                                            >{{ action.home_score }}</span
                                        >
                                        :
                                        <span
                                            class="font-1-2x"
                                            [class.emph-text-node]="action.competitor == 'away'"
                                            [class.text-bold]="action.competitor == 'away'"
                                            >{{ action.away_score }}</span
                                        >
                                    </ng-template>
                                </span>
                                <ng-template #text>
                                    &nbsp;
                                    <!-- <span>
                                {{ 'web.' + sportName + '.' + action.type | translate }}
                            </span> -->
                                    <!-- Substition players-->
                                    <ng-container
                                        *ngIf="
                                            action.substitutionPlayers?.out.length > 0 &&
                                            action.substitutionPlayers?.in.length > 0 &&
                                            !action.competitor
                                        "
                                    >
                                        &nbsp;<img
                                            [src]="action.image + '?color=' + color"
                                            height="13"
                                            *ngIf="action.image"
                                        />&nbsp;
                                        <ng-container>
                                            <strong>
                                                <ng-container
                                                    *ngFor="
                                                        let player of action.substitutionPlayers.in;
                                                        trackBy: trackByIndex
                                                    "
                                                >
                                                    <span class="text-bold">
                                                        {{ player.name }}
                                                    </span>
                                                </ng-container>
                                            </strong>
                                        </ng-container>
                                        <ng-container>
                                            <ng-container
                                                *ngFor="
                                                    let player of action.substitutionPlayers.out;
                                                    trackBy: trackByIndex
                                                "
                                            >
                                                <div>
                                                    {{ player.name }}
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-template>
                            </div>
                            <div
                                class="col-3 d-flex align-self-center"
                                *ngIf="action.substitutionPlayers?.out.length === 0"
                            >
                                &nbsp;

                                <ng-container *ngIf="action.awayScoreDiff > 1">
                                    <div>
                                        <strong class="emph-text-node">+{{ action.awayScoreDiff }}</strong>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-right d-flex justify-content-end">
                        <ng-container *ngIf="action.competitor == 'away'; else away">
                            <div class="d-flex flex-column live-timeline__data">
                                <ng-container
                                    *ngFor="let player of action.mainPlayers; trackBy: trackByIndex; first as isFirst"
                                >
                                    <div class="order-sm-4">
                                        <span [class.text-bold]="isFirst" *ngIf="!player.id">
                                            {{ player.name }}
                                        </span>

                                        <a
                                            *ngIf="player.id"
                                            [routerLink]="['/page/sport/player', sportName + '-' + sportId, player.id]"
                                            class="text-reset"
                                            [class.text-bold]="isFirst"
                                        >
                                            {{ player.name }}</a
                                        >
                                    </div>
                                </ng-container>

                                <ng-container *ngFor="let player of action.suspensionPlayers; trackBy: trackByIndex">
                                    <div class="app-no-wrap">
                                        <strong *ngIf="action.suspension_minutes" class="suspension-minute"
                                            >{{ action.suspension_minutes }}
                                        </strong>
                                        <span class="text-bold" *ngIf="!player.id">
                                            {{ player.name }}
                                        </span>
                                        <a
                                            *ngIf="player.id"
                                            [routerLink]="['/page/sport/player', sportName + '-' + sportId, player.id]"
                                            class="text-reset text-bold"
                                        >
                                            {{ player.name }}</a
                                        >
                                    </div>
                                </ng-container>
                                <ng-container
                                    *ngFor="
                                        let player of action.assistsPlayers;
                                        trackBy: trackByIndex;
                                        last as isLast;
                                        first as isFirst
                                    "
                                >
                                    <div class="order-sm-5">
                                        <span *ngIf="!player.id">{{ player.name }}</span>
                                        <a
                                            *ngIf="player.id"
                                            [routerLink]="['/page/sport/player', sportName + '-' + sportId, player.id]"
                                            class="text-reset"
                                        >
                                            {{ player.name }}</a
                                        >
                                    </div>
                                </ng-container>
                                <!-- Esport Media players-->
                                <ng-container *ngIf="action.mainPlayers.length === 0 && action.players.length === 0">
                                    <ng-container
                                        *ngFor="
                                            let player of action.players_json;
                                            trackBy: trackByIndex;
                                            last as isLast;
                                            first as isFirst
                                        "
                                    >
                                        <div>
                                            <span>
                                                <span [class.text-bold]="isFirst">{{ player.name }}</span
                                                ><ng-container *ngIf="!isLast">,</ng-container>
                                            </span>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <!-- Fouled players-->
                                <ng-container
                                    *ngFor="let player of action.fouledPlayers; trackBy: trackByIndex; last as isLast"
                                >
                                    <div>
                                        <span>
                                            {{ 'web.player_who_' + player.type | translate }} <br />({{
                                                player.name
                                            }})<ng-container *ngIf="!isLast">,</ng-container>
                                        </span>
                                    </div>
                                </ng-container>
                                <!-- Substition players-->

                                <ng-container
                                    *ngIf="action.substitutionPlayers?.in && action.substitutionPlayers?.in.length > 0"
                                >
                                    <ng-container
                                        *ngFor="let player of action.substitutionPlayers.in; trackBy: trackByIndex"
                                    >
                                        <div>
                                            <span class="text-bold">
                                                {{ player.name }}
                                            </span>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        action.substitutionPlayers?.out && action.substitutionPlayers?.out.length > 0
                                    "
                                >
                                    <ng-container
                                        *ngFor="let player of action.substitutionPlayers.out; trackBy: trackByIndex"
                                    >
                                        <div>
                                            <span>
                                                {{ player.name }}
                                            </span>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="action.red_balls_left">
                                    <div>
                                        <span class="red_balls_left">
                                            {{ 'web.red_balls_left' | translate }} {{ action.red_balls_left }}
                                        </span>
                                        <span class="serve serve--snooker-timeline mr-0">{{
                                            action.red_balls_left
                                        }}</span>
                                    </div>
                                </ng-container>
                                <div *ngIf="isTypeText(action.type)" class="order-sm-8">
                                    <span class="text-less-emph">
                                        {{ 'web.' + sportName + '_' + action.type | translate }}
                                        <ng-container *ngIf="action.suspension_reason">
                                            -
                                            {{
                                                'web.suspension_' + action.suspension_reason | lowercase | translate
                                            }}</ng-container
                                        >
                                    </span>
                                </div>
                                <div *ngIf="action.zone && action.method !== 'penalty'" class="order-sm-8">
                                    <span class="text-less-emph">
                                        {{ 'web.' + sportName + '_' + action.zone | translate }}
                                        <ng-container *ngIf="action.shot_type">
                                            ({{ 'web.' + sportName + '_' + action.shot_type | translate }})
                                        </ng-container>
                                        <svg class="icon icon--lg d-none d-lg-inline">
                                            <use href="#icon-net" />
                                        </svg>
                                    </span>
                                </div>

                                <div class="order-sm-7">
                                    <span
                                        class="text-less-emph"
                                        *ngIf="isHandball(sportName) && action.method === 'penalty'"
                                    >
                                        {{ 'web.' + sportName + '_penalty' | translate }}
                                    </span>
                                </div>
                            </div>

                            <div class="text-center d-flex flex-column flex-sm-row px-1 align-items-stretch">
                                <div *ngIf="action.match_clock || action.match_time" class="order-sm-1">
                                    <span>{{ action.match_clock || action.match_time }}</span>
                                </div>
                                <ng-container
                                    *ngIf="action.suspension_minutes && action.suspensionPlayers.length === 0"
                                >
                                    <div class="app-no-wrap">
                                        &nbsp; <strong class="suspension-minute">{{ action.suspension_minutes }}</strong
                                        >&nbsp;
                                    </div>
                                </ng-container>
                                <div class="pr-sm-1">
                                    <img
                                        [src]="action.image + '?color=' + color"
                                        class="order-sm-0"
                                        height="12"
                                        *ngIf="action.image"
                                    />
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #away> &nbsp; </ng-template>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
