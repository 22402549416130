<ng-container *ngIf="actions != null">
    <div class="tab-content match-summary">
        <div
            *ngFor="let period of actions; trackBy: trackByFn2"
            class="tab-pane show active"
            id="tab-match-summary-1-content"
            role="tabpanel"
            aria-labelledby="tab-match-summary-1"
        >
            <h6 class="text-center text-uppercase match-summary__head">{{ period.period.name }}</h6>
            <div
                *ngFor="let action of period.actions; trackBy: trackByFn2"
                [@newLine]
                class="row no-gutters border-top py-1 px-3 small"
            >
                <div class="col-3 font-weight-bold">
                    <ng-container *ngIf="action.team == 'home'; else home">
                        <img
                            [src]="action.image + '?color=' + color"
                            height="12"
                            *ngIf="action.image && action.image.length > 0 && action.team == 'home'"
                            alt="{{ action.name }}"
                        />
                        &nbsp;
                        {{ action.name }}
                    </ng-container>
                    <ng-template #home> &nbsp; </ng-template>
                </div>
                <div class="col-6 font-weight-bold text-center">
                    <div class="row">
                        <div class="col-3">
                            <ng-container *ngIf="action.team == 'home'">
                                <strong class="emph-text-node">+{{ action.points }}</strong>
                            </ng-container>
                            &nbsp;
                        </div>
                        <div class="col-6">
                            <span class="font-weight-normal">
                                <span class="font-1-2x" [innerHTML]="score(action.actual_score, action.team)"></span>
                            </span>
                        </div>
                        <div class="col-3">
                            &nbsp;
                            <ng-container *ngIf="action.team == 'away'">
                                <strong class="emph-text-node">{{ action.points }}+</strong>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-3 font-weight-bold text-right">
                    <ng-container *ngIf="action.team == 'away'; else away">
                        {{ action.name }} &nbsp;
                        <img
                            [src]="action.image + '?color=' + color"
                            height="12"
                            *ngIf="action.image && action.image.length > 0 && action.team == 'away'"
                            alt="{{ action.name }}"
                        />
                    </ng-container>
                    <ng-template #away> &nbsp; </ng-template>
                </div>
            </div>
        </div>
    </div>
</ng-container>
