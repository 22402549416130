<ng-content select="h4.head"></ng-content>
<ul class="nav nav-pills bg-light px-2 app-league-table-container">
    <li class="nav-item">
        <a
            class="nav-link active"
            id="tab-match-h2h-1"
            data-toggle="tab"
            (click)="subActive('ha')"
            href="#league_total_{{ i }}"
            role="tab"
            aria-controls="tab-match-h2h-1-content"
            aria-selected="true"
            >{{ 'web.league_table' | translate }}</a
        >
    </li>
    <li class="nav-item">
        <a
            *ngIf="sportSupportExtra2Tables"
            class="nav-link"
            id="tab-match-h2h-6"
            data-toggle="tab"
            (click)="subActive('ha', 'under_over')"
            href="#league_total_{{ i }}"
            role="tab"
            aria-controls="tab-match-h2h-1-content"
            aria-selected="true"
            >{{ 'web.league_table_uo' | translate }}</a
        >
    </li>
    <li class="nav-item">
        <a
            *ngIf="sportSupportExtraTables && !isNoHalf"
            class="nav-link"
            id="tab-match-h2h-7"
            data-toggle="tab"
            (click)="subActive('ha', 'half_full')"
            href="#league_total_{{ i }}"
            role="tab"
            aria-controls="tab-match-h2h-1-content"
            aria-selected="true"
            >{{ 'web.league_table_half_full' | translate }}</a
        >
    </li>
    <!-- <li class="nav-item">
                <a class="nav-link " id="tab-match-h2h-1" data-toggle="tab" href="#league_form_{{i}}" role="tab" aria-controls="tab-match-h2h-1-content" aria-selected="true">{{ 'web.table_total_form' | translate }}</a>
            </li> -->

    <li class="nav-item" *ngIf="tableOb.data.matrix && tableOb.data.matrix.length > 0">
        <a
            class="nav-link"
            data-cy="app-tab-match-matrix"
            id="tab-match-h2h-4"
            data-toggle="tab"
            (click)="subActive(false)"
            href="#league_matrix_{{ i }}"
            role="tab"
            aria-controls="tab-match-h2h-4-content"
            aria-selected="false"
            >{{ 'web.table_matrix_head' | translate }}</a
        >
    </li>

    <li class="nav-item" *ngIf="hasPlayerStats()">
        <a
            class="nav-link"
            id="tab-match-h2h-5"
            data-toggle="tab"
            (click)="subActive(false)"
            href="#top_scorers_{{ i }}"
            role="tab"
            aria-controls="tab-match-h2h-5-content"
            aria-selected="false"
            >{{ 'web.top_scorers' | translate }}</a
        >
    </li>
</ul>

<ul class="nav nav-pills nav-pills-sub bg-light-sub px-2 table-subnav-level-2" *ngIf="subType" id="subNav_{{ i }}">
    <li class="nav-item">
        <a
            class="nav-link active"
            id="tab-match-h2h-1"
            data-toggle="tab"
            (click)="subNav = 'total'"
            href="#league_total_{{ i }}"
            role="tab"
            aria-controls="tab-match-h2h-1-content"
            aria-selected="true"
            >{{ 'web.table_total_head' | translate }}</a
        >
    </li>
    <li
        class="nav-item"
        *ngIf="tableOb.data.home && tableOb.data.home.length > 0 && tableOb.data.home[0].place !== null"
    >
        <a
            class="nav-link"
            data-cy="app-tab-match-home"
            id="tab-match-h2h-2"
            (click)="subNav = 'home'"
            data-toggle="tab"
            href="#league_home_{{ i }}"
            role="tab"
            aria-controls="tab-match-h2h-2-content"
            aria-selected="false"
            >{{ 'web.table_home_head' | translate }}</a
        >
    </li>
    <li
        class="nav-item"
        *ngIf="tableOb.data.away && tableOb.data.away.length > 0 && tableOb.data.away[0].place !== null"
    >
        <a
            class="nav-link"
            data-cy="app-tab-match-away"
            (click)="subNav = 'away'"
            id="tab-match-h2h-3"
            data-toggle="tab"
            href="#league_away_{{ i }}"
            role="tab"
            aria-controls="tab-match-h2h-3-content"
            aria-selected="false"
            >{{ 'web.table_away_head' | translate }}</a
        >
    </li>
</ul>

<div class="tab-content" id="app-table-tab-content">
    <div
        class="tab-pane show active"
        id="league_total_{{ i }}"
        *ngIf="subNav === 'total'"
        role="tabpanel"
        aria-labelledby="tab-match-h2h-1"
    >
        <app-league-table-table
            [sportId]="sportId"
            [sportName]="sportName"
            [data]="tableOb.data.total"
            [columns]="tableOb.columns"
            [form]="form"
            [dataType]="subTypeData"
            [participantHome]="participantHome"
            [participantAway]="participantAway"
            [isLive]="isLive"
        ></app-league-table-table>
    </div>
    <!-- <div class="tab-pane show" id="league_form_{{i}}" role="tabpanel" aria-labelledby="tab-match-h2h-1">

                <app-league-table-table [sportId]="sportId"  [sportName]="sportName" [data]="tableOb.data.total" [columns]="tableOb.columns" [form]="true" [participantHome]="participantHome" [participantAway]="participantAway"></app-league-table-table>


            </div> -->

    <div
        class="tab-pane show"
        id="league_home_{{ i }}"
        *ngIf="tableOb.data.home && tableOb.data.home.length > 0 && subNav === 'home'"
        role="tabpanel"
        aria-labelledby="tab-match-h2h-1"
    >
        <!--Home start-->
        <app-league-table-table
            [sportId]="sportId"
            [sportName]="sportName"
            [data]="tableOb.data.home"
            [columns]="tableOb.columns"
            [dataType]="subTypeData"
            [participantHome]="participantHome"
            [participantAway]="participantAway"
            [isLive]="isLive"
        ></app-league-table-table>
        <!--Home end-->
    </div>

    <div
        class="tab-pane show"
        id="league_away_{{ i }}"
        *ngIf="tableOb.data.away && tableOb.data.away.length > 0 && subNav === 'away'"
        role="tabpanel"
        aria-labelledby="tab-match-h2h-1"
    >
        <!--Away start-->
        <app-league-table-table
            [sportId]="sportId"
            [sportName]="sportName"
            [data]="tableOb.data.away"
            [columns]="tableOb.columns"
            [dataType]="subTypeData"
            [participantHome]="participantHome"
            [participantAway]="participantAway"
            [isLive]="isLive"
        ></app-league-table-table>
        <!--Away end-->
    </div>

    <div
        class="tab-pane show"
        id="league_matrix_{{ i }}"
        *ngIf="tableOb.data.matrix && tableOb.data.matrix.length > 0"
        role="tabpanel"
        aria-labelledby="tab-match-h2h-1"
    >
        <!--Matrix start-->
        <app-matrix-table
            [sportId]="sportId"
            [sportName]="sportName"
            [data]="tableOb.data.matrix"
            [dataTotal]="tableOb.data.total"
            [participantHome]="participantHome"
            [participantAway]="participantAway"
        ></app-matrix-table>
        <!--Matrix end-->
    </div>

    <div
        class="tab-pane show"
        id="top_scorers_{{ i }}"
        *ngIf="hasPlayerStats()"
        role="tabpanel"
        aria-labelledby="tab-match-h2h-1"
    >
        <app-player-stats
            class="d-block mt-1"
            [subTournamentId]="subTournamentId"
            (onError)="onPlayersError($event)"
            (onPlayersNum)="onPlayersNum($event)"
            [seasonId]="seasonId"
            view="match-detail"
            [sportId]="sportId"
            [sportName]="sportName"
        ></app-player-stats>
        <!--Matrix end-->
    </div>
</div>

<ul class="table-info-footer">
    <li *ngIf="tableOb.comment" class="info">
        {{ tableOb.comment }}
    </li>
</ul>
<ul class="table-info-footer" *ngIf="showFooterInfo">
    <li *ngIf="tableOb.points_win || tableOb.points_win === 0" class="win">
        <div class="d-flex justify-content-start">
            <div class="--child">{{ 'web.table_points_win' | translate }}:</div>
            <div>
                <strong>{{ tableOb.points_win }}</strong>
            </div>
        </div>
    </li>
    <li *ngIf="tableOb.points_loss || tableOb.points_loss === 0" class="loss">
        <div class="d-flex justify-content-start">
            <div class="--child">{{ 'web.table_points_loss' | translate }}:</div>
            <div>
                <strong>
                    <strong>{{ tableOb.points_loss }}</strong></strong
                >
            </div>
        </div>
    </li>
    <li *ngIf="tableOb.points_draw || tableOb.points_draw === 0" class="draw">
        <div class="d-flex justify-content-start">
            <div class="--child">{{ 'web.table_points_draw' | translate }}:</div>
            <div>
                <strong>
                    <strong>{{ tableOb.points_draw }}</strong></strong
                >
            </div>
        </div>
    </li>

    <li *ngIf="tableOb.tie_break_rule" class="info">
        {{ tableOb.tie_break_rule | translate }}
    </li>
</ul>
