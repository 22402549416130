<ng-container *ngIf="playerStats | async as playerStatsSubtournaments; else elseTable">
    <div class="playerstats-wrap" data-cy="playerstats-wrap">
        <div *ngFor="let subtournament of playerStatsSubtournaments | keyvalue">
            <h3 *ngIf="playerStatsSubtournaments.length > 1">
                <ng-template *ngIf="subtournament.value.subtournament_name; else noName">
                    {{ subtournament.value.subtournament_name }}
                </ng-template>
                <ng-template #noName>
                    {{ 'web.overview' | translate }}
                </ng-template>
            </h3>

            <div class="match-info__table small pb-2 text-center">
                <div class="match-info__table__row player-record bg-dark text-white">
                    <div class="match-info__table__cell text-left font-weight-bold"></div>
                    <div class="match-info__table__cell text-left font-weight-bold"></div>
                    <div class="match-info__table__cell text-left font-weight-bold">
                        {{ 'web.playerstats_player_name' | translate }}
                    </div>
                    <ng-container *ngFor="let columnName of subtournament.value.showColumns; index as ind">
                        <div
                            *ngIf="
                                view !== 'match-detail' ||
                                ind === 0 ||
                                columnName === 'total_matches' ||
                                columnName === 'points' ||
                                columnName === 'team_total_goals'
                            "
                            class="match-info__table__cell text-left font-weight-bold text-center"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="{{ 'web.playerstats_tooltip_' + columnName | translate }}"
                        >
                            {{ 'web.playerstats_' + columnName | translate }}
                        </div>
                    </ng-container>
                </div>

                <div
                    class="match-info__table__row app-data-row-data player-record"
                    *ngFor="let player of subtournament.value.players | slice: 0:sliceNum; let i = index"
                >
                    <div class="match-info__table__cell text-left font-weight-bold border-top">{{ i + 1 }}.</div>
                    <div class="match-info__table__cell text-left font-weight-bold border-top text-center">
                        <img
                            *ngIf="player.participant_logo !== null"
                            src="{{ player.participant_logo | responsive: 'participant':'small' }}"
                            height="18"
                            width="18"
                            data-toggle="tooltip"
                            data-placement="top"
                            class="app-participant-logo"
                            title="{{ player.participant_name }}"
                        />
                    </div>
                    <div class="match-info__table__cell text-left font-weight-bold border-top">
                        <a
                            [routerLink]="['/page/sport/player', sportName + '-' + sportId, player.id]"
                            [queryParams]="{ season: seasonId }"
                            class="text-reset"
                            >{{ player.player_name }}</a
                        >
                    </div>

                    <ng-container *ngFor="let columnName of subtournament.value.showColumns; index as i2">
                        <ng-container *ngIf="columnName !== 'team_total_goals'">
                            <div
                                class="match-info__table__cell text-left border-top text-center"
                                *ngIf="
                                    view !== 'match-detail' ||
                                    i2 === 0 ||
                                    columnName === 'total_matches' ||
                                    columnName === 'points'
                                "
                            >
                                <span
                                    *ngIf="(i === 0 || i === 1) && i2 === 0 && player[columnName]"
                                    class="badge badge-success badge--lg"
                                >
                                    {{ player[columnName] ? player[columnName] : '-' }}</span
                                >
                                <span
                                    *ngIf="i === 2 && i2 === 0 && player[columnName]"
                                    class="badge badge-success--light badge--lg"
                                >
                                    {{ player[columnName] ? player[columnName] : '-' }}</span
                                >
                                <span *ngIf="i > 2 || i2 !== 0 || !player[columnName]">
                                    {{ player[columnName] ? player[columnName] : '-' }}</span
                                >
                            </div>
                        </ng-container>
                        <ng-container *ngIf="columnName == 'team_total_goals'">
                            <div class="match-info__table__cell text-left border-top text-center">
                                <div class="player-stats-bar">
                                    <div
                                        class="player-stats-bar__player"
                                        [style.width]="getGoalPrct(player.goals_scored, player[columnName])"
                                    >
                                        {{ player.goals_scored ? player.goals_scored : '-' }}
                                    </div>
                                    {{ player[columnName] }}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #elseTable>
    <app-placeholder-table></app-placeholder-table>
</ng-template>
