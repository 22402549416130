/**
 *  Animation for match actions
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { style, animate, transition } from '@angular/animations';

export const MATCH_ACTION_ANIMATION = [
    transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate(350, style({ transform: 'translateY(0%)', opacity: 1 })),
    ]),
];
