/* eslint-disable @typescript-eslint/naming-convention */
/**
 *  Component authentificator base on permissions
 *  Should be on every page
 *  @author Livescore <it@score-stats.com>
 *  @copyright 2025 livescore
 */

import { Component, OnInit, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { UserState } from 'src/app/store/states/user.state';


import { Observable } from 'rxjs';


import { BaseComponent } from '../../base.component';

import { Permissions, type PermissionsType } from '@/interfaces/user.interface';

@Component({
    selector: 'app-auth-container',
    templateUrl: './auth-container.component.html',
})
export class AuthContainerComponent extends BaseComponent implements OnInit {
    @Select(UserState.isLoggedIn) public isLoggedIn$: Observable<boolean>;
    @Select(UserState.permissions) public $permissions: Observable<Permissions[]>;


    @Input()
    public authOff: boolean = false;

    @Input()
    public permission: PermissionsType;

    public isLoggedIn: boolean = false;

    public grantRights: Permissions[];


    public constructor(


    ) {
        super();
    }

    public ngOnInit(): void {
        this.isLoggedIn$.subscribe((val): void => {
            this.isLoggedIn = val;
        });
        this.$permissions.subscribe((val): void => {
            this.grantRights = val;
        });
    }

    get classMade(): {[key: string]: any} {
        if (this.permission === 'tracker') {
            return { '--tracker': true };
        }

        if (this.permission === 'betting_widgets') {
            return { '--betting': true };
        }

        if (this.permission === 'stream') {
            return { '--video': true };
        }

        if (this.permission === 'stats') {
            return { '--stats': true };
        }

        return { '--heatmap': true };
    }

    get hasPermission(): boolean {
        const ob = this.persmissionObject;

        if (ob && ob.isValid === true) {
            return true;
        }
        return false;
    }

    get persmissionObject(): Permissions | undefined {
        if (!this.grantRights) {
            return undefined;
        }
        return this.grantRights.find((p: Permissions) => p.permission === this.permission);
    }

    get textVal(): string {
        if (this.isLoggedIn && !this.hasPermission) {
            return this.persmissionObject?.permission_name ?? 'web.private_area';
        }
        return 'web.permission_failed';
    }
}
