<iframe
    [src]="url | safe"
    frameborder="0"
    allowfullscreen="true"
    scrolling="no"
    class="stream-video-frameX"
    [ngStyle]="currentStyles"
    width="100%"
    height="300"
></iframe>
